import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import auth from '../../utils/auth';
//import request from '../../utils/request';
import { makeStyles, withStyles } from '@mui/styles';

import cookie from 'react-cookies';
import i18n from 'i18next';
import ActivityUrl from './ActivityUrl.js';
//import CalendarTab from './CalendarTab.js';

import { Box, Typography, FormGroup, Grid, Switch } from '@mui/material';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    //    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const Activity = props => {
  const classes = useStyles();
  const activityId =
    props.match.params.activityId > 7000000000
      ? props.match.params.activityId
      : 0;

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const [wkgSelect, setWkgSelect] = useState(false);
  const [locale, setLocale] = useState('en');

  const handleChangeWkg = event => {
    let ch = Boolean(wkgSelect);
    setWkgSelect(!ch);

    if (!ch === true) {
      cookie.save('wkg_select', 1, { path: '/' });
    } else {
      cookie.save('wkg_select', 0, { path: '/' });
    }
  };

  const WkgSwitch = withStyles({
    switchBase: {
      color: '#4db6ac',
      '&$checked': {
        color: '#f06292'
      },
      '&$checked + $track': {
        backgroundColor: '#ffabc7'
      }
    },
    checked: {},
    track: {
      backgroundColor: '#a0d8d3'
    }
  })(Switch);

  const [dashboardData, setDashboardData] = useState(
    auth.getDashboardInfo()
      ? auth.getDashboardInfo()
      : {
          rank_percent: 0,
          rank_array: null,
          category: 'R',
          race: 'Race',
          hour_record: 0,
          ftp: 0,
          wPrime: 15000,
          weight: 10,
          forte: '',
          rider_type: null,
          rider_ability: null,
          pdcData: null,
          powerzoneData: { powerzone_activities: '' },
          activityData: null,
          yScale: 1000,
          lastTs: 0
        }
  );

  //const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  useEffect(() => {
    let wkg_select = cookie.load('wkg_select');
    if (typeof wkg_select === 'undefined') {
      cookie.save('wkg_select', 0, { path: '/' });
      wkg_select = 0;
    } else {
      if (wkg_select == 1) {
        setWkgSelect(true);
      } else {
        setWkgSelect(false);
      }
    }

    if (language === 'en') {
      setLocale('en-US');
    } else if (language === 'ko') {
      setLocale('kr-KR');
    } else if (language === 'jp') {
      setLocale('ja-JA');
    } else {
      setLocale('en-US');
    }
  }, [language]);

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.allActivity')}
          <FormGroup
            style={{
              float: 'right',
              height: '20px',
              display: 'inline',
              margin: '-3px 0 20px 10px'
            }}>
            <Grid component="label" container alignItems="center" spacing={0}>
              <Grid item style={{ fontSize: '12px', color: '#aaaaaa' }}>
                Watts
              </Grid>
              <Grid item>
                <WkgSwitch
                  checked={wkgSelect}
                  onChange={handleChangeWkg}
                  name="wkgSelect"
                />
              </Grid>
              <Grid item style={{ fontSize: '12px', color: '#aaaaaa' }}>
                W/kg
              </Grid>
            </Grid>
          </FormGroup>
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      <ActivityUrl
        activityId={activityId}
        dashboardData={dashboardData}
        wkgSelect={wkgSelect}
        locale={locale}
      />
    </div>
  );
};

Activity.propTypes = {
  history: PropTypes.object
};

export default withRouter(Activity);
