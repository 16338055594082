import * as React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { imageSize } from 'utils/helper';
import { getImageUrl } from 'utils/getImageUrl';

function Carousel({ data }) {
  const classes = useStyles();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box className={classes.carsouselContainer}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        className={classes.swipeableViewContainer}>
        {data.map((image, index) => (
          <div key={image.originId} className={classes.wrapImage}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                className={classes.image}
                src={getImageUrl(image.images, imageSize.LG)}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      {maxSteps > 1 ? (
        <Box className={classes.buttonContainer}>
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight
              // style={{ fill: activeStep === 0 ? '#cbd5e1' : '#4db6ac' }}
              />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft
              // style={{
              //   fill: activeStep === maxSteps - 1 ? '#cbd5e1' : '#4db6ac'
              // }}
              />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  carsouselContainer: { position: 'relative' },
  swipeableViewContainer: {
    '* > div': { alignItems: 'center' }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: '50%',
    width: '100%',
    transform: 'translateY(-50%)'
  },
  wrapImage: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '360px',
    overflow: 'hidden',
    height: '100%'
  },
  image: {
    display: 'block',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '8px',
    height: '100%'
  }
}));

export default Carousel;
