import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';

import { Box, Divider, Typography, TextField, MenuItem } from '@mui/material';

import { useTranslation } from 'react-i18next';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px'
  },
  desc: {
    margin: '8px 0'
  },
  desc2: {
    display: 'list-item',
    margin: '8px 0 8px 20px'
  },
  infoWarp: {
    maxWidth: 1600,
    margin: '20px auto'
  },
  infoBoxWarp: {
    width: '100%',
    padding: '10px 5px 5px 5px',
    borderRadius: 5,
    textAlign: 'center',
    display: 'inline-block',
    margin: '5px auto',
    verticalAlign: 'top'
  },
  infoBoxResponsive: {
    [theme.breakpoints.up('lg')]: {
      width: 'calc(50% - 5px)'
    }
  },
  infoBoxMainColor: {
    background: '#4DB6AC'
  },
  infoBoxTitleWarp: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 800
  },
  infoBoxContentWarp: {
    backgroundColor: theme.palette.background.paper,
    padding: '20px 10px',
    borderRadius: 5,
    margin: '10px 0 0 0',
    color: '#444'
  },
  tabContentBold: {
    fontWeight: 800,
    fontSize: 16,
    marginBottom: 10,
    color: theme.palette.text.forth
  },
  tabContentNomal: {
    fontWeight: 400,
    fontSize: 14,
    whiteSpace: 'break-spaces',
    color: theme.palette.text.forth
  },
  infoBoxAddWarp: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    margin: '8px 0',
    width: '100%',
    textAlign: 'left'
  }
}));

const ThreeZoneTab = props => {
  const { activityData } = props;
  const classes = useStyles();
  const theme = useTheme();

  //다국어적용
  const { t } = useTranslation(['page']);

  const [efChartOptions, setEfChartOptions] = useState(null);
  const [weeks, setWeeks] = React.useState(12); //기본 12주
  const [efDesc1, setEfDesc1] = React.useState('');
  const [efDesc2, setEfDesc2] = React.useState('');

  function getWeekName(week) {
    return (
      '📅 ' +
      t('Common.lastNWeek') +
      ' ' +
      week +
      ' ' +
      t('Common.weeks') +
      ' (' +
      moment()
        .subtract(week, 'weeks')
        .format('MM/DD') +
      ' ~ ' +
      moment().format('MM/DD') +
      ')'
    );
  }

  useEffect(() => {
    const stripLineX = [];
    const stripLineY = [];

    // 데이터를 날짜별로 그룹화
    const groupedData = {};
    activityData.forEach(item => {
      const date = moment(item.start_date, 'YY/MM/DD HH:mm').format(
        'YYYY/MM/DD'
      ); // 날짜를 YYYY/MM/DD 형식으로 변환
      const movingTime = Number(item.moving_time);
      const average_heartrate = Number(item.average_heartrate);
      const np = Number(item.np);

      if (!groupedData[date]) {
        groupedData[date] = {
          npSum: 0,
          heartRateSum: 0,
          count: 0,
          npWeightSum: 0,
          heartRateWeightSum: 0,
          movingTime: 0
        };
      }

      if (average_heartrate > 0 && np > 0 && movingTime > 600) {
        groupedData[date].npSum += np;
        groupedData[date].heartRateSum += average_heartrate;
        groupedData[date].npWeightSum += np * movingTime;
        groupedData[date].heartRateWeightSum += average_heartrate * movingTime;
        groupedData[date].movingTime += movingTime;
        groupedData[date].count++;
      }
    });
    // console.log(groupedData);

    // 날짜 범위 생성
    const currentDate = moment();
    const twelveWeeksAgo = moment().subtract(weeks, 'weeks');
    const dateRange = [];
    while (twelveWeeksAgo.isSameOrBefore(currentDate, 'day')) {
      dateRange.push(twelveWeeksAgo.format('YYYY/MM/DD'));
      twelveWeeksAgo.add(1, 'day');
    }

    // 날짜 범위에서 데이터가 없는 경우 0 값을 설정
    const result = dateRange.map(date => {
      const averageData = groupedData[date] || {
        npSum: 0,
        heartRateSum: 0,
        count: 0,
        npWeightSum: 0,
        heartRateWeightSum: 0,
        movingTime: 0
      };
      const averageNp =
        averageData.count > 0
          ? Number(averageData.npSum / averageData.count)
          : 0;
      const averageHeartRate =
        averageData.count > 0
          ? Number(averageData.heartRateSum / averageData.count)
          : 0;
      const averageEf = (averageNp / averageHeartRate).toFixed(2);

      const movingTime = averageData.movingTime;
      const averageWeightNp =
        averageData.count > 0
          ? Number(averageData.npWeightSum / movingTime)
          : 0;
      const averageWeightHeartRate =
        averageData.count > 0
          ? Number(averageData.heartRateWeightSum / movingTime)
          : 0;
      const averageWeightEf = (
        averageWeightNp / averageWeightHeartRate
      ).toFixed(2);
      return {
        date,
        averageHeartrate: averageHeartRate,
        averageNp: averageNp,
        averageEf: averageEf,
        averageWeightHeartRate: averageWeightHeartRate,
        averageWeightNp: averageWeightNp,
        averageWeightEf: averageWeightEf,
        movingTime: movingTime,
        count: averageData.count
      };
    });
    // console.log('result', result);

    const efPoints = arrayToChartPoints(result);
    // console.log(efPoints);

    // y축 최대 최소값 계산
    const maxY = Math.max(...efPoints.map(item => item.y));
    const minY = Math.min(
      ...efPoints.filter(item => item.y > 0).map(item => item.y)
    );

    // 이동평균을 계산할 기간
    var movingAveragePeriod = weeks * 7;

    // EF 값만 추출해서 배열을 만듬, null 값은 제외,
    let y_values = efPoints.reduce(function(result, item, index) {
      if (item.value !== null) {
        result.push(item.value);
      }
      return result;
    }, []);

    // x 값은 1부터 시작하는 배열
    var x_values = [];
    for (var i = 1; i < y_values.length + 1; i++) {
      x_values.push(i);
    }

    const lrData = linearRegression(x_values, y_values);
    // console.log(lrData)

    const lrStartPoint = parseFloat(lrData.y_hat[0]?.toFixed(4));
    const lrEndPoint = parseFloat(
      lrData.y_hat[lrData.y_hat.length - 1]?.toFixed(4)
    );
    const increaseRate =
      parseFloat((lrEndPoint - lrStartPoint) / lrStartPoint).toFixed(2) * 100;

    // const maPoints = [];
    // lrData.y_hat.forEach((value, index) => {
    //   maPoints.push({x: index, y: value, markerColor: '#00ff00',});
    // });

    const maPoints = [];
    maPoints.push({ x: 0, y: lrStartPoint, markerColor: '#ffffff00' });
    maPoints.push({
      x: movingAveragePeriod,
      y: lrEndPoint,
      markerColor: '#ffffff00'
    });

    if (increaseRate > 1) {
      setEfDesc1(t('Heartrate.EFIncreaseDesc1'));
      setEfDesc2(t('Heartrate.EFIncreaseDesc2'));
    } else if (increaseRate < -1) {
      setEfDesc1(t('Heartrate.EFDecreaseDesc1'));
      setEfDesc2(t('Heartrate.EFDecreaseDesc2'));
    } else {
      setEfDesc1(t('Heartrate.EFEqualDesc1'));
      setEfDesc2(t('Heartrate.EFEqualDesc2'));
    }

    /******* Chart  *******/
    setEfChartOptions({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      // title: false,
      zoomEnabled: false,
      toolTip: {
        shared: true
      },
      axisX: {
        title: 'Date',
        titleFontSize: 11,
        titleFontColor: '#aaaaaa',
        labelFontSize: 11,
        labelFontColor: '#aaaaaa',
        stripLines: stripLineX,
        tickLength: 1,
        lineThickness: 1,
        interval: 14,
        lineColor: '#aaaaaa',
        crosshair: {
          enabled: false,
          lineColor: '#999999',
          labelFormatter: function(e) {
            return '';
          }
        },
        labelFormatter: function(e) {
          return e.label;
        }
      },
      axisY: {
        maximum: (maxY * 1.05).toFixed(1),
        minimum: (minY * 0.9).toFixed(1),
        title: 'RP/HR',
        titleFontSize: 11,
        titleFontColor: '#aaaaaa',
        margin: 10,
        labelFontSize: 11,
        labelFontColor: '#aaaaaa',
        stripLines: stripLineY,
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          return e.value.toFixed(1);
        },
        gridThickness: 1,
        gridColor: '#eeeeee',
        valueFormatString: '0.00',
        interval: 0.1
      },
      legend: {
        horizontalAlign: 'center',
        verticalAlign: 'bottom',
        fontSize: 12,
        fontColor: theme.palette.chart.text.primary
      },
      data: [
        {
          type: 'scatter',
          name: t('Heartrate.EFPoint'),
          color: '#ff7e75',
          yValueFormatString: '0.00',
          dataPoints: efPoints,
          toolTipContent:
            "<div style='color:#000000'>{label}</div>" +
            "<div style='color:#73838b'>RP {np}</div>" +
            "<div style='color:#73838b'>HR {hr}</div>" +
            "<div style='color:#ff7e75'>EF {y}</div>",
          showInLegend: true,
          legendMarkerType: 'circle'
        },
        {
          type: 'line',
          name: t('Heartrate.movingAverage'),
          color: '#F06292',
          dataPoints: maPoints,
          toolTipContent: null,
          showInLegend: true,
          legendMarkerType: 'line'
        }
      ]
    });
  }, [weeks]);

  function linearRegression(x_values, y_values) {
    //Create the regressor object to store the equation's data
    var regressor = {};

    //Set variables we'll need to get the slope and intercept; we need to find the equation in the format y = m*x+b where m is the slope and b is the intercept
    var x_mean = x_values.reduce((a, b) => a + b, 0) / x_values.length;
    var y_mean = y_values.reduce((a, b) => a + b, 0) / y_values.length;

    //Equations to solve for slope:
    var slope = 0,
      slope_numerator = 0,
      slope_denominator = 0;
    for (let i = 0; i < x_values.length; i++) {
      slope_numerator += (x_values[i] - x_mean) * (y_values[i] - y_mean);
      slope_denominator += Math.pow(x_values[i] - x_mean, 2);
    }

    slope = slope_numerator / slope_denominator;
    // console.log(slope);
    regressor['slope'] = slope;

    //Equation to solve for intercept
    var intercept = y_mean - x_mean * slope;
    regressor['intercept'] = intercept;

    //Get y_hat, or predicted values of y based on x_values
    //Loop through x_values, and run the elements through the lr equation to get predictions
    var y_hat = [];
    for (let i = 0; i < x_values.length; i++) {
      // console.log(x_values[i])
      y_hat.push(x_values[i] * regressor['slope'] + regressor['intercept']);
    }
    regressor['y_hat'] = y_hat;

    //Now to find the r2 score
    var residual_sum_of_squares = 0,
      total_sum_of_squares = 0,
      r2 = 0;

    for (let i = 0; i < y_values.length; i++) {
      residual_sum_of_squares += Math.pow(y_hat[i] - y_values[i], 2);
      total_sum_of_squares += Math.pow(y_hat[i] - y_mean, 2);
    }

    r2 = 1 - residual_sum_of_squares / total_sum_of_squares;

    //Add to regressor object
    regressor['r2'] = r2;
    // console.log(r2);

    return regressor;
  }

  function arrayToChartPoints(arr) {
    let point_arr = [];
    arr.forEach((value, index) => {
      let label = moment(value.date, 'YYYY/MM/DD').format('MM/DD');
      // let y = parseFloat(value.averageEf);
      // let np = parseInt(value.averageNp);
      // let hr = parseInt(value.averageHeartrate);
      let y = parseFloat(value.averageWeightEf);
      let np = parseInt(value.averageWeightNp);
      let hr = parseInt(value.averageWeightHeartRate);

      if (index === arr.length - 1) {
        label = t('Common.today');
      }

      if (value.count === 0 || isNaN(y) || y === Infinity || y === 'Infinity') {
        y = null;
        np = null;
        hr = null;
      }
      point_arr.push({
        x: index,
        y: y,
        value: y,
        label: label,
        np: np,
        hr: hr
      });
    });
    return point_arr;
  }

  const containerPmc = {
    minWidth: '300px',
    height: window.innerHeight > 800 ? '460px' : '320px',
    margin: 'auto'
  };

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <Box style={{ textAlign: 'right' }}>
          <TextField
            variant="outlined"
            select
            className={classes.textField}
            label={t('Powerzone.label.selectWeek')}
            defaultValue={weeks}
            onChange={e => setWeeks(e.target.value)}
            InputProps={{
              classes: { input: classes.inputStyle }
            }}>
            <MenuItem key={0} value={4}>{`${getWeekName(4)}`}</MenuItem>
            <MenuItem key={1} value={12}>{`${getWeekName(12)}`}</MenuItem>
          </TextField>
        </Box>

        <Box className={classes.tabContent}>
          <CanvasJSChart
            containerProps={containerPmc}
            options={efChartOptions}
          />

          <Divider />
          <Box className={classes.infoWarp}>
            <Box
              className={clsx(classes.infoBoxWarp, classes.infoBoxMainColor)}>
              <Box className={classes.infoBoxTitleWarp}>
                {moment()
                  .subtract(weeks, 'weeks')
                  .format('YY/MM/DD') +
                  ' ~ ' +
                  moment().format('YY/MM/DD')}
              </Box>
              <Box className={classes.infoBoxContentWarp}>
                <Box className={classes.tabContentBold}>{efDesc1}</Box>
                <Box className={classes.tabContentNomal}>{efDesc2}</Box>
              </Box>
            </Box>
          </Box>

          <Divider />
          <Box style={{ padding: '12px 0px' }}>
            <Typography className={classes.descTitle} component="div">
              {t('Heartrate.EFIs')}
            </Typography>
            <Typography className={classes.desc} component="div">
              {t('Heartrate.EFIsDesc1')}
            </Typography>
            <Typography className={classes.desc} component="div">
              {t('Heartrate.EFIsDesc2')}
            </Typography>
            <Typography className={classes.desc} component="div">
              {t('Heartrate.EFIsDesc3')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

ThreeZoneTab.propTypes = {
  history: PropTypes.object
};

export default withRouter(ThreeZoneTab);
