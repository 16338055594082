import { useEffect, useState } from "react"


export default function useAutoSize(images, sizes) {
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {

        const handleSizeDetector = () => {
            // console.log("Hooks에 전달되는 데이터", images);
            // datas : 객체의 배열 집합 형태. ex) [{},{},{}]
            if (images) {
                const sizedImages = images.filter(image => image.thumbnailSize === sizes);
                let selectedFilePaths = [];

                // thumbnailSize가 "MEDIUM"인 이미지가 있을 경우 해당 이미지들의 filePath 추출
                if (sizedImages.length > 0) {
                    const mediumFilePaths = sizedImages.map(image => image.filePath);
                    selectedFilePaths = mediumFilePaths;

                } else {
                    // thumbnailSize가 "MEDIUM"인 이미지가 없을 경우 "ORIGIN" 이미지의 filePath 추출
                    const originImages = images.filter(image => image.thumbnailSize === "ORIGIN");
                    const originFilePaths = originImages.map(image => image.filePath);
                    selectedFilePaths = originFilePaths;

                }
                // console.log("이미지 사이즈 선택 이후의 데이터", selectedFilePaths);
                setSelectedImages(selectedFilePaths);
            }
        }
        handleSizeDetector()

    }, [images]);
    return { selectedImages };
}
