import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';

import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Icon } from '@iconify/react';
import StravaIcon from '@iconify/icons-mdi/strava';

import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import auth from '../../../../utils/auth';
import { useTranslation } from 'react-i18next';

import { TimeLine, PowerCurve, PowerZone } from './Chart';

//import $ from "jquery";
//var CanvasJS = window.CanvasJS;

const useStyles = makeStyles(theme => ({
  root: {},
  tableContainer: {
    width: 'auto',
    height: '280px',
    margin: '0px 16px 0px 16px',
    [theme.breakpoints.down('xl')]: {
      marginTop: '-2px',
      height: '222px'
    }
  },
  tableRow: {
    '&$selected:selected': {
      background: '#d8efed'
    }
  },
  tableHeadCell: {
    padding: '4px 16px',
    textAlign: 'center',
    color: '#4db6ac',
    fontSize: '11px',
    fontWeight: '400',
    backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '11px'
    }
  },
  tableCell: {
    fontSize: '12px',
    padding: '10px 8px',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '11px'
    }
  },
  tableCellActivity: {
    display: 'inline-block',
    fontSize: '13px',
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fill-available',
    maxWidth: '480px',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '400px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '220px'
    }
  },
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  stravaLink: {
    color: '#FC4C02'
  },
  modifyLink: {
    color: '#888888',
    marginRight: '16px',
    cursor: 'pointer'
  },

  descTitle: {
    fontSize: '12px',
    marginTop: '10px',
    textAlign: 'center'
  },
  paginationSpacer: {
    flex: '1 1 0%',
    padding: '0 8px 8px 8px'
  },
  modifyButton: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    color: '#4db6ac',
    margin: '8px 0'
  }
}));

const Activities = props => {
  //주석
  //다국어적용 {t('page.key')}
  const { t } = useTranslation(['page']);

  const columns = [
    { id: 'activity_name', label: t('Dashboard.activity'), minWidth: 220 },
    { id: 'average_watts', label: t('Dashboard.avgPower'), minWidth: 90 },
    { id: 'distance', label: t('Dashboard.distance'), minWidth: 80 },
    { id: 'moving_time', label: t('Dashboard.duration'), minWidth: 80 },
    { id: 'ride_data', label: t('Dashboard.date'), minWidth: 100 },
    { id: 'tss_text', label: t('Dashboard.score3'), minWidth: 200 },
    { id: 'activity_update', label: '', minWidth: 160, align: 'right' },
    { id: 'activity_url', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_id', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_desc', label: '', minWidth: 0, display: 'none' }
  ];

  const { dashboardData, wkgSelect, chartType } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [activityUpdateOpen, setActivityUpdateOpen] = React.useState(false);
  const [activityId, setActivityId] = React.useState(props.activityId);
  const [activityName, setActivityName] = React.useState('');
  const [dialogActivityId, setDialogActivityId] = React.useState(0);
  const [dialogActivityName, setDialogActivityName] = React.useState('');
  const [activityReload, setActivityReload] = React.useState('stop');
  const [activityDesc, setActivityDesc] = React.useState(null);
  const [streamDataJson, setStreamDataJson] = useState([]);
  const [circle, setCircle] = useState(false);

  const activityData = dashboardData.activityData;
  const weight = dashboardData.weight;

  let exclude_arr = Array();
  let dst_arr = Array();
  let tss_arr = Array();
  let if_arr = Array();

  let rows = Array();
  let selectedRows = Array();

  const timeout = delay => {
    return new Promise(res => setTimeout(res, delay));
  };

  useEffect(() => {
    const getActivityStream = () => {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      setCircle(true);

      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-activity-stream.php`,
          {
            headers: { Authorization: auth_str },
            params: {
              order: 'get_stream',
              activity_id: activityId
            }
          }
        )
        .then(async function(response) {
          setCircle(false);

          const data = response.data;
          if (data) {
            setStreamDataJson(data);
          }
        });
    };

    if (activityId > 0 && chartType === 'timeLine') {
      getActivityStream();
    }
  }, [activityId]);

  if (activityData)
    _.each(activityData, function(item, i) {
      let watts_str = item.average_watts + 'W ';
      if (wkgSelect)
        watts_str =
          Math.round((item.average_watts / item.weight) * 10) / 10 + ' w/kg ';

      if (item.max_watts > 0 && item.exclude == 0) {
        watts_str += '⚡';
      } else if (item.max_watts > 0 && item.exclude == 1) {
        watts_str += '❔';
        exclude_arr.push(Number(item.activity_id));
      }

      let activity_name = item.activity_name;
      let average_watts = watts_str;
      let distance = item.distance + 'km';
      let distance_number = item.distance;
      let moving_time = secondsToHms(item.moving_time);
      let ride_data = item.start_date;
      let activity_url =
        'https://www.strava.com/activities/' + item.activity_id;
      let activity_id = item.activity_id;
      let timestamp = item.timestamp;
      let updated_at = item.updated_at;
      let ftp = item.ftp;
      let np = item.np;
      let intensity = item.intensity;
      let tss = item.tss == 0 ? 1 : item.tss;

      let tss_text =
        '📊 ' +
        t('Dashboard.np') +
        ' ' +
        np +
        ', ' +
        t('Dashboard.if') +
        ' ' +
        intensity +
        ', ' +
        t('Dashboard.tss') +
        ' ' +
        tss;
      let synopsis_text = item.synopsis_text;
      let anaerobic_text = item.anaerobic_text;
      let aerobic_text = item.aerobic_text;
      let power_text = item.power_text;
      let activity_desc = {
        synopsis_text: synopsis_text,
        anaerobic_text: anaerobic_text,
        aerobic_text: aerobic_text,
        power_text: power_text
      };

      //일주일이 지났으면
      let updated_check =
        updated_at < parseInt(Date.now() / 1000) - 60 * 60 * 24 * 1;
      let activity_update = updated_check ? true : false;

      let d = {
        activity_name,
        average_watts,
        distance,
        moving_time,
        ride_data,
        tss_text,
        activity_update,
        activity_url,
        activity_id,
        activity_desc
      };
      rows.push(d);

      dst_arr.push([i, distance_number]);
      tss_arr.push([i, tss]);
      if_arr.push([i, intensity * 100]);

      selectedRows.push({ activity_id: false });
    });

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /*
	const handleClickTableAway = () => {
		setActivityName('');
		setActivityId(0);
  };
  */

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + 'h ' : '';
      let md = m > 0 ? m + 'm' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + 'm ' : '';
      let sd = s > 0 ? s + 's' : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '00';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  function handleDialogClose() {
    setActivityUpdateOpen(false);
  }

  function handleDialogOpen(e, id, name) {
    e.stopPropagation();
    setActivityId(0);
    setActivityName('');
    setDialogActivityId(id);
    setDialogActivityName(name);
    setActivityUpdateOpen(true);
  }

  const setActivity = (activity_id, order) => {
    setActivityReload('loading');
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-activity.php`,
        { order: order, activity_id: activity_id },
        { headers: { Authorization: auth_str } }
      )
      .then(function(response) {
        let res = response.data;
        if (res.success === true && res.activity_id === activity_id) {
          props.history.push({
            pathname: '/'
          });
        } else {
          alert('Error');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  function Row(props) {
    const { activity_id, row, i } = props;
    const classes = useStyles();

    const isSelected = activityId == activity_id ? true : false;

    return (
      <React.Fragment>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.activity_id}
          selected={isSelected}
          classes={{ selected: classes.selected }}
          className={classes.tableRow}
          onClick={() => {
            setActivityDesc(row.activity_desc.synopsis_text);
            setActivityId(row.activity_id);
            setActivityName(row.activity_name);
          }}>
          {columns.map(column => {
            const value = row[column.id];

            if (column.id === 'activity_name') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCellActivity}>
                  <Button
                    color="secondary"
                    size="small"
                    variant="text"
                    target="_blank"
                    href={row.activity_url}
                    className={classes.stravaButton}>
                    <Icon icon={StravaIcon} className={classes.stravaIcon} />
                  </Button>
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            } else if (column.id === 'activity_update') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}>
                  {value === true ? (
                    <a
                      className={classes.modifyLink}
                      onClick={e =>
                        handleDialogOpen(e, row.activity_id, row.activity_name)
                      }>
                      Modify
                    </a>
                  ) : (
                    ''
                  )}
                  <a
                    color="secondary"
                    size="small"
                    variant="text"
                    target="_blank"
                    href={row.activity_url}
                    className={classes.stravaLink}>
                    View on Strava
                  </a>
                </TableCell>
              );
            } else if (column.display != 'none') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}>
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            }
          })}
        </TableRow>
      </React.Fragment>
    );
  }

  //자식으로부터 activity_id 전달받기
  const handlePeakActivity = id => {
    //검색해야지.
    if (activityData)
      _.each(activityData, function(item, i) {
        if (Number(item.activity_id) === Number(id)) {
          setActivityDesc(item.synopsis_text);
          setActivityId(item.activity_id);
          setActivityName(item.activity_name);

          setPage(Math.floor(i / rowsPerPage));

          return true;
        }
      });
    return false;
  };

  function ChartContent() {
    if (chartType === 'timeLine') {
      $('#chartContainer').remove();

      if (circle === true) {
        //stream 불러올 때 로딩써클
        return (
          <React.Fragment>
            <Box
              style={{
                minHeight: '436px',
                textAlign: 'center',
                padding: '100px'
              }}>
              <CircularProgress size={128} color="secondary" />
            </Box>
          </React.Fragment>
        );
      } else {
        //activity stream
        return (
          <React.Fragment>
            <TimeLine
              extraInfo={auth.getExtraInfo()}
              ftp={Math.round(dashboardData.ftp)}
              wPrime={Math.round(dashboardData.wPrime)}
              activityData={dashboardData.activityData}
              lastTs={dashboardData.lastTs}
              prevTs={dashboardData.prevTs}
              summaryData={dashboardData.summaryData}
              streamDataJson={streamDataJson}
              wkg={wkgSelect}
              weight={dashboardData.weight}
            />
          </React.Fragment>
        );
      }
    } else if (chartType === 'powerZone') {
      return (
        <React.Fragment>
          <PowerZone
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            powerzoneData={dashboardData.powerzoneData}
            wkg={wkgSelect}
            weight={dashboardData.weight}
          />
        </React.Fragment>
      );
    } else if (chartType === 'powerCurve') {
      return (
        <React.Fragment>
          <PowerCurve
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            handlePeakActivity={handlePeakActivity}
            ftp={Math.round(dashboardData.ftp)}
            wPrime={Math.round(dashboardData.wPrime)}
            forte={dashboardData.forte}
            pdcData={dashboardData.pdcData}
            activityData={dashboardData.activityData}
            yScaleMax={dashboardData.yScale}
            lastTs={dashboardData.lastTs}
            prevTs={dashboardData.prevTs}
            wkg={wkgSelect}
            weight={dashboardData.weight}
          />
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  return (
    <Card style={{ borderRadius: '0 0 4px 4px' }}>
      <CardContent style={{ paddingTop: '0px' }} id="canvasjs_wrap">
        <ChartContent />
      </CardContent>

      <Box className={classes.scrollWrap}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.tableHeadCell}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      display: column.display
                    }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <Row
                    key={row.activity_id}
                    activity_id={row.activity_id}
                    row={row}
                    i={i}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{ spacer: classes.paginationSpacer }}
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>

      <Dialog open={activityUpdateOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {"'" + dialogActivityName + "' " + t('Dashboard.modifyActivity')}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {activityReload === 'loading' ? (
            <React.Fragment>
              <Box
                className={classes.circularProgress}
                style={{
                  minWidth: '300px',
                  padding: '100px 0 100px 0',
                  textAlign: 'center'
                }}>
                <CircularProgress size={64} />
              </Box>
            </React.Fragment>
          ) : (
            <List>
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'strava_update')}
                key={1}>
                <ListItemIcon>
                  <Icon
                    icon={StravaIcon}
                    style={{ color: '#4db6ac', fontSize: 40 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.stravaUpdate')}
                  secondary={t('Dashboard.stravaUpdateDesc')}
                />
              </ListItem>
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'reload')}
                key={1}>
                <ListItemIcon>
                  <PermDataSettingIcon
                    style={{ color: '#4db6ac', fontSize: 36 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.recalculateActivity')}
                  secondary={t('Dashboard.recalculateActivityDesc')}
                />
              </ListItem>
              {exclude_arr.indexOf(Number(dialogActivityId)) === -1 ? (
                <ListItem
                  className={classes.modifyButton}
                  button
                  onClick={() => setActivity(dialogActivityId, 'deactivate')}
                  key={2}>
                  <ListItemIcon>
                    <RemoveCircleIcon
                      style={{ color: '#4db6ac', fontSize: 36 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('Dashboard.excludeActivity')}
                    secondary={t('Dashboard.excludeActivityDesc')}
                  />
                </ListItem>
              ) : (
                ''
              )}
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'delete_activity')}
                key={3}>
                <ListItemIcon>
                  <DeleteForeverIcon
                    style={{ color: '#4db6ac', fontSize: 36 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.deleteActivity')}
                  secondary={t('Dashboard.deleteActivityDesc')}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
};

Activities.propTypes = {
  className: PropTypes.string
};

export default withRouter(Activities);
