import React from 'react';

import { makeStyles, styled } from '@mui/styles';
import { Avatar, Box, Typography, Button } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AvatarGroup } from '@mui/material';

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const useStyles = makeStyles(theme => ({
  button: {},
  participateButton: { margin: '16px 0' },
  participantCountText: { marginLeft: '4px' },
  avatar: { width: '30px', height: '30px' },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionContainer: {
    backgroundColor: 'transparent',
    border: 'none',
    // boxShadow: 'none!',
    // backgroundColor: 'red',
    '& > div': {
      padding: '0'
    }
  }
}));

function ParticipantJoin({
  event,
  isApplied,
  eventUsers,
  handleClickEventModalOpen,
  handleClickProfile
}) {
  const classes = useStyles();

  const clubUserResponseUserId = event.clubUserResponse?.userId;
  const hostUserId = event.eventJoinUsers.find(user => user.host === true)
    ?.userId;
  const isAuthor = clubUserResponseUserId === hostUserId;

  return (
    <>
      {/*이벤트 참석 유무 버튼 컴포넌트 */}
      <Box className={classes.participateButton} style={{}}>
        {event.status === 'OPEN' && (
          <Box>
            {!isAuthor ? ( // 이벤트 개설자가 아닌 경우
              !isApplied ? ( // 참여 신청을 하지 않은 경우
                <Button
                  className={classes.button}
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={() => handleClickEventModalOpen('join')}
                  variant="contained">
                  이벤트 참여신청
                </Button>
              ) : (
                // 참여 신청을 한 경우
                <Button
                  className={classes.button}
                  color="secondary"
                  fullWidth
                  size="large"
                  onClick={() => handleClickEventModalOpen('cancel')}
                  variant="contained">
                  이벤트 참여취소
                </Button>
              )
            ) : (
              <Button
                className={classes.button}
                disabled
                fullWidth
                size="large"
                variant="outlined">
                이벤트 개설자
              </Button>
            )}
          </Box>
        )}
      </Box>
      {/* 참석자 수 */}
      <Typography variant="h6" style={{ marginBottom: '8px' }}>
        참석 {eventUsers.length}/{event.maximumUser}
      </Typography>
      {/* 참석자 아코디언 */}
      <Accordion className={classes.accordionContainer}>
        {/* 참석자 아코디언 헤더 */}
        <AccordionSummary
          className={classes.accordionHeader}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <Box className={classes.accordionSummary}>
            <AvatarGroup max={3}>
              {eventUsers &&
                eventUsers.length > 0 &&
                eventUsers.map(user => {
                  return (
                    <Avatar
                      variant="circular"
                      key={user.id}
                      src={user.profileImage}
                      alt={user.displayName}
                      className={classes.avatar}
                    />
                  );
                })}
            </AvatarGroup>
            <Typography className={classes.participantCountText}>
              이 참석합니다.
            </Typography>
          </Box>
        </AccordionSummary>
        {/* 참석자 리스트 */}
        <AccordionDetails>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            {eventUsers &&
              eventUsers.length > 0 &&
              eventUsers.map((user, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '16px',
                      alignItems: 'center'
                    }}>
                    <Avatar
                      variant="circular"
                      onClick={() => handleClickProfile(user.userId)}
                      src={user.profileImage}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '4px',
                        cursor: 'pointer'
                      }}
                    />
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        marginLeft: '8px'
                      }}>
                      <Typography variant="h6">{user.displayName}</Typography>
                      <Typography variant="h6">
                        {`${user.wkg}.xx `}w/kg
                      </Typography>
                    </Box>
                  </div>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ParticipantJoin;
