import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/styles';

function AreaChart({ prefixSumDistance, altitude, setCoordinateIndex }) {
  const theme = useTheme();

  const options = {
    chart: {
      id: 'distanceWithAltitude',
      events: {
        mouseMove: (event, chartContext, config) => {
          const index = config.dataPointIndex;
          if (index >= 0 && index < altitude.length) {
            setCoordinateIndex(index);
          }
        }
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.palette.secondary.main],
    fill: {
      opacity: 0.9,
      type: 'solid'
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 10,
      categories: prefixSumDistance,
      title: {
        text: '거리(km)',
        style: {
          color: theme.palette.text.primary
        }
      },
      tooltip: {
        enabled: false
      },
      labels: {
        style: {
          colors: theme.palette.text.primary
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function(altitude) {
          return altitude.toFixed(0);
        },
        style: {
          colors: theme.palette.text.primary
        }
      },
      title: {
        text: '고도(m)',
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    tooltip: {
      x: {
        formatter: value => {
          return value.toFixed(2) + 'km';
        }
      },
      custom: () => {
        return '';
      }
    }
  };

  const series = [
    {
      name: '고도(m)',
      data: altitude,
      type: 'area'
    }
  ];

  return (
    <div>
      <Chart options={options} series={series} height="200px" />
    </div>
  );
}

export default AreaChart;
