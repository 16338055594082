import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
  Switch
} from '@mui/material';

import {
  Initialization,
  LoadData,
  StatusCard,
  Activities,
  RiderType,
  BikeKit,
  Dialogs
} from './components';
import axios from 'axios';
import auth from '../../utils/auth';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';

// 로딩이 느려 더미데이터 사용
import dummyData from './dummyData.json';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  paper: {
    borderRadius: '4px 4px 0 0'
  },
  tabs: {},
  tab: {
    fontSize: '15px',
    padding: '6px 16px',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      padding: '0px px',
      fontSize: '14px'
    }
  },
  box: {
    padding: theme.spacing(2),
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    backgroundColor: '#ffffff',
    minHeight: '200px'
  },
  panelWrap: {},
  panel: {
    margin: '0 0 -1px 0 !important',
    boxShadow: '0 0 0 1px #cccccc, 0 1px 3px 0 #cccccc',
    backgroundColor: '#fafafa'
  },
  statusCard: {
    paddingBottom: '-8px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '60px',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: '18px',
    lineHeight: '40px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: '#4db6ac'
    }
  },
  icon: {
    height: 40,
    width: 40
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    width: 280,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
    textAlign: 'center',
    color: '#444444',
    whiteSpace: 'pre-wrap'
  },
  modalBody1: {
    fontSize: '11px',
    textAlign: 'left',
    margin: '5px 0'
  },
  modalBody2: {
    fontSize: '11px',
    fontWeight: 800,
    lineHeight: '22px',
    textAlign: 'left',
    wordBreak: 'keep-all',
    margin: '5px 0'
  },
  modalButton: {
    width: '100%',
    boxShadow: 'none',
    fontSize: '20px',
    fontWeight: 800,
    borderRadius: '12px'
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);

  const dashboardDefault = auth.getDashboardInfo()
    ? auth.getDashboardInfo()
    : {
        rank_percent: 0,
        rank_array: null,
        category: 'R0',
        race: 'Race',
        hour_record: 0,
        ftp: 0,
        maxHr: 100,
        wPrime: 15000,
        weight: 10,
        forte: '',
        rider_type: null,
        rider_ability: null,
        pdcData: null,
        metabolismArray: null,
        activityData: null,
        yScale: 1000,
        lastTs: 0
      };
  const [dashboardData, setDashboardData] = useState(dashboardDefault);

  const [activityId, setActivityId] = useState(0);
  const [stravaStatus, setStravaStatus] = useState(0);
  //	const [waiting, setWaiting] = useState('......');
  const [chartType, setChartType] = useState('timeLine');
  const [wkgSelect, setWkgSelect] = useState(false);

  const extraInfo = auth.getExtraInfo()
    ? auth.getExtraInfo()
    : { pretest: 'none' };
  const auth_str = 'Bearer '.concat(auth.getToken().token);
  let bikeKitJson = null;
  let unit = 'metric';

  // 모달창 여부
  // const [modalOpen, setModalOpen]= useState(false);

  if (extraInfo.pretest === 2) {
    bikeKitJson = JSON.parse(extraInfo.bikeKit_json);
    unit = extraInfo.unit ? extraInfo.unit : 'metric';
  }

  useEffect(() => {
    let wkg_select = cookie.load('wkg_select');
    if (typeof wkg_select === 'undefined') {
      cookie.save('wkg_select', 0, { path: '/' });
      wkg_select = 0;
    } else {
      if (wkg_select == 1) {
        setWkgSelect(true);
      } else {
        setWkgSelect(false);
      }
    }

    if (extraInfo.pretest === 'none') {
      console.log('none cookie');
    } else if (extraInfo.pretest === 0) {
    } else if (extraInfo.pretest === 1) {
      /*
			axios.get(
				`${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/initialization.php`, 
				{
					headers: { Authorization: auth_str }, 
					params: {
						order: 'init'
					}
				}
			).then(function (response) {
				if (response) {
					let res = response.data;
					if(res.waiting > 0) { 	
						setWaiting("데이터 분석 완료까지 약 " + secondsToHms(res.waiting) + " 미만 남았습니다");
					} else if(res.waiting === -1) {
						setWaiting('데이터 분석에 실패하였습니다. 새로고침 하여 다시 시도해주세요.');
					} else if(res.waiting === 0) {
						setWaiting('데이터 분석이 완료되었습니다. 새로고침 하시면 완료됩니다.');
						window.location.reload();
					}
				}
			}).catch((err) => {
				console.log(err);
			});
			*/
    } else if (
      extraInfo.pretest === 2 &&
      extraInfo.ftp > 0 &&
      dashboardData.pdcData == null
    ) {
      // 로딩이느려 더미
      // const items = dummyData;
      // const dashboardJson = {
      //   rank_percent: items.rank_percent,
      //   rank_array: items.rank_array,
      //   category: items.category,
      //   race: items.race,
      //   hour_record: items.hour_record,
      //   maxHr: items.max_hr,
      //   maxHrActivity: items.max_hr_activity,
      //   ftp: items.ftp,
      //   wPrime: items.w_prime,
      //   weight: items.weight,
      //   ftpArray: items.ftp_array,
      //   metabolismArray: items.metabolism_array,
      //   forte: items.forte,
      //   rider_type: items.rider_type,
      //   rider_ability: items.rider_ability,
      //   pdcData: items.pdc_list,
      //   activityData: items.activity_list,
      //   yScale: items.y_scale,
      //   summaryData: items.summary_data,
      //   powerzoneData: items.powerzone_data,
      //   lastTs: items.last_ts,
      //   prevTs: items.prev_ts
      // };
      // setDashboardData(dashboardJson);
      // auth.setDashboardInfo(dashboardJson, true);

      // get live-data start
      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/dashboard.php`,
          {
            headers: { Authorization: auth_str },
            params: {
              order: 'get_dashboard',
              days: 12 * 7
            }
          }
        )
        .then(function(response) {
          const items = response.data;
          if (items.ftp > 0 && items.cp > 0 && items.activity_list != null) {
            const dashboardJson = {
              rank_percent: items.rank_percent,
              rank_array: items.rank_array,
              category: items.category,
              race: items.race,
              hour_record: items.hour_record,
              maxHr: items.max_hr,
              maxHrActivity: items.max_hr_activity,
              ftp: items.ftp,
              wPrime: items.w_prime,
              weight: items.weight,
              ftpArray: items.ftp_array,
              metabolismArray: items.metabolism_array,
              forte: items.forte,
              rider_type: items.rider_type,
              rider_ability: items.rider_ability,
              pdcData: items.pdc_list,
              activityData: items.activity_list,
              yScale: items.y_scale,
              summaryData: items.summary_data,
              powerzoneData: items.powerzone_data,
              lastTs: items.last_ts,
              prevTs: items.prev_ts
            };

            setDashboardData(dashboardJson);
            auth.setDashboardInfo(dashboardJson, true);
          } else if (items.ftp === -1 && extraInfo.pretest === 2) {
            setStravaStatus(-1);
          } else if (items.ftp === 0 && extraInfo.pretest === 0) {
            window.location.reload();
          } else if (
            items.ftp === 0 &&
            items.activities_cnt === -1 &&
            extraInfo.strava_id !== 0
          ) {
            setStravaStatus(-2);
          }

          //var timestamp = 1476508607 * 1000;
          //var date = new Date(timestamp);
          //console.log('year is ' + date.getFullYear());
        })
        .catch(function(err) {
          console.log(err);
        });
      // get live-data end
    } else {
      setStravaStatus(-2);
      console.log('Dashboard');
    }

    // 모달
    // let modalDisable = cookie.load("modalDisable"); // 모달 스킵여부
    // if(typeof modalDisable === 'undefined' ) {
    //   setModalOpen(true)
    // }

    // localStorage 에서 fcmToken 확인 후 업데이트
    if (localStorage.getItem('fcmToken')) {
      updateFcmToken({ token: localStorage.getItem('fcmToken') });
    }
  }, []);

  // 스트라바 데이터 존재 유무
  const hasStravaData =
    dashboardData.ftp > 0 && dashboardData.activityData !== null;

  // fcmToken 업데이트
  function updateFcmToken({ token = '' }) {
    // localStorage.setItem("fcmToken", token);
    if (
      auth.getToken() !== null &&
      token !== '' &&
      token !== null &&
      token !== undefined
    ) {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      axios
        .post(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
          {
            order: 'update_fcm_token',
            fcm_token: token
          },
          {
            headers: { Authorization: auth_str }
          }
        )
        .then(response => {
          console.log(response);
          if (response.data.success) {
            console.log(response.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  function FormRow() {
    //lg={3} sm={6} xl={3} xs={12}
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <RiderType
              rider_type={dashboardData.rider_type}
              rider_ability={dashboardData.rider_ability}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <BikeKit bikeKitJson={bikeKitJson} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function Chart(props) {
    const classes = useStyles();
    const dashboardData = props.dashboardData;
    const chartType = props.chartType;

    let tabValue = 0;
    if (chartType === 'timeLine') tabValue = 0;
    else if (chartType === 'powerZone') tabValue = 1;
    else if (chartType === 'powerCurve') tabValue = 2;

    const handleChangeChart = (event, newValue) => {
      setActivityId(0);

      if (newValue === 0) setChartType('timeLine');
      else if (newValue === 1) setChartType('powerZone');
      else if (newValue === 2) setChartType('powerCurve');
    };

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeChart}
            className={classes.tabs}>
            <Tab label={t('Dashboard.trainingTrend')} className={classes.tab} />
            <Tab label={t('Dashboard.zoneAnalysis')} className={classes.tab} />
            <Tab label={t('Dashboard.powerProfile')} className={classes.tab} />
          </Tabs>
        </Paper>
        <Activities
          dashboardData={dashboardData}
          activityId={activityId}
          chartType={chartType}
          wkgSelect={wkgSelect}
        />
        <Dialogs />
      </React.Fragment>
    );
  }

  function EmptyCard(props) {
    if (props.stravaStatus < 0) {
      return (
        <LoadData />
        /*	
								<Card>				
									<CardHeader />
									<CardContent style={{paddingTop:'0px'}}>				
										<Typography component="div" color="textSecondary" gutterBottom>
											활동 데이터가 없습니다. 
										</Typography>
										<Typography component="div" color="textSecondary" gutterBottom>
											스트라바에서 새 활동을 올리시거나
										</Typography>
										<Typography component="div" color="textSecondary" gutterBottom>
											스트라바에서 활동 제목을 수정하시면 자동 연동됩니다. 
										</Typography>
										<Typography component="div" color="textSecondary" gutterBottom>
											(최근 12주간의 데이터, 스트라바 자동 업데이트 설정)
										</Typography>
										<Button 
											onClick={() => updateLatest60(true)} 
											color="primary" 
											style={{padding:'4px 16px 4px 12px',background:'#f5f5f5',fontSize:'20px'}}
										>
											최근데이터 업데이트
										</Button>
									</CardContent>
								</Card>	
				*/
      );
    } else {
      return (
        <Card>
          <CardHeader />
          <CardContent style={{ paddingTop: '0px' }}></CardContent>
        </Card>
      );
    }
  }

  const handleChangeWkg = event => {
    let ch = Boolean(wkgSelect);
    setWkgSelect(!ch);

    if (!ch === true) {
      cookie.save('wkg_select', 1, { path: '/' });
    } else {
      cookie.save('wkg_select', 0, { path: '/' });
    }
  };

  const WkgSwitch = withStyles({
    switchBase: {
      color: '#4db6ac',
      '&$checked': {
        color: '#f06292'
      },
      '&$checked + $track': {
        backgroundColor: '#ffabc7'
      }
    },
    checked: {},
    track: {
      backgroundColor: '#a0d8d3'
    }
  })(Switch);

  const DashboardGrid = props => {
    function MainGrid() {
      if (dashboardData.ftp > 0 && dashboardData.activityData !== null) {
        // 스트라바 데이터가 존재하는 경우 메인 차트 컴포넌트
        return (
          <React.Fragment>
            <Chart dashboardData={dashboardData} chartType={chartType} />
          </React.Fragment>
        );
      } else if (stravaStatus < 0) {
        // 스트라바 데이터가 없는 경우 스트라바 데이터 불러오기 컴포넌트
        return (
          <React.Fragment>
            <EmptyCard stravaStatus={stravaStatus} />
          </React.Fragment>
        );
      } else {
        return (
          <Box
            className={classes.box}
            style={{ textAlign: 'center', padding: '64px 0 64px 0' }}>
            <CircularProgress size={128} />
          </Box>
        );
      }
    }

    if (extraInfo.pretest === 2) {
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            {hasStravaData ? (
              <>
                <Grid item lg={6} xl={6} sm={6} xs={6}>
                  <StatusCard
                    status={'rank'}
                    cardTitle={t('Dashboard.raceRank')}
                    cardContent={extraInfo.rank_percent}
                    category={extraInfo.category}
                    extraInfo={extraInfo}
                    ftpArray={dashboardData.ftpArray}
                    rankArray={dashboardData.rank_array}
                    wkg={wkgSelect}
                    weight={dashboardData.weight}
                  />
                </Grid>
                <Grid item lg={6} xl={6} sm={6} xs={6}>
                  <StatusCard
                    status={'vo2max'}
                    cardTitle={t('Dashboard.vo2max')}
                    cardContent={extraInfo.vo2max}
                    extraInfo={extraInfo}
                    weight={dashboardData.weight}
                  />
                </Grid>
                <Grid item lg={6} xl={6} sm={6} xs={6}>
                  <StatusCard
                    status={'ftp'}
                    cardTitle={t('Dashboard.ftp')}
                    cardContent={extraInfo.ftp}
                    extraInfo={extraInfo}
                    ftpArray={dashboardData.ftpArray}
                    summaryData={dashboardData.summaryData}
                    wkg={wkgSelect}
                    weight={dashboardData.weight}
                  />
                </Grid>
                <Grid item lg={6} xl={6} sm={6} xs={6}>
                  <StatusCard
                    status={'maxHr'}
                    cardTitle={t('Dashboard.maxHr')}
                    cardContent={extraInfo.max_hr}
                    cardDesc={extraInfo.max_hr}
                    extraInfo={extraInfo}
                    ftpArray={dashboardData.ftpArray}
                    maxHrActivity={dashboardData.maxHrActivity}
                    wkg={wkgSelect}
                    weight={dashboardData.weight}
                  />
                </Grid>
              </>
            ) : null}

            {/**
             * 스트라바 데이터 있는 경우 - 메인 차트 컴포넌트
             * 스트라바 데이터 없는 경우 - 데이터 불러오기 컴포넌트
             */}
            <Grid item lg={12} xl={12} sm={12} xs={12}>
              <MainGrid />
            </Grid>

            {/**
             * 역량 & 성향 및 바이크 킷
             */}
            {hasStravaData ? (
              <Grid item lg={12} xl={12} sm={12} xs={12}>
                <FormRow />
              </Grid>
            ) : null}
          </Grid>
        </React.Fragment>
      );
    } else if (extraInfo.pretest === 1) {
      return <React.Fragment>활동불러오는 다이알로그 띄우기</React.Fragment>;
    } else if (extraInfo.pretest === 0) {
      return (
        <React.Fragment>
          <Initialization
            extraInfo={extraInfo}
            StravaId={extraInfo.strava_id}
          />
        </React.Fragment>
      );
    } else if (extraInfo.pretest === 'none') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      return (
        <Box
          className={classes.box}
          style={{ textAlign: 'center', padding: '64px 0 64px 0' }}>
          <CircularProgress size={128} />
        </Box>
      );
    }
  };

  // const handleDisableModal = () => {
  //   // 오늘하루 보지 않기
  //   const targetDate = new Date(new Date().setHours(24, 0, 0, 0)); // 오늘 이후 자정
  //   cookie.save('modalDisable', 'true', { path: '/', expires: targetDate });
  //   setModalOpen(false)
  // }

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.dashboard')}
          {hasStravaData ? (
            <FormGroup
              style={{
                float: 'right',
                height: '20px',
                display: 'inline',
                margin: '-3px 0 20px 10px'
              }}>
              <Grid component="label" container alignItems="center" spacing={0}>
                <Grid item style={{ fontSize: '12px', color: '#aaaaaa' }}>
                  Watts
                </Grid>
                <Grid item>
                  <WkgSwitch
                    checked={wkgSelect}
                    onChange={handleChangeWkg}
                    name="wkgSelect"
                  />
                </Grid>
                <Grid item style={{ fontSize: '12px', color: '#aaaaaa' }}>
                  W/kg
                </Grid>
              </Grid>
            </FormGroup>
          ) : null}
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      <DashboardGrid dashboardData={dashboardData} />

      {/* <Button onClick={() => updateFcmToken({token:'FCM-TOKEN'})} color="primary"> updateFcmToken </Button> */}

      {/* 메인 - 모달(공지사항) */}
      {/* <div>
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={classes.modal}
          >
            <div className={classes.modalPaper}>
              <Box style={{margin: '10px 0 15px'}}>
                <Typography variant="h4" component="h2" style={{fontWeight: 600, marginBottom: 15}}>{t('Modal.title')}</Typography>
                <Typography className={classes.modalBody1}>{t('Modal.contents_1')}</Typography>
                <Typography className={classes.modalBody2}>{t('Modal.contents_2')}</Typography>
                <Typography className={classes.modalBody1}>{t('Modal.contents_3')}</Typography>
                <Button className={classes.modalBody1} style={{textDecoration: 'underline', padding: 0, marginTop: -10}} size='small' onClick={() => handleDisableModal(false)}>{t('Modal.skipToday')}</Button>
              </Box>
              <Box>
                <Button variant="contained" size='medium' color="primary" onClick={() => setModalOpen(false)} className={classes.modalButton}>{t('Modal.closeButton')}</Button>
              </Box>
            </div>
          </Modal>
      </div> */}
    </div>
  );
};

export default Dashboard;
