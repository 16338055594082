import React, { useState } from 'react';
import MapGL, {
  Marker,
  NavigationControl,
  GeolocateControl
} from 'react-map-gl';
import RoomIcon from '@mui/icons-material/Room'; // 이 예시에서 마커 아이콘으로 사용합니다.

import { Box } from '@mui/material';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

function SingleMarkerMap({ updateLocation, initialMarker, markerDesc }) {
  // props로 initialMarker 받음
  const [viewport, setViewport] = useState({
    latitude: initialMarker.latitude,
    longitude: initialMarker.longitude,
    zoom: 11,
    bearing: 0,
    pitch: 0
  });

  // 초기 마커 상태를 props에서 받은 값으로 설정하거나 null로 설정
  const [marker, setMarker] = useState(initialMarker);

  const onMapClick = event => {
    const { lngLat } = event;
    setMarker({
      latitude: lngLat[1],
      longitude: lngLat[0]
    });
    updateLocation(lngLat[1], lngLat[0]);
    // console.log("singlemarkerMAp",lngLat[1], lngLat[0]);
  };

  return (
    <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
      <MapGL
        {...viewport}
        width="100%"
        height="33vh"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onClick={onMapClick}>
        {marker && ( // 마커 상태가 있으면 마커 표시
          <Marker latitude={marker.latitude} longitude={marker.longitude}>
            <RoomIcon
              style={{ color: '#F06292', transform: 'translate(-50%, -100%)' }}
            />
            {markerDesc && (
              <Box
                style={{
                  color: '#000000',
                  backgroundColor: '#FFFFFF',
                  transform: 'translate(-50%, calc(-100% - 55px))',
                  fontSize: 12,
                  padding: 5,
                  border: '1px solid #455a64',
                  borderRadius: 5
                }}>
                {markerDesc}
              </Box>
            )}
          </Marker>
        )}

        <div className="navi-control">
          <NavigationControl
            style={{ position: 'absolute', top: '6px', left: '6px' }}
          />
        </div>
        <div className="current-location-control">
          <GeolocateControl
            style={{ position: 'absolute', top: '6px', right: '6px' }}
          />
        </div>
      </MapGL>
    </div>
  );
}

export default SingleMarkerMap;
