import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Button, IconButton, useMediaQuery, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';

import NoticeBoard from './components/NoticeBoard';
import { Sidebar, Topbar } from './components';
import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: { backgroundColor: theme.palette.background.colored.main }
  },
  root: {
    height: 'auto',
    backgroundColor: theme.palette.background.colored.main,
    margin: '0 auto',
    paddingTop: '48px',

    [theme.breakpoints.up('lg')]: {
      paddingTop: '60px'
    }
  },
  mainWarp: {
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  sideBar: {
    // [theme.breakpoints.up('lg')]: {
    // },
  },
  content: {
    height: 'auto',
    width: '100%',
    padding: theme.spacing(2.4),
    paddingRight: 0,
    maxWidth: 950,
    [theme.breakpoints.down('lg')]: {
      margin: '0 auto',
      padding: theme.spacing(1.6)
    }
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const { t } = useTranslation(['page']);

  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [noticeId, setNoticeId] = useState('latest');
  const [openNoticeDialog, setOpenNoticeDialog] = useState(false);

  const riduckNotice = cookie.load('riduck_notice');
  const riduckSeorakNotice = cookie.load('riduck_seorak_notice');

  const [openSnackbar, setOpenSnackbar] = useState(!Boolean(riduckNotice));
  const [openSeorakSnackbar, setSeorakSnackbar] = useState(
    !Boolean(riduckSeorakNotice)
  );

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenNotice = () => {
    setOpenNoticeDialog(true);
  };

  const handleCloseNotice = () => {
    setOpenNoticeDialog(false);
  };

  const handleCloseSeorakSnackbar = () => {
    setSeorakSnackbar(false);
  };

  const setCookieLimit24Hour = name => {
    cookie.save(name, 'y', { maxAge: 60 * 60 * 24 });
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const SnackbarAction = handleClick => (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClick}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      {pathname === '/_analyzer' ? (
        <Snackbar
          open={openSeorakSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          message={
            <Button
              color="primary"
              style={{ padding: 0 }}
              onClick={() => {
                setNoticeId(2);
                handleOpenNotice();
                handleCloseSeorakSnackbar();
              }}>
              설악 그란폰도 공지사항 확인하기
            </Button>
          }
          action={SnackbarAction(() => {
            handleCloseSeorakSnackbar();
            setCookieLimit24Hour('riduck_seorak_notice');
          })}
        />
      ) : null}
      {pathname === '/_analyzer' ? (
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          message={
            <Button
              color="primary"
              style={{ padding: 0 }}
              onClick={() => {
                setNoticeId('latest');
                handleOpenNotice();
                handleSnackbarClose();
              }}>
              {t('Notice.viewNotice')}
            </Button>
          }
          action={SnackbarAction(() => {
            handleSnackbarClose();
            setCookieLimit24Hour('riduck_notice');
          })}
        />
      ) : null}

      <div className={classes.mainWarp}>
        <Sidebar
          className={classes.sideBar}
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <main className={classes.content} style={{ height: 'auto' }}>
          {children}
          <Footer />
        </main>
      </div>

      {/* 공지글 다이얼로그 */}
      {openNoticeDialog && (
        <NoticeBoard
          postId={noticeId}
          openNotice={openNoticeDialog}
          handleCloseNotice={handleCloseNotice}
        />
      )}
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
