import React, { useState } from 'react';

import { formatNumber } from 'utils/helper';
import { useFormatDate } from 'utils/useFormatDate';
import {
  addStravaActivityLike,
  addStravaActivityExcellent,
  deleteStravaActivityLike,
  deleteStravaActivityExcellent,
  getUserInfoApi
} from 'utils/clientApis';

import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Button,
  Hidden,
  Card,
  Box,
  CardContent,
  Typography
} from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import SpeedIcon from '@mui/icons-material/Speed';
import MovingIcon from '@mui/icons-material/Moving';
import BarChart from '@mui/icons-material/BarChart';
import RssFeed from '@mui/icons-material/RssFeed';
import BoltIcon from '@mui/icons-material/Bolt';
import LaunchIcon from '@mui/icons-material/Launch';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import Activity from 'views/Peloton/Club/Menu/StreamList/Activity';
import ExcellentExplainDialog from './ExcellentExplainDialog';
import ExcellentDoneDialog from './ExcellentDoneDialog';
import { ProfilePop } from 'views/Peloton/Common/ProfilePopModal';

const useStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    // '& > *': {
    //   marginRight: '6px'
    // }
    '&:last-child': {
      marginBottom: 0
    }
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column'
  },
  nick: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //marginTop: theme.spacing(1),
    padding: '12px 12px 4px 12px',
    '& > *': {
      marginRight: '6px'
    }
  },
  cards: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {}
  },
  stravaLink: {
    color: '#FC4C02',
    fontSize: '12px',
    cursor: 'pointer'
  },
  cardMain: {
    padding: '8px',
    '&:last-child': {
      paddingBottom: '12px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px'
    }
  },
  cardMap: {
    // width: '30%',
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: '12px'
    }
  },
  cardData: {
    display: 'flex',
    gap: '4px'
  },
  cardDataText: {
    fontSize: '12px'
  },
  titleBox: {
    marginBottom: '12px',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '& > h3': {
      fontSize: '16px',
      marginRight: '2px'
    },
    [theme.breakpoints.up('md')]: {
      '& > h3': {
        fontSize: '20px'
      }
    }
  },
  launchIcon: {
    marginLeft: '4px',
    fontSize: '16px !important',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px !important'
    }
  },
  buttonContainer: {
    padding: '8px 16px',
    paddingTop: '0',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: '8px',
      paddingTop: '0'
    }
  },
  button: { flexGrow: 1 },
  buttonIcon: {
    marginRight: '8px',
    '&.selected': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px !important'
    }
  },
  buttonText: {
    '&.selected': {
      color: theme.palette.primary.main
    }
  }
}));

export default function StreamCard({
  card,
  clubId,
  PolylineToSVG,
  isPossibleExcellentReaction,
  setIsPossibleExcellentReaction,
  myClubUserId
}) {
  const [activityDialogOpen, setActivityDialogOpen] = useState(false);
  const [isMyLike, setIsMyLike] = useState(Boolean(card.myLikeCount));
  const [isMyExcellent, setIsMyExcellent] = useState(
    Boolean(card.myExcellentCount)
  );
  const [
    isExcellentExplainDialogOpen,
    setIsExcellentExplainDialogOpen
  ] = useState(false);
  const [isExcellentDoneDialogOpen, setIsExcellentDoneDialogOpen] = useState(
    false
  );

  const [selectedUser, setSelectedUser] = useState();
  const [profileOpen, setProfileOpen] = useState(false);

  const likeCountWithoutMe = card.likeCount - card.myLikeCount;
  const excellentCountWithoutMe = card.excellentCount - card.myExcellentCount;

  const classes = useStyles();
  const { formatTimestamp } = useFormatDate();

  /* 프로필 아바타 */
  const handleClickProfile = userId => {
    setSelectedUser(userId);
    setProfileOpen(true);
  };
  const getUserInfo = () => getUserInfoApi(clubId, selectedUser);

  /* 찬사 모달 handler */
  const handleOpenExcellentExplainDialog = () => {
    setIsExcellentExplainDialogOpen(true);
  };
  const handleCloseExcellentExplainDialog = () => {
    setIsExcellentExplainDialogOpen(false);
  };
  const handleOpenExcellentDoneDialog = () => {
    setIsExcellentDoneDialogOpen(true);
  };
  const handleCloseExcellentDoneDialog = () => {
    setIsExcellentDoneDialogOpen(false);
  };

  /* 좋아요 추가 및 삭제 api */
  const handleAddLike = async () => {
    try {
      const response = await addStravaActivityLike(card.activityId);
      if (response.data.success) {
        setIsMyLike(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteLike = async () => {
    try {
      const response = await deleteStravaActivityLike(card.activityId);
      if (response.data.success) {
        setIsMyLike(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* 찬사 추가 및 삭제  */
  const handleAddExcellent = async () => {
    try {
      const response = await addStravaActivityExcellent(card.activityId);
      if (response.data.success) {
        setIsMyExcellent(true);
        setIsPossibleExcellentReaction(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteExcellent = async () => {
    try {
      const response = await deleteStravaActivityExcellent(card.activityId);
      if (response.data.success) {
        setIsMyExcellent(false);
        setIsPossibleExcellentReaction(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* 찬사 버튼 클릭 */
  const handleClickExcellentButton = () => {
    if (isMyExcellent) handleDeleteExcellent();
    else if (isPossibleExcellentReaction) handleOpenExcellentExplainDialog();
    else handleOpenExcellentDoneDialog();
  };

  /* 좋아요 버튼 클릭 */
  const handleClickLikeButton = () => {
    if (isMyLike) handleDeleteLike();
    else handleAddLike();
  };

  return <>
    <Box>
      <Card className={classes.cards} key={card.id}>
        {/* 아바타 */}
        <Box
          className={classes.nick}
          style={{
            cursor: 'pointer'
          }}
          onClick={() => handleClickProfile(card.clubUserId)}>
          <Avatar
            src={card.profileImage}
            style={{
              width: '26px',
              height: '26px',
              marginRight: '8px'
            }}
          />
          <Typography variant="h6" color="textPrimary">
            {card.displayName}
          </Typography>
        </Box>
        {/* 주 내용 (제목, 시간, 각종 기록들) */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}>
          <CardContent className={classes.cardMain}>
            <div className={classes.info}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  gap: 8
                }}>
                <Hidden mdUp>
                  <Box style={{ width: 70 }}>
                    <PolylineToSVG
                      width={60}
                      height={60}
                      polyline={card.summaryPolyline}
                      style={{ marginRight: 8 }}
                    />
                  </Box>
                </Hidden>
                <Box className={classes.titleSection}>
                  <Box
                    className={classes.titleBox}
                    onClick={() => {
                      setActivityDialogOpen(true);
                    }}>
                    <Typography variant="h3">{card.activityName}</Typography>
                    <LaunchIcon
                      // fontSize="small"
                      className={classes.launchIcon}
                    />
                  </Box>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{
                        marginRight: '8px',
                        fontSize: '12px'
                      }}>
                      {formatTimestamp(
                        String(card.startTimestamp),
                        'Asia/Seoul'
                      )}
                    </Typography>
                    <a
                      color="secondary"
                      size="xs"
                      variant="text"
                      target="_blank"
                      href={`https://www.strava.com/activities/${card.activityId}`}
                      className={classes.stravaLink}>
                      View on Strava
                    </a>
                  </div>
                </Box>
              </Box>
            </div>
            {/* 거리, 평속, 상승고도 */}
            <div className={classes.info}>
              <Box className={classes.cardData}>
                <RouteIcon />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`거리: ${(card.distance / 1000).toFixed(2)}km`}
                </Typography>
              </Box>
              <Box className={classes.cardData}>
                <SpeedIcon />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`평속: ${(card.averageSpeed * 3.6).toFixed(2)}km/h`}
                </Typography>
              </Box>
              <Box className={classes.cardData}>
                <MovingIcon />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`상승고도: ${formatNumber(card.totalElevationGain)}m`}
                </Typography>
              </Box>
            </div>
            {/* 강도, 훈련량, 평균파워 */}
            <div className={classes.info}>
              <Box className={classes.cardData}>
                <RssFeed />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`강도: ${card.intensity}`}
                </Typography>
              </Box>
              <Box className={classes.cardData}>
                <BarChart />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`훈련량: ${card.tss}`}
                </Typography>
              </Box>
              <Box className={classes.cardData}>
                <BoltIcon />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.cardDataText}>
                  {`평균파워: ${card.averageWatts.toFixed(0)}w`}
                </Typography>
              </Box>
            </div>
          </CardContent>
          <Hidden mdDown>
            <CardContent className={classes.cardMap}>
              <PolylineToSVG
                polyline={card.summaryPolyline}
                width={125}
                height={125}
              />
            </CardContent>
          </Hidden>
        </Box>
        {/* 좋아요, 찬사 버튼 - 내 활동에는 반응 불가 */}
        {card.clubUserId !== myClubUserId && (
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={handleClickLikeButton}>
              {isMyLike ? (
                <ThumbUpAltIcon
                  className={`${classes.buttonIcon} selected`}
                />
              ) : (
                <ThumbUpOffAltIcon className={classes.buttonIcon} />
              )}
              <Typography
                variant="body1"
                color="textPrimary"
                className={
                  isMyLike
                    ? classes.buttonText + ' selected'
                    : classes.buttonText
                }>
                좋아요{' '}
                {likeCountWithoutMe + (isMyLike ? 1 : 0)
                  ? likeCountWithoutMe + (isMyLike ? 1 : 0)
                  : ''}
              </Typography>
            </Button>
            <Button
              className={classes.button}
              onClick={handleClickExcellentButton}>
              {isMyExcellent ? (
                <AutoAwesomeIcon
                  className={`${classes.buttonIcon} selected`}
                />
              ) : (
                <AutoAwesomeOutlinedIcon className={classes.buttonIcon} />
              )}
              <Typography
                variant="body1"
                color="textPrimary"
                className={
                  isMyExcellent
                    ? classes.buttonText + ' selected'
                    : classes.buttonText
                }>
                대단해요{' '}
                {excellentCountWithoutMe + (isMyExcellent ? 1 : 0)
                  ? excellentCountWithoutMe + (isMyExcellent ? 1 : 0)
                  : ''}
              </Typography>
            </Button>
          </Box>
        )}
      </Card>
    </Box>
    {/* 다이얼로그 */}
    {activityDialogOpen ? (
      <Activity
        activityId={card.activityId}
        handleCloseDialog={() => setActivityDialogOpen(false)}
      />
    ) : null}
    {isExcellentExplainDialogOpen ? (
      <ExcellentExplainDialog
        isOpen={isExcellentExplainDialogOpen}
        handleCloseDialog={handleCloseExcellentExplainDialog}
        handleAddExcellent={handleAddExcellent}
      />
    ) : null}
    {isExcellentDoneDialogOpen ? (
      <ExcellentDoneDialog
        isOpen={isExcellentDoneDialogOpen}
        handleCloseDialog={handleCloseExcellentDoneDialog}
      />
    ) : null}
    {profileOpen && selectedUser && (
      <ProfilePop
        openState={profileOpen}
        setOpen={setProfileOpen}
        getUserInfo={getUserInfo}
      />
    )}
  </>;
}
