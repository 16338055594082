import React from 'react';
//import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Paper } from '@mui/material';
//import PeopleIcon from '@mui/icons-material/PeopleOutlined';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px'
  },
  h4: {
    fontWeight: '700',
    marginBottom: '10px'
  },
  content: {
    fontWeight: 300,
    alignItems: 'center'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const HowToUse = props => {
  //  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid item sm={12} lg={12}>
        기본적인 틀만 갖춰진 상태이고 계속 개발중입니다...... (`19.08.15)
      </Grid>

      <Grid item sm={12} lg={12}>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            개발의도
          </Typography>
          <Typography component="p">
            사이클은 다른 운동과 다르게 한 공간에 머물지 않고 목적지를 정하고
            많은 거리를 이동합니다.
            <br />
            때문에 본인의 객관적인 운동역량(파워수치)를 알고 그에 맞는 코스를
            타고, 비슷한 이들을 찾아 어울리는 것은
            <br />
            사이클 취미를 지속하는데 있어 상당히 중요한 부분입니다. 하지만
            일반적으로 본인의 파워수치를 측정하는 방법은 비싸고 어렵습니다.
            <br />
            이 시뮬레이터는 고가의 파워미터, 사이클링컴퓨터가 없어도 자전거와
            스마트만으로 본인의 파워수치를 추정해볼 수 있게 하는데 목적이
            있습니다
            <br />
            <del>
              파미 사봐야 좁쌀파워보고 좌절할거 굳이 돈지랄하고 중고로 내다
              팔것ㅇ......
            </del>
          </Typography>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            사용방법
          </Typography>
          <Typography component="p">
            -본인의 신체프로필과 자전거 종류를 입력합니다.
            <br />
            -주변의 업힐구간(가능하면)을 찾아 스마트폰 자전거 속도계 등을 통해
            기록을 측정합니다.
            <br />
            -속도와 거리, 획득고도 등의 값을 입력합니다. <br />
            -이를 통해 본인의 w/kg, 파워 등을 추정하거나 운동목표를 세울 수
            있습니다.
          </Typography>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            속도데이터만으로 보다 정확하게 파워를 측정하기
          </Typography>
          <Typography component="p">
            -경사도가 꾸준한 2km 이상의 업힐 기록을 측정하세요 (꽤 정확) <br />
            -경사가 거의 없는 아스팔트 노면의 평지를 '왕복'하는 것 역시 꽤
            정확한 파워를 계산할 수 있습니다. <br />
            -스마트폰 속도계의 경우 평지 고저차가 사이클링컴퓨터에 비해 2~3배
            정도 크게 나오기 때문에 이를 보정해야 합니다.
            <br />
          </Typography>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            자전거 데이터셋
          </Typography>
          <Typography component="p">
            에어로자세와 휠과 타이어 구름저항 변수가 굉장히 커서 우선 대표적인
            5개 데이터로 고정해놓았습니다.
            <br />
            -로드 입문 8.5kg, 콘티넨탈울트라스포츠2 타이어, 후드자세 <br />
            -로드 중상급 7.5kg, 콘티넨탈 5000, 후드+드랍바 중간 자세 <br />
            -TT자전거 9.5, 코르사스피드 튜블러, 에어로헬멧, 에어로자세
            <br />
            -MTB 11.5kg, 레이스킹
            <br />
            -따릉이 18kg, 따릉이 타는 자세
            <br />
            (세분화할 계획)
          </Typography>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            실제 파워데이터와 차이를 만드는 요소들
          </Typography>
          <Typography component="p">
            -평속이 15km/h 이상에서 드래프팅에 의한 기록차이는 매우 큽니다.{' '}
            <br />
            -업힐 중간에 다운힐이나 평지가 포함된 경우 차이가 커집니다. <br />
            -평지는 당연히 바람의 영향이 가장 큽니다. (왕복기록)
            <br />
            -댄싱을 지나치게 많이 하면 힘손실이 많아 파워 대비 기록이 좋지
            않습니다. <br />
            -업힐에서 좌우로 핸들을 크게 틀면서 가면 측정 거리자체가 늘어납니다.{' '}
            <br />
            -무게 1kg 차이는 구름저항이나 공기저항 등에 비하면 무시할 수 있는
            차이입니다. <br />
            -파워미터 기기 자체도 의심할 필요성이 있습니다. 캘리브레이션이 잘
            안되어있는 경우도 많고, 외발형 파워미터들은 오차가 큰 제품들이
            많습니다.
            <br />
          </Typography>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="h4" component="h4" className={classes.h4}>
            참고한 자료 및 도움주신 분들
          </Typography>
          <Typography component="p">
            아이작뉴튼형님
            <br />
            스트라바 파워미터 데이터 올리는 고인물들
            <br />
            한줌도 안되는 자전거판 인맥
            <br />
            내 몸둥이
            <br />
            cyclingpowerlab.com
            <br />
            trainingpeaks.com
            <br />
            bicyclerollingresistance.com
            <br />
            bikecalculator.com
            <br />
            omnicalculator.com
            <br />
            bergfreunde.eu/cycling-wattage-calculator
            <br />
            youtube.com/watch?v=3P2Vrh3QjBw
            <br />
            youtube.com/watch?v=-S2aJiILpHE
            <br />
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

HowToUse.propTypes = {
  className: PropTypes.string
};

export default HowToUse;
