import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  BikeUI
  //  Budget,
  //  TotalUsers,
  //  HowToUse,
  //  Segment,
  //  TotalProfit,
  //  LatestSales,
  //  UsersByDevice,
  //  LatestProducts,
  //  LatestOrders
} from './Tab';

import _ from 'lodash';
import axios from 'axios';
import auth from '../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  }
}));

const AnalyzerTab = () => {
  const classes = useStyles();

  const extraInfo = {
    gender: 'M',
    height: '175',
    weight: '70',
    helmet: 'road'
  };

  const [gearTab, setGearTab] = React.useState(0);

  const gearinfoDefault = {};
  gearinfoDefault.bike_kit_json = JSON.stringify({
    bike_type: 'road_allround',
    bike_weight: 8,
    crr: 0.005,
    cda: 0.32,
    rim_height: 1,
    drivetrain: 'ultegra',
    tire_product: 'Clincher (Butyl tube)',
    tire_width: 2,
    cadence: 90,
    rider_pose: 2,
    surface_select: 2,
    company_name: '',
    powermeter: ''
  });

  useEffect(() => {}, [gearTab]);

  const handleGearTab = (event, newValue) => {
    //    setGearinfo(gears[newValue]);
    setGearTab(newValue);
  };

  function TabList(props) {
    const gears = props.gears;

    let rows = Array();

    console.log(gears);

    if (gears.length > 0) {
      _.each(gears, function(item, i) {
        let g_id = item.g_id;
        let gear_id = item.gear_id;
        let gear_name = item.gear_name;
        let gear_primary = item.gear_primary;
        let weight = item.weight;
        let company_name = item.company_name ? item.company_name : '';
        let powermeter = item.powermeter_name ? item.powermeter_name : '';

        let d = {
          i,
          g_id,
          gear_id,
          gear_name,
          gear_primary,
          weight,
          company_name,
          powermeter
        };

        rows.push(d);
      });

      return (
        <Tabs
          value={gearTab}
          onChange={handleGearTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          {rows.map((row, i) => (
            <Tab key={row.i} label={row.gear_name} />
          ))}
        </Tabs>
      );
    } else {
      return (
        <Tabs
          value={0}
          onChange={handleGearTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          {<Tab key={0} label={'My Bike'} />}
        </Tabs>
      );
    }
  }

  function BikeUIByGear(props) {
    return <BikeUI gearinfo={gearinfoDefault} />;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList gears={0} />
      </AppBar>
      <Box className={classes.tabPanel}>
        <BikeUIByGear gearinfo={gearinfoDefault} gearTab={gearTab} />
      </Box>
    </div>
  );
};

export default AnalyzerTab;
