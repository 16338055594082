import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';

import i18n from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px'
    }
  },
  listContent: {},
  listHeader: {
    display: 'flex',
    paddingBottom: '10px',
    maxWidth: '400px',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%'
    }
  },
  listHeaderStandard: {
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    }
  },
  listHeaderDetail: {
    width: '20%',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    }
  },
  listItemWarp: {
    padding: '10px 8px'
  },
  primaryZones: {
    textAlign: 'center',
    margin: '0px auto 8px'
  },
  primaryZonesSmall: {
    textAlign: 'left',
    margin: '0px auto 0px'
  },
  zoneCircle: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    margin: '4px 4px 4px 4px',
    textAlign: 'center',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      zoom: 0.8,
      margin: '4px 2px 4px 2px'
    },
    [theme.breakpoints.down('md')]: {
      zoom: 0.6,
      margin: '4px 2px 4px 0px'
    }
  },
  zoneCircleFixed: {
    width: '30px',
    height: '30px',
    lineHeight: '24px',
    fontSize: '14px',
    margin: '4px 3px',
    textAlign: 'center',
    display: 'inline-block',
    zoom: 0.9
  },
  zoneCircleSmall: {
    width: '30px',
    height: '30px',
    lineHeight: '24px',
    fontSize: '14px',
    margin: '4px 3px',
    textAlign: 'center',
    display: 'inline-block',
    zoom: 0.65
  },
  zOn: { opacity: 1.0 },
  zOff: { opacity: 0.2 },
  z1: {
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z2: {
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z3: {
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z4: {
    backgroundColor: '#ffd357',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z5: {
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z6: {
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  z7: {
    backgroundColor: '#f06292',
    color: '#ffffff',
    border: '3px #ddd solid'
  },
  animalEmoji: {
    fontSize: 42,
    lineHeight: '42px',
    marginRight: '15px'
  },
  animalZoneWarp: {},
  animalTitleWarp: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      width: '100%'
    }
  },
  animalTitle: {
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minWidth: '240px',
    maxWidth: '400px',
    margin: '0 auto',
    width: '100%'
    // [theme.breakpoints.between("sm","lg")]: {
    //   width: '245px',
    // }
  },
  activityTotalWarp: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '3px 0 5px',
    fontSize: 12,
    color: '#aaa',
    maxWidth: '280px',
    minWidth: '170px',
    width: '100%',
    marginLeft: '10px'
    // [theme.breakpoints.between("sm","lg")]: {
    //   maxWidth: '120px',
    //   textAlign: 'left',
    // }
  },
  activityTotalDownWarp: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: '3px 0 5px',
    fontSize: 12,
    color: '#aaa',
    maxWidth: '400px',
    margin: '0 auto'
  },
  activityTotalBold: {
    fontWeight: 700,
    fontSize: 20,
    marginLeft: '5px'
  },
  animalDescDown: {
    padding: '20px 20px 0px',
    maxWidth: '500px',
    margin: '0 auto'
  },
  animalDescDownTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px',
    color: theme.palette.text.forth
  },
  animalDescDownText1: {
    marginBottom: '5px',
    color: theme.palette.text.forth
  },
  animalDescDownText2: {},
  animalDescTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px',
    color: '#444',
    minWidth: '240px',
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.between('sm', 'xl')]: {
      width: '240px',
      textAlign: 'left'
    }
  },
  animalDesc: {
    display: 'flex',
    gap: '10px',
    width: '100%'
  },
  animalDescText1: {
    width: '50%',
    marginBottom: '5px',
    color: '#444',
    fontWeight: 700
  },
  animalDescText2: {
    width: '50%'
  }
  // activityTotalTextWarp: {
  //   textAlign: 'center',
  //   [theme.breakpoints.up("lg")]: {
  //     textAlign: 'left',
  //   }
  // },
}));

const PowerZone = props => {
  const { powerzoneData } = props;
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  // 설명 토글
  const [isDescOpen, setIsDescOpen] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  // 창 토글
  function toggleDesc(number) {
    let newIsdescOpenArray = [...isDescOpen];
    newIsdescOpenArray.splice(number, 1, !isDescOpen[number]);
    setIsDescOpen(newIsdescOpenArray);
  }

  function PrimaryZones(props) {
    let zonesArr = [1, 2, 3, 4, 5, 6, 7];
    const primaryZonesArr = props.primaryZones;
    const size = props.size;

    let primaryZones = classes.primaryZones;
    let zoneCircle = classes.zoneCircle;
    if (size === 'fixed') {
      zoneCircle = classes.zoneCircleFixed;
    } else if (size === 'small') {
      primaryZones = classes.primaryZonesSmall;
      zoneCircle = classes.zoneCircleSmall;
    }

    return (
      <Box className={primaryZones}>
        {zonesArr.map((zone, index) => (
          <Avatar
            component="span"
            className={
              primaryZonesArr[index] === 1
                ? clsx([zoneCircle, eval(`classes.z${zone}`), classes.zOn])
                : clsx([zoneCircle, eval(`classes.z${zone}`), classes.zOff])
            }>
            Z{zone}
          </Avatar>
        ))}
      </Box>
    );
  }

  function TypeDesc(typeStr) {
    let animalEmoji = '🦆';
    let trainingType = t('Animals.type.etc');
    let primaryZones = Array(0, 0, 0, 0, 0, 0, 0);
    let effectChips = Array(
      t('Animals.effects.etc.0'),
      t('Animals.effects.etc.1')
    );
    let animalTitle = t('Animals.animal.etc');
    let animalDesc = t('Animals.animalDesc.etc');
    let trainingDesc = t('Animals.trainingDesc.etc');
    let whenMeet = t('Animals.whenMeet.etc');

    if (typeStr == 'SPT') {
      animalEmoji = '🦁';
      trainingType = t('Animals.type.spt');
      primaryZones = Array(0, 0, 0, 0, 0, 0, 1);
      effectChips = Array(
        t('Animals.effects.spt.0'),
        t('Animals.effects.spt.1'),
        t('Animals.effects.spt.2'),
        t('Animals.effects.spt.3')
      );
      animalTitle = t('Animals.animal.spt');
      animalDesc = t('Animals.animalDesc.spt');
      trainingDesc = t('Animals.trainingDesc.spt');
      whenMeet = t('Animals.whenMeet.spt');
    } else if (typeStr == 'HIIT') {
      animalEmoji = '🐆';
      trainingType = t('Animals.type.hiit');
      primaryZones = Array(0, 0, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.hiit.0'),
        t('Animals.effects.hiit.1'),
        t('Animals.effects.hiit.2'),
        t('Animals.effects.hiit.3')
      );
      animalTitle = t('Animals.animal.hiit');
      animalDesc = t('Animals.animalDesc.hiit');
      trainingDesc = t('Animals.trainingDesc.hiit');
      whenMeet = t('Animals.whenMeet.hiit');
    } else if (typeStr == 'VO2MAX') {
      animalEmoji = '🐺';
      trainingType = t('Animals.type.vo2max');
      primaryZones = Array(0, 0, 0, 1, 1, 0, 0);
      effectChips = Array(
        t('Animals.effects.vo2max.0'),
        t('Animals.effects.vo2max.1'),
        t('Animals.effects.vo2max.2')
      );
      animalTitle = t('Animals.animal.vo2max');
      animalDesc = t('Animals.animalDesc.vo2max');
      trainingDesc = t('Animals.trainingDesc.vo2max');
      whenMeet = t('Animals.whenMeet.vo2max');
    } else if (typeStr == 'RPT') {
      animalEmoji = '🐴';
      trainingType = t('Animals.type.rpt');
      primaryZones = Array(0, 0, 1, 1, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.rpt.0'),
        t('Animals.effects.rpt.1'),
        t('Animals.effects.rpt.2')
      );
      animalTitle = t('Animals.animal.rpt');
      animalDesc = t('Animals.animalDesc.rpt');
      trainingDesc = t('Animals.trainingDesc.rpt');
      whenMeet = t('Animals.whenMeet.rpt');
    } else if (typeStr == 'SST') {
      animalEmoji = '🐗';
      trainingType = t('Animals.type.sst');
      primaryZones = Array(0, 0, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.sst.0'),
        t('Animals.effects.sst.1'),
        t('Animals.effects.sst.2')
      );
      animalTitle = t('Animals.animal.sst');
      animalDesc = t('Animals.animalDesc.sst');
      trainingDesc = t('Animals.trainingDesc.sst');
      whenMeet = t('Animals.whenMeet.sst');
    } else if (typeStr == 'TEMPO') {
      animalEmoji = '🐷';
      trainingType = t('Animals.type.tempo');
      primaryZones = Array(0, 0, 1, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.tempo.0'),
        t('Animals.effects.tempo.1'),
        t('Animals.effects.tempo.2')
      );
      animalTitle = t('Animals.animal.tempo');
      animalDesc = t('Animals.animalDesc.tempo');
      trainingDesc = t('Animals.trainingDesc.tempo');
      whenMeet = t('Animals.whenMeet.tempo');
    } else if (typeStr == 'BASE') {
      animalEmoji = '🐮';
      trainingType = t('Animals.type.base');
      primaryZones = Array(1, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.base.0'),
        t('Animals.effects.base.1'),
        t('Animals.effects.base.2'),
        t('Animals.effects.base.3')
      );
      animalTitle = t('Animals.animal.base');
      animalDesc = t('Animals.animalDesc.base');
      trainingDesc = t('Animals.trainingDesc.base');
      whenMeet = t('Animals.whenMeet.base');
    } else if (typeStr == 'HVLI') {
      animalEmoji = '🐫';
      trainingType = t('Animals.type.hvli');
      primaryZones = Array(0, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.hvli.0'),
        t('Animals.effects.hvli.1'),
        t('Animals.effects.hvli.2'),
        t('Animals.effects.hvli.3')
      );
      animalTitle = t('Animals.animal.hvli');
      animalDesc = t('Animals.animalDesc.hvli');
      trainingDesc = t('Animals.trainingDesc.hvli');
      whenMeet = t('Animals.whenMeet.hvli');
    } else if (typeStr == 'POL') {
      animalEmoji = '🐘';
      trainingType = t('Animals.type.pol');
      primaryZones = Array(0, 1, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.pol.0'),
        t('Animals.effects.pol.1'),
        t('Animals.effects.pol.2'),
        t('Animals.effects.pol.3')
      );
      animalTitle = t('Animals.animal.pol');
      animalDesc = t('Animals.animalDesc.pol');
      trainingDesc = t('Animals.trainingDesc.pol');
      whenMeet = t('Animals.whenMeet.pol');
    } else if (typeStr == 'PRMD') {
      animalEmoji = '🦌';
      trainingType = t('Animals.type.prmd');
      primaryZones = Array(0, 1, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.prmd.0'),
        t('Animals.effects.prmd.1'),
        t('Animals.effects.prmd.2'),
        t('Animals.effects.prmd.3')
      );
      animalTitle = t('Animals.animal.prmd');
      animalDesc = t('Animals.animalDesc.prmd');
      trainingDesc = t('Animals.trainingDesc.prmd');
      whenMeet = t('Animals.whenMeet.prmd');
    } else if (typeStr == 'BAL') {
      animalEmoji = '🐶';
      trainingType = t('Animals.type.bal');
      primaryZones = Array(1, 1, 1, 1, 1, 1, 1);
      effectChips = Array(
        t('Animals.effects.bal.0'),
        t('Animals.effects.bal.1'),
        t('Animals.effects.bal.2'),
        t('Animals.effects.bal.3')
      );
      animalTitle = t('Animals.animal.bal');
      animalDesc = t('Animals.animalDesc.bal');
      trainingDesc = t('Animals.trainingDesc.bal');
      whenMeet = t('Animals.whenMeet.bal');
    } else if (typeStr == 'RECV') {
      animalEmoji = '🐢';
      trainingType = t('Animals.type.recv');
      primaryZones = Array(1, 0, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.recv.0'),
        t('Animals.effects.recv.1'),
        t('Animals.effects.recv.2')
      );
      animalTitle = t('Animals.animal.recv');
      animalDesc = t('Animals.animalDesc.recv');
      trainingDesc = t('Animals.trainingDesc.recv');
      whenMeet = t('Animals.whenMeet.recv');
    }

    // 전체 액티비티 동물 갯수 시간 계산
    const activityTotalData = calcActivityTotalData(typeStr);

    return {
      typeStr: typeStr,
      animalEmoji: animalEmoji,
      trainingType: trainingType,
      primaryZones: primaryZones,
      effectChips: effectChips,
      animalTitle: animalTitle,
      animalDesc: animalDesc,
      trainingDesc: trainingDesc,
      whenMeet: whenMeet,
      activityTotalCount: activityTotalData.totalCount,
      activityTotalSecond: activityTotalData.totalSecond
    };
  }

  // 전체 액티비티 동물 갯수 시간 계산
  function calcActivityTotalData(typeStr) {
    let totalCount = 0;
    let totalSecond = 0;

    _.each(powerzoneData.powerzone_activities, function(item, id) {
      if (item.type === typeStr) {
        totalCount++;
        totalSecond += item.total;
      }
    });

    return {
      totalCount: totalCount,
      totalSecond: totalSecond
    };
  }

  let animalGuides = [
    TypeDesc('SPT'),
    TypeDesc('HIIT'),
    TypeDesc('VO2MAX'),
    TypeDesc('RPT'),
    TypeDesc('SST'),
    TypeDesc('TEMPO'),
    TypeDesc('BAL'),
    TypeDesc('HVLI'),
    TypeDesc('BASE'),
    TypeDesc('POL'),
    TypeDesc('PRMD'),
    TypeDesc('RECV')
  ];

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '00:00';
    } else if (h > 0) {
      let hd = h > 0 ? (h < 10 ? '0' + h + ':' : h + ':') : '00';
      let md = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00:';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return hd + md + sd;
    } else if (h <= 0 && m > 0) {
      let md = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else if (m <= 0 && s > 0) {
      let md = '00:';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else {
      return '00:00';
    }
  }

  function getMonToToday(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let resultDay = new Date(theYear, theMonth, theDate + (1 - theDayOfWeek));
    let mm = Number(resultDay.getMonth()) + 1;
    let dd = resultDay.getDate();

    mm = String(mm).length === 1 ? '0' + mm : mm;
    dd = String(dd).length === 1 ? '0' + dd : dd;

    let td = new Date();

    let mm2 = Number(td.getMonth()) + 1;
    let dd2 = td.getDate();

    mm2 = String(mm2).length === 1 ? '0' + mm2 : mm2;
    dd2 = String(dd2).length === 1 ? '0' + dd2 : dd2;

    return mm + '/' + dd + ' ~ ' + (mm2 + '/' + dd2);
  }

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <Box className={classes.tabContent}>
          <List className={classes.listContent}>
            <ListSubheader className={classes.listHeader}>
              <Typography
                component="span"
                className={classes.listHeaderStandard}>
                {t('Powerzone.standard')}
                {' (' + getMonToToday(11) + ')'}
              </Typography>
              <Typography component="span" className={classes.listHeaderDetail}>
                {t('Powerzone.detail')}
              </Typography>
            </ListSubheader>
            <Divider />
            {animalGuides.map((guide, index) => (
              <React.Fragment>
                <ListItem
                  className={classes.listItemWarp}
                  key={index}
                  divider={true}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box className={classes.animalTitleWarp}>
                          <Box className={classes.animalTitle}>
                            <Box className={classes.animalEmoji}>
                              {guide.animalEmoji}
                            </Box>
                            <Box className={classes.animalZoneWarp}>
                              <Typography component="span">
                                {guide.trainingType +
                                  ' (' +
                                  guide.typeStr +
                                  ')'}
                              </Typography>
                              <PrimaryZones
                                primaryZones={guide.primaryZones}
                                size={'small'}
                              />
                            </Box>
                            <ExpandMoreIcon
                              onClick={() => {
                                toggleDesc(index);
                              }}
                              style={{
                                cursor: 'pointer',
                                transform: isDescOpen[index]
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)'
                              }}
                            />
                          </Box>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <Box>
                        <Box className={classes.activityTotalDownWarp}>
                          <Box className={classes.activityTotalTextWarp}>
                            <Typography component="span">
                              {t('Powerzone.countMet')}
                            </Typography>
                            <Typography
                              component="span"
                              className={classes.activityTotalBold}>
                              {guide.activityTotalCount < 10 && '0'}
                              {guide.activityTotalCount}
                            </Typography>
                          </Box>
                          <Box className={classes.activityTotalTextWarp}>
                            <Typography component="span">
                              {t('Powerzone.timeTogther')}
                            </Typography>
                            <Typography
                              component="span"
                              className={classes.activityTotalBold}>
                              {secondsToHmsLabel(
                                Number(guide.activityTotalSecond)
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        {isDescOpen[index] && (
                          <Box className={classes.animalDescDown}>
                            <Box className={classes.animalDescDownTitle}>
                              “{guide.animalTitle}”
                            </Box>
                            <Box className={classes.animalDescDownText1}>
                              {guide.trainingDesc}
                            </Box>
                            <Box className={classes.animalDescDownText2}>
                              {guide.whenMeet}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </React.Fragment>
  );
};

PowerZone.propTypes = {
  history: PropTypes.object
};

export default withRouter(PowerZone);
