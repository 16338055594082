import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/helper.js';
import { useFormatDate } from 'utils/useFormatDate';
import { imageSize } from 'utils/helper';
import { getImageUrl } from 'utils/getImageUrl';
import { useSimpledText } from 'utils/useSimpledText';
//
import { makeStyles } from '@mui/styles';
import {
  Card,
  Box,
  CardContent,
  Typography,
  CardHeader,
  Divider
} from '@mui/material';
//
import ReplyIcon from '@mui/icons-material/Reply';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useStyles = makeStyles(theme => ({
  info: {
    display: 'flex',
    justifyContent: 'start',
    '& > *': {
      marginRight: '8px'
    }
  },
  viewReplyInfo: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '& > *': {
      marginRight: '6px'
    }
  },
  cards: {
    marginBottom: '16px'
  },
  header: {
    height: '60px',
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    padding: '16px'
  }
}));

export default function PostCard({ post, idx, uriName, clubProp, page }) {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const { truncateText } = useSimpledText();
  const prop = clubProp;
  const currentPage = page;

  /**
   * @return : 포매팅 된 연월일시 구현부
   */
  const { formatDateWithTime } = useFormatDate();

  function FormatPostDate({ dateData }) {
    const formattedDate = formatDateWithTime(dateData);
    return <>{formattedDate}</>;
  }

  return (
    <React.Fragment key={idx}>
      <RouterLink
        to={{
          pathname: `/peloton/${uriName}/post/${String(post.id)}`,
          state: {
            prop: prop.data,
            postId: post.id,
            userState: prop.data.myClubUserResponse,
            pageNum: currentPage && currentPage
          }
        }}>
        <Card className={classes.cards}>
          <CardHeader
            className={classes.header}
            title={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Typography variant="h6" component="div">
                  {truncateText(post.title, 20)}
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ marginRight: '4px' }}>
                    {post.clubUserResponse?.displayName}
                  </Typography>
                  <Typography variant="body2" component="div">
                    <FormatPostDate dateData={post.regDate} />
                  </Typography>
                </Box>
              </div>
            }
          />
          <Divider />
          {/*  */}
          <CardContent>
            {post.clubPostFiles?.length !== 0 ? (
              <Box className={classes.info}>
                <div
                  style={{
                    overflow: 'hidden',
                    borderRadius: '6px',
                    marginRight: '8px',
                    width: '60px',
                    height: '60px'
                  }}>
                  <img src={getImageUrl(post.clubPostFiles, imageSize.SM)} />
                </div>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItem: 'center',
                    justifyContent: 'start'
                  }}>
                  {truncateText(post.content, 20)}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.info}>
                <Typography
                  variant="body1"
                  style={{
                    display: 'flex',
                    alignItem: 'start',
                    justifyContent: 'start'
                  }}>
                  {truncateText(post.content, 100)}
                </Typography>
              </Box>
            )}
            <div className={classes.viewReplyInfo}>
              <VisibilityIcon sx={{ width: '24px' }} />
              <Typography variant="body2" color="textPrimary">
                {formatNumber(post.viewCount)}
              </Typography>
              <ReplyIcon sx={{ width: '24px' }} />
              <Typography variant="body2" color="textPrimary">
                {post.commentCount}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </RouterLink>
    </React.Fragment>
  );
}
