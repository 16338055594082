/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import {
  Typography,
  List,
  ListItem,
  Box,
  Collapse,
  Button,
  colors,
  Divider
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  subItem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2)
  },
  button: {
    padding: '8px 32px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 400,
    fontSize: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      padding: '6px 24px'
    }
  },
  topMenuButton: {
    padding: '6px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 400,
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      padding: '4px 16px'
    }
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  new: {
    // position: 'relative',
    marginLeft: '4px',
    '& $icon': {
      position: 'absolute',
      top: '0px',
      color: '#F06292',
      fontSize: '8px'
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const {
    mainMenu,
    activityMenu,
    className,
    onSidebarOpen,
    isAdmin,
    ...rest
  } = props;
  const classes = useStyles();

  function TargetButton(props) {
    const menu = props.menu;
    const topMenu = menu.type === 'topMenu';
    const subMenu = menu.type === 'subMenu';

    return (
      <Button
        activeClassName={classes.active}
        className={topMenu ? classes.topMenuButton : classes.button}
        component={CustomRouterLink}
        to={menu.href}
        style={{ opacity: menu.isPrivate ? 0.5 : 1 }}>
        {menu.title}
        {menu.new === true && (
          <Box component="span" className={classes.new}>
            <Typography variant="overline" className={classes.icon}>
              N
            </Typography>
          </Box>
        )}
      </Button>
    );
  }

  const [isCollapsed, setIsCollapsed] = useState(
    Array(mainMenu.length).fill(true)
  );

  const handleToggle = index => {
    setIsCollapsed(prev => {
      const newCollapsedState = [...prev];
      newCollapsedState[index] = !newCollapsedState[index];
      return newCollapsedState;
    });
  };

  // const handleAddAccordion = () => {
  //   setIsCollapsed((prev) => [...prev, false]);
  // };

  useEffect(() => {}, []);

  return (
    <List component="nav" className={clsx(classes.root, className)}>
      {mainMenu.map((menu, i) => {
        const isSubmenu = menu.subMenu?.length > 0;
        const divider = menu.type === 'divider';
        const hide = menu.hide;

        if (hide) {
          return <React.Fragment key={i}></React.Fragment>;
        } else if (divider) {
          return (
            <React.Fragment key={i}>
              <Divider style={{ margin: '8px 0 8px 0' }} />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={i}>
              {(!menu.isPrivate || (menu.isPrivate && isAdmin)) && (
                <React.Fragment>
                  <ListItem
                    className={classes.item}
                    disableGutters
                    key={menu.title}>
                    {isSubmenu ? (
                      <React.Fragment>
                        <Box
                          className={classes.button}
                          onClick={() => handleToggle(i)}>
                          {menu.title}
                        </Box>
                        <Box onClick={() => handleToggle(i)}>
                          {isCollapsed[i] ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <TargetButton menu={menu} />
                      </React.Fragment>
                    )}
                  </ListItem>
                  {menu.subMenu?.map((subMenu, ii) => {
                    return (
                      <Collapse
                        in={isCollapsed[i]}
                        timeout="auto"
                        unmountOnExit
                        key={ii}>
                        <ListItem
                          className={classes.subItem}
                          disableGutters
                          key={subMenu.title}>
                          <TargetButton menu={subMenu} />
                        </ListItem>
                      </Collapse>
                    );
                  })}
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  mainMenu: PropTypes.array.isRequired,
  onSidebarOpen: PropTypes.func
};

export default SidebarNav;
