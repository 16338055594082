export function useFormatDate() {
  const dayjs = require('dayjs');
  const utc = require('dayjs/plugin/utc');
  const timezone = require('dayjs/plugin/timezone');

  dayjs.extend(utc);
  dayjs.extend(timezone);

  // YY/MM/DD 포매터
  const formatDate = (dateTimeString, timeZone) => {
    if (dateTimeString) {
      const formattedDate = dayjs(dateTimeString)
        .tz(timeZone)
        .format('YYYY/MM/DD');
      return formattedDate;
    }
  };
  // YY/MM/DD HH:MM 포매터
  const formatDateWithTime = (dateTimeString, timeZone) => {
    if (dateTimeString) {
      // ISO 8601 형식의 문자열을 Date 객체로 변환
      // getFullYear, getMonth 등의 메서드를 사용하여 연, 월, 일, 시, 분을 추출
      // getMonth는 0부터 시작하므로 1을 더해줌
      // 포맷된 날짜 및 시간 문자열 반환
      const formattedDateWithTime = dayjs(dateTimeString)
        .tz(timeZone)
        .format('YYYY/MM/DD HH:mm');
      return formattedDateWithTime;
    }
  };

  // timestamp 형식으로 들어오는 시간 데이터를 위한 포매터
  const formatTimestamp = (timestamp, timeZone) => {
    // 날짜 문자열을 ISO8601로 변환, timezone을 받아서 시간을 자동으로 변경
    // const formattedDateTimeString =
    // `${dateTimeString.substring(0, 4)}-${dateTimeString.substring(4, 6)}-${dateTimeString.substring(6, 8)}T${dateTimeString.substring(8, 10)}:${dateTimeString.substring(10, 12)}:${dateTimeString.substring(12, 14)}Z`;
    const date = dayjs.unix(timestamp);
    // timezone 플러그인 사용
    const formattedDateWithTimezone = date
      .tz(timeZone)
      .format('YYYY/MM/DD HH:mm');
    return formattedDateWithTimezone;
  };

  /**
   *
   * @param {*} timestamp
   * timestamp의 year, month, date, hour, minute을 리턴
   *
   */
  const getTimeUnits = timestamp => {
    const targetDate = new Date(timestamp);

    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1;
    const date = targetDate.getDate();
    const hour = targetDate.getHours();
    const minute = targetDate.getMinutes();

    return { year, month, date, hour, minute };
  };

  /**
   *
   * @param {*} target
   * @param {*} current
   *
   * 목표날짜와 현재날짜가 며칠 차이나는지 리턴
   *
   */
  const getDateSubtract = (target, current) => {
    const date = dayjs(target);
    const dayLeft = date.diff(current, 'day');

    return dayLeft;
  };

  /**
   *
   */
  const useFormatTime = (hour, minute) => {
    let unit = '오전';
    let newHour = hour;

    if (hour > 12) {
      unit = '오후';
      newHour -= 12;
    }

    return `${unit} ${newHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
  };



  return {
    formatDate,
    formatTimestamp,
    formatDateWithTime,
    getTimeUnits,
    getDateSubtract,
    useFormatTime
  };
}
