import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { colors } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  //	DialogTitle,
  Dialog,
  Divider,
  Paper,
  InputBase,
  IconButton,
  Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

const useStyles = makeStyles({
  search: {
    margin: 10,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  },
  input: {
    marginLeft: 4,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  dialog: { marginTop: '5px' },
  inline: {
    display: 'inline'
  },
  progress: {
    alignItems: 'center'
  }
});

const gradeAvatar = grade => {
  if (grade === '4')
    return { margin: 0, color: '#fff', backgroundColor: colors.pink[100] };
  else if (grade === '3')
    return { margin: 0, color: '#fff', backgroundColor: colors.pink[200] };
  else if (grade === '2')
    return { margin: 0, color: '#fff', backgroundColor: colors.pink[300] };
  else if (grade === '1')
    return { margin: 0, color: '#fff', backgroundColor: colors.pink[500] };
  else if (grade === 'HC')
    return { margin: 0, color: '#fff', backgroundColor: colors.pink[700] };
};

const PowermeterDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [refresh, setRefresh] = useState(0);
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [segments, setSegments] = useState(null);
  const [keyword, setKeyword] = useState('');

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(obj) {
    onClose(obj);
  }

  const initList = async () => {
    try {
      //https://rest.riduck.com/json-api/get-segment.php?order=get_segment_list&climb_category=5
      await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-segment.php`,
          {
            params: {
              order: 'get_segment_list'
            }
          }
        )
        .then(response => {
          if (response.data) {
            setRefresh(refresh + 1);
            setSegments(response.data.segment_list);
            setInit(response.data.segment_list);
            setLoading(false);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSegmentFind = async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-segment.php`,
          {
            params: {
              order: 'get_segment_list',
              name: keyword
              //					climb_category : 5
            }
          }
        )
        .then(response => {
          if (response.data) {
            setRefresh(refresh + 1);
            setSegments(response.data.segment_list);
            console.log(refresh);
          }
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  function handleEmptyList() {
    console.log(init);
    setSegments(init);
    setKeyword('');
  }

  const handleChange = event => {
    setKeyword(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSegmentFind();
    }
  };

  const getSegmentList = segs => {
    if (segs) {
      return (
        <div>
          {segs.map((segment, i) => (
            <ListItem
              button
              onClick={() => handleListItemClick(segment)}
              key={segment.segment_id}
              style={{ fontWeight: 'bold' }}>
              <ListItemAvatar>
                <Avatar style={gradeAvatar(segment.climb_category_name)}>
                  {segment.climb_category_name}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={segment.name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary">
                      {segment.distance + 'm, ' + segment.average_grade + '%'}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </div>
      );
    } else {
      initList();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <Paper className={classes.search}>
        <IconButton className={classes.iconButton} aria-label="menu" size="large">
          <MenuIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Find Strava Segments"
          value={keyword}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputProps={{ 'aria-label': 'find strava segments' }}
        />
        <IconButton
          className={classes.iconButton}
          onClick={handleSegmentFind}
          aria-label="search"
          size="large">
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          onClick={handleEmptyList}
          className={classes.iconButton}
          aria-label="directions"
          size="large">
          <DirectionsIcon />
        </IconButton>
      </Paper>

      <List>
        <ListItem
          button
          onClick={() => handleListItemClick(0)}
          key={0}
          style={{ fontWeight: 'bold' }}>
          <ListItemText primary={t('Common.manualInput')} />
        </ListItem>
        {open && getSegmentList(segments)}
      </List>
      {loading && <LinearProgress />}
    </Dialog>
  );
};

PowermeterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default PowermeterDialog;
