import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    height: '100vh'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const User = props => {
  const { children } = props;

  const classes = useStyles();
  // const isLanding = (window.location.pathname === "/landing");

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

User.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default User;
