import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
//
import { getImageUrl } from 'utils/getImageUrl';
import { imageSize } from 'utils/helper';
import { useSimpledText } from 'utils/useSimpledText';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Box, Chip, Typography, Grid } from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%'
  },
  image: {
    height: '193px',
    width: '100%',
    objectFit: 'contain'
  },
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '16px',
    '& > *': {
      marginRight: '4px'
    }
  }
}));

const PelotonCard = ({ card }) => {
  const classes = useStyles();
  const { truncateText } = useSimpledText();

  const getCardImageSrc = () => {
    return card.clubThumbFiles.length > 0
      ? getImageUrl(card.clubThumbFiles, imageSize.LG)
      : 'images/dummy_box.png';
  };

  return (
    <Grid item xs={12} md={4}>
      <RouterLink
        to={{
          pathname: `/peloton/${card.uriName}`,
          state: { uriName: card.uriName }
        }}>
        <Card className={classes.card}>
          {/* 카드 이미지 */}
          <Box style={{ position: 'relative' }}>
            <img
              className={classes.image}
              src={getCardImageSrc()}
              alt="Peloton"
              style={
                card.myClubUserResponse && card.myClubUserResponse.join
                  ? { border: '2px solid #4db6ac' }
                  : {}
              }
            />
            {/* 가입 대기중 chip */}
            {card.myClubUserResponse && !card.myClubUserResponse.join && (
              <Chip
                style={{
                  position: 'absolute',
                  top: 160,
                  left: 10,
                  zIndex: 999
                }}
                size="small"
                label="가입 대기중"
              />
            )}

            {/* 가입된 펠로톤 chip */}
            {card.myClubUserResponse && card.myClubUserResponse.join && (
              <Chip
                color="primary"
                style={{
                  position: 'absolute',
                  top: 160,
                  left: 10,
                  zIndex: 999
                }}
                size="small"
                label="가입된 펠로톤"
              />
            )}
          </Box>

          <CardContent>
            {/* 제목 */}
            <Typography gutterBottom variant="h4" component="div">
              {truncateText(card.name, 30)}
            </Typography>
            {/* 정보 */}
            <div className={classes.info}>
              <LocationOnIcon fontSize="small" />
              <Typography variant="body1">{card.country}</Typography>
              <PersonIcon />
              <Typography variant="body1">{card.userCount}</Typography>
            </div>
            {/* 설명글 */}
            <Typography variant="body2" color="textSecondary">
              {truncateText(card.description, 86)}
            </Typography>
          </CardContent>
        </Card>
      </RouterLink>
    </Grid>
  );
};

export default PelotonCard;
