import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import auth from '../../utils/auth';
//import request from '../../utils/request';
import { makeStyles } from '@mui/styles';

import HeartRateTab from './HeartRateTab.js';

import { AppBar, Box, Tabs, Tab, Typography } from '@mui/material';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    //    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  }
}));

const HeartRate = props => {
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  const [tabSelect, setTabSelect] = React.useState(0);

  const [dashboardData, setDashboardData] = useState(
    auth.getDashboardInfo()
      ? auth.getDashboardInfo()
      : {
          ftpArray: null,
          rank_percent: 0,
          rank_array: null,
          category: 'R',
          race: 'Race',
          hour_record: 0,
          ftp: 0,
          wPrime: 15000,
          weight: 10,
          forte: '',
          rider_type: null,
          rider_ability: null,
          pdcData: null,
          activityData: null,
          summaryData: null,
          yScale: 1000,
          lastTs: 0
        }
  );

  //	console.log(auth.getDashboardInfo());

  useEffect(() => {
    // let wkg_select = cookie.load("wkg_select");
    // if(typeof wkg_select === 'undefined' ) {
    // 	cookie.save('wkg_select', 0, { path: '/' });
    // 	wkg_select = 0;
    // } else {
    // 	if(wkg_select == 1) {
    // 		setWkgSelect(true);
    // 	} else {
    // 		setWkgSelect(false);
    // 	}
    // }

    if (!dashboardData.activityData) {
      if (!auth.getDashboardInfo()) {
        props.history.push('/dashboard');
      }
    }
  }, []);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={t('Heartrate.EFHeader')} />
        {/* <Tab key={1} label={t('Powerprofile.powerPeriodHeader')} />	 */}
      </Tabs>
    );
  }

  function TabContents(props) {
    const tabSelect = props.tabSelect;

    if (tabSelect === 0) {
      return (
        <HeartRateTab
          activityData={dashboardData.activityData}
          ftpArray={dashboardData.ftpArray}
          summaryData={dashboardData.summaryData}
        />
      );
      // } else if (tabSelect === 1) {
      // 	return (
      // 		<PowerPeriodTab
      // 			activityData={dashboardData.activityData}
      // 			pdcData={dashboardData.pdcData}
      // 			ftpArray={dashboardData.ftpArray}
      // 			wkg={dashboardData.wkg}
      // 			weight={dashboardData.weight}
      // 			wkgSelect={wkgSelect}
      // 		/>
      // 	)
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.heartrate')}
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>

      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      {dashboardData.summaryData != null ? (
        <TabContents tabSelect={tabSelect} />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

HeartRate.propTypes = {
  history: PropTypes.object
};

export default withRouter(HeartRate);
