import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  InputAdornment,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl
} from '@mui/material';

import _ from 'lodash';

import itemList from '../../../../utils/SettingOptionItems.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  selectBox: {
    width: 'calc(100% - 8px)',
    marginBottom: '8px'
  },
  card: {
    width: '100%',
    margin: '0px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  spaceAround: {
    marginTop: '-16px',
    paddingBottom: '10px',
    justifyContent: 'space-around'
  }
}));

const SettingOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { auth, extraInfo, strava, updateOptions } = props;
  const classes = useStyles();
  const userInfo = auth.getUserInfo();

  //unit default
  //	const unit = extraInfo.unit ? extraInfo.unit : 'metric';

  const [updateAvail, setUpdateAvail] = useState(true); //useState(Boolean(extraInfo.updateAvail));
  const [nationId, setNationId] = useState(extraInfo.nation); //useState(Boolean(extraInfo.updateAvail));

  const [Rider, setRider] = useState({
    nation: extraInfo.nation,
    language: extraInfo.language,
    timezone: extraInfo.timezone,
    unit: extraInfo.unit
  });

  //핸들링 함수
  const handleChangeMethod = prop => event => {
    setRider({ ...Rider, [prop]: event.target.value });
  };

  const handleChangeNation = prop => event => {
    setNationId(event.target.value);
  };

  //Nation 함수
  const NationRange = nation_id => {
    let range = [];

    _.each(itemList.countryList, function(item, id) {
      if (nation_id === id)
        range.push(
          <MenuItem key={id} value={id} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={id}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  //Range menuitem 함수
  const LanguageRange = language_id => {
    let range = [];

    _.each(itemList.languageList, function(item, id) {
      if (language_id === id)
        range.push(
          <MenuItem key={id} value={id} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={id}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  const TimezoneRange = timezone_str => {
    let range = [];

    _.each(itemList.timezoneList, function(item, id) {
      if (timezone_str === item)
        range.push(
          <MenuItem key={id} value={item} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={item}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  const UnitRange = unit_str => {
    let range = [];

    range.push(
      <MenuItem
        key={0}
        value={'imperial'}
        selected={unit_str === 'imperial' ? true : false}>
        Imperial
      </MenuItem>
    );
    range.push(
      <MenuItem
        key={1}
        value={'metric'}
        selected={unit_str !== 'imperial' ? true : false}>
        Metric
      </MenuItem>
    );

    return range;
  };

  function updateSettingOptions() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        {
          order: 'update_setting_options',
          new_nation: nationId,
          new_language: Rider.language,
          new_timezone: Rider.timezone,
          new_unit: Rider.unit
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success) {
          let exInfo = auth.getExtraInfo();
          exInfo.nation = response.data.new_nation;
          exInfo.language = response.data.new_language;
          exInfo.timezone = response.data.new_timezone;
          exInfo.unit = response.data.new_unit;

          exInfo.updateAvail = false;
          auth.setExtraInfo(exInfo, true);

          updateOptions('on');
          setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography
          component="div"
          variant="caption"
          align="center"
          style={{ margin: '12px 8px 0 8px' }}></Typography>

        <CardContent>
          <Grid container spacing={1} className={classes.cardContentBox}>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.nation')}
                size="small"
                value={nationId}
                disabled={!updateAvail}
                onChange={handleChangeNation(nationId)}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {NationRange(nationId)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.language')}
                size="small"
                value={Rider.language}
                disabled={!updateAvail}
                onChange={handleChangeMethod('language')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {LanguageRange(Rider.language)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.timezone')}
                size="small"
                value={Rider.timezone}
                disabled={!updateAvail}
                onChange={handleChangeMethod('timezone')}>
                {TimezoneRange(Rider.timezone)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.unit')}
                size="small"
                value={Rider.unit}
                disabled={!updateAvail}
                onChange={handleChangeMethod('unit')}>
                {UnitRange(Rider.unit)}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.spaceAround}>
          <Button
            className={classes.setButton}
            onClick={updateSettingOptions}
            variant="contained"
            color="primary">
            {t('Options.modify')}
          </Button>
        </CardActions>
        {/*
					(!updateAvail && extraInfo.updateAvailDate) ? 
					<Typography component="div" variant="caption" align="center" style={{margin:'8px 0 16px 0'}}>
						{extraInfo.updateAvailDate} {t('Options.afterModify')}
					</Typography>
					: ""
          */}
      </Card>
    </React.Fragment>
  );
};

SettingOptions.propTypes = {
  className: PropTypes.string
};

export default SettingOptions;
