import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { Redirect, useLocation } from 'react-router-dom';
import { Box, Typography, Snackbar } from '@mui/material';

import { default as SettingsTab } from './SettingsTab.js';

import axios from 'axios';
import auth from '../../utils/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
    //    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px #dddddd solid'
    }
  },
  whiteLine: {
    marginBottom: '16px',
    borderBottom: '1px #ffffff solid',
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px #ffffff solid'
    }
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    fontWeight: '500',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    width: '100%',
    maxWidth: '352px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
    textAlign: 'center',
    color: '#444444',
    whiteSpace: 'pre-wrap',
    height: '196px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  modalBodyWarp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  },
  modalBody: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    wordBreak: 'keep-all',
    margin: '5px 0'
  },
  modalWarp: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  modalButton: {
    width: '100%',
    fontSize: '14px',
    borderRadius: '4px'
  }
}));

const Settings = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { history } = props;
  const [redirect, setRedirect] = React.useState(false);

  const [expanded, setExpanded] = React.useState('');
  const [newPanel, setNewPanel] = React.useState('');
  const [settingsSubTitle, setSettingsSubTitle] = React.useState('');
  const [refreshTopbar, setRefresh] = React.useState('off');

  const location = useLocation();
  const classes = useStyles();

  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [snackopen, setSnackopen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [transition, setTransition] = React.useState(undefined);

  if (props.location.search) {
    const query = new URLSearchParams(props.location.search);
    const code = query.get('code');

    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/strava/register-strava.php`,
          {
            params: {
              jwt: auth.getToken().token,
              exchange_type: 'connect',
              code: code,
              grant_type: 'authorization_code'
            }
          }
        )
        .then(function(response) {
          //        console.log(response.data);
          let rtv = response.data;

          //        console.log(rtv);

          if (rtv) {
            alert(t('Options.stravaConnected'));

            auth.clearExtraInfo();

            setTimeout(() => {
              window.location = 'https://' + window.location.hostname;
            }, 1000);
          } else {
            alert(t('Options.connectError'));

            auth.clearExtraInfo();

            setTimeout(() => {
              window.location = 'https://' + window.location.hostname;
            }, 1000);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (location.pathname.indexOf('/settings/account') > -1) {
      setExpanded('account');
      setSettingsSubTitle(t('Options.settingsAccount'));
    } else if (location.pathname.indexOf('/settings/activity') > -1) {
      setExpanded('activity');
      setSettingsSubTitle(t('Options.settingsActivity'));
    } else if (location.pathname.indexOf('/settings/connect') > -1) {
      setExpanded('connect');
      setSettingsSubTitle(t('Options.settingsConnect'));
    } else {
      // /settings/profile, settings
      setExpanded('connect');
      setSettingsSubTitle(t('Options.settingsProfile'));
    }
  }, [location]);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    history.push('/settings/' + panel);
  };

  const updateOptions = value => {
    //    console.log(value + " " + refreshTopbar);
    setRefresh(value);
    window.location.reload();
    // auth.clearExtraInfo();
    // auth.getExtraInfo();
  };

  const handleConfirmNavigate = () => {
    setModalOpen(false);
    history.push('/settings/' + newPanel);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSnackOpen = msg => {
    msg ? setSnackMsg(msg) : setSnackMsg('');
    setSnackopen(true);
  };

  const handleSnackClose = () => {
    setSnackopen(false);
  };

  //  console.log(props.location.search);

  return (
    <div className={classes.root}>
      {redirect === true ? (
        <Redirect to="/dashboard" />
      ) : (
        <React.Fragment>
          <Box className={classes.topBar}>
            <Typography variant="h3" className={classes.title}>
              {t('Options.settings')}
            </Typography>
          </Box>

          <div className={classes.whiteLine}></div>

          <Box className={classes.drawerWrap}>
            <SettingsTab />
          </Box>

          <Snackbar
            open={snackopen}
            autoHideDuration={2000}
            onClose={handleSnackClose}
            TransitionComponent={transition}
            message={snackMsg === '' ? t('Options.saveData') : snackMsg}
            key={transition ? transition.name : ''}
          />
        </React.Fragment>
      )}

      {/* <ConfirmationModal open={modalOpen} onClose={handleCloseModal} onConfirm={handleConfirmNavigate} /> */}
    </div>
  );
};

export default Settings;
