import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import auth from 'utils/auth';
import Bike from './Bike/Bike';
import Simulation from './Simulation/Simulation';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const BikeUI = ({ gearinfo, handleDeleteGear }) => {
  const classes = useStyles();
  const extraInfo = auth.getExtraInfo();

  const gearInfo = gearinfo;
  const bikeKit = JSON.parse(gearInfo.bike_kit_json);

  //사용자 정보에서 config 정보 불러오기
  let initRider = {
    gender: 'M',
    height: 172,
    weight: '62.0',
    helmet: 'road'
  };

  let initBike = {
    bikeType: 'road_allround',
    bikeWeight: '8.0',
    tireProduct: 'Clincher mid (butyl)',
    drivetrain: 'ultegra',
    companyName: '',
    powermeter: ''
  };

  let initCrrVal = 0.005;
  let initCda = 0.4;
  let initTireWidth = 2;
  let initRimHeight = 1;
  let initCadence = 90;
  let initRiderPose = 2;
  let initSurface = 2;

  if (bikeKit !== null) {
    //		console.log("read bikeKit");

    initRider = {
      gender: extraInfo.gender,
      height: extraInfo.height,
      weight: extraInfo.weight,
      helmet: 'road' //bikeKit.helmet',
    };

    //		console.log("bikeKit");
    //		console.log(bikeKit);

    initBike = {
      bikeType: bikeKit.bike_type,
      bikeWeight: bikeKit.bike_weight,
      tireProduct: bikeKit.tire_product,
      drivetrain: bikeKit.drivetrain,
      //bikeKit에서 집어넣어야 한다
      companyName: bikeKit.company_name,
      powermeter: bikeKit.powermeter
    };

    //		console.log(initBike);

    initTireWidth = bikeKit.tire_width;
    initRimHeight = bikeKit.rim_height;
    initCadence = bikeKit.cadence;
    initRiderPose = bikeKit.rider_pose;
    initSurface = bikeKit.surface_select;

    initCrrVal = bikeKit.crr;
    initCda = bikeKit.cda;
  }

  const [crrVal, setCrrVal] = useState(initCrrVal);
  const [roadSurface, setRoadSurface] = useState(Number(initSurface));
  const [cda, setCda] = useState(initCda);
  const [rider, setRider] = useState(initRider);
  const [bike, setBike] = useState(initBike);

  const [riderPose, setRiderPose] = useState(Number(initRiderPose));
  const [rimHeight, setRimHeight] = useState(Number(initRimHeight));
  const [tireWidth, setTireWidth] = useState(Number(initTireWidth));
  const [cadence, setCadence] = useState(Number(initCadence));

  return (
    <div className={classes.root}>
      {/* 자전거 설정 */}
      <Bike
        rider={rider}
        bike={bike}
        tireWidth={tireWidth}
        rimHeight={rimHeight}
        riderPose={riderPose}
        roadSurface={roadSurface}
        gearInfo={gearInfo}
        crrVal={crrVal}
        cda={cda}
        cadence={cadence}
        handleDeleteGear={handleDeleteGear}
        setCrrVal={setCrrVal}
        setRoadSurface={setRoadSurface}
        setCda={setCda}
        setRider={setRider}
        setBike={setBike}
        setRiderPose={setRiderPose}
        setRimHeight={setRimHeight}
        setTireWidth={setTireWidth}
        setCadence={setCadence}
      />

      {/* 시뮬레이션 */}
      <Simulation
        rider={rider}
        bike={bike}
        crrVal={crrVal}
        cda={cda}
        rimHeight={rimHeight}
        tireWidth={tireWidth}
        cadence={cadence}
        riderPose={riderPose}
        roadSurface={roadSurface}
      />
    </div>
  );
};

BikeUI.propTypes = {
  className: PropTypes.string
};

export default withRouter(BikeUI);
