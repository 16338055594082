import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
//
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import { getImageUrl } from 'utils/getImageUrl';
import { imageSize } from 'utils/helper';
import { useFormatDate } from 'utils/useFormatDate';
import { useSimpledText } from 'utils/useSimpledText';
import PopUpDialog from 'views/Peloton/Common/PopUpDialogLayout';
//
import { fetchEventApi } from 'utils/clientApis';

import { statusTracker, paceTracker } from 'utils/helper';
//
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
//
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AvatarGroup from '@mui/material/AvatarGroup';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';
import MiniDate from 'views/Peloton/Common/MiniDate';
import CreateEvent from './Create';
import NoData from 'views/Peloton/Common/NoData';
import scrollToTop from 'views/Peloton/Utils/scrollToTop';

const Events = prop => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const history = useHistory();
  const location = useLocation();

  const { formatDateWithTime } = useFormatDate();
  const { truncateText } = useSimpledText();
  const screenRef = useRef();
  const userClubRole = prop?.data.myClubUserResponse?.clubRole;

  const [openCreatePop, setOpenCreatePop] = useState(false);

  const [events, setEvents] = useState();
  const [pageTotal, setPageTotal] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    handleSearchEvents();
  }, []);

  const handleSearchEvents = async () => {
    setIsDataLoading(true);
    try {
      const response = await fetchEventApi(prop.data.id, currentPage - 1, 12);
      if (response) {
        setPageTotal(response.data.response?.totalPages);

        // 데이터 세팅
        let newEvents = response.data.response?.content;
        newEvents = newEvents.map(event => {
          // event.pace = paceTracker(event.pace); // 이벤트 성격
          // event.status = statusTracker(event.status); // 이벤트 상태
          event.isDone = isEventDoneFunction(event.meetingTime, event.status); // 이벤트 진행 상황
          return event;
        });

        // console.log('newEvents', newEvents);

        setEvents(newEvents);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsDataLoading(false);
    }
  };

  /**
   * @returns : meetingTime 데이터를 넣으면, 현재 시간과 비교하여 일정의 경과 여부를 리턴한다.
   */
  const isEventDoneFunction = meetingTime => {
    const timeNow = Date.now();
    const meetingAt = new Date(formatDateWithTime(meetingTime, 'Asia/Seoul'));
    const timeAt = new Date(formatDateWithTime(timeNow, 'Asia/Seoul'));

    if (meetingAt <= timeAt) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * @return : 페이지네이션 구현부.
   */
  useEffect(() => {
    // screenRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
    // 페이지 번호가 변경될 때마다 새로운 페이지 데이터를 불러옵니다.
    handleSearchEvents();
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    history.push(`?page=${newPage}`);
    scrollToTop();
  };

  // 뒤로 가기 감지
  useEffect(() => {
    let unlisten = history.listen(location => {
      const qs = location.search.replace('?', '');
      const pairs = qs.split('&');
      const items = {};

      for (let i = 0; i < pairs.length; i++) {
        items[pairs[i].split('=')[0]] = pairs[i].split('=')[1];
      }

      if (history.action === 'PUSH') {
      }
      if (history.action === 'POP') {
        const page = items.page ? Number(items.page) : 1;
        setCurrentPage(page);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // search 파라미터 변경시 스크롤 최상단으로 이동
  useEffect(() => {
    scrollToTop();
  }, [location.search]);

  const handleCloseCreatePop = () => {
    setOpenCreatePop(false);
  };

  return (
    <Box>
      <div ref={screenRef} style={{ scrollMarginTop: '140px' }} />
      {/* 데이터 로딩중 */}
      {!isDataLoading ? (
        <>
          <Box>
            <Typography
              className={classes.title}
              style={{ marginBottom: '16px' }}>
              이벤트
            </Typography>
            {userClubRole &&
              (userClubRole === 'ADMIN' || userClubRole === 'MANAGER') && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{ width: '100%', marginBottom: '16px' }}
                  onClick={() => setOpenCreatePop(true)}>
                  <DirectionsBikeIcon
                    style={{ color: 'white', marginRight: '8px' }}
                  />
                  <Typography
                    variant="body1"
                    style={{ color: 'white', fontWeight: '500' }}>
                    새 이벤트 만들기
                  </Typography>
                </Button>
              )}
            {openCreatePop && (
              <PopUpDialog
                openPop={openCreatePop}
                title="이벤트 만들기"
                handleClose={handleCloseCreatePop}>
                <CreateEvent handleClosePop={handleCloseCreatePop} />
              </PopUpDialog>
            )}
            {events?.length ? (
              <>
                {/* 이벤트 데이터 존재하는 경우 */}
                <Grid container spacing={2}>
                  {events &&
                    events.map((data, idx) => (
                      <Grid key={idx} item xs={12} md={4}>
                        <RouterLink
                          key={idx}
                          to={{
                            pathname: `/peloton/${prop.data.uriName}/event/${data.id}`
                          }}>
                          <Card className={classes.cardContainer}>
                            {/**
                             * 이미지
                             */}
                            <Box className={classes.imageContainer}>
                              {data.eventThumbFiles.length > 0 ? (
                                <img
                                  className={classes.image}
                                  src={getImageUrl(
                                    data.eventThumbFiles,
                                    imageSize.LG
                                  )}
                                  alt="Event"
                                />
                              ) : (
                                <img
                                  className={classes.image}
                                  src="/images/pelotonSkeleton.png"
                                  alt="Event Dummy"
                                />
                              )}
                              {data.status !== 'OPEN' ? (
                                <>
                                  {/**
                                   * 이벤트 종료시 사진 블러처리
                                   */}
                                  <Box>
                                    <Box
                                      className={classes.imageDoneContainer}
                                    />
                                    <Box
                                      style={{
                                        position: 'absolute',
                                        top: '50%',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}>
                                      <Typography
                                        variant="h4"
                                        style={{
                                          backgroundColor: '#ffffffaa',
                                          color: '#455a64',
                                          padding: 5,
                                          borderRadius: 8,
                                          fontSize: 16
                                        }}>
                                        {`${statusTracker(
                                          data.status
                                        )}된 이벤트 입니다.`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  {/**
                                   * 이벤트 종료 전 날짜 벳지
                                   */}
                                  <Box className={classes.eventDateBadge}>
                                    <MiniDate
                                      meetingTime={data.meetingTime}
                                      type="badge"
                                    />
                                  </Box>
                                </>
                              )}
                            </Box>
                            <CardContent>
                              {/**
                               * 카드 정보
                               */}
                              <Typography
                                variant="h4"
                                style={{ marginBottom: '8px' }}>
                                {truncateText(data.name, 26)}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '-8px'
                                }}>
                                <LocationOnIcon fontSize="small" />
                                {truncateText(data.eventLocations[0].name, 20)}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                style={{ marginBottom: '8px' }}>
                                {truncateText(data.description, 20)}
                              </Typography>
                              <Box className={classes.chipContainer}>
                                <Box className={classes.chipWrap}>
                                  <Chip
                                    label={statusTracker(data.status)}
                                    className={classes.chip}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                  />
                                  <Chip
                                    label={paceTracker(data.pace)}
                                    className={classes.chip}
                                    size="small"
                                    color="primary"
                                  />
                                </Box>
                                <AvatarGroup max={3}>
                                  {data.eventJoinUsers.map((user, index) => (
                                    <Avatar
                                      key={index}
                                      style={{
                                        width: '26px',
                                        height: '26px',
                                        cursor: 'pointer'
                                      }}
                                      variant="circular"
                                      src={user.profileImage}
                                      alt={
                                        user.profileImage
                                          ? 'Profile'
                                          : 'Dummy Profile'
                                      }
                                    />
                                  ))}
                                </AvatarGroup>
                              </Box>
                            </CardContent>
                          </Card>
                        </RouterLink>
                      </Grid>
                    ))}
                </Grid>

                {/* 페이지네이션 컴포넌트 */}
                <Box my={2} display="flex" justifyContent="center">
                  <Pagination
                    count={Number(pageTotal)}
                    page={currentPage}
                    onChange={handleChangePage}
                    shape="rounded"
                    hidePrevButton
                    hideNextButton
                  />
                </Box>
              </>
            ) : (
              <>
                {/* 이벤트 데이터 존재하지 않는 경우 */}
                <NoData title="개설된 이벤트가 없습니다." />
              </>
            )}
          </Box>
        </>
      ) : (
        <CustomCircularProgress />
      )}
    </Box>
  );
};
export default Events;

Events.propTypes = {
  history: PropTypes.object
};

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    marginBottom: '16px'
  },
  title: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  chapter: {
    backgroundColor: 'white',
    padding: '24px',
    height: '100vh'
  },
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    '& > *': {
      marginRight: '2px'
    }
  },
  info2: {
    display: 'flex',
    justifyContent: 'end',
    '& > *': {
      marginRight: '2px'
    }
  },
  cardContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
    // marginBottom: '16px'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(100% * (10 / 16))'
  },
  imageDoneContainer: {
    background: '#ffffff',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    opacity: '0.5'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  cards: {
    marginBottom: '16px'
  },
  header: {
    display: 'flex',
    padding: '16px',
    height: '24px'
  },
  chipContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  chipWrap: {
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      marginRight: '8px'
    }
  },
  eventDateBadge: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '3px'
  }
}));
