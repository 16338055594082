import { createTheme } from '@mui/material/styles';

import { darkModePalette, lightModePalette } from './palette';

const useTheme = mode => {
  const palette = mode === 'light' ? lightModePalette : darkModePalette;

  const typography = {
    fontFamily: [
      'Noto Sans KR',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      color: palette.text.primary,
      fontSize: '35px',
      letterSpacing: '-0.24px',
      lineHeight: '40px'
    },
    h2: {
      color: palette.text.primary,
      fontSize: '29px',
      letterSpacing: '-0.24px',
      lineHeight: '32px'
    },
    h3: {
      color: palette.text.primary,
      fontSize: '24px',
      letterSpacing: '-0.06px',
      lineHeight: '28px'
    },
    h4: {
      color: palette.text.primary,
      fontSize: '20px',
      letterSpacing: '-0.06px',
      lineHeight: '24px'
    },
    h5: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '20px'
    },
    h6: {
      color: palette.text.primary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px'
    },
    subtitle1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px'
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
    body1: {
      color: palette.text.primary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px'
    },
    body2: {
      color: palette.text.secondary,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px'
    },
    button: {
      color: palette.text.primary,
      fontSize: '14px'
    },
    caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px'
    },
    overline: {
      color: palette.text.secondary,
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase'
    }
  };

  const breakpoints = {
    // values: {
    //   xs: 0,
    //   sm: 400,
    //   md: 720,
    //   lg: 1280,
    //   xl: 1920,
    // },
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920
    }
  };

  let theme = createTheme({
    palette: { mode: mode, ...palette },
    typography,
    boxShadow: {
      primary:
        mode === 'light'
          ? '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
          : '0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.2)',
      secondary:
        mode === 'light' ? '0 1px 5px #dddddd' : '0 1px 5px rgba(0, 0, 0, 0.5);'
    },
    components: {
      MuiButton: {
        defaultProps: {
          color: 'default'
        },
        styleOverrides: {
          root: {
            fontWeight: 400
          },
          contained: {
            height: '36px'
          }
          // outlined: {
          //   border: '1px solid rgba(0, 0, 0, 0.23)'
          // }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          color: 'default'
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: palette.icon,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03)'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          elevation1: {
            boxShadow:
              mode === 'light'
                ? '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
                : '0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.2)'
          },
          elevation4: {
            boxShadow:
              mode === 'light'
                ? '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
                : '0 0 0 1px rgba(255, 255, 255, 0.1), 0 1px 3px 0 rgba(255, 255, 255, 0.2)'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            ...typography.body1,
            borderBottom: `1px solid ${palette.divider}`
          },
          head: {
            lineHeight: '1.5rem'
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.colored.light
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          positionStatic: {
            transform: 'translateZ(0)'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '@media (min-width: 600px)': {
              minWidth: '160px'
            },
            fontWeight: 400
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: 8
          }
        }
      }
    },
    breakpoints
  });

  return theme;
};

export default useTheme;
