import React, { useState } from 'react';
import cookie from 'react-cookies';

import { makeStyles } from '@mui/styles';
import { Typography, Fade, Paper, Stack, Box, Button } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export default function CookiePolicy() {
  const { t } = useTranslation(['page']);
  const [bannerOpen, setBannerOpen] = useState(true);

  const closeBanner = () => {
    setBannerOpen(false);
    cookie.save('cookie_policy', 'y');
  };
  const { language } = i18n;

  const cookiePolicyGuideKo =
    'https://riduck.gitbook.io/riduck-guide/etc/cookie-policy';
  const cookiePolicyGuideEn =
    'https://riduck.gitbook.io/riduck-guide/v/english/etc/cookie-policy';

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={bannerOpen}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            zIndex: 1300,
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1
          }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="center"
            gap={2}>
            <Box
              sx={{
                flexShrink: 1,
                alignSelf: { xs: 'flex-start', sm: 'center' },
                maxWidth: 1024
              }}>
              <Typography variant="body1" sx={{ display: 'inline' }}>
                {t('Notice.cookieSentence1')}
              </Typography>
              <a
                href={
                  language === 'ko' ? cookiePolicyGuideKo : cookiePolicyGuideEn
                }
                target="_blank"
                rel="noopener noreferrer">
                <Typography
                  variant="body1"
                  sx={{ display: 'inline', textDecoration: 'underline' }}>
                  {t('Notice.cookiePolicy')}
                </Typography>
              </a>
              <Typography variant="body1" sx={{ display: 'inline' }}>
                {t('Notice.cookieSentence2')}
              </Typography>
            </Box>
            <Stack
              gap={2}
              direction={{
                xs: 'row-reverse',
                sm: 'row'
              }}
              sx={{
                flexShrink: 0,
                alignSelf: { xs: 'flex-end', sm: 'center' }
              }}>
              <Button
                size="small"
                onClick={closeBanner}
                variant="contained"
                color="primary">
                {t('Notice.confirm')}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
}
