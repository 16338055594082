import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Avatar } from '@mui/material';

const useStyles = makeStyles(theme => ({
  title: { marginTop: '4px' },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContents: 'center',
    flexDirection: 'column',
    marginTop: '8px'
  },
  avatar: {
    width: '60px',
    height: '60px',
    cursor: 'pointer'
  },
  name: {
    marginTop: '8px'
  },
  wkg: {}
}));

function Organizer({ eventJoinUsers, handleClickProfile }) {
  const classes = useStyles();

  const host = eventJoinUsers.find(({ host }) => host);

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        개설자
      </Typography>
      <Box className={classes.container}>
        <Avatar
          src={host.profileImage}
          className={classes.avatar}
          onClick={() => handleClickProfile(host.userId)}
        />
        <Typography variant="h6" className={classes.name}>
          {host.displayName}
        </Typography>
        <Typography variant="h6" className={classes.wkg}>
          {`${host.wkg}.xx `}w/kg
        </Typography>
      </Box>
    </>
  );
}

export default Organizer;
