import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  popWrap: {
    padding: '24px 0px 16px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '300px'
  }
}));

/**
 * type이 event인 경우 pelotonId를 eventId로 봄
 */
export const ProfilePop = ({ openState, setOpen, getUserInfo }) => {
  const classes = useStyles();
  const [memberData, setMemberData] = useState();

  useEffect(() => {
    handleUserInfo();
  }, []);

  const handleUserInfo = async () => {
    try {
      const response = await getUserInfo();
      // console.log(response);
      if (response) {
        setMemberData(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return <>
    {openState && memberData && (
      <Box>
        <Dialog
          open={openState}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}>
          <Box className={classes.popWrap}>
            <Avatar
              src={memberData.profileImage}
              style={{
                width: '70px',
                height: '70px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
            />
            <DialogTitle id="alert-dialog-title">
              <Typography variant="h3">{memberData.displayName}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Chip
                label={`FTP | ${memberData.wkg}.xx w/kg`}
                className={classes.chip}
                size="small"
                color="primary"
              />
              <DialogContentText
                id="alert-dialog-description"
                text="small"
                color="textSecondary"></DialogContentText>
            </DialogContent>
          </Box>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )}
  </>;
};
