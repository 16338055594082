import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Switch,
  Slider
} from '@mui/material';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  textBold: {
    fontSize: '14px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  ftpTypeSwitchWrap: {
    margin: '12px auto 0',
    fontSize: '13px'
  },
  ftpSetBox: {
    marginBottom: '8px',
    fontSize: '12px',
    textAlign: 'center'
  },
  spaceAround: {
    padding: '0px 0px 16px 0px',
    justifyContent: 'space-around'
  },
  boxFlex: {
    display: 'flex',
    maxWidth: '240px',
    margin: '0 auto'
  },
  weekTssTable: {
    minWidth: '140px',
    margin: '2px 0px',
    padding: '0px',
    fontSize: '11px',
    lineHeight: '14px',
    height: '30px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px'
  },
  tssEmoji: {
    height: 28,
    width: 28,
    backgroundColor: '#f5f5f5',
    margin: '-2px 6px 0 6px',
    fontSize: '20px'
  }
}));

const TssSlider = withStyles({
  root: {
    color: '#8cc1ec',
    height: '180px',
    minWidth: '36px',
    padding: '0px',
    margin: '24px 0 0 -8px'
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  track: {
    height: 2
  },
  rail: {
    height: 2,
    opacity: 0.6,
    backgroundColor: '#8cc1ec'
  },
  mark: {
    opacity: 0.6,
    backgroundColor: '#8cc1ec',
    height: 1,
    width: 5,
    marginTop: 0
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  },
  markLabel: {
    fontSize: '12px',
    margin: '0 0 2px 4px'
  },
  markLabelActive: {
    fontWeight: 500,
    color: '#f06292'
  }
})(Slider);

const WeekTssOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { auth, extraInfo, adjustWeekTss, updateOptions } = props;
  const initWeekTss = Math.round(
    (Math.round(extraInfo.min_week_tss) +
      Math.round(extraInfo.adjust_week_tss)) /
      0.55
  );

  const [updateAvail, setUpdateAvail] = useState(
    Boolean(extraInfo.updateAvail)
  );
  const [customAdjustWeekTss, setCustomAdjustWeekTss] = useState(
    Math.round(adjustWeekTss)
  );
  const [weekTssSetDialog, setWeekTssSetDialog] = useState(false);
  const [divWeekTss, setDivWeekTss] = useState(initWeekTss);

  const classes = useStyles();
  const wkgWeek = Math.ceil(extraInfo.ftp / extraInfo.weight) * 10;

  const weekTssTable = [
    {
      emoji: '😮',
      guide: t('TssGuide.tss_guide_0'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.22) +
        ' ' +
        t('Common.under')
    },
    {
      emoji: '😐',
      guide: t('TssGuide.tss_guide_1'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.22 + 1) +
        '~' +
        Math.round(divWeekTss * 0.55)
    },
    {
      emoji: '🙂',
      guide: t('TssGuide.tss_guide_2'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.55 + 1) +
        '~' +
        Math.round(divWeekTss * 0.75)
    },
    {
      emoji: '😀',
      guide: t('TssGuide.tss_guide_3'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.75 + 1) +
        '~' +
        Math.round(divWeekTss * 0.9)
    },
    {
      emoji: '😍',
      guide: t('TssGuide.tss_guide_4'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.9 + 1) +
        '~' +
        Math.round(divWeekTss * 1.05)
    },
    {
      emoji: '🤤',
      guide: t('TssGuide.tss_guide_5'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 1.05 + 1) +
        '~' +
        Math.round(divWeekTss * 1.2)
    },
    {
      emoji: '😨',
      guide: t('TssGuide.tss_guide_6'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 1.2) +
        ' ' +
        t('Common.over')
    }
  ];

  const weekTssArray = [
    {
      value: -1 * wkgWeek * 4,
      label: '-' + (wkgWeek * 4).toString()
    },
    {
      value: -1 * wkgWeek * 3,
      label: '-' + (wkgWeek * 3).toString()
    },
    {
      value: -1 * wkgWeek * 2,
      label: '-' + (wkgWeek * 2).toString()
    },
    {
      value: -1 * wkgWeek * 1,
      label: '-' + (wkgWeek * 1).toString()
    },
    {
      value: 0,
      label: '0'
    },
    {
      value: wkgWeek * 1,
      label: '+' + (wkgWeek * 1).toString()
    },
    {
      value: wkgWeek * 2,
      label: '+' + (wkgWeek * 2).toString()
    },
    {
      value: wkgWeek * 3,
      label: '+' + (wkgWeek * 3).toString()
    },
    {
      value: wkgWeek * 4,
      label: '+' + (wkgWeek * 4).toString()
    },
    {
      value: wkgWeek * 5,
      label: '+' + (wkgWeek * 5).toString()
    },
    {
      value: wkgWeek * 6,
      label: '+' + (wkgWeek * 6).toString()
    }
  ];

  function updateWeekTssSet() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);
    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        {
          order: 'update_adjust_week_tss_set',
          adjust_week_tss: customAdjustWeekTss
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success) {
          updateOptions('on');
          //				setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const adjustWeekTssSet = customAdjustWeekTss => {
    setCustomAdjustWeekTss(Number(customAdjustWeekTss));

    let inTss = Math.round(
      (Number(extraInfo.min_week_tss) + Number(customAdjustWeekTss)) / 0.55
    );

    setDivWeekTss(inTss);
  };

  const handleWeekTssSetDialogOpen = () => {
    setWeekTssSetDialog(true);
  };

  const handleWeekTssSetDialogClose = () => {
    setWeekTssSetDialog(false);
  };

  return (
    <Card className={classes.card}>
      <Typography
        component="div"
        variant="caption"
        align="center"
        style={{ margin: '12px 8px 0px 8px' }}>
        {t('Options.pleaseTssGuide')}
      </Typography>

      <CardContent>
        <Box className={classes.boxFlex}>
          <TssSlider
            color="primary"
            orientation="vertical"
            track={false}
            defaultValue={Math.round(adjustWeekTss)}
            min={-1 * wkgWeek * 4}
            max={wkgWeek * 6}
            step={wkgWeek}
            marks={weekTssArray}
            onChange={(event, customAdjustWeekTss) =>
              adjustWeekTssSet(Number(customAdjustWeekTss))
            }
            valueLabelDisplay="off"
            style={{ height: '200px' }}
          />
          <List>
            {weekTssTable.map((guide, index) => (
              <ListItem className={classes.weekTssTable} key={index}>
                <ListItemAvatar style={{ minWidth: '0px' }}>
                  <Avatar className={classes.tssEmoji}>{guide.emoji}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontSize: '11px', lineHeight: '12px' }}
                      component="span">
                      {guide.guide}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontSize: '11px', lineHeight: '12px' }}
                      component="div">
                      {guide.tss}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box style={{ textAlign: 'center', margin: '8px' }}>
          <Typography color="primary" component="span">
            {customAdjustWeekTss > 0 ? '+' : ''}
            {customAdjustWeekTss}
          </Typography>{' '}
          {t('Options.adjusTssGuide')}
        </Box>
      </CardContent>
      <CardActions className={classes.spaceAround}>
        <Button
          className={classes.setButton}
          onClick={updateWeekTssSet}
          variant="contained"
          color="primary">
          {t('Options.modify')}
        </Button>
      </CardActions>

      <Dialog open={weekTssSetDialog} onClose={handleWeekTssSetDialogClose}>
        <DialogTitle style={{ cursor: 'move' }}>
          {t('Options.alertAdjustTss')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component="pre">
              {t('Options.alertAdjustTssTip')}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleWeekTssSetDialogClose}
            disabled={false /*!updateAvail*/}>
            {t('Options.confirm')}
          </Button>
          <Button onClick={handleWeekTssSetDialogClose}>
            {t('Options.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

WeekTssOptions.propTypes = {
  className: PropTypes.string
};

export default WeekTssOptions;
