import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function CustomCircularProgress() {
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <CircularProgress size={128} />
    </Box>
  );
}

export default CustomCircularProgress;
