import React from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
//
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
//

const useStyles = makeStyles(theme => ({
  root: {},
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  main: {
    borderRadius: '8px',
    width: '100%'
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subMenuWrap: {},
  subMenu: {
    cursor: 'pointer',
    fontSize: '14px',
    color: '#4DB6AC',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  titleSpace: {
    display: 'block',
    width: '8px'
  }
}));

function PelotonLayout({ clubName, children }) {
  const classes = useStyles();
  const history = useHistory();
  const { uriName } = useParams();

  return (
    <Box>
      <Box className={classes.topBar}>
        <RouterLink
          style={{ cursor: 'pointer' }}
          to={{
            pathname: `/pelotons`
          }}>
          <Typography className={classes.title}>펠로톤</Typography>
        </RouterLink>
        {/* TODO: Select Box로 수정하기. (가입한 펠로톤 확인 및 이동) */}
        {uriName && clubName ? (
          <Box className={classes.subMenuWrap}>
            <Typography
              className={classes.subMenu}
              onClick={() => history.push(`/peloton/${uriName}`)}>
              {clubName}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <div className={classes.whiteLine} />
      <Box className={classes.main}>{children}</Box>
    </Box>
  );
}
export default PelotonLayout;
