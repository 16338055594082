import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserRankApi } from 'utils/clientApis';
import RankTable from './RankTable';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';

const useStyles = makeStyles(theme => ({
  rankWarp: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },

  rankTable: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '45%'
    }
  },

  title: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const Ranks = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const stringedId = String(data.id);

  const [users, setUsers] = useState([]);
  const [wkgRank, setWkgRank] = useState([]);
  const [tssRank, setTssRank] = useState([]);
  const [distanceRank, setDistanceRank] = useState([]);
  const [elevationRank, setElevationRank] = useState([]);

  const RANK_LIMIT = 10;

  useEffect(() => {
    handleFetchRank();
  }, []);

  const handleFetchRank = async () => {
    try {
      const response = await fetchUserRankApi(stringedId);
      setUsers(response.data.response);
      setWkgRank(
        sortByDataResultAsc(response.data.response, 'wkgRank').slice(
          0,
          RANK_LIMIT
        )
      );
      setTssRank(
        sortByDataResultDesc(response.data.response, 'weekTss').slice(
          0,
          RANK_LIMIT
        )
      );
      setDistanceRank(
        sortByDataResultDesc(response.data.response, 'distance').slice(
          0,
          RANK_LIMIT
        )
      );
      setElevationRank(
        sortByDataResultDesc(
          response.data.response,
          'totalElevationGain'
        ).slice(0, RANK_LIMIT)
      );
    } catch (err) {
      console.error(err);
    }
  };

  const sortByDataResultDesc = (dataArray, key) => {
    const filteredData = dataArray.filter(
      item => !item.displayName.startsWith('__')
    );
    return filteredData.sort((a, b) => b[key] - a[key]);
  };

  const sortByDataResultAsc = (dataArray, key) => {
    const filteredData = dataArray.filter(
      item => !item.displayName.startsWith('__')
    );
    return filteredData.sort((a, b) => a[key] - b[key]);
  };

  const RankTableWithTitle = ({ title, keyData, keyword }) => (
    <Box className={classes.rankTable}>
      <Typography className={classes.title} style={{ marginBottom: '16px' }}>
        {title}
      </Typography>
      <RankTable keyData={keyData} keyword={keyword} clubId={data.id} />
    </Box>
  );

  return (
    <>
      {users.length ? (
        <>
          <Box className={classes.rankWarp}>
            <RankTableWithTitle
              title="FTP(w/Kg)"
              keyData={wkgRank}
              keyword="wkg"
            />
            <RankTableWithTitle title="TSS" keyData={tssRank} keyword="wTSS" />
            <RankTableWithTitle
              title="누적거리"
              keyData={distanceRank}
              keyword="distance"
            />
            <RankTableWithTitle
              title="획득고도"
              keyData={elevationRank}
              keyword="totalElevationGain"
            />
          </Box>
          {/* <Box>
            <Typography className={classes.title}>
              {`전체 회원`}
            </Typography>
            <RankTableSort data={users} />
          </Box> */}
        </>
      ) : (
        <CustomCircularProgress />
      )}
    </>
  );
};
export default Ranks;
