import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import auth from '../../../utils/auth';
import request from '../../../utils/request';
import { makeStyles, useTheme } from '@mui/styles';

import { AppBar, Box, Button, Divider, Typography } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '16px',
    color: '#73838b',
    fontWeight: '500',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  },
  desc: {
    margin: '12px 0 12px 0',
    padding: '12px',
    background: theme.palette.background.colored.main
  }
}));

const Indoor = () => {
  const classes = useStyles();

  const token = auth.getToken().token;

  //다국어적용
  const { t } = useTranslation(['page']);

  //const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  useEffect(() => {}, []);

  function workoutOpen() {
    var form = document.createElement('form');
    form.action = 'https://workout.riduck.com/';
    form.method = 'POST';
    form.target = '_blank';

    var input = document.createElement('input');
    input.name = 'jwt';
    input.value = token;
    form.appendChild(input);
    //.concat((auth.getToken().token))

    form.style.display = 'none';
    document.body.appendChild(form);

    form.submit();
  }

  function feedbackOpen() {
    var form = document.createElement('form');
    form.action = `${process.env.REACT_APP_RESTAPI_BASE_URL}/slowtwitch/connect_slowtwitch`;
    form.method = 'POST';
    form.target = '_blank';

    var input = document.createElement('input');
    input.name = 'jwt';
    input.value = token;
    form.appendChild(input);
    //.concat((auth.getToken().token))

    form.style.display = 'none';
    document.body.appendChild(form);

    form.submit();
  }

  function reportOpen() {
    var form = document.createElement('form');
    form.action = 'https://report.riduck.com/report_downloader';
    form.method = 'POST';
    form.target = '_blank';

    var input = document.createElement('input');
    input.name = 'jwt';
    input.value = token;
    form.appendChild(input);
    //.concat((auth.getToken().token))

    form.style.display = 'none';
    document.body.appendChild(form);

    form.submit();
  }

  function strategyOpen() {
    var form = document.createElement('form');
    form.action = 'https://report.riduck.com/seorak';
    form.method = 'POST';
    form.target = '_blank';

    var input = document.createElement('input');
    input.name = 'jwt';
    input.value = token;
    form.appendChild(input);
    //.concat((auth.getToken().token))

    form.style.display = 'none';
    document.body.appendChild(form);

    form.submit();
  }

  return (
    <React.Fragment>
      <Box style={{ marginBottom: '12px' }}>
        <Typography variant="h3" className={classes.title}>
          슬로우트위치 온라인 클래스를 위한 전용 페이지
          <br />
        </Typography>
        <Typography className={classes.desc}>
          - slowtwitch 게시판을 통해 온라인 영상과 워크아웃, 개별 피드백을
          받으실 수 있습니다.
          <br />- 슬로우트위치 게시판은 본 페이지에서만 로그인이 가능합니다.
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => feedbackOpen()}>
          <OpenInNewIcon />
          &nbsp;슬로우트위치 열기
        </Button>

        <Divider style={{ margin: '24px 0' }} />

        <Typography variant="h3" className={classes.title}>
          슬로우트위치 워크아웃
          <br />
        </Typography>
        <Typography className={classes.desc}>
          - 전체지원 : PC/MAC + 블루투스동글, 모든 안드로이드 기기에서의 Chrome
          브라우저에서 실행 가능합니다
          <br />- 일부지원 : 아이폰/아이패드 등 (ZWO 다운로드만 가능)
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => workoutOpen()}>
          <OpenInNewIcon />
          &nbsp;워크아웃 열기
        </Button>
      </Box>

      {/*      
        <Divider style={{margin:"24px 0"}} />

        <Typography variant="h3" className={classes.title}>
          스페셜 분석<br />    
        </Typography>
        <Typography className={classes.desc}>
          -설악산 그란폰도 분석 
        </Typography>
        <Button variant="contained" size='large' color="primary" onClick={() => strategyOpen()}>
          <OpenInNewIcon />&nbsp;분석페이지 열기
        </Button>

<Button variant="contained" size='large' color="secondary" onClick={() => strategyOpen()}>
          <OpenInNewIcon />&nbsp;저수령 그란폰도 공략 다운로드
        </Button>


<Box style={{"marginBottom":"12px"}}>    
        <Typography variant="h3" className={classes.title}>
          슬로우트위치 온라인 클래스를 위한 전용 페이지<br />    
        </Typography>
        <Typography className={classes.desc}>
          - slowtwitch 게시판을 통해 온라인 영상과 워크아웃, 개별 피드백을 받으실 수 있습니다.<br />
          - 슬로우트위치 게시판은 본 페이지에서만 로그인이 가능합니다. 
        </Typography>
        <Button variant="contained" size='large' color="primary" onClick={() => feedbackOpen()}>
          <OpenInNewIcon />&nbsp;슬로우트위치 열기
        </Button>        

        <Divider style={{margin:"24px 0"}} />

        <Typography variant="h3" className={classes.title}>
          슬로우트위치 워크아웃<br />    
        </Typography>
        <Typography className={classes.desc}>
          - 전체지원 : PC/MAC + 블루투스동글, 모든 안드로이드 기기에서의 Chrome 브라우저에서 실행 가능합니다<br />
          - 일부지원 : 아이폰/아이패드 등 (ZWO 다운로드만 가능)
        </Typography>
        <Button variant="contained" size='large' color="primary" onClick={() => workoutOpen()}>
          <OpenInNewIcon />&nbsp;워크아웃 열기
        </Button>
      </Box>


      <Divider style={{margin:"24px 0"}} />

      <Typography variant="h3" className={classes.title}>
        슬로우트위치 종합 레포트   
      </Typography>

      <Box style={{"marginTop":"12px"}}>
        <Typography className={classes.desc}>
        -종합레포트는 모든 세션이 끝난 뒤, 제공 받으실 수 있습니다. 
        </Typography>
        <Button variant="contained" style={{marginRight:'12px'}} size='large' color="secondary" onClick={() => reportOpen()}>
          <OpenInNewIcon />&nbsp;레포트 다운로드 받기
        </Button>

        <Button variant="contained" size='large' color="secondary" onClick={() => strategyOpen()}>
          <OpenInNewIcon />&nbsp;저수령 그란폰도 공략 다운로드
        </Button>

      </Box>
*/}
    </React.Fragment>
  );
};

Indoor.propTypes = {
  history: PropTypes.object
};

export default withRouter(Indoor);
