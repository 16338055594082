import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';

import { Box, Card, Chip, Divider, Typography } from '@mui/material';

import i18n from 'i18next';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px'
    }
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px'
    }
  },
  desc: {
    margin: '8px 0',
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  desc2: {
    display: 'list-item',
    fontSize: '14px',
    margin: '8px 0 8px 20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  zoneGuideBlock: {
    margin: '10px 0',
    padding: '10px',
    fontSize: '14px',
    textAlign: 'center'
  },
  zoneWeekName: {
    marginBottom: '5px'
  },
  effectChipBox: {
    textAlign: 'center',
    marginBottom: '8px'
  },
  effectChip: {
    color: '#4db6ac',
    margin: '-1px 2px 0 0',
    fontSize: '13px',
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    border: '1px #dddddd solid',
    [theme.breakpoints.down('lg')]: {
      zoom: 0.9
    }
  }
}));

const PowerZone = props => {
  const {
    activityData,
    pdcData,
    powerzoneData,
    ftpArray,
    wkg,
    weight,
    activityName
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const ftp = ftpArray.wftp;
  const tte = ftpArray.wtte;

  const [powerZoneOption, setPowerZoneOption] = React.useState(null);
  // const [ activityId, setActivityId ] = useState(props.activityId);
  const [weekId, setWeekId] = useState(-1);
  const [powerzoneAvg, setPowerzoneAvg] = useState([]);

  const z1_points = [];
  const z2_points = [];
  const z3_points = [];
  const z4_points = [];
  const z5_points = [];
  const z6_points = [];
  const z7_points = [];

  const containerZone = {
    minWidth: '300px',
    height: window.innerHeight > 800 ? '360px' : '240px',
    margin: '0 auto 10px auto'
  };

  let powerzoneWeeks = _.reverse(powerzoneData.powerzone_weeks);
  let index = 12;

  _.each(powerzoneWeeks, function(item, id) {
    let time_arr = item.time;

    if (time_arr.length === 7) {
      let total =
        time_arr[0] +
        time_arr[1] +
        time_arr[2] +
        time_arr[3] +
        time_arr[4] +
        time_arr[5] +
        time_arr[6];
      let z1_per = Math.round((100 * time_arr[6]) / total);
      let z2_per = Math.round((100 * time_arr[5]) / total);
      let z3_per = Math.round((100 * time_arr[4]) / total);
      let z4_per = Math.round((100 * time_arr[3]) / total);
      let z5_per = Math.round((100 * time_arr[2]) / total);
      let z6_per = Math.round((100 * time_arr[1]) / total);
      let z7_per = Math.round((100 * time_arr[0]) / total);

      z1_points.push({
        x: index,
        y: time_arr[6],
        per: z1_per,
        color: '#aaaaaa',
        activity_id: item.activity_id,
        label: 'z1'
      });
      z2_points.push({
        x: index,
        y: time_arr[5],
        per: z2_per,
        color: '#76b5e8',
        activity_id: item.activity_id,
        label: 'z2'
      });
      z3_points.push({
        x: index,
        y: time_arr[4],
        per: z3_per,
        color: '#b0d36d',
        activity_id: item.activity_id,
        label: 'z3'
      });
      z4_points.push({
        x: index,
        y: time_arr[3],
        per: z4_per,
        color: '#ffd357',
        activity_id: item.activity_id,
        label: 'z4'
      });
      z5_points.push({
        x: index,
        y: time_arr[2],
        per: z5_per,
        color: '#ffa75c',
        activity_id: item.activity_id,
        label: 'z5'
      });
      z6_points.push({
        x: index,
        y: time_arr[1],
        per: z6_per,
        color: '#ff7e75',
        activity_id: item.activity_id,
        label: 'z6'
      });
      z7_points.push({
        x: index,
        y: time_arr[0],
        per: z7_per,
        color: '#f06292',
        activity_id: item.activity_id,
        label: 'z7',
        indexLabel: TypeDesc(item.type).animalEmoji + '' + item.type,
        indexLabelPlacement: 'outside'
      });

      index--;
    }
  });

  let StackZ1 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z1',
    markerSize: 0,
    color: '#aaaaaa',
    fillOpacity: 0.8,
    dataPoints: z1_points,
    click: onClickStack
  };

  let StackZ2 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z2',
    markerSize: 0,
    color: '#76b5e8',
    fillOpacity: 0.8,
    dataPoints: z2_points,
    click: onClickStack
  };

  let StackZ3 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z3',
    markerSize: 0,
    color: '#b0d36d',
    fillOpacity: 0.8,
    dataPoints: z3_points,
    click: onClickStack
  };

  let StackZ4 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z4',
    markerSize: 0,
    color: '#ffd357',
    fillOpacity: 0.8,
    dataPoints: z4_points,
    click: onClickStack
  };

  let StackZ5 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z5',
    markerSize: 0,
    color: '#ffa75c',
    fillOpacity: 0.8,
    dataPoints: z5_points,
    click: onClickStack
  };

  let StackZ6 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z6',
    markerSize: 0,
    color: '#ff7e75',
    fillOpacity: 0.8,
    dataPoints: z6_points,
    click: onClickStack
  };

  let StackZ7 = {
    showInLegend: true,
    legendMarkerType: 'circle',
    type: 'stackedColumn',
    name: 'Z7',
    markerSize: 0,
    color: '#f06292',
    fillOpacity: 0.8,
    dataPoints: z7_points,
    indexLabelFontColor: theme.palette.chart.text.primary,
    click: onClickStack
  };

  function onClickStack(e) {
    let index = Number(e.dataPoint.x);
    setWeekId(12 - index);
  }

  function getDateYmd(date) {
    if (language === 'en') {
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    } else {
      return (
        date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      );
    }
  }

  function ZoneGuide() {
    const classes = useStyles();

    let typeDescArr = [];
    if (weekId > -1) {
      typeDescArr = TypeDesc(powerzoneWeeks[Number(weekId)].type);
    } else {
      typeDescArr = TypeDesc(powerzoneData.powerzone_avg.type_avg);
    }
    let trainingTypeAvg =
      ' ' + typeDescArr['animalEmoji'] + ' ' + typeDescArr['trainingType'];
    let effectChips = typeDescArr['effectChips'];
    let weekName = '';

    if (weekId > -1) {
      if (weekId === 0) {
        weekName = '📅 ' + t('Common.thisWeek');
      } else {
        weekName = '📅 ' + weekId + t('Common.weeksAgo');
      }
      weekName += ' (' + getMonToSun(weekId) + ')';
    } else {
      weekName = '📅 ' + t('Common.all');
      weekName += ' (' + getMonToToday(11) + ')';
    }

    return (
      <Card className={classes.zoneGuideBlock}>
        <Box
          className={classes.zoneWeekName}
          display={{ xs: 'block', sm: 'block', md: 'inline' }}>
          {weekName}
        </Box>
        <Box
          className={classes.zoneTrainingType}
          display={{ xs: 'inline', sm: 'inline', md: 'inline' }}>
          {trainingTypeAvg}
        </Box>
        <Box
          display={{ xs: 'none', sm: 'none', md: 'inline' }}
          style={{ zoom: 0.95, marginLeft: '12px' }}>
          <EffectChips effectChips={effectChips.slice(0, 2)} />
        </Box>
      </Card>
    );
  }

  function TypeDesc(typeStr) {
    let animalEmoji = '🦆';
    let trainingType = t('Animals.type.etc');
    let primaryZones = Array(0, 0, 0, 0, 0, 0, 0);
    let effectChips = Array(
      t('Animals.effects.etc.0'),
      t('Animals.effects.etc.1')
    );
    let animalTitle = t('Animals.animal.etc');
    let animalDesc = t('Animals.animalDesc.etc');
    let trainingDesc = t('Animals.trainingDesc.etc');

    if (typeStr == 'SPT') {
      animalEmoji = '🦁';
      trainingType = t('Animals.type.spt');
      primaryZones = Array(0, 0, 0, 0, 0, 0, 1);
      effectChips = Array(
        t('Animals.effects.spt.0'),
        t('Animals.effects.spt.1'),
        t('Animals.effects.spt.2'),
        t('Animals.effects.spt.3')
      );
      animalTitle = t('Animals.animal.spt');
      animalDesc = t('Animals.animalDesc.spt');
      trainingDesc = t('Animals.trainingDesc.spt');
    } else if (typeStr == 'HIIT') {
      animalEmoji = '🐆';
      trainingType = t('Animals.type.hiit');
      primaryZones = Array(0, 0, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.hiit.0'),
        t('Animals.effects.hiit.1'),
        t('Animals.effects.hiit.2'),
        t('Animals.effects.hiit.3')
      );
      animalTitle = t('Animals.animal.hiit');
      animalDesc = t('Animals.animalDesc.hiit');
      trainingDesc = t('Animals.trainingDesc.hiit');
    } else if (typeStr == 'VO2MAX') {
      animalEmoji = '🐺';
      trainingType = t('Animals.type.vo2max');
      primaryZones = Array(0, 0, 0, 1, 1, 0, 0);
      effectChips = Array(
        t('Animals.effects.vo2max.0'),
        t('Animals.effects.vo2max.1'),
        t('Animals.effects.vo2max.2')
      );
      animalTitle = t('Animals.animal.vo2max');
      animalDesc = t('Animals.animalDesc.vo2max');
      trainingDesc = t('Animals.trainingDesc.vo2max');
    } else if (typeStr == 'RPT') {
      animalEmoji = '🐴';
      trainingType = t('Animals.type.rpt');
      primaryZones = Array(0, 0, 1, 1, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.rpt.0'),
        t('Animals.effects.rpt.1'),
        t('Animals.effects.rpt.2')
      );
      animalTitle = t('Animals.animal.rpt');
      animalDesc = t('Animals.animalDesc.rpt');
      trainingDesc = t('Animals.trainingDesc.rpt');
    } else if (typeStr == 'SST') {
      animalEmoji = '🐗';
      trainingType = t('Animals.type.sst');
      primaryZones = Array(0, 0, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.sst.0'),
        t('Animals.effects.sst.1'),
        t('Animals.effects.sst.2')
      );
      animalTitle = t('Animals.animal.sst');
      animalDesc = t('Animals.animalDesc.sst');
      trainingDesc = t('Animals.trainingDesc.sst');
    } else if (typeStr == 'TEMPO') {
      animalEmoji = '🐷';
      trainingType = t('Animals.type.tempo');
      primaryZones = Array(0, 0, 1, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.tempo.0'),
        t('Animals.effects.tempo.1'),
        t('Animals.effects.tempo.2')
      );
      animalTitle = t('Animals.animal.tempo');
      animalDesc = t('Animals.animalDesc.tempo');
      trainingDesc = t('Animals.trainingDesc.tempo');
    } else if (typeStr == 'BASE') {
      animalEmoji = '🐮';
      trainingType = t('Animals.type.base');
      primaryZones = Array(1, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.base.0'),
        t('Animals.effects.base.1'),
        t('Animals.effects.base.2'),
        t('Animals.effects.base.3')
      );
      animalTitle = t('Animals.animal.base');
      animalDesc = t('Animals.animalDesc.base');
      trainingDesc = t('Animals.trainingDesc.base');
    } else if (typeStr == 'HVLI') {
      animalEmoji = '🐫';
      trainingType = t('Animals.type.hvli');
      primaryZones = Array(0, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.hvli.0'),
        t('Animals.effects.hvli.1'),
        t('Animals.effects.hvli.2'),
        t('Animals.effects.hvli.3')
      );
      animalTitle = t('Animals.animal.hvli');
      animalDesc = t('Animals.animalDesc.hvli');
      trainingDesc = t('Animals.trainingDesc.hvli');
    } else if (typeStr == 'POL') {
      animalEmoji = '🐘';
      trainingType = t('Animals.type.pol');
      primaryZones = Array(0, 1, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.pol.0'),
        t('Animals.effects.pol.1'),
        t('Animals.effects.pol.2'),
        t('Animals.effects.pol.3')
      );
      animalTitle = t('Animals.animal.pol');
      animalDesc = t('Animals.animalDesc.pol');
      trainingDesc = t('Animals.trainingDesc.pol');
    } else if (typeStr == 'PRMD') {
      animalEmoji = '🦌';
      trainingType = t('Animals.type.prmd');
      primaryZones = Array(0, 1, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.prmd.0'),
        t('Animals.effects.prmd.1'),
        t('Animals.effects.prmd.2'),
        t('Animals.effects.prmd.3')
      );
      animalTitle = t('Animals.animal.prmd');
      animalDesc = t('Animals.animalDesc.prmd');
      trainingDesc = t('Animals.trainingDesc.prmd');
    } else if (typeStr == 'BAL') {
      animalEmoji = '🐶';
      trainingType = t('Animals.type.bal');
      primaryZones = Array(1, 1, 1, 1, 1, 1, 1);
      effectChips = Array(
        t('Animals.effects.bal.0'),
        t('Animals.effects.bal.1'),
        t('Animals.effects.bal.2'),
        t('Animals.effects.bal.3')
      );
      animalTitle = t('Animals.animal.bal');
      animalDesc = t('Animals.animalDesc.bal');
      trainingDesc = t('Animals.trainingDesc.bal');
    } else if (typeStr == 'RECV') {
      animalEmoji = '🐢';
      trainingType = t('Animals.type.recv');
      primaryZones = Array(1, 0, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.recv.0'),
        t('Animals.effects.recv.1'),
        t('Animals.effects.recv.2')
      );
      animalTitle = t('Animals.animal.recv');
      animalDesc = t('Animals.animalDesc.recv');
      trainingDesc = t('Animals.trainingDesc.recv');
    }

    return {
      typeStr: typeStr,
      animalEmoji: animalEmoji,
      trainingType: trainingType,
      primaryZones: primaryZones,
      effectChips: effectChips,
      animalTitle: animalTitle,
      animalDesc: animalDesc,
      trainingDesc: trainingDesc
    };
  }

  function getMonToSun(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let thisWeek = [];

    for (let i = 1; i < 8; i++) {
      let resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
      let yyyy = resultDay.getFullYear();
      let mm = Number(resultDay.getMonth()) + 1;
      let dd = resultDay.getDate();

      mm = String(mm).length === 1 ? '0' + mm : mm;
      dd = String(dd).length === 1 ? '0' + dd : dd;

      thisWeek[i - 1] = mm + '/' + dd;
    }

    return thisWeek[0] + ' ~ ' + thisWeek[6];
  }

  function getMonToToday(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let resultDay = new Date(theYear, theMonth, theDate + (1 - theDayOfWeek));
    let mm = Number(resultDay.getMonth()) + 1;
    let dd = resultDay.getDate();

    mm = String(mm).length === 1 ? '0' + mm : mm;
    dd = String(dd).length === 1 ? '0' + dd : dd;

    let td = new Date();

    let mm2 = Number(td.getMonth()) + 1;
    let dd2 = td.getDate();

    mm2 = String(mm2).length === 1 ? '0' + mm2 : mm2;
    dd2 = String(dd2).length === 1 ? '0' + dd2 : dd2;

    return mm + '/' + dd + ' ~ ' + (mm2 + '/' + dd2);
  }

  function EffectChips(props) {
    const chipArr = props.effectChips;

    return (
      <React.Fragment>
        {chipArr.map((effect, index) => (
          <Chip
            key={index}
            size="small"
            className={classes.effectChip}
            label={effect}
          />
        ))}
      </React.Fragment>
    );
  }

  useEffect(() => {
    const stripLineX = [];
    const stripLineY = [];

    function onItemMouseOver(e) {
      for (let i = 0; i < 7; i++) {
        e.chart.data[i].set('fillOpacity', 0.2);
      }

      e.chart.data[e.dataSeriesIndex].set('fillOpacity', 1);
    }

    function onItemMouseOut(e) {
      for (let i = 0; i < 7; i++) {
        e.chart.data[i].set('fillOpacity', 0.8);
      }
    }

    setPowerZoneOption({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: false,
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let week =
            e.entries[0].dataPoint.x === 12
              ? t('Common.thisWeek') + ''
              : 12 - e.entries[0].dataPoint.x + t('Common.weeksAgo');
          let trainingType = TypeDesc(
            powerzoneData.powerzone_weeks[12 - e.entries[0].dataPoint.x].type
          ).trainingType;

          let z1 = secondsToHms(e.entries[0].dataPoint.y);
          let z2 = secondsToHms(e.entries[1].dataPoint.y);
          let z3 = secondsToHms(e.entries[2].dataPoint.y);
          let z4 = secondsToHms(e.entries[3].dataPoint.y);
          let z5 = secondsToHms(e.entries[4].dataPoint.y);
          let z6 = secondsToHms(e.entries[5].dataPoint.y);
          let z7 = secondsToHms(e.entries[6].dataPoint.y);
          let total = secondsToHms(
            e.entries[0].dataPoint.y +
              e.entries[1].dataPoint.y +
              e.entries[2].dataPoint.y +
              e.entries[3].dataPoint.y +
              e.entries[4].dataPoint.y +
              e.entries[5].dataPoint.y +
              e.entries[6].dataPoint.y
          );

          return (
            '<style>' +
            '.week-warp {background:#fff;}' +
            '.week-title {font-size:12px;color:#73838b;padding:8px 8px 0 8px;text-align:center;border:0;}' +
            '.zone-wrap {width:auto;background:#f5f5f5;border-radius:4px;margin:8px;}' +
            '.expect-wrap {float:right;width:116px;background:#f5f5f5;border-radius:4px;margin:8px 8px 8px 0;}' +
            '.zone-list { list-style:none;font-size:12px;font-weight:normal;color:#73838b;padding:4px 8px; }' +
            '.expect-list { list-style:none;font-size:12px;color:#73838b;padding:4px 8px; }' +
            '.zone-list .label { display:inline-block;width:70px;line-height:16px; }' +
            '.expect-list .label { display:inline-block;width:74px;line-height:16px; }' +
            '.sub-title { text-align:center;padding-bottom:4px;margin-bottom:4px;border-bottom:1px #dddddd solid; }' +
            '.week-title .total { margin-left:4px;color: #444; }' +
            '.label .marker { background:#76b5e8;height:8px;width:8px;border-radius:8px;position:relative;display:inline-block;margin-right:3px;}' +
            '.label .marker.z1 { background:#4db6ac }' +
            '.label .marker.z2 { background:#6fc892 }' +
            '.label .marker.z3 { background:#b0d36d }' +
            '.label .marker.z4 { background:#ffd357 }' +
            '.label .marker.z5 { background:#ffa75c }' +
            '.label .marker.z6 { background:#ff7e75 }' +
            '.label .marker.z7 { background:#f06292 }' +
            '</style>' +
            "<div class='week-warp'>" +
            "<div class='week-title'>" +
            '<span>' +
            week +
            '</span>' +
            "<span class='total'>" +
            total +
            '</span>' +
            '</div>' +
            "<div class='zone-wrap'>" +
            "<ul class='zone-list'>" +
            "<li><div class='sub-title'>" +
            trainingType +
            '</div></li>' +
            "<li><div class='label'><span class='marker z7'></span>" +
            t('Common.z7') +
            '</div> ' +
            z7 +
            '</li>' +
            "<li><div class='label'><span class='marker z6'></span>" +
            t('Common.z6') +
            '</div> ' +
            z6 +
            '</li>' +
            "<li><div class='label'><span class='marker z5'></span>" +
            t('Common.z5') +
            '</div> ' +
            z5 +
            '</li>' +
            "<li><div class='label'><span class='marker z4'></span>" +
            t('Common.z4') +
            '</div> ' +
            z4 +
            '</li>' +
            "<li><div class='label'><span class='marker z3'></span>" +
            t('Common.z3') +
            '</div> ' +
            z3 +
            '</li>' +
            "<li><div class='label'><span class='marker z2'></span>" +
            t('Common.z2') +
            '</div> ' +
            z2 +
            '</li>' +
            "<li><div class='label'><span class='marker z1'></span>" +
            t('Common.z1') +
            '</div> ' +
            z1 +
            '</li>' +
            '</ul>' +
            '</div>' +
            '</div>'
          );
        }
      },
      axisX: {
        labelFormatter: function(e) {
          let week =
            e.value === 12
              ? t('Common.thisWeek') + ''
              : 12 - e.value + t('Common.weeksAgo') + '';
          return week;
        },
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        //        labelFontSize: 12,
        labelFontColor: '#aaaaaa',
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            let week =
              e.value === 12
                ? t('Common.thisWeek') + ''
                : 12 - e.value + t('Common.weeksAgo') + '';
            return week;
          }
        }
      },
      axisY: {
        labelFormatter: function(e) {
          return Math.ceil(e.value / 3600) + t('Common.hour');
        },
        labelFontSize: 11,
        labelFontColor: '#aaaaaa',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        minimum: 0
      },
      legend: {
        horizontalAlign: 'center',
        verticalAlign: 'bottom',
        fontSize: 12,
        fontColor: theme.palette.chart.text.primary,
        itemmouseover: onItemMouseOver,
        itemmouseout: onItemMouseOut
      },
      data: [StackZ1, StackZ2, StackZ3, StackZ4, StackZ5, StackZ6, StackZ7]
    });

    function secondsToHms(time) {
      let d = Number(time);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      if (h > 0) {
        let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
        let md = m > 0 ? m + t('Common.min') : '';

        return hd + md;
      } else if (h <= 0) {
        let md = m > 0 ? m + t('Common.min') + ' ' : '';
        let sd = s > 0 ? s + t('Common.sec') : '';

        return md + sd;
      }
    }

    stripLineY.push({
      type: 'dash',
      value: 0,
      color: '#000000',
      lineDashType: 'dash',
      lineThickness: 2,
      lineColor: '#333333'
    });
  }, []);

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <ZoneGuide powerzoneAvg={powerzoneAvg} />
        <CanvasJSChart
          containerProps={containerZone}
          options={powerZoneOption}
        />
        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Powerzone.WeeklyTrainingIs')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Powerzone.WeeklyTrainingIsDesc')}
          </Typography>
        </Box>

        <Divider />
      </Box>
    </React.Fragment>
  );
};

PowerZone.propTypes = {
  history: PropTypes.object
};

export default withRouter(PowerZone);
