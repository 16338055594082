import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';

import { useTranslation } from 'react-i18next';
import ApexChart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';
import ChartOptions from './Options/OptionPowerZone.js';

const useStyles = makeStyles(theme => ({
  root: {},
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  activityContainer: {
    height: 436,
    overflow: 'visible',
    margin: '0px -4px 0px -14px',
    padding: '10px 0 24px 0',
    [theme.breakpoints.down('xl')]: {
      height: 368
    },
    [theme.breakpoints.down('lg')]: {
      height: 276
    },
    [theme.breakpoints.down('sm')]: {
      height: 256
    }
  },
  stackedBarContainer: {
    height: 436,
    overflow: 'visible',
    margin: '0px -4px 0px -14px',
    padding: '10px 0 24px 0',
    [theme.breakpoints.down('xl')]: {
      height: 368
    },
    [theme.breakpoints.down('lg')]: {
      height: 276
    },
    [theme.breakpoints.down('sm')]: {
      height: 256
    }
  },
  zoneChartFlex: {
    flexGrow: 1,
    height: '416px',
    margin: '-6px 0 0 -10px',
    [theme.breakpoints.down('xl')]: {
      height: 348
    },
    [theme.breakpoints.down('lg')]: {
      height: 256
    },
    [theme.breakpoints.down('sm')]: {
      height: 236
    }
  },
  zoneCardFlex: {
    flexBasis: '340px',
    margin: '16px 6px 26px 0px',
    padding: '4px',
    border: 'none',
    background: '#fafafa',
    overflowY: 'auto',
    height: '374px',
    [theme.breakpoints.down('xl')]: {
      height: 306
    },
    [theme.breakpoints.down('lg')]: {
      height: 214
    },
    [theme.breakpoints.down('sm')]: {
      height: 194
    }
  },
  zoneCardHeader: {
    padding: '8px 0 12px 0 ',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      padding: '6px 0 10px 0'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 0 8px 0'
    }
  },
  zoneCardHeaderTitle: {
    fontSize: '18px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '15px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    }
  },
  zoneCardContent: {
    padding: '0 0 0 0 !important'
  },
  zoneCardDesc: {
    fontSize: '13px',
    textAlign: 'left',
    padding: '0px 8px 8px 8px ',
    [theme.breakpoints.down('xl')]: {
      padding: '0px 6px 10px 6px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '18px',
      padding: '0px 4px 8px 4px'
    }
  },

  zoneDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  zoneCardSubTitle: {
    fontSize: '14px',
    textAlign: 'left',
    padding: '6px',
    color: '#73838b',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      padding: '4px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      padding: '3px'
    }
  },
  primaryZones: {
    textAlign: 'center',
    margin: '0px auto 8px'
  },
  primaryZonesSmall: {
    textAlign: 'left',
    margin: '0px auto 0px'
  },
  zoneCircle: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    margin: '4px 4px 4px 4px',
    textAlign: 'center',
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      zoom: 0.8,
      margin: '4px 2px 4px 2px'
    },
    [theme.breakpoints.down('md')]: {
      zoom: 0.6,
      margin: '4px 2px 4px 0px'
    }
  },
  zoneCircleFixed: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    margin: '4px 2px 4px 2px',
    textAlign: 'center',
    display: 'inline-block',
    zoom: 0.9
  },
  zoneCircleSmall: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '14px',
    margin: '4px 2px 4px 2px',
    textAlign: 'center',
    display: 'inline-block',
    zoom: 0.6
  },
  zOn: { opacity: 1.0 },
  zOff: { opacity: 0.2 },
  z1: { backgroundColor: '#aaaaaa', color: '#ffffff' },
  z2: { backgroundColor: '#76b5e8', color: '#ffffff' },
  z3: { backgroundColor: '#b0d36d', color: '#ffffff' },
  z4: { backgroundColor: '#ffd357', color: '#ffffff' },
  z5: { backgroundColor: '#ffa75c', color: '#ffffff' },
  z6: { backgroundColor: '#ff7e75', color: '#ffffff' },
  z7: { backgroundColor: '#f06292', color: '#ffffff' },
  effectChipBox: {
    textAlign: 'center',
    marginBottom: '8px'
  },
  effectChip: {
    color: '#4db6ac',
    margin: '-1px 2px 0 0',
    fontSize: '13px',
    cursor: 'pointer',
    background: '#ffffff',
    border: '1px #dddddd solid',
    [theme.breakpoints.down('lg')]: {
      zoom: 0.9
    }
  },

  zoneGuideBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% - 18px)',
    flexGrow: 1,
    height: '36px',
    margin: '0px 0 0px 12px'
  },
  zoneLeftButton: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    padding: '4px'
  },
  zoneGuideButton: {
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
    fontSize: '15px',
    color: '#455a64',
    background: '#f5f5f5',
    opacity: '0.9',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    borderRadius: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      borderRadius: '4px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  zoneTextBold: {
    fontSize: '15px',
    margin: '8px auto 0 auto',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    }
  },
  chartController: {
    height: '40px',
    marginTop: '8px',
    textAlign: 'center'
  },

  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0',
    [theme.breakpoints.down('min')]: {
      width: '240px'
    }
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('min')]: {
      width: '240px'
    }
  },
  zoneTotalChart: {
    height: '200px',
    [theme.breakpoints.down('md')]: {
      height: '150px'
    }
  },

  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },

  listContent: {
    padding: '0px'
  },
  listItem: {
    padding: '2px 12px 0px 12px',
    borderBottom: '1px #f5f5f5 solid'
  },
  animalEmoji: {
    backgroundColor: '#eeeeee',
    height: 40,
    width: 40,
    marginTop: '-2px'
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('md')]: {
      height: 32,
      width: 32
    }
  },

  tsbDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('md')]: {
      height: 32,
      width: 32
    }
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  }
}));

const PowerZone = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const { ftp, powerzoneData, activityName, activityDesc, wkg, weight } = props;
  const [activityId, setActivityId] = useState(props.activityId);
  const [weekId, setWeekId] = useState(-1);
  const [zoneGuideDialogOpen, setZoneGuideDialogOpen] = useState(false);

  const powerzoneAvg = powerzoneData.powerzone_avg;

  const zoneGuides = '';

  const classes = useStyles();

  function getMonToSun(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let thisWeek = [];

    for (let i = 1; i < 8; i++) {
      let resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
      let yyyy = resultDay.getFullYear();
      let mm = Number(resultDay.getMonth()) + 1;
      let dd = resultDay.getDate();

      mm = String(mm).length === 1 ? '0' + mm : mm;
      dd = String(dd).length === 1 ? '0' + dd : dd;

      thisWeek[i - 1] = mm + '/' + dd;
    }

    return thisWeek[0] + ' ~ ' + thisWeek[6];
  }

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') : '';
      let md = m > 0 ? m + t('Common.min') : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function TypeDesc(typeStr) {
    let animalEmoji = '🦆';
    let trainingType = t('Animals.type.etc');
    let primaryZones = Array(0, 0, 0, 0, 0, 0, 0);
    let effectChips = Array(
      t('Animals.effects.etc.0'),
      t('Animals.effects.etc.1')
    );
    let animalTitle = t('Animals.animal.etc');
    let animalDesc = t('Animals.animalDesc.etc');
    let trainingDesc = t('Animals.trainingDesc.etc');

    if (typeStr == 'SPT') {
      animalEmoji = '🦁';
      trainingType = t('Animals.type.spt');
      primaryZones = Array(0, 0, 0, 0, 0, 0, 1);
      effectChips = Array(
        t('Animals.effects.spt.0'),
        t('Animals.effects.spt.1'),
        t('Animals.effects.spt.2'),
        t('Animals.effects.spt.3')
      );
      animalTitle = t('Animals.animal.spt');
      animalDesc = t('Animals.animalDesc.spt');
      trainingDesc = t('Animals.trainingDesc.spt');
    } else if (typeStr == 'HIIT') {
      animalEmoji = '🐆';
      trainingType = t('Animals.type.hiit');
      primaryZones = Array(0, 0, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.hiit.0'),
        t('Animals.effects.hiit.1'),
        t('Animals.effects.hiit.2'),
        t('Animals.effects.hiit.3')
      );
      animalTitle = t('Animals.animal.hiit');
      animalDesc = t('Animals.animalDesc.hiit');
      trainingDesc = t('Animals.trainingDesc.hiit');
    } else if (typeStr == 'VO2MAX') {
      animalEmoji = '🐺';
      trainingType = t('Animals.type.vo2max');
      primaryZones = Array(0, 0, 0, 1, 1, 0, 0);
      effectChips = Array(
        t('Animals.effects.vo2max.0'),
        t('Animals.effects.vo2max.1'),
        t('Animals.effects.vo2max.2')
      );
      animalTitle = t('Animals.animal.vo2max');
      animalDesc = t('Animals.animalDesc.vo2max');
      trainingDesc = t('Animals.trainingDesc.vo2max');
    } else if (typeStr == 'RPT') {
      animalEmoji = '🐴';
      trainingType = t('Animals.type.rpt');
      primaryZones = Array(0, 0, 1, 1, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.rpt.0'),
        t('Animals.effects.rpt.1'),
        t('Animals.effects.rpt.2')
      );
      animalTitle = t('Animals.animal.rpt');
      animalDesc = t('Animals.animalDesc.rpt');
      trainingDesc = t('Animals.trainingDesc.rpt');
    } else if (typeStr == 'SST') {
      animalEmoji = '🐗';
      trainingType = t('Animals.type.sst');
      primaryZones = Array(0, 0, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.sst.0'),
        t('Animals.effects.sst.1'),
        t('Animals.effects.sst.2')
      );
      animalTitle = t('Animals.animal.sst');
      animalDesc = t('Animals.animalDesc.sst');
      trainingDesc = t('Animals.trainingDesc.sst');
    } else if (typeStr == 'TEMPO') {
      animalEmoji = '🐷';
      trainingType = t('Animals.type.tempo');
      primaryZones = Array(0, 0, 1, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.tempo.0'),
        t('Animals.effects.tempo.1'),
        t('Animals.effects.tempo.2')
      );
      animalTitle = t('Animals.animal.tempo');
      animalDesc = t('Animals.animalDesc.tempo');
      trainingDesc = t('Animals.trainingDesc.tempo');
    } else if (typeStr == 'BASE') {
      animalEmoji = '🐮';
      trainingType = t('Animals.type.base');
      primaryZones = Array(1, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.base.0'),
        t('Animals.effects.base.1'),
        t('Animals.effects.base.2'),
        t('Animals.effects.base.3')
      );
      animalTitle = t('Animals.animal.base');
      animalDesc = t('Animals.animalDesc.base');
      trainingDesc = t('Animals.trainingDesc.base');
    } else if (typeStr == 'HVLI') {
      animalEmoji = '🐫';
      trainingType = t('Animals.type.hvli');
      primaryZones = Array(0, 1, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.hvli.0'),
        t('Animals.effects.hvli.1'),
        t('Animals.effects.hvli.2'),
        t('Animals.effects.hvli.3')
      );
      animalTitle = t('Animals.animal.hvli');
      animalDesc = t('Animals.animalDesc.hvli');
      trainingDesc = t('Animals.trainingDesc.hvli');
    } else if (typeStr == 'POL') {
      animalEmoji = '🐘';
      trainingType = t('Animals.type.pol');
      primaryZones = Array(0, 1, 0, 0, 1, 1, 0);
      effectChips = Array(
        t('Animals.effects.pol.0'),
        t('Animals.effects.pol.1'),
        t('Animals.effects.pol.2'),
        t('Animals.effects.pol.3')
      );
      animalTitle = t('Animals.animal.pol');
      animalDesc = t('Animals.animalDesc.pol');
      trainingDesc = t('Animals.trainingDesc.pol');
    } else if (typeStr == 'PRMD') {
      animalEmoji = '🦌';
      trainingType = t('Animals.type.prmd');
      primaryZones = Array(0, 1, 1, 1, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.prmd.0'),
        t('Animals.effects.prmd.1'),
        t('Animals.effects.prmd.2'),
        t('Animals.effects.prmd.3')
      );
      animalTitle = t('Animals.animal.prmd');
      animalDesc = t('Animals.animalDesc.prmd');
      trainingDesc = t('Animals.trainingDesc.prmd');
    } else if (typeStr == 'BAL') {
      animalEmoji = '🐶';
      trainingType = t('Animals.type.bal');
      primaryZones = Array(1, 1, 1, 1, 1, 1, 1);
      effectChips = Array(
        t('Animals.effects.bal.0'),
        t('Animals.effects.bal.1'),
        t('Animals.effects.bal.2'),
        t('Animals.effects.bal.3')
      );
      animalTitle = t('Animals.animal.bal');
      animalDesc = t('Animals.animalDesc.bal');
      trainingDesc = t('Animals.trainingDesc.bal');
    } else if (typeStr == 'RECV') {
      animalEmoji = '🐢';
      trainingType = t('Animals.type.recv');
      primaryZones = Array(1, 0, 0, 0, 0, 0, 0);
      effectChips = Array(
        t('Animals.effects.recv.0'),
        t('Animals.effects.recv.1'),
        t('Animals.effects.recv.2')
      );
      animalTitle = t('Animals.animal.recv');
      animalDesc = t('Animals.animalDesc.recv');
      trainingDesc = t('Animals.trainingDesc.recv');
    }

    return {
      typeStr: typeStr,
      animalEmoji: animalEmoji,
      trainingType: trainingType,
      primaryZones: primaryZones,
      effectChips: effectChips,
      animalTitle: animalTitle,
      animalDesc: animalDesc,
      trainingDesc: trainingDesc
    };
  }

  function EffectChips(props) {
    const chipArr = props.effectChips;

    return (
      <React.Fragment>
        {chipArr.map((effect, index) => (
          <Chip
            key={index}
            size="small"
            className={classes.effectChip}
            label={effect}
          />
        ))}
      </React.Fragment>
    );
  }

  function PrimaryZones(props) {
    let zonesArr = [1, 2, 3, 4, 5, 6, 7];
    const primaryZonesArr = props.primaryZones;
    const size = props.size;

    let primaryZones = classes.primaryZones;
    let zoneCircle = classes.zoneCircle;
    if (size === 'fixed') {
      zoneCircle = classes.zoneCircleFixed;
    } else if (size === 'small') {
      primaryZones = classes.primaryZonesSmall;
      zoneCircle = classes.zoneCircleSmall;
    }

    return (
      <Box className={primaryZones}>
        {zonesArr.map((zone, index) => (
          <Avatar
            component="span"
            className={
              primaryZonesArr[index] === 1
                ? clsx([zoneCircle, eval(`classes.z${zone}`), classes.zOn])
                : clsx([zoneCircle, eval(`classes.z${zone}`), classes.zOff])
            }>
            Z{zone}
          </Avatar>
        ))}
      </Box>
    );
  }

  function HorizontalBar(props) {
    const activityId = props.activityId;
    const weekId = props.weekId;
    const powerzoneData = props.powerzoneData;
    const zoneDetailOptions = cloneDeep(props.options.zoneDetail);

    let zoneObj = null;

    if (activityId > 0) {
      zoneObj = powerzoneData.powerzone_activities[activityId];
    } else if (weekId > -1) {
      zoneObj = powerzoneData.powerzone_weeks[weekId];
    }

    if (typeof zoneObj === 'undefined') {
      return (
        <Box style={{ width: '100%', margin: '20px auto' }}>
          <Typography style={{ textAlign: 'center' }}>
            {t('Dashboard.noAnalysis')}
          </Typography>
        </Box>
      );
    }

    const activityCount =
      zoneObj.activity_cnt > 0
        ? '(' + zoneObj.activity_cnt + t('Common.cnt') + ')'
        : '';
    const stackedType = 'per';
    const typeStr = zoneObj.type;
    const duration = secondsToHms(zoneObj.total) + ' ' + activityCount;

    let zoneDetailSeries = [{ data: zoneObj.per }];

    console.log(zoneObj.per);

    if (stackedType === 'time') {
      zoneDetailSeries = [{ data: zoneObj.time }];
    }

    let typeDescArr = TypeDesc(typeStr);
    let trainingType =
      typeDescArr['animalEmoji'] + ' ' + typeDescArr['trainingType'];
    let primaryZones = typeDescArr['primaryZones'];
    let effectChips = typeDescArr['effectChips'];
    let animalTitle = typeDescArr['animalTitle'];
    let animalDesc = typeDescArr['animalDesc'];
    let trainingDesc = typeDescArr['trainingDesc'];

    return (
      <Box style={{ display: 'flex' }}>
        <div className={classes.zoneChartFlex}>
          <ApexChart
            options={zoneDetailOptions}
            series={zoneDetailSeries}
            type="bar"
            width={'100%'}
            height={'100%'}
            style={{ marginTop: '-6px' }}
          />
        </div>
        <Card className={classes.zoneCardFlex}>
          <CardHeader
            className={classes.zoneCardHeader}
            title={
              <Typography
                color="primary"
                className={classes.zoneCardHeaderTitle}>
                {trainingType}
              </Typography>
            }
          />
          <Divider />
          <CardContent className={classes.zoneCardContent}>
            <Typography
              className={classes.zoneCardSubTitle}
              style={{ textAlign: 'left' }}
              component="div">
              {t('Dashboard.effectiveDuration')}
              <div
                style={{
                  display: 'inline',
                  marginLeft: '8px',
                  color: '#666666'
                }}>
                {duration}
              </div>
            </Typography>
          </CardContent>
          <Divider />
          <CardContent className={classes.zoneCardContent}>
            <Typography className={classes.zoneCardSubTitle} component="div">
              {t('Dashboard.primaryZones')}
            </Typography>
            <PrimaryZones primaryZones={primaryZones} />
          </CardContent>
          <Divider />
          <CardContent className={classes.zoneCardContent}>
            <Typography className={classes.zoneCardSubTitle} component="div">
              {t('Dashboard.trainingDesc')}
            </Typography>
            <Typography className={classes.zoneCardDesc} component="div">
              {typeDescArr['trainingType'] + ' (' + typeStr + ') - '}
              {trainingDesc}
            </Typography>
          </CardContent>
          <Divider />
          <CardContent className={classes.zoneCardContent}>
            <Typography className={classes.zoneCardSubTitle} component="div">
              {t('Dashboard.trainingEffects')}
            </Typography>
            <Box className={classes.effectChipBox}>
              <EffectChips effectChips={effectChips} />
            </Box>
          </CardContent>
          <Divider />
          <CardContent className={classes.zoneCardContent}>
            <Typography className={classes.zoneCardSubTitle} component="div">
              {animalTitle}
            </Typography>
            <Typography className={classes.zoneCardDesc} component="div">
              {animalDesc}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  function StackedBar(props) {
    const powerzoneData = props.powerzoneData;
    const zoneTrendOptions = cloneDeep(props.options.zoneTrend);

    const powerzonePer = powerzoneData.powerzone_per;
    const powerzoneTime = powerzoneData.powerzone_time;
    const weekMaxTime = powerzoneData.week_max_time;

    const stackedType = 'time';

    let zoneWeekSeries = [
      { name: 'Z1', data: powerzonePer.z1, type: 'bar' },
      { name: 'Z2', data: powerzonePer.z2, type: 'bar' },
      { name: 'Z3', data: powerzonePer.z3, type: 'bar' },
      { name: 'Z4', data: powerzonePer.z4, type: 'bar' },
      { name: 'Z5', data: powerzonePer.z5, type: 'bar' },
      { name: 'Z6', data: powerzonePer.z6, type: 'bar' },
      { name: 'Z7', data: powerzonePer.z7, type: 'bar' }
    ];

    if (stackedType === 'time') {
      zoneWeekSeries = [
        { name: 'Z1', data: powerzoneTime.z1, type: 'bar' },
        { name: 'Z2', data: powerzoneTime.z2, type: 'bar' },
        { name: 'Z3', data: powerzoneTime.z3, type: 'bar' },
        { name: 'Z4', data: powerzoneTime.z4, type: 'bar' },
        { name: 'Z5', data: powerzoneTime.z5, type: 'bar' },
        { name: 'Z6', data: powerzoneTime.z6, type: 'bar' },
        { name: 'Z7', data: powerzoneTime.z7, type: 'bar' }
      ];
      zoneTrendOptions.yaxis.max = weekMaxTime;
    }

    //클릭했을시 주간 상세보기
    zoneTrendOptions.chart.events.dataPointSelection = function(
      event,
      chartContext,
      config
    ) {
      //			console.log(config.dataPointIndex);
      let index = Number(config.dataPointIndex);
      setWeekId(11 - index);
    };

    let typeFontSize = '11px';
    if (window.innerWidth < 600) typeFontSize = '6px';

    zoneTrendOptions.dataLabels = {
      enabled: true,
      style: {
        fontSize: typeFontSize,
        fontColor: '#ffffff',
        colors: ['#ffffff']
      },
      textAnchor: 'middle',
      formatter: function(val, opt) {
        let type = powerzonePer.type[opt.dataPointIndex];
        if (opt.seriesIndex == 1 && type !== 'NONE') return type;
      },
      background: {
        enabled: false
      },
      dropShadow: {
        enabled: false
      }
    };

    zoneTrendOptions.tooltip.custom = function({
      series,
      seriesIndex,
      dataPointIndex,
      w
    }) {
      let weekTimeSum = secondsToHms(
        series[0][dataPointIndex] +
          series[1][dataPointIndex] +
          series[2][dataPointIndex] +
          series[3][dataPointIndex] +
          series[4][dataPointIndex] +
          series[5][dataPointIndex] +
          series[6][dataPointIndex]
      );

      let week = powerzonePer.z1.length - dataPointIndex - 1;
      if (week === 0) week = t('Common.thisWeek') + ' ' + weekTimeSum;
      else week += t('Common.weeksAgo') + ' ' + weekTimeSum;

      let trainingType = '';
      let typeStr = powerzonePer.type[dataPointIndex];

      let z1 = Number(series[0][dataPointIndex]) + '%';
      let z2 = Number(series[1][dataPointIndex]) + '%';
      let z3 = Number(series[2][dataPointIndex]) + '%';
      let z4 = Number(series[3][dataPointIndex]) + '%';
      let z5 = Number(series[4][dataPointIndex]) + '%';
      let z6 = Number(series[5][dataPointIndex]) + '%';
      let z7 = Number(series[6][dataPointIndex]) + '%';

      if (stackedType === 'time') {
        z1 = secondsToHms(Number(series[0][dataPointIndex]));
        z2 = secondsToHms(Number(series[1][dataPointIndex]));
        z3 = secondsToHms(Number(series[2][dataPointIndex]));
        z4 = secondsToHms(Number(series[3][dataPointIndex]));
        z5 = secondsToHms(Number(series[4][dataPointIndex]));
        z6 = secondsToHms(Number(series[5][dataPointIndex]));
        z7 = secondsToHms(Number(series[6][dataPointIndex]));
      }

      if (z1 === 0) {
        return '<div></div>';
      }

      if (typeStr == 'HIIT') trainingType = t('Animals.type.hiit');
      else if (typeStr == 'SPT') trainingType = t('Animals.type.spt');
      else if (typeStr == 'VO2MAX') trainingType = t('Animals.type.vo2max');
      else if (typeStr == 'RPT') trainingType = t('Animals.type.rpt');
      else if (typeStr == 'SST') trainingType = t('Animals.type.sst');
      else if (typeStr == 'TEMPO') trainingType = t('Animals.type.tempo');
      else if (typeStr == 'BASE') trainingType = t('Animals.type.base');
      else if (typeStr == 'HVLI') trainingType = t('Animals.type.hvli');
      else if (typeStr == 'POL') trainingType = t('Animals.type.pol');
      else if (typeStr == 'PRMD') trainingType = t('Animals.type.prmd');
      else if (typeStr == 'RECV') trainingType = t('Animals.type.recv');
      else if (typeStr == 'BAL') trainingType = t('Animals.type.bal');
      else trainingType = t('Animals.type.etc');

      return (
        '<style>' +
        '.week-title {font-size:12px;color:#73838b;padding:8px 8px 0 8px;text-align:center}' +
        '.zone-wrap {float:left;width:auto;background:#f5f5f5;border-radius:4px;margin:8px;}' +
        '.expect-wrap {float:right;width:116px;background:#f5f5f5;border-radius:4px;margin:8px 8px 8px 0;}' +
        '.zone-list { list-style:none;font-size:12px;font-weight:normal;color:#73838b;padding:4px 8px; }' +
        '.expect-list { list-style:none;font-size:12px;color:#73838b;padding:4px 8px; }' +
        '.zone-list .label { display:inline-block;width:70px;line-height:16px; }' +
        '.expect-list .label { display:inline-block;width:74px;line-height:16px; }' +
        '.sub-title { text-align:center;padding-bottom:4px;margin-bottom:4px;border-bottom:1px #dddddd solid; }' +
        '.label .marker { background:#76b5e8;height:8px;width:8px;border-radius:8px;position:relative;display:inline-block;margin-right:3px;}' +
        '.label .marker.z1 { background:#4db6ac }' +
        '.label .marker.z2 { background:#6fc892 }' +
        '.label .marker.z3 { background:#b0d36d }' +
        '.label .marker.z4 { background:#ffd357 }' +
        '.label .marker.z5 { background:#ffa75c }' +
        '.label .marker.z6 { background:#ff7e75 }' +
        '.label .marker.z7 { background:#f06292 }' +
        '</style>' +
        "<div class='week-title'>" +
        '<span>' +
        week +
        '</span>' +
        '</div>' +
        "<div class='zone-wrap'>" +
        "<ul class='zone-list'>" +
        "<li><div class='sub-title'>" +
        trainingType +
        '</div></li>' +
        "<li><div class='label'><span class='marker z7'></span>" +
        t('Common.z7') +
        '</div> ' +
        z7 +
        '</li>' +
        "<li><div class='label'><span class='marker z6'></span>" +
        t('Common.z6') +
        '</div> ' +
        z6 +
        '</li>' +
        "<li><div class='label'><span class='marker z5'></span>" +
        t('Common.z5') +
        '</div> ' +
        z5 +
        '</li>' +
        "<li><div class='label'><span class='marker z4'></span>" +
        t('Common.z4') +
        '</div> ' +
        z4 +
        '</li>' +
        "<li><div class='label'><span class='marker z3'></span>" +
        t('Common.z3') +
        '</div> ' +
        z3 +
        '</li>' +
        "<li><div class='label'><span class='marker z2'></span>" +
        t('Common.z2') +
        '</div> ' +
        z2 +
        '</li>' +
        "<li><div class='label'><span class='marker z1'></span>" +
        t('Common.z1') +
        '</div> ' +
        z1 +
        '</li>' +
        '</ul>' +
        '</div>'
      );
    };

    return (
      <React.Fragment>
        <ApexChart
          options={zoneTrendOptions}
          series={zoneWeekSeries}
          height={'100%'}
          style={{ marginTop: '-12px' }}
        />
      </React.Fragment>
    );
  }

  //setStreamData(data.stream_json);
  //setAltitudeMax(data.altitude_max);
  function handleZoneGuideDialogOpen() {
    setZoneGuideDialogOpen(true);
  }

  function handleZoneGuideDialogClose() {
    setZoneGuideDialogOpen(false);
  }

  function ZoneGuideDialogContent(props) {
    const powerzoneData = props.powerzoneData;
    const powerzoneAvg = powerzoneData.powerzone_avg;
    const zoneGuideOptions = cloneDeep(props.options.zoneGuide);

    const [zoneGuideTab, setZoneGuideTab] = React.useState(0);

    const handleZoneGuideTab = (event, newIndex) => {
      setZoneGuideTab(newIndex);
    };

    if (typeof powerzoneAvg === 'undefined') {
      return (
        <Box style={{ width: '100%', margin: '20px auto' }}>
          <Typography style={{ textAlign: 'center' }}>
            {t('Dashboard.noActivities')}
          </Typography>
        </Box>
      );
    }

    const activityCount =
      powerzoneAvg.activity_cnt > 0
        ? '(' + powerzoneAvg.activity_cnt + t('Common.cnt') + ')'
        : '';
    const typeStr = powerzoneAvg.type_avg;
    const duration =
      secondsToHms(powerzoneAvg.total_time) + ' ' + activityCount;

    let zoneGuideSeries = [{ data: powerzoneAvg.zone_avg }];

    let typeDescArr = TypeDesc(typeStr);
    let trainingType =
      typeDescArr['animalEmoji'] + ' ' + typeDescArr['trainingType'];
    let primaryZones = typeDescArr['primaryZones'];
    let effectChips = typeDescArr['effectChips'];

    let animalGuides = [
      TypeDesc('SPT'),
      TypeDesc('HIIT'),
      TypeDesc('VO2MAX'),
      TypeDesc('RPT'),
      TypeDesc('SST'),
      TypeDesc('TEMPO'),
      TypeDesc('BAL'),
      TypeDesc('HVLI'),
      TypeDesc('BASE'),
      TypeDesc('POL'),
      TypeDesc('PRMD'),
      TypeDesc('RECV')
    ];

    return (
      <React.Fragment>
        <Box>
          <Paper elevation={0} className={classes.tabs}>
            <Tabs
              value={zoneGuideTab}
              onChange={handleZoneGuideTab}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab label={t('Dashboard.zoneTab1')} />
              <Tab label={t('Dashboard.zoneTab2')} />
            </Tabs>
          </Paper>
          {zoneGuideTab === 1 ? (
            <Box className={classes.tabContent}>
              <List className={classes.listContent}>
                {animalGuides.map((guide, index) => (
                  <ListItem className={classes.listItem} key={index}>
                    <ListItemAvatar>
                      <Avatar className={classes.animalEmoji}>
                        {guide.animalEmoji}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div>
                          <Typography component="span">
                            {guide.trainingType + ' (' + guide.typeStr + ')'}
                          </Typography>
                          <PrimaryZones
                            primaryZones={guide.primaryZones}
                            size={'small'}
                          />
                        </div>
                      }
                      secondary={''}
                    />
                  </ListItem>
                ))}
              </List>
              <Divider />
              <Box>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.animalAndCycle')}
                </Typography>
                <Box>
                  <Typography className={classes.tsbDesc} component="div">
                    {t('Dashboard.animalAndCycleDesc')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.tabContent}>
              <Typography className={classes.tabTitle} component="div">
                {t('Dashboard.zoneRate')}
              </Typography>
              <Box className={classes.zoneTotalChart}>
                <ApexChart
                  options={zoneGuideOptions}
                  series={zoneGuideSeries}
                  type="bar"
                  width={'100%'}
                  height={'100%'}
                  style={{ margin: '-24px 0 0px 0' }}
                />
              </Box>
              <Box
                style={{
                  fontSize: '13px',
                  margin: '-16px 0 12px 0',
                  lineHeight: '24px',
                  textAlign: 'center'
                }}>
                <Typography className={classes.zoneTextBold} component="div">
                  {trainingType}
                </Typography>
                <div style={{ margin: '0 0 8px 0', color: '#666666' }}>
                  {duration}
                </div>
                <Divider />
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.primaryZones')}
                </Typography>
                <PrimaryZones primaryZones={primaryZones} size={'fixed'} />
                <Divider />
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.trainingEffects')}
                </Typography>
                <Box className={classes.effectChipBox}>
                  <EffectChips effectChips={effectChips} />
                </Box>
              </Box>
              <Divider />
              <Box>
                <Typography
                  className={classes.zoneDesc}
                  component="div"></Typography>
                <Typography
                  className={classes.zoneDesc}
                  component="div"></Typography>
              </Box>
            </Box>
          )}
        </Box>
      </React.Fragment>
    );
  }

  function ZoneGuide(props) {
    const powerzoneAvg = props.powerzoneAvg;
    const classes = useStyles();

    ////#ffd357 노
    //f491b2 빨
    //8cc1ec 파랑
    //4db6ac 녹
    //powerzoneAvg.type_avg

    let typeDescArr = TypeDesc(powerzoneAvg.type_avg);
    let trainingTypeAvg =
      typeDescArr['animalEmoji'] + ' ' + typeDescArr['trainingType'];
    let effectChips = typeDescArr['effectChips'];
    //		let primaryZones = typeDescArr['primaryZones'];

    if (activityId > 0) {
      return (
        <div className={classes.zoneGuideBlock}>
          <IconButton
            className={classes.zoneLeftButton}
            onClick={() => {
              setActivityId(0);
              setWeekId(-1);
            }}
            size="large">
            <BackspaceIcon />
          </IconButton>
          <Button variant="contained" className={classes.zoneGuideButton}>
            {'📝 ' + activityName}
          </Button>
        </div>
      );
    } else if (weekId > -1) {
      let weekName = '';
      if (weekId === 0) weekName = '📅 ' + t('Common.thisWeek');
      else weekName = '📅 ' + weekId + t('Common.weeksAgo');

      weekName += ' (' + getMonToSun(weekId) + ')';

      return (
        <div className={classes.zoneGuideBlock}>
          <IconButton
            className={classes.zoneLeftButton}
            onClick={() => {
              setActivityId(0);
              setWeekId(-1);
            }}
            size="large">
            <BackspaceIcon />
          </IconButton>
          <Button
            variant="contained"
            className={classes.zoneGuideButton}
            style={{ color: '#f06292' }}>
            {weekName}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.zoneGuideBlock}>
          <IconButton className={classes.zoneLeftButton} size="large">
            <InfoOutlinedIcon />
          </IconButton>
          <Button
            onClick={handleZoneGuideDialogOpen}
            variant="contained"
            color="inherit"
            className={classes.zoneGuideButton}
            display="block">
            {trainingTypeAvg}
            <Box
              display={{ xs: 'none', sm: 'none', md: 'inline' }}
              style={{ zoom: 0.95, marginLeft: '12px' }}>
              <EffectChips effectChips={effectChips.slice(0, 2)} />
            </Box>
          </Button>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {activityId > 0 || weekId > -1 ? (
        <Box className={classes.activityContainer}>
          <ZoneGuide powerzoneAvg={powerzoneAvg} />
          <HorizontalBar
            options={ChartOptions}
            powerzoneData={powerzoneData}
            weekId={weekId}
            activityId={activityId}
          />
        </Box>
      ) : (
        <Box className={classes.stackedBarContainer}>
          <ZoneGuide powerzoneAvg={powerzoneAvg} />
          <StackedBar options={ChartOptions} powerzoneData={powerzoneData} />
        </Box>
      )}

      <Dialog open={zoneGuideDialogOpen} onClose={handleZoneGuideDialogClose}>
        <DialogTitle>{t('Dashboard.zoneDialogTitle')}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}>
          <ZoneGuideDialogContent
            zoneGuides={zoneGuides}
            options={ChartOptions}
            powerzoneData={powerzoneData}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

PowerZone.propTypes = {
  className: PropTypes.string
};

export default withRouter(PowerZone);
