import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
//
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import { searchListApi } from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Chip,
  InputAdornment,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Stack } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Fab from '@mui/material/Fab';
import CreateIcon from '@mui/icons-material/Create';

import CustomCircularProgress from '../Common/CustomCircularProgress';
import PelotonLayout from '../Common/PelotonLayout';
import PelotonCard from './PelotonCard';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    alignItems: 'center'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvasHead: {
    padding: '16px 16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  canvasBody: {
    padding: '16px 16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  chipWrap: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '16px',
    '& .MuiChip-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled
      }
    },
    '& > *': {
      marginRight: '4px'
    }
  },
  searchWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end'
  },
  banner: {
    /*#4db6ac*/
    backgroundColor: 'lightblue',
    border: '1px #dddddd solid',
    height: '120px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',

    backgroundImage: "url('/images/peloton_notice.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 85%',
    backgroundSize: 'cover'
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '300px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  input: {
    fontSize: '16px'
  }
}));

const List = () => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  /**
   * @return : formData, onChange, request API
   */
  const [formData, setFormData] = useState({
    values: {
      name: ''
    }
  });

  const [data, setData] = useState();
  const [page, setPage] = useState(0);

  const [pageTotal, setPageTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = React.useState(false);

  const ITEMS_PER_PAGE = 9;

  useEffect(() => {
    handleFetchPelotonForPage();
  }, []);

  const [selectedChip, setSelectedChip] = useState('전체');
  const [isSearched, setIsSearched] = useState(false);

  const handleCategoryChipClick = (event, label) => {
    event.preventDefault();

    setIsSearched(false);
    setFormData({
      values: {
        name: ''
      }
    });
    setSelectedChip(label);

    /**
     * 전체 : 모든 데이터를 setData
     * 내 펠로톤 : myClubUserResponse가 빈 배열이 아닌 데이터만 setData
     * 가입 대기 : myClubUserResponse.join이 false인 데이터만
     */
  };

  useEffect(() => {
    // 사용자가 다른 목록 조건을 선택할 때 마다 요청
    handleFetchPelotonForPage();
  }, [selectedChip]);

  const handleSearchInputChange = event => {
    const value = event.target.value;
    setFormData({
      values: {
        name: value
      }
    });
  };

  const handleSearchInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSearch = () => {
    setPage(0);
    setData([]);
    handleSearchPelotonForPage();
    setSelectedChip('검색');
    setIsSearched(true);
  };

  /**
   * @return : 페이지네이션 구현부.
   */
  useEffect(() => {
    // 페이지 번호가 변경될 때마다 새로운 페이지 데이터를 불러옵니다.
    handleFetchPelotonForPage();
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const [searchKey, setSearchKey] = useState('');

  // 페이지네이션을 위한 검색 핸들러
  const handleSearchPelotonForPage = async () => {
    try {
      const keyword = formData.values;
      if (keyword) {
        const response = await searchListApi(keyword, page, ITEMS_PER_PAGE);
        setData(response.data.response.content);
        setSearchKey(keyword.name);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 페이지네이션을 위한 API 핸들러
  const handleFetchPelotonForPage = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const keyword = formData.values;
      const response = await searchListApi(
        keyword,
        currentPage - 1,
        ITEMS_PER_PAGE
      );
      if (response.data.success) {
        /**
         * Chip label에 따른 State 분기
         *
         * 전체 : 모든 데이터를 setData
         * 내 펠로톤 : myClubUserResponse가 빈 배열이 아닌 데이터만 setData
         * 가입 대기 : myClubUserResponse.join이 false인 데이터만
         */
        if (selectedChip === '전체') {
          setData(response.data.response.content);
          setPageTotal(response.data.response.totalPages);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PelotonLayout>
      <>
        <Box className={classes.canvasHead}>
          {/* 배너 */}
          <Box className={classes.banner}></Box>
        </Box>

        <Box className={classes.canvasBody}>
          {data ? (
            <>
              {/* 검색창 */}
              <form>
                <Box className={classes.searchWrap}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label={'검색'}
                    name="search"
                    onChange={handleSearchInputChange}
                    onKeyPress={handleSearchInputKeyPress}
                    type="text"
                    value={formData.values.name}
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.input
                      },
                      endAdornment: (
                        <InputAdornment position="end" onClick={handleSearch}>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    size="small"
                  />
                </Box>
              </form>
              {/* 검색 관련 칩 */}
              <Box className={classes.chipWrap}>
                <Stack direction="row" spacing={1}>
                  {/* 전체 */}
                  <Chip
                    label={'전체'}
                    onClick={event => handleCategoryChipClick(event, '전체')}
                    color={selectedChip === '전체' ? 'primary' : 'default'}
                    clickable={true}
                  />
                  {/* 검색 칩 */}
                  {isSearched ? (
                    <Chip
                      label={`'${searchKey}'의 검색결과`}
                      onClick={handleSearch}
                      color={selectedChip === '검색' ? 'primary' : 'default'}
                      clickable={true}
                    />
                  ) : null}
                </Stack>
              </Box>

              <Box alt="Pagenation Components">
                {/* 펠로톤 카드 */}
                <Box>
                  <Grid container spacing={2}>
                    {data.map(card => (
                      <PelotonCard card={card} key={card.id} />
                    ))}
                  </Grid>
                </Box>

                {/* 페이지네이션 컴포넌트 */}
                <Box my={2} display="flex" justifyContent="center">
                  <Pagination
                    count={pageTotal}
                    page={currentPage}
                    onChange={handleChangePage}
                    shape="rounded"
                    hidePrevButton
                    hideNextButton
                  />
                </Box>

                {/* 펠로톤 생성 floating button */}
                <Box
                  style={{
                    position: 'fixed',
                    bottom: '24px',
                    right: '24px',
                    zIndex: '99'
                  }}>
                  <RouterLink
                    to={{
                      pathname: `/pelotons/create`
                    }}>
                    <Fab
                      color="primary"
                      size="large"
                      style={{
                        backgroundColor: '#4DB6AC',
                        width: '130px',
                        borderRadius: '100px'
                      }}>
                      <Typography variant="h6" style={{ color: 'white' }}>
                        펠로톤 생성
                      </Typography>
                      <CreateIcon
                        style={{ color: 'white', marginLeft: '8px' }}
                      />
                    </Fab>
                  </RouterLink>
                </Box>
              </Box>
            </>
          ) : (
            <CustomCircularProgress />
          )}
        </Box>
      </>
    </PelotonLayout>
  );
};

List.propTypes = {
  history: PropTypes.object
};

export default withRouter(List);
