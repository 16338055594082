import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@mui/styles';
import cookie from 'react-cookies';
import ClubList from './components/ClubList.js';
import Invitation from './components/Invitation.js';
import {
  AppBar,
  Box,
  Button,
  Typography,
  FormGroup,
  Grid,
  Tabs,
  Tab,
  FormControlLabel,
  Switch
} from '@mui/material';
import auth from '../../utils/auth';

const Admin = props => {
  const classes = useStyles();

  const { t } = useTranslation(['page']);
  const extraInfo = auth.getExtraInfo();
  const [tabSelect, setTabSelect] = React.useState(0);

  useEffect(() => {}, []);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={`클럽 관리`} />
        <Tab key={1} label={`초대 코드`} />
      </Tabs>
    );
  }

  function TabContents(props) {
    const tabSelect = props.tabSelect;

    if (tabSelect === 0) {
      return <ClubList />;
    } else if (tabSelect === 1) {
      return <Invitation />;
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          관리자 메뉴
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      <Box className={classes.canvas}>
        <AppBar position="static" color="default" className={classes.tab}>
          <TabList />
        </AppBar>
        <TabContents tabSelect={tabSelect} />
      </Box>
    </div>
  );
};

Admin.propTypes = {
  history: PropTypes.object
};

export default withRouter(Admin);

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvas: {
    backgroundColor: 'white',
    padding: '16px 16px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0',
    marginBottom: '8px'
  }
}));
