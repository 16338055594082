import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';

import axios from 'axios';
import auth from '../../utils/auth';
import request from '../../utils/request';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  progress: {
    textAlign: 'center',
    margin: theme.spacing(2)
  }
}));

const Strava = props => {
  const [redirect, setRedirect] = React.useState(false);

  if (props.location.search) {
    const query = new URLSearchParams(props.location.search);
    const code = query.get('code');

    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/strava/register-strava.php`,
          {
            params: {
              jwt: auth.getToken().token,
              exchange_type: 'connect',
              code: code,
              grant_type: 'authorization_code'
            }
          }
        )
        .then(function(response) {
          console.log(response.data);

          if (response.data) {
            setRedirect(true);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {redirect === true ? <Redirect to="/dashboard" /> : ''}
    </div>
  );
};

Strava.propTypes = {
  history: PropTypes.object
};

export default withRouter(Strava);
