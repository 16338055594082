import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  TextField,
  Switch,
  Slider,
  Avatar
} from '@mui/material';

import _, { set } from 'lodash';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    marginBottom: '16px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  subTitle: {
    fontSize: '16px',
    margin: '16px 0 0 16px'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  spaceAround: {
    padding: '0px 0px 16px 0px',
    justifyContent: 'space-around'
  },

  textBold: {
    fontSize: '14px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  ftpTypeSwitchWrap: {
    margin: '12px auto 0',
    fontSize: '13px'
  },
  ftpSetBox: {
    marginBottom: '8px',
    fontSize: '12px',
    textAlign: 'center'
  },
  maxHrTypeSwitchWrap: {
    margin: '12px auto 0',
    fontSize: '13px'
  },
  maxHrSetBox: {
    marginBottom: '8px',
    fontSize: '12px',
    textAlign: 'center'
  },
  boxFlex: {
    display: 'flex',
    maxWidth: '240px',
    margin: '0 auto'
  },
  weekTssTable: {
    minWidth: '140px',
    margin: '2px 0px',
    padding: '0px',
    fontSize: '11px',
    lineHeight: '14px',
    height: '30px',
    backgroundColor: theme.palette.background.colored.main,
    borderRadius: '4px'
  },
  tssEmoji: {
    height: 28,
    width: 28,
    backgroundColor: '#f5f5f5',
    margin: '-2px 6px 0 6px',
    fontSize: '20px'
  }
}));

const FtpTypeSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const MaxHrTypeSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const PdSlider = withStyles({
  root: {
    color: '#8cc1ec'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  mark: {
    backgroundColor: '#ffffff',
    height: 8,
    width: 1,
    marginTop: 0
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  markLabel: {
    top: 24
  }
})(Slider);

const TssSlider = withStyles({
  root: {
    color: '#8cc1ec',
    height: '180px',
    minWidth: '36px',
    padding: '0px',
    marginTop: '24px',
    marginLeft: '-8px'
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  track: {
    height: 2
  },
  rail: {
    marginLeft: '-2px',
    width: 2,
    opacity: 0.6,
    backgroundColor: '#8cc1ec'
  },
  mark: {
    opacity: 0.6,
    backgroundColor: '#8cc1ec',
    height: 1,
    width: 5,
    marginTop: 0
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  },
  markLabel: {
    fontSize: '12px',
    marginLeft: '-4px'
  },
  markLabelActive: {
    fontWeight: 500,
    color: '#f06292'
  }
})(Slider);

const ActivityOption = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);
  const classes = useStyles();

  const {
    extraInfo,
    auth,
    updateOptions,
    ftpType,
    ftp,
    tte,
    wftp,
    wtte,
    customMaxHr,
    maxHr,
    adjustWeekTss,
    handleSnackOpen
  } = props;

  // console.log("extraInfo", extraInfo)
  // console.log("ftpType", ftpType)
  // console.log("ftp", ftp)
  // console.log("tte", tte)
  // console.log("wftp", wftp)
  // console.log("wtte", wtte)
  // console.log("customMaxHr", customMaxHr)
  // console.log("maxHr", maxHr)
  // console.log("adjustWeekTss", adjustWeekTss)
  // console.log("getdashboardInfo", auth.getDashboardInfo())

  // ftp
  const [ftpTypeValue, setFtpTypeValue] = React.useState(ftpType);
  const [customFTP, setCustomFTP] = useState(Math.round(ftp));
  const [customTTE, setCustomTTE] = useState(Math.round(tte));
  const [ftpSetDialog, setFtpSetDialog] = useState(false);
  const stereoCurve = JSON.parse(extraInfo.pdc_json).stereo_all;

  // hr
  const [adjustMaxHr, setAdjustMaxHr] = useState(Math.round(maxHr));
  const [adjustSwitch, setAdjustSwitch] = useState(customMaxHr);
  const [maxHrSetDialog, setMaxHrSetDialog] = useState(false);

  // tss
  const initWeekTss = Math.round(
    (Math.round(extraInfo.min_week_tss) +
      Math.round(extraInfo.adjust_week_tss)) /
      0.55
  );
  const [updateAvail, setUpdateAvail] = useState(
    Boolean(extraInfo.updateAvail)
  );
  const [customAdjustWeekTss, setCustomAdjustWeekTss] = useState(
    Math.round(adjustWeekTss)
  );
  const [weekTssSetDialog, setWeekTssSetDialog] = useState(false);
  const [divWeekTss, setDivWeekTss] = useState(initWeekTss);
  const wkgWeek = Math.ceil(extraInfo.ftp / extraInfo.weight) * 10;

  const [isFtpChange, setIsFtpChange] = useState(false);
  const [isHrChange, setIsHrChange] = useState(false);
  const [isTssChange, setIsTssChange] = useState(false);

  const tteArray = [
    {
      value: 20,
      label: '20' + t('Common.min')
    },
    {
      value: 40,
      label: '40' + t('Common.min')
    },
    {
      value: 60,
      label: '60' + t('Common.min')
    },
    {
      value: 80,
      label: '80' + t('Common.min')
    }
  ];

  const bpmArray = [
    {
      value: 160,
      label: '160'
    },
    {
      value: 180,
      label: '180'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 220,
      label: '220'
    },
    {
      value: 240,
      label: '240'
    }
  ];

  const weekTssTable = [
    {
      emoji: '😮',
      guide: t('TssGuide.tss_guide_0'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.22) +
        ' ' +
        t('Common.under')
    },
    {
      emoji: '😐',
      guide: t('TssGuide.tss_guide_1'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.22 + 1) +
        '~' +
        Math.round(divWeekTss * 0.55)
    },
    {
      emoji: '🙂',
      guide: t('TssGuide.tss_guide_2'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.55 + 1) +
        '~' +
        Math.round(divWeekTss * 0.75)
    },
    {
      emoji: '😀',
      guide: t('TssGuide.tss_guide_3'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.75 + 1) +
        '~' +
        Math.round(divWeekTss * 0.9)
    },
    {
      emoji: '😍',
      guide: t('TssGuide.tss_guide_4'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 0.9 + 1) +
        '~' +
        Math.round(divWeekTss * 1.05)
    },
    {
      emoji: '🤤',
      guide: t('TssGuide.tss_guide_5'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 1.05 + 1) +
        '~' +
        Math.round(divWeekTss * 1.2)
    },
    {
      emoji: '😨',
      guide: t('TssGuide.tss_guide_6'),
      tss:
        t('TssGuide.week') +
        ' ' +
        Math.round(divWeekTss * 1.2) +
        ' ' +
        t('Common.over')
    }
  ];

  const weekTssArray = [
    {
      value: -1 * wkgWeek * 4,
      label: '-' + (wkgWeek * 4).toString()
    },
    {
      value: -1 * wkgWeek * 3,
      label: '-' + (wkgWeek * 3).toString()
    },
    {
      value: -1 * wkgWeek * 2,
      label: '-' + (wkgWeek * 2).toString()
    },
    {
      value: -1 * wkgWeek * 1,
      label: '-' + (wkgWeek * 1).toString()
    },
    {
      value: 0,
      label: '0'
    },
    {
      value: wkgWeek * 1,
      label: '+' + (wkgWeek * 1).toString()
    },
    {
      value: wkgWeek * 2,
      label: '+' + (wkgWeek * 2).toString()
    },
    {
      value: wkgWeek * 3,
      label: '+' + (wkgWeek * 3).toString()
    },
    {
      value: wkgWeek * 4,
      label: '+' + (wkgWeek * 4).toString()
    },
    {
      value: wkgWeek * 5,
      label: '+' + (wkgWeek * 5).toString()
    },
    {
      value: wkgWeek * 6,
      label: '+' + (wkgWeek * 6).toString()
    }
  ];

  function TteFtpCalc(props) {
    let tte_v = props.tte;
    let ftp_v = props.ftp;
    let stereoCurve = props.stereoCurve;

    let preSec = 0;
    let nextSec = 0;
    let secArr = [1200, 1800, 2400, 3600, 7200];

    for (let i = 0; i < 5; i++) {
      if (tte_v * 60 >= secArr[i - 1] && tte_v * 60 <= secArr[i]) {
        preSec = secArr[i - 1];
        nextSec = secArr[i];
        break;
      }
    }

    let rate = (tte_v * 60 - preSec) / (nextSec - preSec);

    ftp_v =
      stereoCurve[preSec] + (stereoCurve[nextSec] - stereoCurve[preSec]) * rate;

    setCustomFTP(ftp_v);
    setCustomTTE(tte_v);

    return (
      <Box className={classes.ftpSetBox}>
        FTP{' '}
        <Typography
          className={classes.textBold}
          color="secondary"
          component="span">
          {Math.round(ftp_v)}
        </Typography>
        W / TTE{' '}
        <Typography
          className={classes.textBold}
          color="secondary"
          component="span">
          {Math.round(tte_v)}
        </Typography>
        분
      </Box>
    );
  }

  const handleFtpTypeValue = event => {
    setIsFtpChange(true);
    if (event.target.checked) {
      setFtpTypeValue('wftp');
    } else {
      setFtpTypeValue('cftp');
    }
  };

  const handleMaxHrTypeValue = event => {
    setIsHrChange(true);
    if (event.target.checked) {
      setAdjustSwitch(0);
    } else {
      setAdjustSwitch(1);
    }
  };

  const adjustWeekTssSet = customAdjustWeekTss => {
    setIsTssChange(true);
    setCustomAdjustWeekTss(Number(customAdjustWeekTss));

    let inTss = Math.round(
      (Number(extraInfo.min_week_tss) + Number(customAdjustWeekTss)) / 0.55
    );

    setDivWeekTss(inTss);
  };

  function hadleUpdateOptions(params) {
    const auth_str = 'Bearer '.concat(auth.getToken().token);
    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        params,
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success === true) {
          updateOptions('on');

          // 토스트 알림.
          handleSnackOpen();
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleChangeTTE = customTTE => {
    // console.log("handleChangeTTE", customTTE)
    setCustomTTE(customTTE);
    setIsFtpChange(true);
  };

  const handleChangeMaxHr = adjustMaxHr => {
    // console.log("handleChangeMaxHr", adjustMaxHr)
    setAdjustMaxHr(adjustMaxHr);
    setIsHrChange(true);
  };

  const updateSettingOptions = type => {
    if (type === 'update_ftp_set') {
      const params = {
        order: 'update_ftp_set',
        custom_ftp: customFTP,
        custom_tte: customTTE,
        ftp_type: ftpTypeValue
      };
      hadleUpdateOptions(params);
    } else if (type === 'update_max_hr_set') {
      const params = {
        order: 'update_max_hr_set',
        adjust_max_hr: adjustMaxHr,
        custom_max_hr: adjustSwitch
      };
      hadleUpdateOptions(params);
    } else if (type === 'update_adjust_week_tss_set') {
      const params = {
        order: 'update_adjust_week_tss_set',
        adjust_week_tss: customAdjustWeekTss
      };
      hadleUpdateOptions(params);
    }
  };

  // 자동 업데이트 막음.
  // useEffect(() => {
  //   const params = {
  //     order : 'update_ftp_set',
  //     custom_ftp : customFTP,
  //     custom_tte : customTTE,
  //     ftp_type : ftpTypeValue,
  //   }
  //   if(isFtpChange) {
  //     // console.log("params", params)
  //     hadleUpdateOptions(params)
  //   }
  // }, [customFTP, ftpTypeValue]);

  // useEffect(() => {
  //   const params = {
  //     order : 'update_max_hr_set',
  //     adjust_max_hr : adjustMaxHr,
  //     custom_max_hr : adjustSwitch,
  //   }
  //   if(isHrChange) {
  //     // console.log("params", params)
  //     hadleUpdateOptions(params)
  //   }
  // }, [adjustMaxHr, adjustSwitch]);

  // useEffect(() => {
  //   const params = {
  //     order : 'update_adjust_week_tss_set',
  //     adjust_week_tss : customAdjustWeekTss,
  //   }
  //   if(isTssChange) {
  //     // console.log("params", params)
  //     hadleUpdateOptions(params)
  //   }
  // }, [customAdjustWeekTss]);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.ftpOptions')}
        </Typography>
        {extraInfo.ftp > 10 ? (
          <CardContent>
            <Typography
              component="div"
              align="center"
              style={{ margin: '12px 8px 16px 8px' }}>
              {t('Options.pleaseAutoFtp')}
            </Typography>

            <Typography component="div" className={classes.ftpTypeSwitchWrap}>
              <Grid
                component="label"
                container
                style={{ width: '132px', margin: '0 auto' }}>
                <Grid item>{t('Options.manual')}</Grid>
                <Grid item>
                  <FtpTypeSwitch
                    checked={ftpTypeValue === 'wftp'}
                    onChange={handleFtpTypeValue}
                    name="ftpType"
                  />
                </Grid>
                <Grid item>{t('Options.auto')}</Grid>
              </Grid>
            </Typography>

            <Box style={{ width: '180px', margin: '16px auto 0 auto' }}>
              {ftpTypeValue === 'cftp' ? (
                <React.Fragment>
                  <TteFtpCalc
                    tte={customTTE}
                    ftp={customFTP}
                    stereoCurve={stereoCurve}
                  />
                  <PdSlider
                    color="primary"
                    defaultValue={Math.round(tte)}
                    min={20}
                    step={1}
                    max={80}
                    marks={tteArray}
                    onChange={(event, customTTE) =>
                      handleChangeTTE(Math.round(customTTE))
                    }
                    valueLabelDisplay="off"
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Box className={classes.ftpSetBox}>
                    FTP{' '}
                    <Typography
                      className={classes.textBold}
                      color="secondary"
                      component="span">
                      {Math.round(wftp)}
                    </Typography>
                    W / TTE{' '}
                    <Typography
                      className={classes.textBold}
                      color="secondary"
                      component="span">
                      {Math.round(wtte)}
                    </Typography>
                    {t('Common.min')}
                  </Box>
                </React.Fragment>
              )}
            </Box>

            <CardActions className={classes.rowReverse}>
              <Button
                className={classes.setButton}
                onClick={() => updateSettingOptions('update_ftp_set')}
                variant="contained"
                color="primary">
                {t('Options.modify')}
              </Button>
            </CardActions>
          </CardContent>
        ) : (
          <CardContent>
            <Typography component="div" align="center">
              {t('Options.noData')}
            </Typography>
          </CardContent>
        )}
      </Card>

      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.maxHrOptions')}
        </Typography>

        {extraInfo.max_hr > 0 ? (
          <CardContent>
            <Typography
              component="div"
              align="center"
              style={{ margin: '12px 8px 16px 8px' }}>
              {t('Options.pleaseAutoFtp')}
            </Typography>
            <Typography component="div" className={classes.maxHrTypeSwitchWrap}>
              <Grid
                component="label"
                container
                style={{ width: '132px', margin: '0 auto' }}>
                <Grid item>{t('Options.manual')}</Grid>
                <Grid item>
                  <MaxHrTypeSwitch
                    checked={adjustSwitch === 0}
                    onChange={handleMaxHrTypeValue}
                    name="maxHrType"
                  />
                </Grid>
                <Grid item>{t('Options.auto')}</Grid>
              </Grid>
            </Typography>
            <Box style={{ width: '180px', margin: '16px auto 0 auto' }}>
              {adjustSwitch === 1 && (
                <React.Fragment>
                  <Box className={classes.maxHrSetBox}>
                    Max HR{' '}
                    <Typography
                      className={classes.textBold}
                      color="secondary"
                      component="span">
                      {Math.round(adjustMaxHr)}
                    </Typography>{' '}
                    bpm
                  </Box>

                  <PdSlider
                    color="primary"
                    defaultValue={Math.round(adjustMaxHr)}
                    min={160}
                    step={1}
                    max={240}
                    marks={bpmArray}
                    onChange={(event, adjustMaxHr) =>
                      handleChangeMaxHr(Math.round(adjustMaxHr))
                    }
                    // onChange={(event, adjustMaxHr) =>	setAdjustMaxHr(Math.round(adjustMaxHr))}
                    valueLabelDisplay="off"
                  />
                </React.Fragment>
              )}
            </Box>
            <CardActions className={classes.rowReverse}>
              <Button
                className={classes.setButton}
                onClick={() => updateSettingOptions('update_max_hr_set')}
                variant="contained"
                color="primary">
                {t('Options.modify')}
              </Button>
            </CardActions>
          </CardContent>
        ) : (
          <CardContent>
            <Typography component="div" align="center">
              {t('Options.noData')}
            </Typography>
          </CardContent>
        )}
      </Card>

      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.adjustTss')}
        </Typography>

        {extraInfo.ftp > 10 ? (
          <CardContent>
            <Typography
              component="div"
              align="center"
              style={{ margin: '12px 8px 16px 8px' }}>
              {t('Options.pleaseTssGuide')}
            </Typography>

            <Box className={classes.boxFlex}>
              <TssSlider
                color="primary"
                orientation="vertical"
                track={false}
                defaultValue={Math.round(adjustWeekTss)}
                min={-1 * wkgWeek * 4}
                max={wkgWeek * 6}
                step={wkgWeek}
                marks={weekTssArray}
                onChange={(event, customAdjustWeekTss) =>
                  adjustWeekTssSet(Number(customAdjustWeekTss))
                }
                valueLabelDisplay="off"
                style={{ height: '200px' }}
              />
              <List>
                {weekTssTable.map((guide, index) => (
                  <ListItem className={classes.weekTssTable} key={index}>
                    <ListItemAvatar style={{ minWidth: '0px' }}>
                      <Avatar className={classes.tssEmoji}>
                        {guide.emoji}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          style={{ fontSize: '11px', lineHeight: '12px' }}
                          component="span">
                          {guide.guide}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          style={{ fontSize: '11px', lineHeight: '12px' }}
                          component="div">
                          {guide.tss}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box style={{ textAlign: 'center', margin: '8px' }}>
              <Typography color="primary" component="span">
                {customAdjustWeekTss > 0 ? '+' : ''}
                {customAdjustWeekTss}
              </Typography>{' '}
              {t('Options.adjusTssGuide')}
            </Box>

            <CardActions className={classes.rowReverse}>
              <Button
                className={classes.setButton}
                onClick={() =>
                  updateSettingOptions('update_adjust_week_tss_set')
                }
                variant="contained"
                color="primary">
                {t('Options.modify')}
              </Button>
            </CardActions>
          </CardContent>
        ) : (
          <CardContent>
            <Typography component="div" align="center">
              {t('Options.noData')}
            </Typography>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

ActivityOption.propTypes = {
  className: PropTypes.string
};

export default ActivityOption;
