import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './Rab.module.scss';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  bikeZoom: {
    [theme.breakpoints.down('md')]: {
      zoom: 0.16,
      MozTransform: 'scale(0.16)'
    },
    [theme.breakpoints.up('md')]: {
      zoom: 0.3,
      MozTransform: 'scale(0.3)'
    },
    [theme.breakpoints.up('lg')]: {
      zoom: 0.4
    },
    [theme.breakpoints.up('xl')]: {
      zoom: 0.4,
      MozTransform: 'scale(0.4)'
    },
    zoom: 0.3,
    MozTransform: 'scale(0.3)',
    animationDuration: '20s'
  }
}));

const RiderAndBike = props => {
  const classes = useStyles();
  const poseClass = [];
  const {
    gender,
    helmetType,
    bikeType,
    tireWidth,
    rimHeight,
    riderPose,
    cadence,
    roadSurface
  } = props;

  const Surface = roadSurface => {
    let rtv = [];

    switch (roadSurface) {
      case 0:
        rtv.push(styles.trackWood);
        break;
      case 1:
        rtv.push(styles.trackAsphalt);
        break;
      case 2:
        rtv.push(styles.asphalt);
        break;
      case 3:
        rtv.push(styles.bikeRoad);
        break;
      case 4:
        rtv.push(styles.roughConcrete);
        break;
      default:
        rtv.push(styles.asphalt);
        break;
    }
    return rtv;
  };

  const getWheel = (rimHeight, tireWidth, leftRight) => {
    let rimPt = 16 / 25;
    let rimUnit = 25;

    let tirePt = 16 / 25;
    let tireUnit = 25;

    switch (rimHeight) {
      case 0:
        rimUnit = 25;
        break;
      case 1:
        rimUnit = 35;
        break;
      case 2:
        rimUnit = 50;
        break;
      case 3:
        rimUnit = 65;
        break;
      case 4:
        rimUnit = 80;
        break;
      case 5:
        rimUnit = 350;
        break;
      default:
        rimUnit = 25;
        break;
    }

    switch (tireWidth) {
      case 0:
        tireUnit = 23;
        break;
      case 1:
        tireUnit = 24;
        break;
      case 2:
        tireUnit = 25;
        break;
      case 3:
        tireUnit = 26;
        break;
      case 4:
        tireUnit = 27;
        break;
      case 5:
        tireUnit = 28;
        break;
      case 11:
        tireUnit = 34;
        break;
      default:
        tireUnit = 25;
        break;
    }

    let rimPx = (rimPt * rimUnit).toFixed(1);
    if (rimUnit === 350 && leftRight === 'right')
      rimPx = (rimPt * 80).toFixed(1);
    let tirePx = (tirePt * tireUnit).toFixed(1);

    const wheel = {
      boxShadow: `0 0 0 ${rimPx}px #3a3a3a inset, 0 0 0 ${tirePx}px #333`
    };

    return wheel;
  };

  const poseChanger = pose => {
    switch (pose) {
      case 0:
        poseClass.push(styles.tops);
        break;
      case 1:
        poseClass.push(styles.hoodsStem4);
        break;
      case 2:
        poseClass.push(styles.hoodsStem2);
        break;
      case 3:
        poseClass.push(styles.hoodsStem0);
        break;
      case 4:
        poseClass.push(styles.drops);
        break;
      case 5:
        poseClass.push(styles.leverEnds);
        break;
      case 6:
        poseClass.push(styles.aeroBars);
        break;
      default:
        poseClass.push(styles.hoodsStem2);
        break;
    }

    return false;
  };
  poseChanger(riderPose);

  const frameClass = bikeType => {
    let rtv = [];

    switch (bikeType) {
      case 'road_allround':
        rtv.push(styles.allround);
        break;
      case 'road_aero':
        rtv.push(styles.aero);
        break;
      case 'road_endurance':
        rtv.push(styles.endurance);
        break;
      case 'road_tt':
        rtv.push(styles.tt);
        break;
      case 'hybrid':
        rtv.push(styles.hybrid);
        break;
      case 'mtb':
        rtv.push(styles.mtb);
        break;
      case 'cruisor':
        rtv.push(styles.cruisor);
        break;
      case 'minivelo':
        rtv.push(styles.minivelo);
        break;
      case 'trainer':
        rtv.push(styles.trainer);
        break;
      default:
        rtv.push(styles.allround);
        break;
    }
    return rtv;
  };

  const genderClass = genderType => {
    let gender = [];

    switch (genderType) {
      case 'M':
        gender.push(styles.male);
        break;
      case 'F':
        gender.push(styles.female);
        break;
      default:
        gender.push(styles.male);
        break;
    }

    return gender;
  };

  const helmetClass = helmetType => {
    let rtv = [];

    switch (helmetType) {
      case 'road':
        rtv.push(styles.road);
        break;
      case 'aero':
        rtv.push(styles.aero);
        break;
      case 'tt':
        rtv.push(styles.tt);
        break;
      default:
        rtv.push(styles.road);
        break;
    }
    return rtv;
  };

  const cadenceSpeed = cadence => {
    let rtv = [];

    switch (cadence) {
      case 30:
        rtv.push(styles.cadence30);
        break;
      case 40:
        rtv.push(styles.cadence40);
        break;
      case 50:
        rtv.push(styles.cadence50);
        break;
      case 60:
        rtv.push(styles.cadence60);
        break;
      case 70:
        rtv.push(styles.cadence70);
        break;
      case 80:
        rtv.push(styles.cadence80);
        break;
      case 90:
        rtv.push(styles.cadence90);
        break;
      case 100:
        rtv.push(styles.cadence100);
        break;
      case 110:
        rtv.push(styles.cadence110);
        break;
      case 120:
        rtv.push(styles.cadence120);
        break;
      case 130:
        rtv.push(styles.cadence130);
        break;
      case 140:
        rtv.push(styles.cadence140);
        break;
      case 150:
        rtv.push(styles.cadence150);
        break;
      case 160:
        rtv.push(styles.cadence160);
        break;
      default:
        rtv.push(styles.cadence90);
        break;
    }
    return rtv;
  };

  return (
    <div className={classes.root + ' ' + classes.bikeZoom}>
      {/* 자전거 */}
      <div
        className={
          styles.bike +
          ' ' +
          poseClass +
          ' ' +
          frameClass(bikeType) +
          ' ' +
          cadenceSpeed(cadence)
        }>
        <div className={styles.part + ' ' + styles.frameSet}>
          <div className={styles.bar + ' ' + styles.leftTop}></div>
          <div className={styles.bar + ' ' + styles.leftBottom}></div>
          <div className={styles.bar + ' ' + styles.left}></div>
          <div className={styles.bar + ' ' + styles.top}></div>
          <div className={styles.bar + ' ' + styles.bottom}></div>
          <div className={styles.bar + ' ' + styles.right}></div>
          <div className={styles.aeroPart}></div>
        </div>

        <div className={styles.part + ' ' + styles.saddle}>
          <div className={styles.sitHere}></div>
          <div className={styles.saddlepen}></div>
        </div>

        <div
          className={styles.part + ' ' + styles.wheel + ' ' + styles.left}
          style={getWheel(rimHeight, tireWidth, 'left')}>
          <div className={styles.sticker}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
        </div>

        <div
          className={styles.part + ' ' + styles.wheel + ' ' + styles.right}
          style={getWheel(rimHeight, tireWidth, 'right')}>
          <div className={styles.sticker}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
          <div className={styles.spoke}></div>
        </div>

        <div className={styles.part + ' ' + styles.axis + ' ' + styles.left}>
          <div className={styles.derailleur}></div>
        </div>
        <div
          className={
            styles.part + ' ' + styles.axis + ' ' + styles.right
          }></div>

        <div className={styles.part + ' ' + styles.pedals}>
          <div className={styles.inside}></div>
          <div className={styles.outside}></div>

          <div className={styles.pedalstem + ' ' + styles.front}>
            <div className={styles.pedalbase + ' ' + styles.front}></div>
          </div>
          <div className={styles.pedalstem + ' ' + styles.back}>
            <div className={styles.pedalbase + ' ' + styles.back}></div>
          </div>
        </div>

        <div
          className={
            styles.part + ' ' + styles.chainsTop + ' ' + frameClass(bikeType)
          }></div>
        <div
          className={
            styles.part + ' ' + styles.chainsBottom + ' ' + frameClass(bikeType)
          }></div>

        <div className={styles.part + ' ' + styles.handlebar}>
          <div className={styles.forkStem}>
            <div className={styles.handleStem}>
              <div className={styles.prehandle}>
                <div className={styles.handle}></div>
              </div>
            </div>
          </div>
        </div>

        {/* 라이더 */}
        <div className={styles.rider}>
          <div className={styles.lowerBody + ' ' + genderClass(gender)}>
            <div className={styles.bottom}>
              <div className={styles.rightThigh}>
                <div className={styles.rightCalf}>
                  <div className={styles.rightFoot}></div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.upperBody + ' ' + genderClass(gender)}>
            <div
              className={styles.helmet + ' ' + helmetClass(helmetType)}></div>
            <div className={styles.shoulder}>
              <div className={styles.arm}></div>
              <div className={styles.aeroBarBottom}></div>
            </div>
          </div>
        </div>

        <div className={styles.backgroundLeg}>
          <div className={styles.lowerBody}>
            <div className={styles.bottom + ' ' + genderClass(gender)}>
              <div className={styles.leftThigh}>
                <div className={styles.leftCalf}>
                  <div className={styles.leftFoot}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Surface(roadSurface)}></div>
    </div>
  );
};

export default RiderAndBike;
