import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@mui/styles';
import { AppBar, Tabs, Tab, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import auth from 'utils/auth';
import BikeUI from './BikeUI';
import Layout from './Layout';
import SimpleDialog from './Bike/Dialog/SimpleDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  }
}));

const AnalyzerTab = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(['page']);

  const extraInfo = auth.getExtraInfo()
    ? auth.getExtraInfo()
    : { pretest: 'none' };

  const gearinfoDefault = {
    gear_name: 'My Bike',
    bike_type: 'road_allround',
    bike_weight: 8,
    crr: 0.005,
    cda: 0.32,
    rim_height: 1,
    drivetrain: 'ultegra',
    tire_product: 'Clincher (Butyl tube)',
    tire_width: 2,
    cadence: 90,
    rider_pose: 2,
    surface_select: 2,
    company_name: '',
    powermeter: ''
  };

  const bikesJson = JSON.parse(extraInfo.bikes_json);

  const [gears, setGears] = useState(bikesJson ? bikesJson : [gearinfoDefault]);
  const [gearTab, setGearTab] = useState(0);
  const [bikeFullOpenDialog, setBikeFullOpenDialog] = useState(false);
  const [bikeNoneOpenDialog, setBikeNoneOpenDialog] = useState(false);

  const handleGearTab = (event, newValue) => {
    setGearTab(newValue);
  };

  const handleDeleteGear = () => {
    if (gears.length > 1) {
      setGears(prev => [
        ...prev.slice(0, gearTab),
        ...prev.slice(gearTab + 1, gears.length)
      ]);
      if (gearTab !== 0) {
        setGearTab(gearTab - 1);
      }
    } else setBikeNoneOpenDialog(true);
  };

  function BikeUIByGear() {
    const gearinfo = gears[gearTab];

    if (
      gearinfo?.bike_kit_json === undefined ||
      gearinfo.bike_kit_json === ''
    ) {
      gearinfo.bike_kit_json = JSON.stringify({
        bike_type: 'road_allround',
        bike_weight: 8,
        crr: 0.005,
        cda: 0.32,
        rim_height: 1,
        drivetrain: 'ultegra',
        tire_product: 'Clincher (Butyl tube)',
        tire_width: 2,
        cadence: 90,
        rider_pose: 2,
        surface_select: 2,
        company_name: '',
        powermeter: ''
      });
    }

    return <BikeUI gearinfo={gearinfo} handleDeleteGear={handleDeleteGear} />;
  }

  const ButtonInTabs = ({ className, onClick, children }) => {
    return (
      <Button className={className} onClick={onClick} children={children} />
    );
  };

  return (
    <Layout>
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.tab}>
          <Tabs
            value={gearTab}
            onChange={handleGearTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            {gears.map((gear, index) => (
              <Tab key={index} label={gear.gear_name} />
            ))}
            <ButtonInTabs
              onClick={() => {
                if (gears.length < 5) {
                  setGears(prev => [...prev, gearinfoDefault]);
                } else {
                  setBikeFullOpenDialog(true);
                }
              }}
              className={classes.addButton}>
              <AddIcon style={{ color: theme.palette.primary.main }} />
            </ButtonInTabs>
          </Tabs>
        </AppBar>
        <Box className={classes.tabPanel}>
          <BikeUIByGear />
        </Box>
      </div>
      {bikeFullOpenDialog ? (
        <SimpleDialog
          openState={bikeFullOpenDialog}
          setOpen={setBikeFullOpenDialog}
          contentText={t('BikeUI.maximumBikes')}
        />
      ) : null}
      {bikeNoneOpenDialog ? (
        <SimpleDialog
          openState={bikeNoneOpenDialog}
          setOpen={setBikeNoneOpenDialog}
          contentText={t('BikeUI.leastOneBike')}
        />
      ) : null}
    </Layout>
  );
};

export default AnalyzerTab;
