import { isEmpty } from 'lodash';
import axios from 'axios';

const TOKEN_KEY = 'jwtToken';
const USER_INFO = 'userInfo';
const EXTRA_INFO = 'extraInfo';
const DASHBOARD_INFO = 'dashboardInfo';

//const REFRESH_TOKEN = 'refreshToken';

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
  /**
   * Remove an item from the used storage
   * @param  {String} key [description]
   */
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  exists(key) {
    if (localStorage && localStorage.getItem(key)) {
      return true;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return true;
    }

    return false;
  },

  // reload(key) {
  //   if(key === EXTRA_INFO) {
  // 	  const auth_str = 'Bearer '.concat((auth.getToken().token)); 

  //     axios.get(
  //       `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-user-info.php`, 
  //       {
  //         headers: { Authorization: auth_str },
  //         params: { order: 'get_extra_info' }
  //       }
  //     ).then(function (response) {
  //       let json = response.data;
  //       if(json.success) {
  //         auth.setExtraInfo(json.extra_info, true);		
  //       } else {
  //         auth.clearExtraInfo();
  //         auth.clearDashboardInfo();
  //       }
  //     }).catch(function (error) {
  //       auth.clearUserInfo();
  //       auth.clearExtraInfo();
  //       auth.clearDashboardInfo();
  //       auth.clearToken();				
  //     });
  //   }
  // },

  /**
   * Clear all app storage
   */
  clearAppStorage() {
    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearToken(tokenKey = TOKEN_KEY) {
    return auth.clear(tokenKey);
  },

  clearUserInfo(userInfo = USER_INFO) {
    return auth.clear(userInfo);
  },

  clearExtraInfo(extraInfo = EXTRA_INFO) {
    return auth.clear(extraInfo);
  },

  clearDashboardInfo(dashboardInfo = DASHBOARD_INFO) {
    return auth.clear(dashboardInfo);
  },

  /**
   * Returns data from storage
   * @param  {String} key Item to get from the storage
   * @return {String|Object}     Data from the storage
   */
  get(key) {
    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },

  getToken(tokenKey = TOKEN_KEY) {
    return auth.get(tokenKey);
  },

  getUserInfo(userInfo = USER_INFO) {
    return auth.get(userInfo);
  },

  getExtraInfo(extraInfo = EXTRA_INFO) {
    return auth.get(extraInfo);
  },

  getDashboardInfo(dashboardInfo = DASHBOARD_INFO) {
    return auth.get(dashboardInfo);
  },

  /**
   * Set data in storage
   * @param {String|Object}  value    The data to store
   * @param {String}  key
   * @param {Boolean} isLocalStorage  Defines if we need to store in localStorage or sessionStorage
   */
  set(value, key, isLocalStorage) {
    if (isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
    return auth.set(value, tokenKey, isLocalStorage);
  },

  setUserInfo(value = '', isLocalStorage = false, userInfo = USER_INFO) {
    return auth.set(value, userInfo, isLocalStorage);
  },

  setExtraInfo(value = '', isLocalStorage = false, extraInfo = EXTRA_INFO) {
    return auth.set(value, extraInfo, isLocalStorage);
  },

  setDashboardInfo(value = '', isLocalStorage = false, dashboardInfo = DASHBOARD_INFO) {
    return auth.set(value, dashboardInfo, isLocalStorage);
  },

};


export default auth;