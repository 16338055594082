import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  Modal
} from '@mui/material';

// icon
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import _ from 'lodash';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  selectBox: {
    width: 'calc(100% - 8px)',
    marginBottom: '8px'
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    marginBottom: '16px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  subTitle: {
    fontSize: '16px',
    margin: '16px 0 0 16px'
  },

  textLeft: {
    textAlign: 'right !important',
    paddingRight: '16px',
    lineHeight: '40px'
  },
  textRight: {
    paddingLeft: '16px !important',
    textAlign: 'left',
    lineHeight: '40px'
  },
  textLink: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  nameBox: {
    display: 'flex',
    gap: 5,
    alignItems: 'center'
  },
  name: {
    fontSize: '16px',
    lineHeight: '36px',
    textAlign: 'center'
  },
  editIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '8px'
  },
  saveButton: {
    height: 36,
    marginLeft: '8px'
  },
  nameInput: {
    maxWidth: 200,
    '& > div > input': {
      padding: '10.5px 14px'
    },
    '& > label': {
      transform: 'translate(14px, 13px) scale(1)'
    },
    '& > p': {
      position: 'absolute',
      bottom: -15,
      color: '#f44336'
    }
  },

  signoutBox: {
    marginTop: 24,
    paddingTop: 12,
    borderTop: '1px #dddddd solid'
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    width: 280,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
    textAlign: 'center',
    color: '#444444',
    whiteSpace: 'pre-wrap'
  },
  modalBody1: {
    fontSize: '11px',
    textAlign: 'left',
    margin: '5px 0'
  },
  modalBody2: {
    fontSize: '11px',
    fontWeight: 800,
    lineHeight: '22px',
    textAlign: 'left',
    wordBreak: 'keep-all',
    margin: '5px 0'
  },
  modalButton: {
    width: '100%',
    boxShadow: 'none',
    fontSize: '20px',
    fontWeight: 800,
    borderRadius: '12px'
  }
}));

const AccountOption = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { auth, extraInfo, handleSnackOpen } = props;
  const classes = useStyles();
  const userInfo = auth.getUserInfo();

  const [isEditEmail, setIsEditEmail] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const schema = {
    email: {
      email: {
        message: '^' + t('SignUp.errorEmailFormat')
      },
      presence: {
        allowEmpty: false,
        message: '^' + t('SignUp.nickname') + ' is required'
      },
      length: {
        maximum: 64
      }
    }
  };

  const handleEditEmail = isEdit => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ['email']: userInfo.user_email
      },
      touched: {
        ...formState.touched,
        ['email']: true
      }
    }));
    setIsEditEmail(isEdit);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  function hadleUpdateUserEmail() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    const params = {
      order: 'user_update',
      new_user_email: formState.values.email
    };

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-user.php`,
        params,
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        console.log(response.data);
        if (response.data.success) {
          handleSnackOpen();
          // 새로고침.
          // auth.clearExtraInfo();
          // window.location.reload();
        } else {
          if (response.data.message === 'user_email_duplicate') {
            handleSnackOpen(t('SignUp.nickname') + ' is duplicate');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleClickWithdraw = e => {
    setWithdrawModalOpen(true);
  };

  const handleWithdraw = e => {
    if (
      formState.values.confirmPassword === '' ||
      formState.values.confirmPassword === null ||
      formState.values.confirmPassword === undefined
    ) {
      alert(t('SignUp.errorPasswordNull'));
    } else {
      if (auth.getToken() !== null) {
        const auth_str = 'Bearer '.concat(auth.getToken().token);

        axios
          .post(
            `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/leave-riduck.php`,
            {
              password: formState.values.confirmPassword
            },
            {
              headers: { Authorization: auth_str }
            }
          )
          .then(response => {
            // console.log(response);
            if (response.data.success) {
              alert(t('Options.withdrawSuccess'));
              // e.preventDefault();
              auth.clearToken();
              window.location.reload();
            } else {
              if (response.data.message === 'password_incorrect') {
                alert(t('Options.passwordIncorrect'));
              } else {
                alert(t('Options.withdrawFail'));
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };

  const handleClickChangePassword = e => {
    setChangePasswordModalOpen(true);
  };

  const handleClickSignOut = event => {
    event.preventDefault();

    auth.clearToken();
    window.location.reload();
  };

  const handleChangePassword = e => {
    // if(formState.values.confirmPassword === '' || formState.values.confirmPassword === null || formState.values.confirmPassword === undefined) {
    //   alert(t('SignUp.errorPasswordNull'));
    // } else {
    //   if(auth.getToken() !== null) {
    //     const auth_str = 'Bearer '.concat((auth.getToken().token));
    //     axios.post(
    //       `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/leave-riduck.php`,
    //       {
    //         password : formState.values.confirmPassword
    //       },
    //       {
    //         headers: { Authorization: auth_str },
    //       }
    //     ).then(response => {
    //       // console.log(response);
    //       if(response.data.success) {
    //         alert(t('Options.withdrawSuccess'));
    //         // e.preventDefault();
    //         auth.clearToken();
    //         window.location.reload();
    //       } else {
    //         if(response.data.message === 'password_incorrect') {
    //           alert(t('Options.passwordIncorrect'));
    //         } else {
    //           alert(t('Options.withdrawFail'));
    //         }
    //       }
    //     }).catch((err) => {
    //       console.log(err);
    //     });
    //   }
    // }
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.account')}
        </Typography>

        <CardContent>
          <Grid container spacing={1} className={classes.cardContentBox}>
            <Grid item md={5} xs={3} className={classes.textLeft}>
              가입일
            </Grid>
            <Grid item md={7} xs={9} className={classes.textRight}>
              {userInfo.user_registered}
            </Grid>
            <Grid item md={5} xs={3} className={classes.textLeft}>
              ID
            </Grid>
            <Grid item md={7} xs={9} className={classes.textRight}>
              {userInfo.user_login}
            </Grid>
            <Grid item md={5} xs={3} className={classes.textLeft}>
              E-Mail
            </Grid>
            <Grid item md={7} xs={9} className={classes.textRight}>
              {isEditEmail ? (
                <Box className={classes.nameBox}>
                  <TextField
                    className={classes.nameInput}
                    fullWidth
                    label={t('SignUp.email')}
                    name={'email'}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.email}
                    variant="outlined"
                    error={hasError('')}
                    helperText={
                      hasError('email') ? formState.errors.email[0] : null
                    }
                  />
                  {!hasError('email') && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      className={classes.saveButton}
                      onClick={e => hadleUpdateUserEmail()}>
                      {t('Options.saveButton')}
                    </Button>
                  )}
                  <CloseIcon
                    className={classes.editIcon}
                    onClick={() => handleEditEmail(false)}
                  />
                </Box>
              ) : (
                <Box className={classes.nameBox}>
                  <Typography className={classes.name}>
                    {userInfo.user_email}
                  </Typography>
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => handleEditEmail(true)}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.signoutBox}>
            {/* <Grid item md={5} xs={5} className={classes.textLeft}></Grid>
            <Grid item md={7} xs={7} className={clsx(classes.textRight, classes.textLink)} onClick={() => handleClickChangePassword()}>{t("Options.changePassword")}</Grid> */}
            <Grid item md={6} xs={6} className={classes.textRight}>
              <Typography
                component="span"
                style={{ color: '#dddddd', lineHeight: '36px' }}
                onClick={() => handleClickWithdraw()}>
                {t('Options.withdraw')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={6} className={classes.textLeft}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickSignOut}>
                {t('Options.signOut')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div>
        <Modal
          open={withdrawModalOpen}
          onClose={() => setWithdrawModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}>
          <div className={classes.modalPaper}>
            <Box style={{ margin: '10px 0 15px' }}>
              <Typography
                variant="h4"
                component="h2"
                style={{ fontWeight: 600, marginBottom: 15 }}>
                {t('Common.information')}
              </Typography>
              <Typography className={classes.modalBody1}>
                {t('Options.withdrawDesc1')}
              </Typography>
              <Typography
                className={classes.modalBody1}
                style={{ fontWeight: 600, margin: '10px auto' }}>
                {t('Options.withdrawDesc2')}
              </Typography>
              <Box>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label={t('SignUp.confirmPassword')}
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  onClick={handleWithdraw}
                  className={classes.modalButton}>
                  {t('Options.withdraw')}
                </Button>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleWithdraw}
                className={classes.modalButton}>
                {t('Options.withdraw')}
              </Button>
            </Box>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          open={changePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={classes.modal}>
          <div className={classes.modalPaper}>
            <Box style={{ margin: '10px 0 15px' }}>
              <Typography
                variant="h4"
                component="h2"
                style={{ fontWeight: 600, marginBottom: 15 }}>
                {t('Common.information')}
              </Typography>
              <Box>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label={t('SignUp.confirmPassword')}
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label={t('SignUp.confirmPassword')}
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  onClick={handleChangePassword}
                  className={classes.modalButton}>
                  {t('Options.withdraw')}
                </Button>
              </Box>
              <Box>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label={t('SignUp.confirmPassword')}
                  name="confirmPassword"
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleChangePassword}
                className={classes.modalButton}>
                {t('Options.withdraw')}
              </Button>
            </Box>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

AccountOption.propTypes = {
  className: PropTypes.string
};

export default AccountOption;
