import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Avatar, Button } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import auth from '../../../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    border: '5px #eeeeee solid',
    margin: '0 auto 10px auto'
  },
  name: {
    fontSize: '14px',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {extraInfo !== null ? (
        <div>
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            to="/settings"
            src={extraInfo.profile_image}
          />
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/settings"
            className={classes.button}
            startIcon={<AccountCircleIcon />}>
            {userInfo.display_name}
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
