import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import CreateIcon from '@mui/icons-material/Create';
import PermMediaIcon from '@mui/icons-material/PermMedia';

import CreatePost from 'views/Peloton/Club/Menu/PostList/Create/CreatePost';
import PopUpDialog from 'views/Peloton/Common/PopUpDialogLayout';

const useStyles = makeStyles(theme => ({
  container: { marginBottom: '16px' },
  content: {
    paddingBottom: '16px !important'
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    width: '40px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      width: '36px',
      height: '36px'
    }
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  button: {
    flexGrow: 1,
    padding: '0 8px'
  },
  writeBox: {
    width: '100%',
    padding: '8px 12px',
    marginLeft: '12px',
    borderRadius: '16px',
    boxShadow: theme.boxShadow.primary,
    cursor: 'pointer',
    backgroundColor: theme.palette.cancel.light,
    '&:hover': {
      backgroundColor: theme.palette.cancel.main
    }
  },
  write: {
    color: theme.palette.cancel.dark,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  icon: {
    paddingRight: '8px'
  },
  text: {
    color: theme.palette.primary.main
  },
  divider: {
    margin: '16px 0 8px'
  }
}));

function PostWrite({ handleGetPostData, profileImage }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Box className={classes.avatarContainer}>
            <Avatar className={classes.avatar} src={profileImage} />
            <Box className={classes.writeBox} onClick={() => handleOpen()}>
              <Typography variant="subtitle1" className={classes.write}>
                지금 무슨 일이 일어나고 있나요?
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={() => handleOpen()}
              color="primary">
              <CreateIcon className={classes.icon} fontSize="large" />
              <Typography className={classes.text}>글쓰기</Typography>
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleOpen()}
              color="primary">
              <PermMediaIcon className={classes.icon} fontSize="large" />
              <Typography className={classes.text}>미디어</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
      <PopUpDialog openPop={open} handleClose={handleClose} title="글쓰기">
        <CreatePost
          handleClose={handleClose}
          handleGetPostData={handleGetPostData}
        />
      </PopUpDialog>
    </>
  );
}

export default PostWrite;
