import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//import auth from '../../utils/auth';
//import request from '../../utils/request';
import { makeStyles } from '@mui/styles';

import PMCTab from './PMCTab.js';

import { Box, Typography } from '@mui/material';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    //    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const PMC = props => {
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  const extraInfo = auth.getExtraInfo();
  // const [wkgSelect, setWkgSelect]= useState(false);

  // const handleChangeWkg = (event) => {
  // 	let ch = Boolean(wkgSelect);
  // 	setWkgSelect(!ch);

  // 	if(!ch === true) {
  // 		cookie.save('wkg_select', 1, { path: '/' });
  // 	} else {
  // 		cookie.save('wkg_select', 0, { path: '/' });
  // 	}
  // };

  // const WkgSwitch = withStyles({
  // 	switchBase: {
  // 		color: '#4db6ac',
  // 		'&$checked': {
  // 			color: '#f06292',
  // 		},
  // 		'&$checked + $track': {
  // 			backgroundColor: '#ffabc7',
  // 		},
  // 	},
  // 	checked: {

  // 	},
  // 	track: {
  // 		backgroundColor: '#a0d8d3',
  // 	},
  // })(Switch);

  const [dashboardData, setDashboardData] = useState(
    auth.getDashboardInfo()
      ? auth.getDashboardInfo()
      : {
          rank_percent: 0,
          rank_array: null,
          category: 'R',
          race: 'Race',
          hour_record: 0,
          ftp: 0,
          wPrime: 15000,
          weight: 10,
          forte: '',
          rider_type: null,
          rider_ability: null,
          pdcData: null,
          activityData: null,
          summaryData: null,
          yScale: 1000,
          lastTs: 0
        }
  );

  //const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  useEffect(() => {
    // let wkg_select = cookie.load("wkg_select");
    // if(typeof wkg_select === 'undefined' ) {
    // 	cookie.save('wkg_select', 0, { path: '/' });
    // 	wkg_select = 0;
    // } else {
    // 	if(wkg_select == 1) {
    // 		setWkgSelect(true);
    // 	} else {
    // 		setWkgSelect(false);
    // 	}
    // }

    if (!dashboardData.activityData) {
      if (!auth.getDashboardInfo()) {
        props.history.push('/dashboard');
      }
    }
  }, []);

  /*
    //  const [gearTab, setGearTab ] = React.useState(0);      
 
    useEffect(() => {	
      const getPmcJson = () => {
        const auth_str = 'Bearer '.concat((auth.getToken().token)); 
  
        setCircle(true);
        setPmcDataJson([]);
        
        axios.get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-pmc.php`, 
          { 
            headers: { Authorization: auth_str }, 
            params: { 
              order:'get_pmc',
              days: 84
            }
          }
        ).then(async function (response) {
          setCircle(false);
  
          const data = response.data;
          if(data) {
            setPmcDataJson(data);
          }
        });
      }	
  
      getPmcJson();
    
    }, []);  
  
*/

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.pmc')}
          {/* <FormGroup style={{float:'right', height:'20px', display:'inline', margin:'-3px 0 20px 10px'}}>
						<Grid component="label" container alignItems="center" spacing={0}>
							<Grid item style={{fontSize:'12px', color:'#aaaaaa'}}>Watts</Grid>
							<Grid item>	
								<WkgSwitch checked={wkgSelect} onChange={handleChangeWkg} name="wkgSelect" />
							</Grid>
							<Grid item style={{fontSize:'12px', color:'#aaaaaa'}}>W/kg</Grid>
						</Grid>
          </FormGroup> */}
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      {dashboardData.summaryData != null ? (
        <PMCTab summaryData={dashboardData.summaryData} />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

PMC.propTypes = {
  history: PropTypes.object
};

export default withRouter(PMC);
