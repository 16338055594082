import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Button,
  Hidden,
  Link,
  IconButton
} from '@mui/material';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import { useLocation } from 'react-router-dom';
import { ModeContext } from 'context/modeProvider';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import axios from 'axios';
import auth from '../../utils/auth';

const useStyles = makeStyles(theme => ({
  footerWrap: {
    opacity: 0.6,
    padding: theme.spacing(2),
    paddingTop: 0,
    lineHeight: '32px',
    textAlign: 'center'
  },
  inviteCodeOpen: {
    position: 'fixed',
    zIndex: 999,
    right: 24,
    bottom: 24
  },
  languageButton: {
    background: theme.palette.background.colored.dark,
    minWidth: '16px',
    margin: '0 8px 4px',
    padding: '2px 8px 2px 8px',
    fontSize: '12px',
    border: '1px #aaaaaa solid'
  },
  languageList: {
    background: theme.palette.background.colored.main
  },
  languageDialogTitle: {
    color: theme.palette.text.forth,
    minWidth: '160px',
    padding: 'px 20px',
    margin: '0px 12px',
    textAlign: 'center'
  }
}));

function LanguageDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
    i18n.changeLanguage(value);
    //업데이트도 해줘야 함

    if (auth.getToken() !== null) {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      axios
        .post(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
          {
            order: 'language_update',
            language: value
          },
          {
            headers: { Authorization: auth_str }
          }
        )
        .then(response => {
          if (response.data.success) {
            return (
              <React.Fragment>
                <Redirect from="/" to="/dashboard" />
              </React.Fragment>
            );
            //        auth.setExtraInfo(exInfo, true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle className={classes.languageDialogTitle}>
        {t('Common.languageSelect')}
      </DialogTitle>
      <List className={classes.languageList}>
        <ListItem
          button
          onClick={() => handleListItemClick('en')}
          key={'gb'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="ENGLISH" />
        </ListItem>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('ko')}
          key={'kor'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="한국어" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick('zhCn')}
          key={'zhCn'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="简体中文" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick('zhHk')}
          key={'zhHk'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="繁体中文" />
        </ListItem>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('jp')}
          key={'jp'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="日本語" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick('es')}
          key={'es'}
          style={{ textAlign: 'center' }}>
          <ListItemText primary="Español" />
        </ListItem>
      </List>
    </Dialog>
  );
}

LanguageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

const Footer = props => {
  const { className, ...rest } = props;

  const location = useLocation();
  const classes = useStyles();
  const [flagCode, setFlagCode] = React.useState('gb');
  const language = i18n.language;
  const [langStr, setLangStr] = useState('English');
  const { mode, handleMode } = useContext(ModeContext);

  useEffect(() => {
    if (language === 'en') {
      setFlagCode('gb');
      setLangStr('English');
    } else if (language === 'ko') {
      setFlagCode('kor');
      setLangStr('한국어');
    } else if (language === 'zhCn') {
      setFlagCode('zhCn');
      setLangStr('简体中文');
    } else if (language === 'zhHk') {
      setFlagCode('zhHk');
      setLangStr('繁体中文');
    } else if (language === 'es') {
      setFlagCode('es');
      setLangStr('Español');
    } else if (language === 'jp') {
      setFlagCode('jp');
      setLangStr('日本語');
    } else {
      setFlagCode('gb');
      setLangStr('English');
    }
  }, [language]);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('en');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);

    if (value === 'en') {
      setFlagCode('gb');
      setLangStr('English');
    } else if (value === 'ko') {
      setFlagCode('kor');
      setLangStr('한국어');
    } else if (language === 'zhCn') {
      setFlagCode('zhCn');
      setLangStr('简体中文');
    } else if (language === 'zhHk') {
      setFlagCode('zhHk');
      setLangStr('繁体中文');
    } else if (language === 'es') {
      setFlagCode('es');
      setLangStr('Español');
    } else if (language === 'jp') {
      setFlagCode('jp');
      setLangStr('日本語');
    } else {
      setFlagCode('gb');
      setLangStr('English');
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <React.Fragment>
        <div className={classes.footerWrap}>
          <div style={{ margin: '8px 0 0 0', textAlign: 'center' }}>
            <Link href="https://strava.com" target="_blank">
              <img
                alt="Strava"
                src="/images/strava.png"
                style={{ width: '100px' }}
              />
            </Link>
          </div>
          <Box>
            <IconButton onClick={handleMode} size="large">
              {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Box>
          <Typography component="span">
            <Button
              color="inherit"
              onClick={handleClickOpen}
              className={classes.languageButton}>
              {/*<Flag code={flagCode} height="16" style={{width:'32px', height:'16px', margin:'0 4px' }} />*/}
              {langStr.toUpperCase()}
            </Button>
          </Typography>
          &nbsp;
          <Typography component="span">
            &copy;&nbsp;
            <Link
              component="a"
              href="https://www.strava.com/clubs/riduck"
              target="_blank">
              {new Date().getFullYear()} Riduck, Inc. All rights reserved
            </Link>
          </Typography>
        </div>

        {// language === 'ko' ?
        language === 'ko' &&
        !location.pathname.includes('/pelotons') &&
        !location.pathname.includes('/peloton') ? (
          <Button
            className={classes.inviteCodeOpen}
            color="primary"
            size="small"
            type="submit"
            variant="contained"
            onClick={() => {
              window.open('https://riduck.gitbook.io/riduck-guide');
            }}>
            {'라이덕 설명서'}
          </Button>
        ) : (
          ''
        )}

        <LanguageDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </React.Fragment>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
