import React, { useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

import { Box, Dialog, Paper, Button, Typography } from '@mui/material';

const useStyles = makeStyles({
  paper: {
    margin: 10,
    padding: '2px 4px',
    alignItems: 'center',
    minWidth: '300px'
  },
  header: {
    fontSize: '15px',
    textAlign: 'center',
    margin: '0px 0px 12px 0px'
  },
  activities: {
    height: '200px',
    overflow: 'auto',
    borderBottom: '1px #dddddd solid',
    padding: 12
  },
  activity: {
    fontSize: '12px',
    textAlign: 'left'
  },
  box: {
    fontSize: '13px',
    padding: 12,
    textAlign: 'left'
  },
  resultBox: {
    padding: 12,
    textAlign: 'center'
  },
  movePage: {
    marginTop: 12
  },
  divider: {
    height: 28,
    margin: 4
  },
  dialog: { marginTop: '5px' },
  inline: {
    display: 'inline'
  },
  progress: {
    alignItems: 'center'
  }
});

const InviteResultDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, open } = props;
  const [loading, setLoading] = useState(true);

  const jsonData = props.jsonData;

  function handleClose(result) {
    onClose(result);
    //주소 이동해야 함
  }

  function handleResultClick(result) {
    onClose(result);
  }

  function Result(props) {
    const { result } = props;
    const t = props.t;

    setLoading(false);

    function AfterRedirect(props) {
      const page = props.to;
      const t = props.t;
      const [redirect, setRedirect] = useState(false);
      setTimeout(() => setRedirect(true), 10000);

      return redirect ? (
        <Redirect to={page} />
      ) : (
        <Typography>{t('Landing.redirectMain')}</Typography>
      );
    }

    if (result === 'success') {
      return (
        <Box className={classes.resultBox}>
          <Typography>{jsonData.result_text}</Typography>
          <RouterLink
            to={{
              pathname: '/sign-up',
              search: jsonData.invite_code
            }}>
            <Button
              className={classes.movePage}
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              onClick={() => handleResultClick(jsonData.invite_code)}>
              {jsonData.invite_code}
            </Button>
          </RouterLink>
        </Box>
      );
    } else if (result === 'reject') {
      //5초뒤에 처음으로 이동
      return (
        <Box className={classes.resultBox}>
          <Typography>{jsonData.result_text}</Typography>
          <AfterRedirect to="/" t={t} />
        </Box>
      );
    } else if (result === 'error') {
      //5초뒤에 처음으로 이동
      return (
        <Box className={classes.resultBox}>
          <Typography>{jsonData.result_text}</Typography>
          <AfterRedirect to="/" t={t} />
        </Box>
      );
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <Paper className={classes.paper}>
        {jsonData.activities && (
          <Box className={classes.activities}>
            <Typography className={classes.header}>
              {t('Landing.rideLatest')}
            </Typography>
            {jsonData.activities.map(activity => (
              <Typography className={classes.activity} key={activity.index}>
                {activity.is_power + ' ' + activity.activity_name}
              </Typography>
            ))}
          </Box>
        )}
        {jsonData.analysis && (
          <Box className={classes.box}>
            <Typography className={classes.header}>
              {jsonData.analysis.strava_name}
              {t('Landing.analysisHeader')}
              {/*jsonData.analysis.strava_id*/}
            </Typography>
            <div>
              {'🚴 ' +
                jsonData.analysis.total_activities +
                t('Landing.totalActivities')}
            </div>
            <div>
              {'🛣️ ' +
                jsonData.analysis.total_distance +
                t('Landing.totalDistance')}
            </div>
            <div>
              {'⚡ ' +
                jsonData.analysis.power_device_count +
                t('Landing.totalPowerData')}
            </div>
          </Box>
        )}
        {jsonData.result && <Result result={jsonData.result} t={t} />}
      </Paper>
      {loading && <LinearProgress />}
    </Dialog>
  );
};

InviteResultDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default InviteResultDialog;
