//import React from 'react';
//import uuid from 'uuid/v1';
//#ffd357 노
//f491b2 빨 
//8cc1ec 파랑
//4db6ac #71c5bd 녹


export default {
  zoneTrend: {
    chart: {
      type: 'bar',
      offsetY: 0,
      offsetX: 0,
      stacked: true,
      animations: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          console.log(config.dataPointIndex);
        }
      }
    },  
    legend: {
      position:'bottom',
      fontSize: '11px',
      itemMargin: {
        horizontal: 2,
        vertical: 0
      },
    },
    colors: ['#dddddd', '#76b5e8', '#b0d36d', '#ffd357', '#ffa75c', '#ff7e75', '#f06292'],
    fill : {
      opacity: 0.8
    }, 
    stroke: {
      width: [0, 0, 0, 0, 0, 0, 0],
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },  
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "90%",
      }
    },     
    dataLabels: {
      enabled: true,
    },    
    xaxis: {
      categories: [],
      labels: {
        show: false,
        formatter: function(value) {
          let week = value;

          if(week === 0) return "this week";
          else return week + "weeks ago";
        }
      },
      axisTicks: {
        show: false,
      },
      tooltip :{
        enabled: false
      }       
    },
    yaxis: {
      seriesName: 'Zone',
      show: false,
      min: 0,
      max: 100,
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (value) => { return Math.round(value) + ' %' },
      },
      tooltip :{
        enabled: false
      }       
    },
    tooltip: {
      custom: function(){
        return false;
      },
      fixed: {
        enabled: false,
        position: 'topLeft',
        offsetX: 30,
        offsetY: 0,
      },      
    }
  },

  zoneDetail: {
    chart: {
      type: 'bar',
      offsetY: 0,
      offsetX: 0,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },  
    legend: {
      show: false,
      position:'bottom',
      fontSize: '11px',
      itemMargin: {
        horizontal: 3,
        vertical: 0
      },
    },
    colors: ['#f06292','#ff7e75','#ffa75c','#ffd357','#b0d36d','#76b5e8','#dddddd'],
    plotOptions: {
      bar: {
        barHeight: '96%',
        distributed: true,
        horizontal: true,
        endingShape:'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    fill : {
      colors: ['#f06292', '#ff7e75', '#ffa75c', '#ffd357', '#b0d36d', '#76b5e8', '#dddddd'],
      type: 'solid',
      opacity: 0.8
    }, 
    dataLabels: {
      enabled: true,
      offsetX: 20,
      style: {
        fontSize: '11px',
        fontColor: '#ffffff',
        colors: ['#f06292', '#ff7e75', '#ffa75c', '#ffd357', '#b0d36d', '#76b5e8', '#dddddd'],
      },
      textAnchor: 'start',
      formatter: function (val, opt) {
        return val + "%";
      }, 
      background: {
        enabled: true,
        foreColor: '#ffffff',
        padding: 4,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: '#ffffff',
        opacity: 0.8,
      },     
      dropShadow: {
        enabled: false
      }
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },  
    xaxis: {
      categories: ['Z1','Z2','Z3','Z4','Z5','Z6','Z7'],
      labels: {
        show: false,
        formatter: function(value) {
          let week = value;

          if(week === 0) return "this week";
          else return week + "weeks ago";
        }
      },
      axisTicks: {
        show: false,
      },
      tooltip :{
        enabled: false
      }       
    },
    yaxis: {
      seriesName: 'Zone',
      show: false,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (value) => { return Math.round(value) + ' %' },
      },
    },
    tooltip: {
      enabled: false,
    }
  },


  zoneGuide: {
    chart: {
      type: 'bar',
      offsetY: 0,
      offsetX: 0,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },  
    legend: {
      show: false,
    },
    colors: ['#dddddd', '#76b5e8', '#b0d36d', '#ffd357', '#ffa75c', '#ff7e75', '#f06292'],
    plotOptions: {
      bar: {
        barHeight: '96%',
        distributed: true,
        horizontal: false,
        endingShape:'rounded',
        dataLabels: {
          position: 'top',
        },
      },
    },
    fill : {
      colors: ['#dddddd', '#76b5e8', '#b0d36d', '#ffd357', '#ffa75c', '#ff7e75', '#f06292'],
      type: 'solid',
      opacity: 0.8
    }, 
    dataLabels: {
      enabled: true,
      offsetY: -16,
      style: {
        fontSize: '11px',
        fontColor: '#ffffff',
        colors: ['#dddddd', '#76b5e8', '#b0d36d', '#ffd357', '#ffa75c', '#ff7e75', '#f06292']
      },
      textAnchor: 'middle',
      formatter: function (val, opt) {
        return val + "%";
      }, 
      background: {
        enabled: true,
        foreColor: '#ffffff',
        padding: 4,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: '#ffffff',
        opacity: 0.8,
      },     
      dropShadow: {
        enabled: false
      }
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },  
    xaxis: {
      categories: ['Z1','Z2','Z3','Z4','Z5','Z6','Z7'],
      labels: {
        show: false,
        formatter: function(value) {
          let week = value;

          if(week === 0) return "this week";
          else return week + "weeks ago";
        }
      },
      axisTicks: {
        show: false,
      },
      tooltip :{
        enabled: false
      }       
    },
    yaxis: {
      seriesName: 'Zone',
      show: false,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (value) => { return Math.round(value) + ' %' },
      },
    },
    tooltip: {
      enabled: false,
    }
  },  
};
