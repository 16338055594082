import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
  useParams,
  useHistory
} from 'react-router-dom/cjs/react-router-dom.min';
//
import validate from 'validate.js';
import PropTypes from 'prop-types';
import { addPostApi, fetchPelotonURI } from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Fab,
  Button,
  TextField,
  Typography,
  Container,
  FormHelperText
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 160,
      minimum: 2
    }
  },
  content: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 1000
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  canvas: {
    backgroundColor: theme.palette.background.paper,
    padding: '16px 16px',
    boxShadow: theme.boxShadow.primary,
    borderRadius: '4px',
    marginTop: '8px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textField: {
    margin: '8px 0'
  },
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '8px 0',
    width: '100%',
    borderRadius: '4px',
    border: '1px dashed var(--divider, rgba(0, 0, 0, 0.12))',
    padding: '24px 16px',
    cursor: 'pointer'
  },
  buttonWrap: {
    display: 'flex',
    width: '100%',
    padding: '16px'
  },
  image: {
    borderRadius: '6px',
    width: '100%',
    overflow: 'hidden'
  },
  imagePreviews: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%'
  },
  xButton: {
    color: 'white',
    position: 'absolute',
    right: '4px',
    top: '4px',
    height: '16px',
    cursor: 'pointer'
  },
  input: {
    fontSize: '16px'
  }
}));

function CreatePost({ handleClose, handleGetPostData }) {
  const history = useHistory();
  const { uriName } = useParams();
  const [clubData, setClubData] = useState({});

  const classes = useStyles();
  const fileInputRef = useRef(null);

  const [formState, setFormState] = useState({
    data: {
      title: null,
      content: null,
      isPublic: true,
      clubPostCategoryId: 1
    },
    errors: {},
    touched: {},
    isValid: false
  });
  const [formImage, setFormImage] = useState();

  useEffect(() => {
    handleFetchPelotonInfo();
  }, []);

  // validation
  useEffect(() => {
    const errors = validate(formState.data, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // console.log('eventLocations:', formState.values.eventLocations[0].latitude, formState.values.eventLocations[0].longitude)
  }, [formState.data]);

  // 클럽 정보 조회
  const handleFetchPelotonInfo = async () => {
    try {
      const response = await fetchPelotonURI(uriName);
      setClubData(response);

      // 가입자가 아닌경우 리다이렉트
      if (response.myClubUserResponse === null) {
        history.push(`/peloton/${response.uriName}`);
      }

      // 가입대기, 차단된 사용자인 경우 리다이렉트
      if (
        response.myClubUserResponse.blocked === true ||
        response.myClubUserResponse.join === false
      ) {
        history.push(`/peloton/${response.uriName}`);
      }

      // 클럽 정보를 가져온 후, formState에 클럽 ID를 설정
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          clubId: response.id
        }
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputFields = ['title', 'content'];
      const currentIndex = inputFields.findIndex(
        field => field === event.target.name
      );
      if (currentIndex < inputFields.length - 1) {
        const nextField = inputFields[currentIndex + 1];
        document.getElementsByName(nextField)[0].focus();
      } else {
        handleCreatePost(event);
      }
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [event.target.name]: event.target.value
      }
    }));
  };

  const [imgURI, setImgURI] = useState([]);

  const imagePreviewMaker = async files => {
    try {
      const newImageURIs = await Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
      setImgURI(prevImageURIs => [...prevImageURIs, ...newImageURIs]);
    } catch (error) {
      console.error('이미지를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  const handleImageUpload = async event => {
    const uploadedFiles = Array.from(event.target.files);
    setFormImage(currentFiles => {
      const updatedFiles =
        currentFiles && Array.isArray(currentFiles)
          ? [...currentFiles, ...uploadedFiles.map(file => ({ files: file }))]
          : uploadedFiles.map(file => ({ files: file }));

      return updatedFiles;
    });
    await imagePreviewMaker(uploadedFiles);
  };

  const handleCreatePost = async event => {
    event.preventDefault();

    try {
      const response = await addPostApi(
        formState?.data,
        formImage,
        clubData.id
      );
      if (response) {
        handleClose();
        handleGetPostData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveImage = index => {
    const filteredFiles = formImage.filter(
      (_, fileIndex) => fileIndex !== index
    );
    const filteredImgURIs = imgURI.filter((_, uriIndex) => uriIndex !== index);

    setFormImage(filteredFiles);
    setImgURI(filteredImgURIs);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.canvas}>
        <form className={classes.createForm}>
          <TextField
            className={classes.textField}
            fullWidth
            helperText={'게시글의 제목을 입력하세요.'}
            label={'제목'}
            name="title"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            type="text"
            value={formState.data.title || ''}
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
          <TextField
            className={classes.textField}
            // error={hasError('content')}

            fullWidth
            helperText={
              // hasError('content') ? formState.errors.content[0] :
              '게시글의 내용을 입력하세요.'
            }
            label={'내용'}
            name="content"
            multiline
            rows={4}
            onChange={handleChange}
            type="text"
            value={formState.data.content || ''}
            variant="outlined"
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
          {imgURI.length === 0 ? (
            <>
              <Container
                className={classes.uploadBox}
                onClick={() => fileInputRef.current.click()}
                style={{ cursor: 'pointer' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none">
                  <path
                    d="M14.5 2H6.5C5.4 2 4.51 2.9 4.51 4L4.5 20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM18.5 20H6.5V4H13.5V9H18.5V20ZM8.5 15.01L9.91 16.42L11.5 14.84V19H13.5V14.84L15.09 16.43L16.5 15.01L12.51 11L8.5 15.01Z"
                    fill="#4DB6AC"
                  />
                </svg>
                <div className={classes.textContainer}>
                  <Typography
                    align="center"
                    color="primary"
                    variant="body1"
                    style={{ textDecoration: 'underline', marginRight: '2px' }}>
                    클릭하여
                  </Typography>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1">
                    이미지 업로드
                  </Typography>
                </div>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body1">
                  PNG, JPG, JPEG (max. 3MB)
                </Typography>
                <input
                  id="upload-input"
                  type="file"
                  accept="image/.jpg, .jpeg, .png"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
              </Container>
              <FormHelperText
                style={{ textAlign: 'start', padding: '0px 16px' }}>
                이미지를 등록하세요. (1280*720px 권장)
              </FormHelperText>
            </>
          ) : Array.isArray(imgURI) ? (
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                  width: '94%'
                }}>
                {imgURI.map((uri, index) => (
                  <Box
                    key={uri}
                    style={{
                      position: 'relative',
                      marginTop: '8px',
                      marginRight: '8px',
                      display: 'flex',
                      flexDirection: 'row',
                      width: '25%',
                      alignItems: 'center',
                      overflow: 'hidden'
                    }}>
                    <img
                      className={classes.image}
                      src={uri}
                      alt={`imagePreview ${index}`}
                    />
                    <HighlightOffIcon
                      onClick={() => handleRemoveImage(index)}
                      className={classes.xButton}
                    />
                  </Box>
                ))}
              </Box>
              <Box className={classes.imagePreviews}>
                <Fab
                  color="primary"
                  size="small"
                  style={{
                    display: 'flex',
                    marginLeft: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    fileInputRef.current && fileInputRef.current.click()
                  }>
                  <AddCircleIcon style={{ color: 'white' }} />
                </Fab>
                <input
                  id="upload-input"
                  type="file"
                  accept="image/.jpg, .jpeg, .png"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
              </Box>
            </>
          ) : null}
          <div className={classes.buttonWrap}>
            <Button
              className={classes.Button}
              color="primary"
              fullWidth
              size="large"
              onClick={handleClose}
              variant="text">
              취소
            </Button>
            <Button
              className={classes.Button}
              color="primary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
              onClick={handleCreatePost}
              variant="contained">
              등록
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
}

CreatePost.propTypes = {
  history: PropTypes.object
};

export default withRouter(CreatePost);
