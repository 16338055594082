import request from './request';
import auth from './auth';
import axios from 'axios';

// 양재 Local
// const pelotonApiURL = 'http://192.168.35.125:8080';

// 원격
// const pelotonApiURL = "http://13.125.30.143:8080"

// 홍제 Local
// const pelotonApiURL = "http://192.168.2.57:8080"

// aws test
const pelotonApiURL = 'https://rst6.riduck.com';

const token = auth.getToken() ? auth.getToken().token : null;
const auth_str = token ? 'Bearer ' + token : null;

/** ----------------------------------------------------------- */

/**
 * @return : 데이터 요청 Api, 혹은 URL을 관리하는 소스 코드입니다.
 */

// 펠로톤 검색, 목록 조회
export const searchListApi = async (keyword, page, size) => {
  try {
    if ((keyword, page, size)) {
      const formData = new FormData();

      // JSON 데이터를 FormData에 추가
      formData.append('data', JSON.stringify(keyword));

      const response = await axios.post(
        `${pelotonApiURL}/api/club/search?page=${page}&size=${size}`,
        formData,
        {
          headers: { Authorization: auth_str }
        }
      );
      return response;
    }
  } catch (err) {
    console.error(err);
  }
};

// 공지
export const fetchNoticeApi = async () => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/clubPost/notice/list?page=0&size=10`,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 가입 신청
export const submitPelotonMemberApi = async pelotonId => {
  try {
    const formData = new FormData();

    const response = await axios.post(
      `${pelotonApiURL}/api/club/${pelotonId}/join`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 가입 신청 승락
export const acceptJoinMemberApi = async (pelotonId, clubUserId) => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ targetClubUserId: String(clubUserId) })
    );

    const response = await axios.post(
      `${pelotonApiURL}/api/club/${pelotonId}/accept`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 가입 신청 거절
export const rejectJoinMemberApi = async (pelotonId, clubUserId) => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ targetClubUserId: String(clubUserId) })
    );

    const response = await axios.delete(
      `${pelotonApiURL}/api/club/${pelotonId}/reject`,
      {
        data: formData,
        headers: {
          Authorization: auth_str,
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 회원 탈퇴
export const quitPelotonMemberApi = async pelotonId => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/club/${pelotonId}/leave`,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const patchMemberRoleApi = async pelotonId => {
  try {
    const formData = new FormData();
    const response = await axios.patch(
      `${pelotonApiURL}/api/club/${pelotonId}/userRole`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 내 정보 조회
export const getMyUserInfoApi = async pelotonId => {
  try {
    if (pelotonId) {
      const response = await axios(
        `${pelotonApiURL}/api/club/${pelotonId}/user/my`,
        {
          method: 'GET',
          headers: { Authorization: auth_str }
        }
      );
      return response;
    }
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 사용자 단건 정보 조회
export const getUserInfoApi = async (pelotonId, clubUserId) => {
  try {
    if (pelotonId && clubUserId) {
      const response = await axios(
        `${pelotonApiURL}/api/club/${pelotonId}/clubUser/${clubUserId}`,
        {
          method: 'GET',
          headers: { Authorization: auth_str }
        }
      );
      return response;
    }
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 사용목록 정보 조회
export const getUsersInfoApi = async pelotonId => {
  try {
    if (pelotonId) {
      const response = await axios(
        `${pelotonApiURL}/api/club/${pelotonId}/users`,
        {
          method: 'POST',
          headers: { Authorization: auth_str }
        }
      );
      return response;
    }
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 개설
export const createClubApi = async (value, files) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(value));

    if (files && files.length) {
      files.forEach((file, index) => {
        formData.append('files', file.files);
      });
    }

    const response = await axios.post(`${pelotonApiURL}/api/club`, formData, {
      headers: { Authorization: auth_str }
    });

    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 수정 사항 반영
export const editClubApi = async (data, files, pelotonId) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  if (files && files.length) {
    files.forEach(file => {
      if (file instanceof File) {
        formData.append('files', file);
      }
    });
  }
  try {
    const response = await axios.put(
      `${pelotonApiURL}/api/club/${pelotonId}`,
      formData,
      { headers: { Authorization: auth_str } }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시글 조회
export const fetchPostsApi = async (pelotonId, page, size) => {
  try {
    const response = await axios(
      `${pelotonApiURL}/api/clubPost/${pelotonId}/list?page=${page}&size=${size}`,
      {
        method: 'POST',
        headers: { Authorization: auth_str }
      }
    );
    // console.log("postData", response)
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시글 생성
export const addPostApi = async (data, files, pelotonId) => {
  console.log(files);
  try {
    const formData = new FormData();

    // JSON 데이터를 FormData에 추가
    formData.append('data', JSON.stringify(data));

    // formData.append("files", files);
    // 파일 객체를 FormData에 추가
    if (files && files.length) {
      files.forEach((file, index) => {
        formData.append('files', file.files);
      });
    }

    const response = await axios.post(
      `${pelotonApiURL}/api/clubPost/${pelotonId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          Accept: 'application/json'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시글 수정
export const updatePostApi = async (data, files, pelotonId, postId) => {
  try {
    const formData = new FormData();

    // JSON 데이터를 FormData에 추가
    formData.append('data', JSON.stringify(data));

    // 파일 객체를 FormData에 추가
    if (files && files.length) {
      files.forEach((file, index) => {
        formData.append('files', file.files);
      });
    }

    const response = await axios.put(
      `${pelotonApiURL}/api/clubPost/${pelotonId}/${postId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시글 삭제
export const deletePostApi = async (pelotonId, postId) => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/clubPost/${pelotonId}/${postId}`,
      {
        headers: {
          Authorization: auth_str
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 코멘트 조회
export const fetchCommentsApi = async (uriName, postId) => {
  try {
    const response = await axios(
      `${pelotonApiURL}/api/clubPostComment/name/${uriName}/${postId}/list`,
      {
        method: 'POST',
        headers: { Authorization: auth_str }
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

// 코멘트 삭제
export const deleteCommentsApi = async (clubId, postId, commentId) => {
  try {
    const response = await axios(
      `${pelotonApiURL}/api/clubPostComment/${clubId}/${postId}/${commentId}`,
      {
        method: 'DELETE',
        headers: { Authorization: auth_str, 'Access-Control-Allow-Origin': '*' }
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

// 클럽 게시물 부모 댓글 생성
export const AddClubPostParentCommentApi = async (clubId, postId, comment) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.post(
      `${pelotonApiURL}/api/clubPostComment/${clubId}/${postId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 클럽 게시물 대댓글 생성
export const AddClubPostChildrenCommentApi = async (
  clubId,
  postId,
  formState
) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(formState));

    const response = await axios.post(
      `${pelotonApiURL}/api/clubPostComment/${clubId}/${postId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 코멘트 수정
export const EditCommentApi = async (clubId, postId, comment, commentId) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.put(
      `${pelotonApiURL}/api/clubPostComment/${clubId}/${postId}/${commentId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 상세 조회
export const fetchPelotonURI = async uriName => {
  try {
    const response = await axios(`${pelotonApiURL}/api/club/name/${uriName}`, {
      method: 'GET',
      headers: { Authorization: auth_str }
    });
    // console.log("uri", response.data.response)
    return response.data.response;
  } catch (err) {
    console.error(err);
  }
};

// 클럽 멤버 간 랭킹 조회
export const fetchUserRankApi = async pelotonId => {
  try {
    const response = await axios(
      `${pelotonApiURL}/api/club/${pelotonId}/ranking`,
      {
        method: 'POST',
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 단일 게시글 조회
export const fetchSinglePostApi = async (uriName, postId) => {
  try {
    const response = await axios(
      `${pelotonApiURL}/api/clubPost/name/${uriName}/${postId}`,
      {
        method: 'GET',
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 활동 스트림 조회
export const fetchPelotonStreamApi = async (pelotonId, page) => {
  try {
    const pageStartedZero = page;
    const response = await axios.post(
      `${pelotonApiURL}/api/club/${pelotonId}/activities?page=${pageStartedZero}`,
      {},
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 목록 조회
export const fetchEventApi = async (clubId, page, size) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ clubId }));

    const response = await axios.post(
      `${pelotonApiURL}/api/event/search/?page=${page}&size=${size}`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 이벤트 생성
export const addPelotonEventApi = async (data, files, mapFiles) => {
  console.log(data, files, mapFiles);
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    // 파일 객체를 FormData에 추가
    if (files && files.length) {
      files.forEach((file, index) => {
        formData.append('files', file.files);
      });
    }

    // 파일 객체를 FormData에 추가
    if (mapFiles) {
      formData.append('mapFiles', mapFiles.mapFiles);
    }

    const response = await axios.post(`${pelotonApiURL}/api/event`, formData, {
      headers: { Authorization: auth_str }
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 이벤트 수정
export const updatePelotonEventApi = async (eventId, data, files, mapFiles) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    // 파일 객체를 FormData에 추가
    if (files && files.length) {
      files.forEach((file, index) => {
        formData.append('files', file.files);
      });
    }
    // 파일 객체를 FormData에 추가
    if (mapFiles) {
      console.log('mapFiles');
      formData.append('mapFiles', mapFiles.mapFiles);
    }

    console.log(formData);

    const response = await axios.put(
      `${pelotonApiURL}/api/event/${eventId}`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 단일 이벤트 조회
export const fetchSingleEventApi = async eventId => {
  try {
    const response = await axios(`${pelotonApiURL}/api/event/${eventId}`, {
      method: 'GET',
      headers: { Authorization: auth_str }
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 참여 신청
export const applyEventApi = async eventId => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/event/${eventId}/join`,
      null,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 신청 취소
export const cancelEventApi = async eventId => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/event/${eventId}/cancel`,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 취소 (폭파)
export const forceCancelEventApi = async eventId => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/event/${eventId}`,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 회원 강퇴
export const expelUserApi = async (pelotonId, clubUserId) => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ targetClubUserId: String(clubUserId) })
    );

    const response = await axios.delete(
      `${pelotonApiURL}/api/club/${pelotonId}/expel`,
      {
        data: formData,
        headers: {
          Authorization: auth_str
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 일반 회원 승급 / 관리자 회원 강등
export const promoteUserApi = async (
  pelotonId,
  clubUserId,
  userRoleForward
) => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        targetClubUserId: String(clubUserId),
        clubRole: userRoleForward
      })
    );

    const response = await axios.put(
      `${pelotonApiURL}/api/club/${pelotonId}/userRole`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 어드민 페이지 클럽 목록
export const clubListAdminApi = async () => {
  try {
    const response = await axios.get(`${pelotonApiURL}/api/admin/club/list`, {
      headers: {
        Authorization: auth_str
      }
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 펠로톤 생성 승인
export const acceptMakePelotonApi = async pelotonId => {
  try {
    const response = await axios.put(
      `${pelotonApiURL}/api/admin/club/accept/${pelotonId}`,
      {
        headers: {
          Authorization: auth_str
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 어드민 페이지 초대코드 목록
export const invitationCodeListApi = async () => {
  try {
    const response = await axios.get(
      `${pelotonApiURL}/api/admin/club/inviteCode`,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 초대코드 생성
export const makeInvitaionCodeApi = async (count, memo) => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ createCount: String(count), memo: memo })
    );

    const response = await axios.post(
      `${pelotonApiURL}/api/admin/club/inviteCode`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 리스트 조회
export const fetchPostListApi = async postCategoryId => {
  try {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({ postCategoryId: postCategoryId }) // 1: 공지사항
    );

    const response = await axios.post(
      `${pelotonApiURL}/api/post/list?page=0&size=10`,
      formData,
      {
        headers: { Authorization: auth_str }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 조회
export const fetchPostApi = async postId => {
  try {
    const response = await axios.get(`${pelotonApiURL}/api/post/${postId}`, {
      headers: { Authorization: auth_str }
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 댓글 조회
export const fetchPostCommentsApi = async postId => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/postComment/${postId}/list`,
      {
        headers: { Authorization: auth_str }
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 댓글 작성 (부모 댓글)
export const AddPostParentCommentApi = async (postId, comment) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.post(
      `${pelotonApiURL}/api/postComment/${postId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 댓글 수정
export const editPostCommentApi = async (postId, commentId, comment) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.put(
      `${pelotonApiURL}/api/postComment/${postId}/${commentId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 게시판 댓글 삭제
export const deletePostCommentApi = async (postId, commentId) => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/postComment/${postId}/${commentId}`,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getEventJoinUserInfoApi = async (eventId, userId) => {
  try {
    if (eventId && userId) {
      const response = await axios(
        `${pelotonApiURL}/api/event/${eventId}/joinUser/${userId}`,
        {
          method: 'GET',
          headers: { Authorization: auth_str }
        }
      );
      return response;
    }
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 글 댓글 생성
export const addEventParentCommentApi = async (eventId, comment) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.post(
      `${pelotonApiURL}/api/eventComment/${eventId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 글 대댓글 생성
export const addEventChildrenCommentApi = async (eventId, comment) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.post(
      `${pelotonApiURL}/api/eventComment/${eventId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 글 댓글 받기
export const fetchEventCommentsApi = async eventId => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/eventComment/${eventId}/list`,
      {},
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 글 댓글 수정
export const editEventCommentApi = async (eventId, commentId, comment) => {
  console.log(eventId, commentId, comment);
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(comment));

    const response = await axios.put(
      `${pelotonApiURL}/api/eventComment/${eventId}/${commentId}`,
      formData,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// 이벤트 글 댓글 삭제
export const deleteEventCommentApi = async (eventId, commentId) => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/eventComment/${eventId}/${commentId}`,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// stravaActivity like 추가
export const addStravaActivityLike = async stravaActivityId => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/stravaActivity/${stravaActivityId}/reaction/LIKE`,
      {},
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// stravaActivity like 삭제
export const deleteStravaActivityLike = async stravaActivityId => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/stravaActivity/${stravaActivityId}/reaction/LIKE`,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// stravaActivity excellent 추가
export const addStravaActivityExcellent = async stravaActivityId => {
  try {
    const response = await axios.post(
      `${pelotonApiURL}/api/stravaActivity/${stravaActivityId}/reaction/EXCELLENT`,
      {},
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

// stravaActivity excellent 삭제
export const deleteStravaActivityExcellent = async stravaActivityId => {
  try {
    const response = await axios.delete(
      `${pelotonApiURL}/api/stravaActivity/${stravaActivityId}/reaction/EXCELLENT`,
      {
        headers: {
          Authorization: auth_str,
          'Access-Control-Allow-Origin': '*'
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};
