import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import auth from '../../utils/auth';
import request from '../../utils/request';
//
import {
  Link,
  Button,
  TextField,
  InputAdornment,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 2
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  nickname: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 1
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
      message: 'is not a valid email address'
    }
  }
};

function SignUpRenew() {
  const [formState, setFormState] = useState({
    isValid: false,
    values: { rememberMe: true },
    touched: {},
    isFilled: false,
    errors: {}
  });
  // 화면의 UI 분기를 위해 만든 State. ID&PW >>> Nick&Email 분기.
  const [isIDFilled, setIsIDFilled] = useState(false);
  // 회원가입 요청에서 생긴 에러메시지를 TextField Label에 HelperText로 적용해주기 위함.
  const [resCode, setResCode] = useState({
    code: null
  });

  /**
   *  @desc : 코드 작동에 필요한 변수 선언들
   */
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const { t } = useTranslation(['page']);
  const classes = useStyles();

  /**
   *  @desc : formData에 들어오는 value의 validation
   */
  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  /**
   *  @desc : 이벤트 로직 (Change Handler, Submit Handler, AutoLogin Handler)
   */
  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      isFilled: true,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleAutoLogin = req => {
    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/aam/v2/authenticate`;

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user.data, true);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSignUp = event => {
    event.persist();
    event.preventDefault();

    const req = formState.values;
    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/wp/v2/users/register`;
    //rest wp-content/plugins/wp-rest-user/public/class-wp-rest-user-public.php

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        // 이메일 주소가 이미 DB에 존재하는 경우 || 아이디가 이미 DB에 존재하는 경우.
        if (response.code === 398 || response.code === 399) {
          setResCode({ code: response.code });
          // 회원가입에 문제가 없었을 경우.
        } else if (response.code === 200) {
          setResCode({ code: response.code });
          req.issueJWT = true;
          handleAutoLogin(req);
        }
        // console.log(response)
      })
      .catch(err => {
        console.log(err);
      });
  };

  /**
   *  @desc : enter키의 의도 외적인 작동 통제
   */
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      if (!isIDFilled && formState.isValid) {
        setIsIDFilled(true);
      } else if (isIDFilled && formState.isValid) {
        event.preventDefault();
        handleSignUp(event);
      }
    }
  };

  /**
   *  @desc :
   */
  const textAreaValidHandler = event => {
    event.preventDefault();

    /**
     * ⚠️ 작업 중
     * (2월 4주차 Randy가 비밀번호 정책 만들어 주면 개발 착수 -> 정책대로 스키마 짜고, 여기 하단에 로직 작성.)
     * @requires :
     * 1. 비밀번호 강도가 보통 이상인 경우, setIsIDFilled(true)
     * 2. 보통 이하일 경우 취약한 비밀번호 사용 안내 팝업
     */

    if (formState.isFilled || formState.values.username >= 2) {
      setIsIDFilled(true);
    }
  };

  /**
   * @desc : 입력창 Focus && 비밀번호 보기 on/off 토글 로직
   */
  const [isShowPwChecked, setShowPwChecked] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const nicknameRef = useRef(null);
  const emailRef = useRef(null);

  useEffect(() => {
    if (!isIDFilled) {
      usernameRef.current.focus();
    } else if (isIDFilled) {
      nicknameRef.current.focus();
    }
  }, [isIDFilled]);

  const handleShowPwChecked = () => {
    const password = passwordRef.current;
    if (password === null) return;
    setShowPwChecked(!isShowPwChecked);
    password.type = isShowPwChecked ? 'password' : 'text';
  };

  // function handleProviderResponseClose(value) {
  //   setProviderResponseOpen(false);
  //   setProviderResponse(null);
  // };

  /*
    const handleClickSignOut = event => {
      event.preventDefault();
      auth.clearToken();
      window.location.reload();
    }
  */

  // 소셜로그인 하기
  // const [providerResponse, setProviderResponse] = useState(null);
  // const [providerResponseOpen, setProviderResponseOpen] = useState(false);

  // proper 로그인 실패 경고
  // const [popoverOpen, setPopoverOpen] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // 로그인 페이스북
  /*
    const responseFacebook = (res) => {	
      const requestURL = './auth/facebook/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
        console.log(err);
      });
    }
  */

  //로그인 구글
  /*
    const responseGoogle = (res) => {
  //	console.log(res.accessToken);
  
      const requestURL = './auth/google/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  //				console.log(response);
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
  //      console.log(err);
      });
    }
  */

  return (
    <>
      <div>
        <img
          alt="Logo"
          src="/images/logos/logo.png"
          className={classes.logoImage}
        />
      </div>
      <div className={classes.subtitle}>
        <Typography
          align="center"
          color="textPrimary"
          style={{ fontWeight: '700' }}
          variant="h4">
          {t('SignUp.makeAccount')}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {!isIDFilled ? t('SignUp.wantSignup2') : t('SignUp.wantSignup3')}
        </Typography>
      </div>
      <form className={classes.textArea} onSubmit={handleSignUp}>
        {!isIDFilled ? (
          <>
            {' '}
            <TextField
              inputRef={usernameRef}
              className={classes.textField}
              error={hasError('username')}
              fullWidth
              helperText={
                hasError('username') ? formState.errors.username[0] : null
              }
              label={t('SignIn.id')}
              name="username"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values.username || ''}
              variant="outlined"
            />
            <TextField
              inputRef={passwordRef}
              className={classes.textField}
              error={hasError('password') || !resCode}
              fullWidth
              helperText={
                hasError('password')
                  ? formState.errors.password[0]
                  : !resCode && formState.values.password
                  ? t('SignIn.wrongPassword')
                  : null
              }
              label={t('SignIn.password')}
              name="password"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
              InputProps={
                !isShowPwChecked
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityRoundedIcon
                            onClick={handleShowPwChecked}
                            className={classes.eyeimage}
                          />
                        </InputAdornment>
                      )
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityOffRoundedIcon
                            onClick={handleShowPwChecked}
                            className={classes.eyeimage}
                          />
                        </InputAdornment>
                      )
                    }
              }
            />
          </>
        ) : (
          <>
            <TextField
              inputRef={nicknameRef}
              className={classes.textField}
              error={hasError('nickname')}
              fullWidth
              helperText={
                hasError('nickname') ? formState.errors.nickname[0] : null
              }
              label={t('SignUp.nickname')}
              name="nickname"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values.nickname || ''}
              variant="outlined"
            />
            <TextField
              inputRef={emailRef}
              className={classes.textField}
              error={hasError('email') || !resCode}
              fullWidth
              helperText={
                (hasError('email') ? formState.errors.email[0] : null) ||
                (resCode && resCode === 398 ? t('SignUp.errorEmail') : null)
              }
              label={t('SignUp.email')}
              name="email"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="email"
              value={formState.values.email || ''}
              variant="outlined"
            />
          </>
        )}
        {!isIDFilled ? (
          <>
            <Button
              className={classes.signUpButton}
              color="primary"
              disabled={
                !formState.isFilled ||
                (formState.values.username &&
                  formState.values.username.length < 2) ||
                (formState.values.password &&
                  formState.values.password.length < 1)
              }
              fullWidth
              size="large"
              onClick={textAreaValidHandler}
              variant="contained">
              {t('SignIn.next')}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={classes.signUpButton}
              color="primary"
              disabled={!formState.isValid}
              /* disabled={!formState.values.nickname || 
                (formState.values.nickname && formState.values.nickname.length < 1) || 
                (!formState.values.email)} */
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              {t('SignUp.makeAccount')}
            </Button>
          </>
        )}
      </form>
      <div className={classes.footerText}>
        <Typography align="center" color="textSecondary" variant="body1">
          {t('SignUp.survey1')}
          {t('SignUp.survey2')},{<br />}
          {t('SignUp.survey3')}
          {t('SignUp.survey4')}
          {t('SignUp.survey5')}
          {t('SignUp.survey6')}
          {t('SignUp.survey7')}
        </Typography>
        <Link
          align="center"
          color="textSecondary"
          component={RouterLink}
          to="/sign-in-renew"
          variant="body1">
          {t('SignIn.login')}
        </Link>
      </div>
    </>
  );
}
export default withRouter(SignUpRenew);

SignUpRenew.propTypes = {
  history: PropTypes.object
};

/**
 *  @desc : MUI V4, useStyles 스타일링
 */
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  logoImage: {
    width: '216px'
  },
  subtitle: {
    marginTop: theme.spacing(4),
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start'
  },
  textField: {
    marginTop: theme.spacing(3)
  },
  textArea: {
    width: '80vw',
    height: '30vh'
  },
  signUpButton: {
    marginTop: theme.spacing(3)
  },
  footerText: {
    marginTop: theme.spacing(2),
    columnGap: '10px',
    width: '66vw',
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4)
    }
  },
  popover: {
    display: 'flex',
    alignItems: 'center'
  },
  popoverText: {
    padding: theme.spacing(2),
    backgroundColor: '#444444',
    color: '#ffffff'
  },
  eyeimage: {
    cursor: 'pointer'
  }
}));
