import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Box, Typography } from '@mui/material';

import axios from 'axios';
import { InviteCodeDialog, InviteResultDialog } from './components';
import AnimalsStyles from './components/Animals/animals.module.scss';

import LottieComponent from './components/Animals/LottieComponent';
import Cow from './components/asset/2024_cow.json';
import Boar from './components/asset/2024_boar.json';
import Camel from './components/asset/2024_camel.json';
import Pig from './components/asset/2024_pig.json';
import Lion from './components/asset/2024_lion.json';
import Wolf from './components/asset/2024_wolf.json';

import Tiger from './components/asset/2024_tiger.json';
import Dog from './components/asset/2024_dog.json';
import Deer from './components/asset/2024_deer.json';
import Leopard from './components/asset/2024_leopard.json';
import Horse from './components/asset/2024_horse.json';
import Elephant from './components/asset/2024_elephant.json';

const useStyles = makeStyles(theme => ({
  '@global': { body: { backgroundColor: '#f4f6f8' } },
  root: {},
  headerWrap: {
    position: 'fixed',
    zIndex: 10000,
    width: '100%',
    padding: '10px 0 0 0',
    minHeight: '44px !important',
    lineHeight: '44px',
    background: '#4DB6AC',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      minHeight: '44px !important',
      lineHeight: '44px'
    }
  },
  logoWrap: {
    padding: '0 0 0 0',
    maxWidth: '1200px',
    margin: '0 auto',
    textAlign: 'center'
  },
  logoImage: {
    width: 120,
    [theme.breakpoints.up('lg')]: {
      width: 136
    }
  },

  boxWrap: {
    padding: 'min(30px, 3.0vw) min(16px, 1.6vw) ',
    minWidth: '300px',
    textAlign: 'center',
    maxWidth: '1200px',
    margin: '0 auto 0 auto'
  },
  branding: {
    padding: 'min(30px, 3.0vw) min(16px, 1.6vw) ',
    minWidth: '300px',
    textAlign: 'center',
    marginTop: '-64px',
    paddingTop: '64px',
    height: '100vh',
    background: '#4DB6AC',

    backgroundImage:
      'url("/images/landing/landing_lightning.png"), url("/images/landing/landing_heartrate.png"), url("/images/landing/landing_speedmeter.png"), linear-gradient(to bottom, #4DB6AC 80%, #f5f5f5 100%)',
    // 설정하고자 하는 배경 사이즈를 전체 화면 높이의 20%로 설정
    backgroundSize: '18vh, 20vh, 32vh, auto',
    // 각 이미지의 위치 조정: 우측 상단, 좌측 중앙, 우측 하단
    backgroundPosition:
      'calc(50%) 30px, 10vw center, calc(100% - 5vw) bottom, 0',
    backgroundRepeat: 'no-repeat'
  },
  techGuide: {
    marginTop: 'min(20px, 2.0vw)'
    /*    background:'#4db6ac',*/
  },
  animalBox: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: '0 auto 10px auto',
    display: 'flex',
    justifyContent: 'center'
  },
  animalGuide: {
    marginTop: 'min(20px, 2.0vw)'
    /*    background:'#ffd357',*/
  },
  dataConnect: {
    marginTop: 'min(20px, 2.0vw)'
    /*    background:'#8cc1ec',*/
  },
  catchphrase: {
    maxWidth: '600px',
    fontWeight: 500,
    fontSize: 'clamp(18px, 4.4vw, 40px)',
    lineHeight: 'clamp(24px, 5.2vw, 48px)',
    margin: 'min(32px, 4.8vw) auto',
    color: '#FFFFFF',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(3.0)
    }
  },
  catchphraseWrap: {
    paddingLeft: '24px'
  },
  catchphraseDesc: {
    fontWeight: 300,
    fontSize: 'min(18px, 3vw)',
    lineHeight: 'min(28px, 4.2vw)',
    paddingRight: 'min(28px, 3vw)',
    marginBottom: 'min(32px, 3vw)',
    color: '#ffffff',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '100px'
      //      display: 'none'
    }
  },
  catchphraseImage: {
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    content: '',
    paddingTop: '90%',
    marginRight: '24px',
    marginTop: theme.spacing(1.8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2.0),
      paddingTop: '80%',
    }
  },

  signButtonBox: {
    marginTop: 'clamp(24px, 5.0vh, 60px)',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },
  signInButton: {
    borderRadius: '16px',
    fontSize: '16px',
    height: '32px',
    color: '#3c948b',
    background: '#ffffff',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
      borderRadius: '24px',
      height: '48px'
    }
  },
  signUpButton: {
    marginRight: theme.spacing(4.0),
    fontSize: '16px',
    borderRadius: '16px',
    height: '32px',
    color: '#ffffff',
    background: '#3c948b',
    [theme.breakpoints.up('lg')]: {
      fontSize: '20px',
      marginRight: theme.spacing(3.0),
      borderRadius: '24px',
      height: '48px'
    }
  },
  section: {
    padding: 'min(0px, 0vw) min(16px, 1.0vw) ',
    minWidth: '300px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '60vh'
    }
  },
  sectionGrid: {
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      //      background:'#999999',
    }
  },
  sectionTextGrid: {
    height:'clamp(100px, 30vh, 360px)',
    //    background:'#cccccc',
    [theme.breakpoints.up('md')]: {
      //      marginTop:theme.spacing(3.0),
      height: 'clamp(100px, 60vh, 360px)'
    }
  },
  sectionHead: {
    maxWidth: '600px',
    fontWeight: 500,
    fontSize: 'clamp(24px, 3.0vw, 32px)',
    lineHeight: 'clamp(32px, 3.4vw, 40px)',
    padding: 'clamp(28px, 3.0vw, 36px)',
    margin: 'min(16px, 1.0vw) auto',
    color: '#444444',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      padding: 'clamp(22px, 2.4vw, 28px)',
      textAlign: 'center'
    }
  },
  sectionSub: {
    fontWeight: 300,
    fontSize: 'clamp(16px, 1.3vw, 22px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)',
    maxHeight: 'clamp(60px, 6.0vw, 100px)',
    overflowY: 'visible',
    padding: '0 clamp(24px, 2.8vw, 32px)',
    marginBottom: 'min(36px, 3.6vw)',
    color: '#999999',
    textAlign: 'left'
  },
  sectionImageRight: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    content: '',
    width: '100%',
    height: 'clamp(160px, 32vh, 460px)',
    [theme.breakpoints.up('md')]: {
      //      marginTop:theme.spacing(3.0),
      height: 'clamp(200px, 60vh, 460px)'
    },
  },
  sectionImageLeft: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    content: '',
    width: '100%',
    height: 'clamp(160px, 32vh, 460px)',
    //    marginTop:theme.spacing(2.0),
    [theme.breakpoints.up('md')]: {
      //      marginTop:theme.spacing(3.0),
      height: 'clamp(200px, 60vh, 460px)'
    },
  },

  sectionButtonBox: {
    marginTop: 'clamp(30px, 6.0vw, 100px)',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  },

  countNumber: {
    fontSize: 'min(22px, 3vw)',
    lineHeight: 'min(32px, 3.2vw)'
  },

  phraseHead: {
    maxWidth: '1000px',
    fontWeight: 300,
    fontSize: 'min(36px, 4.0vw)',
    lineHeight: 'min(48px, 5.2vw)',
    margin: 'min(30px, 3.0vw) auto',
    color: '#3c948b'
  },
  phraseSub: {
    fontSize: 'min(22px, 3vw)',
    lineHeight: 'min(32px, 3.2vw)',
    marginBottom: 'min(32px, 3.2vw)',
    color: '#999999'
  },
  grid3wrap: {
    maxWidth: '800px',
    margin: 'min(20px, 2.0vw) auto',
    fontSize: 'min(16px, 2.0vw)',
    lineHeight: 'min(24px, 2.4vw)'
  },
  gridContainer: {
    margin: '0 auto',
    padding: theme.spacing(2),
    maxWidth: '1000px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%'
  },
  grid: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    height: '100%'
  },

  quoteContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  inviteCodeOpen: {
    margin: '4px 0 4px 24px',
    borderRadius: '2px',
    height: '36px'
  },
  gridImage: {
    maxWidth: '85%',
    marginBottom: 'min(12px, 1.2vw)'
  },
  gridImage2: {
    maxWidth: '90%',
    marginBottom: 'min(12px, 1.2vw)'
  }
}));

const Landing = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const { history } = props;

  const [inviteCodeOpen, setInviteCodeOpen] = React.useState(false);
  const [inviteResultOpen, setInviteResultOpen] = React.useState(false);
  const [jsonData, setJsonData] = React.useState({});
  const lang = i18n.language;

  //  const [ redirect, setRedirect ] = React.useState(false);
  const classes = useStyles();

  const openInviteCodeDialog = () => {
    setInviteCodeOpen(true);
  };

  const openInviteResultDialog = () => {
    setInviteResultOpen(true);
  };

  function handleClickInviteCodeClose() {
    setInviteCodeOpen(false);
  }

  function handleClickInviteResultClose() {
    setInviteResultOpen(false);
  }

  useEffect(() => {
    /*
          {t('Common.today')} <Typography className={classes.countNumber} component="span">0</Typography>
          {t('Landing.countRide')}
*/
    //code 받아서 메시지 띄우기
    //https://riduck.com/landing?hash=000000&state=&code=e2480c21ab75c204c267c453e9418bb719697d77&scope=read,activity:read_all

    //    console.log(props);

    if (props.location.search) {
      const query = new URLSearchParams(props.location.search);
      const code = query.get('code');
      const hash = query.get('hash');

      //hash 도 맞아야 함
      console.log(code);

      if (code && code.length > 36) {
        setInviteResultOpen(true);

        try {
          axios
            .get(
              `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-invitation.php`,
              {
                params: {
                  order: 'code_by_self',
                  code: code,
                  hash: hash,
                  lang: lang
                }
              }
            )
            .then(response => {
              if (response.data) {
                const json = response.data;
                setJsonData(json);
              }
            });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, []);

  return (
    //window.open('https://www.strava.com/clubs/riduck', '_blank')
    <div className={classes.root}>
      <Box className={classes.headerWrap}>
        <Box className={classes.logoWrap}>
          <img
            alt="Logo"
            src="/images/landing/landingLogo.png"
            className={classes.logoImage}
          />
        </Box>
      </Box>

      <div className={classes.animalBox}>
        <div className={AnimalsStyles.animalsBottomWrap}>
          <LottieComponent
            className={AnimalsStyles.cow}
            animationData={Cow}
            tooltip="cow"
          />
          <LottieComponent
            className={AnimalsStyles.boar}
            animationData={Boar}
            tooltip="boar"
          />
          <LottieComponent
            className={AnimalsStyles.camel}
            animationData={Camel}
            tooltip="camel"
          />
          <LottieComponent
            className={AnimalsStyles.pig}
            animationData={Pig}
            tooltip="pig"
          />
          <LottieComponent
            className={AnimalsStyles.lion}
            animationData={Lion}
            tooltip="lion"
          />
          <LottieComponent
            className={AnimalsStyles.wolf}
            animationData={Wolf}
            tooltip="wolf"
          />
          <LottieComponent
            className={AnimalsStyles.dog}
            animationData={Dog}
            tooltip="dog"
          />
          <LottieComponent
            className={AnimalsStyles.deer}
            animationData={Deer}
            tooltip="deer"
          />
          <LottieComponent
            className={AnimalsStyles.leopard}
            animationData={Leopard}
            tooltip="leopard"
          />
          <LottieComponent
            className={AnimalsStyles.horse}
            animationData={Horse}
            tooltip="horse"
          />
          <LottieComponent
            className={AnimalsStyles.elephant}
            animationData={Elephant}
            tooltip="elephant"
          />
        </div>
      </div>

      <div className={classes.branding}>
        <Grid
          container
          alignItems="center"
          spacing={0}
          style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <Grid
            item
            xl={5}
            lg={7}
            md={7}
            sm={12}
            xs={12}
            className={classes.catchphraseWrap}>
            <Typography component="div" className={classes.catchphrase}>
              {t('Landing.catchphrase')}
            </Typography>
            <Typography component="div" className={classes.catchphraseDesc}>
              {t('Landing.catchphraseDesc')}
            </Typography>
            <Box className={classes.signButtonBox}>
              <RouterLink to="/sign-up">
                <Button
                  className={classes.signUpButton}
                  size="large"
                  type="submit"
                  variant="contained">
                  {t('SignIn.signup')}
                </Button>
              </RouterLink>
              <RouterLink to="/sign-in">
                <Button
                  className={classes.signInButton}
                  size="large"
                  type="submit"
                  variant="contained">
                  {t('SignIn.login')}
                </Button>
              </RouterLink>
            </Box>
          </Grid>
          <Grid item xl={7} lg={5} md={5} sm={12} xs={12}>
            <div
              className={classes.catchphraseImage}
              style={{
                backgroundImage: 'url(/images/landing/landing5_pc.png)'
              }}></div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#FFFCF5' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}
          style={{ flexDirection: 'row-reverse' }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <Typography component="div" className={classes.sectionHead}>
              {t('Landing.section1_head')}
            </Typography>
            <Typography component="div" className={classes.sectionSub}>
              {t('Landing.section1_sub')}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageRight}
              style={{
                backgroundImage: 'url(/images/landing/landing1_pc2.png)',
                position: 'relative'
              }}>
              <div 
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>             
              <LottieComponent
                animationData={Elephant}
                className={AnimalsStyles.elephantLanding}
              />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#ffffff' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <Typography component="div" className={classes.sectionHead}>
              {t('Landing.section2_head')}
            </Typography>
            <Typography component="div" className={classes.sectionSub}>
              {t('Landing.section2_sub')}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageLeft}
              style={{
                backgroundImage: 'url(/images/landing/landing2_pc2.png)',
                position: 'relative'
              }}>
              <div 
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>             
              <LottieComponent
                animationData={Horse}
                className={AnimalsStyles.horseLanding}
              />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#F3F9FF' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}
          style={{ flexDirection: 'row-reverse' }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <Typography component="div" className={classes.sectionHead}>
              {t('Landing.section3_head')}
            </Typography>
            <Typography component="div" className={classes.sectionSub}>
              {t('Landing.section3_sub')}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageRight}
              style={{
                backgroundImage: 'url(/images/landing/landing3_pc2.png)',
                position: 'relative'
              }}>
              <div 
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>             
              <LottieComponent
                animationData={Boar}
                className={AnimalsStyles.boarLanding}
              />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#ffffff' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <Typography component="div" className={classes.sectionHead}>
              {t('Landing.section4_head')}
            </Typography>
            <Typography component="div" className={classes.sectionSub}>
              {t('Landing.section4_sub')}
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageLeft}
              style={{
                backgroundImage: 'url(/images/landing/landing4_pc2.png)',
                position: 'relative'
              }}>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <LottieComponent
                  animationData={Tiger}
                  className={AnimalsStyles.tigerLanding}
                />
                <LottieComponent
                  animationData={Lion}
                  className={AnimalsStyles.lionLanding}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ height: '55px' }}>
        {/*
      <Grid container className={classes.sectionGrid} alignItems="center" spacing={0} style={{flexDirection:'row-reverse'}}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.sectionTextGrid}>
          <Typography component="div" className={classes.sectionHead}>
            {t('Landing.section5_head')}
          </Typography>
          <Typography component="div" className={classes.sectionSub}>
            {t('Landing.section5_sub')}
          </Typography>        
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        </Grid>
      </Grid>
      */}
      </div>

      <InviteCodeDialog
        open={inviteCodeOpen}
        onClose={handleClickInviteCodeClose}
      />
      <InviteResultDialog
        open={inviteResultOpen}
        jsonData={jsonData}
        onClose={handleClickInviteResultClose}
      />
    </div>
  );
};

export default withRouter(Landing);
