import React from 'react';
import { makeStyles } from '@mui/styles';
import auth from '../../utils/auth';
import { useTranslation } from 'react-i18next';

import { Typography, Box, Button, Paper } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '16px'
  },
  paper: {
    maxWidth: '660px',
    padding: theme.spacing(4),
    margin: '0 auto',
    textAlign: 'center'
  },
  logOutText: {
    marginBottom: '16px'
  }
}));

const SignOut = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const classes = useStyles();

  auth.clearToken();
  auth.clearUserInfo();

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="div" className={classes.logOutText}>
          {t('SignIn.logouted')}
        </Typography>
        <Button variant="contained" color="primary" href="/sign-in">
          {t('SignIn.toFirst')}
        </Button>
      </Paper>
    </Box>
  );
};

export default SignOut;
