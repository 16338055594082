import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid
} from '@mui/material';

import { makeStyles, useTheme } from '@mui/styles';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useReactToPrint } from 'react-to-print';

import SimulationCourseDialog from '../../Legacy/Contents/SimulationCourseDialog';
import SeorakGranfondo from 'views/Analyzer/SeorakGranfondo';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.colored.light,
    border: '1px #dddddd solid',
    height: '100%',
    marginBottom: theme.spacing(2)
  },
  infoTitle: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#333333',
    marginTop: 10,
    marginBottom: 10,
    borderRadius: '4px'
  },
  resultDesc: {
    color: '#cccccc',
    margin: '0px',
    fontSize: '12px'
  },
  content: { padding: 0 },
  margin: { margin: 0 },
  textField: {
    width: '100%',
    '& input:disabled': {
      color: '#eeeeee'
    }
  },
  textFieldLast: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  inputStyle: {
    [theme.breakpoints.down('md')]: {
      height: '16px !important',
      fontSize: '12px !important',
      padding: '14px 8px 10px 8px !important'
    }
  },
  buttonStyle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  image: { height: 48, width: 48 },
  actions: { justifyContent: 'flex-end' },
  input: { marginLeft: 8, flex: 1 },
  smallGrid: {
    [theme.breakpoints.down('md')]: {
      padding: '4px !important'
    },
    textAlign: 'center'
  },
  formLabel: {
    '& span': {
      fontSize: '12px'
    }
  },
  iconButton: { padding: 10 },
  divider: {
    background: '#f4f4f4 !important',
    margin: '20px 0 20px 0 !important'
  },
  divider2: {
    background: '#dddddd !important',
    margin: '20px 0 12px 0 !important'
  },
  cardHeader: {
    color: '#90a4ae',
    fontSize: '15px',
    '& span': {
      [theme.breakpoints.down('md')]: {
        fontSize: '12px'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    }
  },
  segmentSelectButton: {
    fontSize: '13px !important',
    padding: '2px 8px 2px 8px !important',
    margin: '6px 8px 0 0 !important'
  },

  bikeBrandSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  powerDeviceSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  box: {
    boxShadow: 'inset 0 0 0 1px #c4c4c4',
    boxSizing: 'border-box',
    borderRadius: 4,
    width: '100%',
    height: '52px',
    padding: '10px 24px 0px 24px',
    marginTop: 0,
    marginBottom: 18,
    '&:last-child': {
      marginTop: '-1px !important',
      marginBottom: 0
    },
    '& span.MuiSlider-root': {
      padding: '10px 0'
    },
    '& span.MuiSlider-markLabel': {
      fontSize: '11px',
      top: '22px'
    }
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    zIndex: '999',
    width: '100%',
    padding: '0px 8px'
  }
}));

const SimulationCourseSegment = () => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const theme = useTheme();

  const [simulationCourse, setSimulationCourse] = useState({
    name: '설악 그란폰도',
    value: 'seorakGranfondo'
  });

  const [ftpType, setFtpType] = useState('wftp'); // wftp, sftp 구분하여 전송
  const [simulationStatus, setSimulationStatus] = useState('unmount'); // unmount, loading, mount 단계로 나뉨
  const [simulationCourseDialogOpen, setSimulationCourseDialogOpen] = useState(
    false
  );
  const [seorakDialogOpen, setSeorakDialogOpen] = useState(false);

  const printRef = useRef();

  const handleSimulationCourseDialogOpen = () => {
    setSimulationCourseDialogOpen(true);
  };

  const handleSimulationCourseDialogClose = () => {
    setSimulationCourseDialogOpen(false);
  };

  const handleSelectSimulationCourse = course => {
    setSimulationCourse(course);
  };

  const handleSimulationStatus = status => {
    setSimulationStatus(status);
  };

  const handleSeorakDialogOpen = () => {
    setSeorakDialogOpen(true);
  };
  const handleSeorakDialogClose = () => {
    setSeorakDialogOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader
        title={t('BikeUI.courseSimulation')}
        action={
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSimulationCourseDialogOpen}
              className={classes.segmentSelectButton}>
              {simulationCourse.name}
            </Button>
            <SimulationCourseDialog
              open={simulationCourseDialogOpen}
              onClose={handleSimulationCourseDialogClose}
              handleSelectSimulationCourse={handleSelectSimulationCourse}
            />
          </div>
        }
        style={{ padding: '12px 16px 6px 16px' }}
        classes={{ title: classes.cardHeader }}
      />
      <CardContent>
        <Box style={{ textAlign: 'center', marginBottom: '12px' }}>
          <div>
            정기적으로 FTP 테스트 및 파워테스트를 수행하시고 본인 FTP를
            관리하시는 분들은 WFTP를 참고해주시고,
          </div>
          <div>
            FTP 테스트나 파워테스트를 수행하지 않으나 심박계를 사용하시는 분들은
            SFTP(submax)를 참고해주세요.
          </div>
        </Box>
        <Box
          container
          justify="center"
          style={{ textAlign: 'center', marginBottom: '12px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (simulationCourse) {
                setFtpType('wftp');
                handleSeorakDialogOpen();
                handleSimulationStatus('loading');
                setTimeout(() => {
                  handleSimulationStatus('mount');
                }, 3000);
              }
            }}>
            <EqualizerIcon
              className={clsx(classes.leftIcon, classes.iconSmall)}
            />
            WFTP&nbsp;{t('BikeUI.runSimulation')}
          </Button>
        </Box>
        <Box container justify="center" style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (simulationCourse) {
                setFtpType('sftp');
                handleSeorakDialogOpen();
                handleSimulationStatus('loading');
                setTimeout(() => {
                  handleSimulationStatus('mount');
                }, 3000);
              }
            }}>
            <EqualizerIcon
              className={clsx(classes.leftIcon, classes.iconSmall)}
            />
            SFTP&nbsp;{t('BikeUI.runSimulation')}
          </Button>
        </Box>

        {simulationStatus !== 'unmount' &&
        simulationCourse &&
        simulationCourse.value === 'seorakGranfondo' &&
        seorakDialogOpen ? (
          <Dialog
            open={seorakDialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
            fullScreen={fullScreen}
            scroll="body">
            <Box className={classes.titleContainer}>
              <DialogTitle text="h6">
                {t('BikeUI.courseSimulation')}
              </DialogTitle>
              <Box>
                <PrintIcon
                  onClick={handlePrint}
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                />
                <CloseIcon
                  onClick={handleSeorakDialogClose}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
            <Divider />
            <div className={classes.whiteLine} />
            <DialogContent
              ref={printRef}
              alt="post"
              style={{ padding: '8px 8px', marginBottom: '8px' }}>
              <SeorakGranfondo
                simulationStatus={simulationStatus}
                ftpType={ftpType}
                handleSimulationStatus={handleSimulationStatus}
                ref={printRef}
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default SimulationCourseSegment;
