import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export const lightModePalette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: colors.teal[700],
    main: colors.teal[300],
    light: colors.teal[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.pink[700],
    main: colors.pink[300],
    light: colors.pink[100]
  },
  third: {
    contrastText: white,
    dark: colors.blue[700],
    main: colors.blue[300],
    light: colors.blue[100]
  },
  fourth: {
    contrastText: white,
    dark: colors.amber[700],
    main: colors.amber[300],
    light: colors.amber[100]
  },
  success: {
    contrastText: white,
    dark: colors.lightGreen[700],
    main: colors.lightGreen[300],
    light: colors.lightGreen[100]
  },
  info: {
    contrastText: white,
    dark: colors.lightBlue[700],
    main: colors.lightBlue[300],
    light: colors.lightBlue[100]
  },
  cancel: {
    contrastText: white,
    dark: colors.grey[700],
    main: colors.grey[300],
    light: colors.grey[100]
  },
  warning: {
    contrastText: white,
    dark: colors.yellow[700],
    main: colors.yellow[300],
    light: colors.yellow[100]
  },
  error: {
    contrastText: white,
    dark: colors.pink[700],
    main: colors.pink[300],
    light: colors.pink[100]
  },
  text: {
    primary: colors.blueGrey[700],
    secondary: colors.blueGrey[300],
    link: colors.blue[300],
    third: black,
    forth: '#444444'
  },
  default: {
    contrastText: '#fff',
    dark: 'rgb(48, 62, 70)',
    light: 'rgb(106, 123, 131)',
    main: '#455A64'
  },
  background: {
    default: '#f4f6f8',
    paper: white,
    colored: {
      light: colors.grey[50],
      main: colors.grey[100],
      dark: colors.grey[200]
    }
  },
  chart: {
    background: 'white',
    primary: {
      axis: {
        line: '#dddddd',
        tick: '#f5f5f5',
        grid: '#f5f5f5',
        fontColor: 'black'
      }
    },
    secondary: {
      axis: { grid: '#eeeeee', interlaced: '#f5f5f5' }
    },
    text: {
      primary: '#666666',
      secondary: '#333333',
      third: '#f06292',
      background: '#ffffff'
    },
    tick: '#f5f5f5'
  },
  icon: colors.blueGrey[300],
  divider: colors.grey[200]
};

export const darkModePalette = {
  primary: {
    contrastText: '#fff',
    dark: '#004d40',
    main: '#00796b',
    light: '#48a999'
  },
  secondary: {
    contrastText: '#fff',
    dark: '#880e4f',
    main: '#c2185b',
    light: '#f06292'
  },
  third: {
    contrastText: '#fff',
    dark: '#0d47a1',
    main: '#1976d2',
    light: '#63a4ff'
  },
  fourth: {
    contrastText: '#fff',
    dark: '#ff6f00',
    main: '#ffa000',
    light: '#ffc046'
  },
  success: {
    contrastText: '#fff',
    dark: '#33691e',
    main: '#689f38',
    light: '#99d066'
  },
  info: {
    contrastText: '#fff',
    dark: '#01579b',
    main: '#0288d1',
    light: '#5eb8ff'
  },
  cancel: {
    contrastText: '#fff',
    dark: '#424242',
    main: '#757575',
    light: '#bdbdbd'
  },
  warning: {
    contrastText: '#fff',
    dark: '#f57f17',
    main: '#fbc02d',
    light: '#ffeb3b'
  },
  error: {
    contrastText: '#fff',
    dark: '#880e4f',
    main: '#c2185b',
    light: '#f06292'
  },
  text: {
    primary: '#e0e0e0',
    secondary: '#b0bec5',
    link: '#90caf9',
    third: '#e0e0e0',
    forth: '#dddddd'
  },
  default: {
    main: '#E0E0E0'
  },
  background: {
    default: '#2c2c2c',
    paper: '#424242',
    colored: {
      light: '#1e1e1e',
      main: '#2e2e2e',
      dark: '#303030'
    }
  },
  chart: {
    background: '#424242',
    primary: {
      axis: {
        line: '#dddddd',
        tick: '#424242',
        grid: '#f5f5f5',
        fontColor: white
      }
    },
    secondary: {
      axis: { grid: '#303030', interlaced: '#2e2e2e' }
    },
    text: {
      primary: '#cccccc',
      secondary: '#cccccc',
      third: '#c2185b',
      fontColor: '#e0e0e0'
    },
    tick: '#f5f5f5'
  },
  icon: '#b0bec5',
  divider: '#757575'
};
