import * as React from 'react';
import { acceptMakePelotonApi } from 'utils/clientApis';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * 
 * @prop : {
 *      label : 페이지 컴포넌트의 핸들러를 통해 넘어오는 라벨입니다. 모달 컴포넌트의 내용을 분기하는 조건이 됩니다.
        openState : 모달의 on/off를 감독하는 boolean State 입니다.
        setOpen : 모달을 닫는 유저플로우를 구현하기 위해 전달된 setState 함수입니다.
        pelotonId : 접속 중인 펠로톤 클럽의 아이디입니다.
        clubName : 접속 중인 펠로톤 클럽의 이름입니다.
        guideText : 회원 가입 신청 시 보여줄 가입 안내 텍스트입니다.
        uriName : 접속 중인 펠로톤 클럽의 주소입니다.
        userData : 클릭 이벤트가 발생한 User의 정보가 들어있는 객체형 State입니다.
 * }
 * 
 */

export default function AdminModal({ label, openState, setOpen, clubData }) {
  const selectedClubData = clubData.club;
  console.log(selectedClubData);
  /**
   * @return : 모달의 버튼과 연결되어 있는 모든 핸들러 함수.
   */
  // 펠로톤 가입신청 승락, 거부 핸들러
  const handleAcceptandReject = async () => {
    try {
      if (label === 'accept') {
        const response = await acceptMakePelotonApi(selectedClubData?.id);
        response && setOpen(false);
      }
    } catch (err) {
      setOpen(false);
      console.error(err);
    }
  };

  return (
    <>
      {openState && selectedClubData && (
        <Box>
          {label && label === 'accept' && (
            <Dialog
              open={openState}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`펠로톤 클럽'${selectedClubData.name}'의 생성을 승인하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="secondary">
                    취소
                  </Button>
                  <Button
                    onClick={handleAcceptandReject}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="primary">
                    승인
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}
const useStyles = makeStyles(() => ({
  root: {
    //    padding: theme.spacing(2)
  }
}));
