import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { ProfilePop } from 'views/Peloton/Common/ProfilePopModal';
import { getUserInfoApi } from 'utils/clientApis';
import { formatNumber } from 'utils/helper';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    marginBottom: '16px'
  },
  table: {
    borderCollapse: 'separate'
  },
  trophyIcon: {
    '&.gold': {
      color: '#FFD400'
    },
    '&.silver': {
      color: 'silver' // 1번 트로피의 색상
    },
    '&.bronze': {
      color: '#624637'
    },
    '&.transparent': {
      display: 'none',
      color: 'transparent'
    }
  }
}));

// keyData = w/kg, wTSS 등의 기준으로 필터된 데이터
export default function RankTable({ title, keyData, keyword, clubId }) {
  const classes = useStyles();

  const [profileOpen, setProfileOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleClickProfile = userId => {
    setSelectedUserId(userId);
    setProfileOpen(true);
  };

  const getUserInfo = () => {
    return getUserInfoApi(clubId, selectedUserId);
  };

  const getRankClassName = index => {
    if (index === 0) return `${classes.trophyIcon} gold`;
    else if (index === 1) return `${classes.trophyIcon} silver`;
    else if (index === 2) return `${classes.trophyIcon} bronze`;
    else return `${classes.trophyIcon} transparent`;
  };

  return (
    <>
      <Box className={classes.container}>
        <TableContainer component={Paper}>
          <Table
            aria-label="simple table"
            size="small"
            className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {keyword === 'wkg' && `FTP(w/Kg)`}
                  {keyword === 'wTSS' && `wTSS`}
                  {keyword === 'distance' && `누적거리 (km)`}
                  {keyword === 'totalElevationGain' && `획득고도 (m)`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keyData.map((rank, idx) => (
                <TableRow key={idx}>
                  <TableCell align="center" style={{ width: '72px' }}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      <EmojiEventsOutlinedIcon
                        className={getRankClassName(idx)}
                      />
                      {idx > 2 ? idx + 1 : null}
                    </Box>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    align="center"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                    <Avatar
                      src={rank.profileImage}
                      style={{
                        width: '34px',
                        height: '34px',
                        marginRight: '8px',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleClickProfile(rank.id)}
                    />
                    {rank.displayName}
                  </TableCell>

                  <TableCell align="center">
                    {keyword === 'wkg' && `${rank.wkg}.xx`}
                    {keyword === 'wTSS' && `${formatNumber(rank.weekTss)}`}
                    {keyword === 'distance' &&
                      `${formatNumber((rank.distance / 1000).toFixed(2))}`}
                    {keyword === 'totalElevationGain' &&
                      `${formatNumber(rank.totalElevationGain)}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {profileOpen && (
        <ProfilePop
          openState={profileOpen}
          setOpen={setProfileOpen}
          getUserInfo={getUserInfo}
        />
      )}
    </>
  );
}
