import React, { useState } from 'react';
import {
  Avatar,
  IconButton,
  Menu,
  Box,
  MenuItem,
  Typography,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  AddClubPostChildrenCommentApi,
  getUserInfoApi
} from 'utils/clientApis';
import { useFormatDate } from 'utils/useFormatDate';

import { deleteCommentsApi } from 'utils/clientApis';

import DeleteModal from 'views/Peloton/Common/DeleteModal';
import { ProfilePop } from 'views/Peloton/Common/ProfilePopModal';
import CommentWrite from 'views/Peloton/Common/CommentWrite';

function Comment({
  comment,
  type = 'parent',
  userInfoNow,
  pelotonId,
  postId,
  handleGetComments,
  submitEditComment
}) {
  const ITEM_HEIGHT = 48;

  const classes = useStyles();
  const { formatDateWithTime } = useFormatDate();
  const [showWriteChildComment, setShowWriteChildComment] = useState(false);
  const [commentChildState, setCommentChildState] = useState({
    data: {
      contents: '',
      parentCommentId: comment.id
    },
    errors: {},
    touched: {},
    isValid: false
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [commentState, setCommentState] = useState({
    data: {
      contents: comment.contents
    },
    errors: {},
    touched: {},
    isValid: false
  });
  const [commentMenuState, setCommentMenuState] = useState({});
  const [profileOpen, setProfileOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleEditChangeComment = event => {
    const { name, value } = event.target;
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  const handleChildCommentEditChange = event => {
    event.persist();

    const { name, value } = event.target;
    setCommentChildState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  //   대댓글 생성
  const handleAddReply = async parentCommentId => {
    try {
      const response = await AddClubPostChildrenCommentApi(
        pelotonId,
        postId,
        commentChildState.data
      );
      if (response.data.success) {
        handleGetComments();
        setCommentChildState({
          data: {
            contents: '',
            parentCommentId
          },
          errors: {},
          touched: {},
          isValid: false
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const resetCommentState = () => {
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        contents: comment.contents
      }
    }));
  };

  // 코멘트 메뉴 클릭 핸들러
  const handleCommentMenuOpen = event => {
    // 현재 클릭된 코멘트에 대한 메뉴 상태만 업데이트
    setCommentMenuState({
      anchorEl: event.currentTarget,
      open: true // 메뉴를 열 상태로 설정
    });
  };

  // 코멘트 메뉴 닫기 핸들러
  const handleCommentMenuClose = () => {
    // 현재 클릭된 코멘트에 대한 메뉴 상태를 닫힘 상태로 업데이트
    setCommentMenuState(prev => ({
      ...prev,
      open: false // 메뉴를 닫는 상태로 설정
    }));
  };

  const handleClickProfile = () => {
    setProfileOpen(true);
  };

  const handleClickDeleteModalOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteSession = async () => {
    const response = await deleteCommentsApi(pelotonId, postId, comment.id);
    if (response) {
      handleGetComments();
      setDeleteOpen(false);
    }
  };

  const getUserInfo = () => {
    return getUserInfoApi(pelotonId, comment.commentClubUserId);
  };

  function FormatCommentDate({ dateData }) {
    const formattedDate = formatDateWithTime(dateData, 'Asia/Seoul');
    return <>{formattedDate}</>;
  }

  const isWriter = userInfoNow.nickname === comment.commentUserDisplayName;
  const isAdminOrManager =
    userInfoNow.role === 'MANAGER' || userInfoNow.role === 'ADMIN';

  return (
    <Container className={classes.commentView}>
      <Box style={{ width: '100%' }}>
        <div className={classes.commentContainer}>
          {/* 댓글 작성자 정보  */}
          <div style={{ display: 'flex', width: '100%' }}>
            <Avatar
              src={comment?.commentUserProfileImage}
              variant="circular"
              style={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleClickProfile()}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              {comment?.commentUserDisplayName && (
                <Typography variant="h6" color="textPrimary">
                  {!comment?.commentUserDisplayName
                    ? '탈퇴한 회원'
                    : comment?.commentUserDisplayName}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ cursor: 'default' }}>
                <FormatCommentDate dateData={comment?.regDate} />
              </Typography>
            </div>
          </div>
          {/* 메뉴 아이콘 */}
          {/* TODO: 현재 대댓글 기능 비활성화. 디자인 수정 필요 */}
          {/* {comment.used && type === 'parent' ? (
            <Button
              color="primary"
              className={classes.replyButton}
              onClick={() => setShowWriteChildComment(prev => !prev)}>
              댓글 달기
            </Button>
          ) : null} */}
          {comment.used && (isWriter || isAdminOrManager) && (
            <>
              {/* 아이콘 */}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={event => handleCommentMenuOpen(event)}
                size="large">
                <MoreVertIcon />
              </IconButton>

              {/* 메뉴 */}
              {commentMenuState?.open && (
                <Menu
                  id="long-menu"
                  anchorEl={commentMenuState?.anchorEl}
                  keepMounted
                  open={!!commentMenuState?.open}
                  onClose={() => handleCommentMenuClose()}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '15ch',
                      borderRadius: '24px'
                    }
                  }}>
                  {/* 작성자인 경우 수정 메뉴 아이템 */}
                  {isWriter ? (
                    <MenuItem
                      onClick={() => {
                        setIsEditMode(true);
                        handleCommentMenuClose();
                      }}>
                      수정
                    </MenuItem>
                  ) : null}

                  {/* 작성자인 경우 삭제 메뉴 아이템, 관리자인 경우 삭제(관리자) 메뉴 아이템 */}
                  {isWriter ? (
                    <MenuItem onClick={() => handleClickDeleteModalOpen()}>
                      삭제
                    </MenuItem>
                  ) : isAdminOrManager ? (
                    <MenuItem onClick={() => handleClickDeleteModalOpen()}>
                      {'삭제 (관리자)'}
                    </MenuItem>
                  ) : null}
                </Menu>
              )}
            </>
          )}
        </div>

        {/* 댓글 내용 */}
        <Box className={classes.body}>
          {comment.used ? (
            isEditMode ? (
              <CommentWrite
                type="edit"
                value={commentState.data.contents}
                onEditChange={handleEditChangeComment}
                onButtonClick={() => {
                  submitEditComment(commentState, comment.id);
                  setIsEditMode(false);
                }}
                onEditCancel={() => {
                  resetCommentState();
                  setIsEditMode(false);
                }}
              />
            ) : (
              <>
                <Typography variant="body1" color="textPrimary">
                  {comment.contents?.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {paragraph}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </>
            )
          ) : (
            <Typography variant="body1" color="textPrimary">
              삭제된 댓글입니다.
            </Typography>
          )}
        </Box>
        {showWriteChildComment ? (
          <CommentWrite
            value={commentChildState?.data.contents || ''}
            onEditChange={handleChildCommentEditChange}
            onButtonClick={() => handleAddReply(comment.id)}
          />
        ) : null}
      </Box>

      {profileOpen && (
        <ProfilePop
          openState={profileOpen}
          setOpen={setProfileOpen}
          getUserInfo={getUserInfo}
        />
      )}
      {deleteOpen && (
        <DeleteModal
          handleDeleteSession={handleDeleteSession}
          openState={deleteOpen}
          contentText={'정말로 게시글을 삭제 하시겠습니까?'}
          setOpen={setDeleteOpen}
        />
      )}
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  body: {
    '& > *': {
      marginBottom: '16px'
    }
  },
  commentView: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    padding: '8px 12px',
    justifyContent: 'space-between',
    height: '100%',
    borderBottom: '1px #dddddd solid',
    '& > *': {
      marginRight: '0px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px 24px'
    }
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginBottom: '8px'
  },
  replyButton: {
    whiteSpace: 'nowrap'
  }
}));

export default Comment;
