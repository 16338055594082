import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Badge, Fab, Hidden, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 5px #dddddd'
  },
  headerBar: {
    textAlign: 'center',
    padding: '6px'
  },
  appBar: {
    height: 'auto !important',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 5px #dddddd'
  },
  card: {
    width: '100%',
    padding: '16px'
  },
  toolbar: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    minHeight: '48px !important',
    padding: '4px 0 0 0',

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      minHeight: '60px !important'
    }
  },
  flexLeft: {
    flexBasis: '20%',
    textAlign: 'left'
  },
  flexRight: {
    flexBasis: '20%',
    textAlign: 'right'
  },
  logoFlex: {
    flexBasis: '60%',
    // backgroundColor: '#fff',
    textAlign: 'center',
    padding: '4px 0 0 0'
  },
  logoImage: {
    width: 120,
    [theme.breakpoints.up('lg')]: {
      width: 136
    }
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  iconButton: {
    color: 'primary',
    marginRight: '5px'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.flexLeft}>
          <Hidden lgUp>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={onSidebarOpen}
              size="large">
              <MenuIcon />
            </IconButton>
          </Hidden>
        </div>

        <RouterLink to="/" className={classes.logoFlex}>
          <img
            alt="Logo"
            src="/images/logos/logo.png"
            className={classes.logoImage}
          />
        </RouterLink>

        <div className={classes.flexRight}>
          <Hidden lgDown mdUp>
            <IconButton color="primary" className={classes.iconButton} size="large">
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="primary" className={classes.signOutButton} size="large">
              <InputIcon />
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
