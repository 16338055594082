import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  Divider,
  Paper,
  InputBase,
  IconButton,
  Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

const useStyles = makeStyles({
  search: {
    margin: 10,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  },
  input: {
    marginLeft: 4,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  dialog: { marginTop: '5px' },
  inline: {
    display: 'inline'
  },
  progress: {
    alignItems: 'center'
  }
});

const SimulationCourseDialog = ({
  open,
  onClose,
  handleSelectSimulationCourse
}) => {
  const { t } = useTranslation(['page']);

  const classes = useStyles();

  const courseListAll = [{ value: 'seorakGranfondo', name: '설악 그란폰도' }];

  const [keyword, setKeyword] = useState();
  const [courseList, setCourseList] = useState(courseListAll);

  const handleSearchChange = event => {
    setKeyword(event.target.value);
  };

  const handleSimulationSearch = () => {
    if (keyword === '') setCourseList(courseListAll);
    else
      setCourseList(
        courseListAll.filter(course => course.name.includes(keyword))
      );
  };

  const handleSearchKeyDown = event => {
    if (event.key === 'Enter') {
      handleSimulationSearch();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <Paper className={classes.search}>
        <IconButton className={classes.iconButton} aria-label="menu" size="large">
          <MenuIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={'코스 검색'}
          value={keyword}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          inputProps={{ 'aria-label': '코스 검색' }}
        />
        <IconButton
          className={classes.iconButton}
          onClick={handleSimulationSearch}
          aria-label="search"
          size="large">
          <SearchIcon />
        </IconButton>
      </Paper>
      <List>
        {courseList.length ? (
          courseList.map(course => (
            <ListItem
              button
              onClick={() => {
                handleSelectSimulationCourse(course);
                onClose();
              }}
              key={course.value}
              style={{ fontWeight: 'bold' }}>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText primary={course.name} />
            </ListItem>
          ))
        ) : (
          <ListItem style={{ fontWeight: 'bold' }}>
            <ListItemText primary={'검색 결과 존재하지 않습니다.'} />
          </ListItem>
        )}
      </List>
    </Dialog>
  );
};

export default SimulationCourseDialog;
