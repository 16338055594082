import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

//jQuery 추가
import $ from 'jquery';

//Chart 추가
import CanvasJSReact from '../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {},
  powerContainer: {
    height: '240px',
    padding: '4px',
    [theme.breakpoints.down('xl')]: {
      height: '200px'
    },
    [theme.breakpoints.down('md')]: {
      height: '160px'
    }
  },
  tableContainer: {
    height: 300,
    overflow: 'y-auto',
    margin: '12px 0px 0px 0px',
    padding: '4px',
    [theme.breakpoints.down('xl')]: {
      height: 240
    },
    [theme.breakpoints.down('md')]: {
      height: 200
    }
  },
  streamWrap: {
    padding: '8px 4px',
    marginBottom: '12px',
    borderBottom: '1px #dddddd solid'
  },
  streamSummary: {
    background: '#f5f5f5',
    borderRadius: '4px',
    marginTop: '16px',
    padding: '6px 12px'
  },
  summaryTitle: {
    lineHeight: '32px',
    fontWeight: 500,
    fontSize: '15px'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  xaxisMatabolismel: {
    color: '#888888',
    fontSize: '11px'
  },
  intervalGrid: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    padding: '8px'
  },
  tableHeadCell: {
    padding: '10px 16px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '11px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 4px',
      fontSize: '9px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '10px 16px',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '11px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 4px',
      fontSize: '9px'
    }
  }
}));

//const showText = useMediaQuery('(min-width:600px)');
//console.log (showText) ;

const MatabolismChart = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const extraInfo = props.extraInfo;

  //unit default
  const unit = extraInfo.unit ? extraInfo.unit : 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const {
    pdcData,
    activityId,
    ftp,
    maxHr,
    streamDataJson,
    metabolismArray,
    wkg,
    weight
  } = props;
  const [stripLines, setStripLines] = useState([]);
  const [pdGuideDialogOpen, setPdGuideDialogOpen] = React.useState(false);
  const [intervalChartOptions, setMatabolismChartOptions] = useState({});
  const [wattsChartOptions, setWattsChartOptions] = useState({});
  const [hrChartOptions, setHrChartOptions] = useState({});
  const [altitudeChartOptions, setAltitudeChartOptions] = useState({});

  const [intervalChart, setMatabolismChart] = useState(null);
  const [powerChart, setPowerChart] = useState(null);
  const [hrChart, setHrChart] = useState(null);
  const [altitudeChart, setAltitudeChart] = useState(null);

  const streamOrg = streamDataJson.stream;
  const streamData = streamDataJson.stream_json;

  const activityMatabolism = metabolismArray; //.powerzone_activities[String(activityId)];

  console.log(activityMatabolism);

  const allArr = activityMatabolism.all_arr;
  const fatArr = activityMatabolism.fat_arr;
  const glycArr = activityMatabolism.glyc_arr;

  const AT = activityMatabolism.at;
  const wftp = activityMatabolism.wftp;
  const fatmax = activityMatabolism.fatmax;
  const fatmaxHour = activityMatabolism.fatmax_hour;

  console.log(fatArr);

  const wattsArray = streamOrg.watts;
  const watts0Array = streamOrg.watts0;
  const awcArray = streamOrg.awc;
  const heartrateArray = streamOrg.heartrate;
  const distanceArray = streamOrg.distance;

  const wattsPoints = streamData.watts;
  const heartratePoints = streamData.heartrate;
  const awcPoints = streamData.awc;

  const altitudePoints = streamData.altitude;
  const distancePoints = streamData.distance;
  const durationPoints = streamData.duration;

  const wattsMax = streamData.watts_max,
    wattsMaxIndex = streamData.watts_max_index;

  const activityFTP = streamDataJson.ftp;
  const activityName = streamDataJson.activity_name;
  const altitudeMax = streamDataJson.altitude_max;
  const stravaDescription = streamDataJson.strava_description;
  const streamLength = streamDataJson.obj_length;
  const movingDistance = streamDataJson.distance;
  const movingTime = streamDataJson.moving_time;
  const rideType = streamDataJson.ride_type;

  //	console.log("ww " + window.innerHeight);

  const containerPower = {
    width: '100%',
    height: window.innerHeight > 800 ? '240px' : '160px',
    margin: 'auto'
  };

  const containerHr = {
    width: '100%',
    height: window.innerHeight > 800 ? '120px' : '80px',
    margin: 'auto'
  };

  const containerAltitude = {
    width: '100%',
    height: window.innerHeight > 800 ? '120px' : '80px',
    margin: 'auto'
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  function findKeyByValue(Array, value) {
    for (let l = 0; l < Array.length; l++) {
      if (Array[l] >= value) return l;
    }
  }

  useEffect(() => {
    const { moving10Points, stripLineArray, bubbleArray } = Matabolism10Time(
      wattsArray,
      awcArray,
      wattsPoints
    );
    const { hrZoneArr, hrZonePoints } = ArrangeHrZone(
      heartrateArray,
      heartratePoints,
      0
    );

    setStripLines(stripLineArray);

    /******* watts Chart  *******/
    setWattsChartOptions({
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: true,
      zoomType: 'x',
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            //배터리
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(wattsMax)
                ) +
                '</strong> %';
              content += '<br/>';
            } else if (i == 1) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong> W';
              content += '<br/>';
              content +=
                'FTP ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(activityFTP)
                ) +
                '</strong> %';
              content += '<br/>';
            }
          }

          return content;
        }
      },
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        stripLines: stripLineArray,
        crosshair: {
          enabled: false,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsMatabolismel(e.value);
            }
          }
        }
      },
      axisY: {
        margin: 10,
        maximum: Math.round(wattsMax) + 150,
        labelFontSize: 11,
        labelFontColor: '#666666',
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          if (e.value > 999) {
            return e.value;
          } else {
            return e.value + 'w';
          }
        },
        gridThickness: 1,
        gridColor: '#eeeeee',
        minimum: 1
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.2,
          name: t('Dashboard.battery'),
          yValueFormatString: '# %',
          lineThickness: 1,
          color: '#dddddd',
          legendMarkerType: 'square',
          dataPoints: awcPoints
        },
        {
          type: 'area',
          fillOpacity: 1.0,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 0,
          fillOpacity: 0.8,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: wattsPoints
        },
        {
          type: 'column',
          fillOpacity: 0.8,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 1,
          color: '#f5f5f5',
          click: onMouseoverMatabolism,
          legendMarkerType: 'square',
          dataPoints: moving10Points
        },
        {
          type: 'bubble',
          name: 'INTERVAL',
          fillOpacity: 0.8,
          indexMatabolismelFontColor: '#ffffff',
          toolTipContent: '<b>{name}</b><br/>x: {x}<br/>y: {y}<br/> z: {z}',
          click: onMouseoverMatabolism,
          dataPoints: bubbleArray
        }
      ]
    });

    function onMouseoverMatabolism(e) {
      let hash = '#interval_' + e.dataPoint.id;
      let color = e.dataPoint.color;

      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });

      $(hash).css({ 'background-color': color });

      if ($(hash).position()) {
        $('#intervalTable').animate({ scrollTop: $(hash).position().top }, 200);
      }
    }

    function onMouseoutMatabolism(e) {
      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });
    }

    function onMouseover(e) {
      /*
			x: Math.round((intervalStart+intervalEnd)/2), 
			y: avgPower + 150, 
			z: usage, 
			name: avgPower, 
			color:color,
			zone:zone
			*/
      //console.log(e.dataPoint.z);

      let hash = '#interval_' + e.dataPoint.id;
      let color = e.dataPoint.color;

      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });

      $(hash).css({ 'background-color': color, color: '#ffffff' });

      if ($(hash).position()) {
        $('#intervalTable').animate({ scrollTop: $(hash).position().top }, 200);
        //				console.log($(hash).position().top);
      }

      //			console.log(e.dataPoint.id + " " + e.dataPoint.x + " " + e.dataPoint.y + " " + e.dataPoint.z + " " + e.dataPoint.name + " " + e.dataPoint.color + " " + e.dataPoint.zone);
    }

    /******* hr Chart  *******/
    setHrChartOptions({
      animationEnabled: false,
      theme: 'light2',
      title: false,
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            //배터리
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                Math.round(e.entries[i].dataPoint.y) +
                '</strong> bpm';
              content += '<br/>';
              content +=
                t('Activity.maxHr') +
                ' ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(maxHr)
                ) +
                '</strong> %';
              content += '<br/>';
            }
          }

          return content;
        }
      },
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        stripLines: stripLineArray
      },
      axisY: {
        margin: 10,
        labelFontSize: 11,
        labelFontColor: '#666666',
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          return e.value + 'bpm';
        },
        gridThickness: 1,
        gridColor: '#eeeeee',
        minimum: 60
      },
      data: [
        {
          type: 'column',
          fillOpacity: 0.35,
          name: t('Dashboard.hrm'),
          yValueFormatString: '# bpm',
          lineThickness: 1,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: hrZonePoints
        }
      ]
    });

    /******* altitude Chart  *******/
    setAltitudeChartOptions({
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: true,
      zoomType: 'x',
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong>m';
              content += '<br/>';

              if (rideType === 'Ride') {
                content +=
                  t('Dashboard.distance') +
                  ' ' +
                  '<strong>' +
                  Math.round(e.entries[i].dataPoint.x / 100) / 10 +
                  '</strong>km';
                content += '<br/>';
              } else {
                content +=
                  t('Dashboard.distance') +
                  ' ' +
                  '<strong>' +
                  Math.round(distanceArray[e.entries[i].dataPoint.x] / 100) /
                    10 +
                  '</strong>km';
                content += '<br/>';
              }
            } else {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                secondsToHmsMatabolismel(e.entries[i].dataPoint.y) +
                '</strong>';
              content += '<br/>';
            }
          }
          return content;
        }
      },
      axisX: {
        gridThickness: 1,
        tickLength: 1,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFontColor: '#666666',
        gridColor: '#eeeeee',
        labelAngle: 0,
        stripLines: stripLineArray,
        labelFormatter: function(e) {
          if (rideType === 'Ride') {
            let navDistance =
              unit === 'metric'
                ? Math.round(e.value / 100) / 10 + 'km'
                : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

            return navDistance;
          } else if (rideType === 'VirtualRide') {
            return secondsToHmsMatabolismel(e.value);
          }
        },
        crosshair: {
          enabled: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsMatabolismel(e.value);
            }
          }
        }
      },
      axisY: {
        maximum: altitudeMax < 100 ? 100 : altitudeMax,
        margin: 10,
        labelFontSize: 11,
        labelFontColor: '#666666',
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: '#eeeeee',
        labelFormatter: function(e) {
          return '';
          /*
					if(altitudeMax > 999) {
						return Math.round(e.value);
					} else {
						return Math.round(e.value) + "m";
					}
					*/
        }
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.altitude'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: altitudePoints
        },
        {
          visible: false,
          type: 'line',
          fillOpacity: 0.4,
          name: t('Dashboard.duration'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: durationPoints
        }
      ]
    });
  }, []);

  const classes = useStyles();

  function handlePdGuideDialogOpen() {
    setPdGuideDialogOpen(true);
  }

  function handlePdGuideDialogClose() {
    setPdGuideDialogOpen(false);
  }

  function MatabolismTable(props) {
    const { stripLines } = props;

    const classes = useStyles();

    /*
		"z7":"스프린트",
		"z6":"무산소",
		"z5":"Vo2Max",
		"z4":"젖산역치",
		"z3":"템포",
		"z2":"지구력",
		"z1":"활성회복",
*/

    let key = 0;
    return (
      <TableContainer
        component={Paper}
        id={'intervalTable'}
        className={classes.tableContainer}>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                {t('Common.intervalName')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Dashboard.avgPower')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.intervalIntensity')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.intervalDuration')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.batteryConsumption')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.kJouls')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: '400px' }}>
            {stripLines.map(interval => {
              if (interval.type === 'range') {
                //							console.log(interval);
                /*
        "intervalName":"인터벌종류",
        "intervalIntensity":"인터벌강도",
        "intervalDuration":"지속시간",
        "batteryConsumption":"배터리소모",         
        "energyConsumption":"에너지"              

								type:"range",
								showOnTop: true,
								startValue:intervalStart,
								endValue:intervalEnd,                
								color:color,
								opacity:0.2,
								power:avgPower,
								name: zoneAnimal + " " + zone, 
								Matabolismel:indexMatabolismel,
								zone:zone
*/

                key++;

                return (
                  <TableRow
                    key={interval.startValue}
                    id={'interval_' + interval.startValue}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row">
                      {interval.zoneAnimal +
                        ' ' +
                        interval.name +
                        ' ' +
                        t('Common.interval') +
                        ' (' +
                        interval.Matabolismel +
                        ')'}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {interval.power} W
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round((100 * interval.power) / activityFTP)} %
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {secondsToHms(interval.duration)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round(10 * interval.usage) / 10} %
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round(0.01 * interval.power * interval.duration) /
                        10}{' '}
                      kJ
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function MatabolismList(props) {
    const { stripLines } = props;
    let key = 0;

    return (
      <div
        style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '320px' }}>
        <Grid container spacing={1} className={classes.gridContainer}>
          {stripLines.map(interval => {
            key++;

            if (interval.type === 'range') {
              return (
                <Grid item lg={12} md={12} sm={12} key={key}>
                  <Box className={classes.intervalGrid}>
                    <div>color : {interval.color}</div>
                    <div>
                      time :{' '}
                      {secondsToHms(interval.endValue - interval.startValue)}
                    </div>
                    <div>power : {interval.power}</div>
                  </Box>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
    );
  }

  /******* powerzone Chart  *******/
  const toolTip = {
    shared: true,
    contentFormatter: function(e) {
      let content = secondsToHms(e.entries[0].dataPoint.x);
      content += ' ' + t('Activity.peakPower') + '<br/>';

      for (let i = 0; i < e.entries.length; i++) {
        if (i == 0) {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        } else {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        }
      }
      return content;
    }
  };

  //awc % 포함하기
  let toolTipWatts = {
    shared: true,
    contentFormatter: function(e) {
      let content = ' ';
      for (let i = 0; i < e.entries.length; i++) {
        content +=
          e.entries[i].dataSeries.name +
          ' ' +
          '<strong>' +
          e.entries[i].dataPoint.y +
          '</strong>w';
        content += '<br/>';
        content +=
          'ZONE ' + '<strong>' + e.entries[i].dataPoint.zone + '</strong>';
        content += '<br/>';
      }
      return content;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.streamWrap}></div>
      <CanvasJSChart
        className={classes.powerContainer}
        containerProps={containerPower}
        options={wattsChartOptions}
        onRef={ref => setPowerChart(ref)}
      />
      <CanvasJSChart
        containerProps={containerHr}
        options={hrChartOptions}
        onRef={ref => setHrChart(ref)}
      />
      <CanvasJSChart
        containerProps={containerAltitude}
        options={altitudeChartOptions}
        onRef={ref => setAltitudeChart(ref)}
      />
      <MatabolismTable stripLines={stripLines} style={{ marginTop: '12px' }} />

      <Dialog open={pdGuideDialogOpen} onClose={handlePdGuideDialogClose}>
        <DialogTitle>{t('Dashboard.powerProfile')}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}></DialogContent>
      </Dialog>
    </React.Fragment>
  );

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function secondsToHmsMatabolismel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function sortJSON(data, key, type) {
    if (type == undefined) {
      type = 'asc';
    }
    return data.sort(function(a, b) {
      let x = a[key];
      let y = b[key];
      if (type == 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      } else if (type == 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }
    });
  }

  function Matabolism10Time(wattsArray, awcArray, wattsPoints) {
    let moving10Points = [];
    let moving10sum = 0;
    let index = 0;
    let bubbleArray = [];
    let intervalOn = false;
    let intervalStart = 0;
    let intervalEnd = 0;
    let stripLines = [];

    if (wattsArray.length > 10) {
      for (let i = 0; i < 10; i++) {
        moving10sum += wattsArray[i];
      }
    }

    index = 5;

    _.each(wattsPoints, function(point, key) {
      let color = '#ffffff';
      let zone = '';
      let x_key = point.x;

      if (key > 5) {
        let this_sum =
          moving10sum - wattsArray[index - 5] + wattsArray[index + 5];
        let this_avg = Math.round(this_sum / 10);

        moving10sum = this_sum;

        if (this_avg > 0 && this_avg <= activityFTP * 1.0) {
          color = 'transparent';
          zone = 'Low';

          //인터벌 종료 지점에서 stripline 종료 후 정보 입력
          if (intervalOn === true) {
            intervalEnd = point.x;

            //major interval
            let filter = 5;
            let sum = 0;
            let avgPower = 0;
            let usage = 0;
            let indexMatabolismel = 'Z4';
            let zoneAnimal = '🐗';
            let duration = 0;

            if (rideType === 'Ride') filter = 100;

            if (intervalEnd - intervalStart >= filter) {
              //여기서 kj과 몇% 달았는지 계산
              let intervalStartKey = findKeyByValue(
                distanceArray,
                intervalStart
              );
              let intervalEndKey = findKeyByValue(distanceArray, intervalEnd);

              if (rideType === 'Ride') {
                usage = awcArray[intervalStartKey] - awcArray[intervalEndKey];

                let cnt = 0;
                for (let i = intervalStartKey; i < intervalEndKey; i++) {
                  if (wattsArray[i] > 0) {
                    sum += wattsArray[i];
                    cnt++;
                  }
                }

                avgPower = Math.round(sum / cnt);
                duration = intervalEndKey - intervalStartKey;
              } else {
                usage = awcArray[intervalStart] - awcArray[intervalEnd];

                for (let i = intervalStart; i < intervalEnd; i++) {
                  sum += wattsArray[i];
                }

                avgPower = Math.round(sum / (intervalEnd - intervalStart));
                duration = intervalEnd - intervalStart;
              }

              if (avgPower < activityFTP * 1.05) {
                color = '#ffd357';
                zone = 'Threshold';
                indexMatabolismel = 'z4';
                zoneAnimal = '🐗';
              } else if (avgPower < activityFTP * 1.2) {
                color = '#ffa75c';
                zone = 'Vo2max';
                indexMatabolismel = 'z5';
                zoneAnimal = '🐺';
              } else if (avgPower < activityFTP * 1.5) {
                color = '#ff7e75';
                zone = 'Anaerobic';
                indexMatabolismel = 'z6';
                zoneAnimal = '🐆';
              } else if (avgPower < activityFTP * 2.0) {
                color = '#ff7e75';
                zone = 'Anaerobic';
                indexMatabolismel = 'z7';
                zoneAnimal = '🐯';
              } else {
                color = '#f06292';
                zone = 'Sprint';
                indexMatabolismel = 'z7';
                zoneAnimal = '🦁';
              }

              stripLines.push({
                type: 'range',
                name: t('Common.' + indexMatabolismel),
                showOnTop: true,
                startValue: intervalStart,
                endValue: intervalEnd,
                duration: duration,
                color: color,
                opacity: 0.2,
                power: avgPower,
                usage: usage,
                zoneAnimal: zoneAnimal,
                Matabolismel: indexMatabolismel,
                zone: zone
              });

              bubbleArray.push({
                x: Math.round((intervalStart + intervalEnd) / 2),
                y: avgPower + 160,
                z: usage > 10 ? usage : 5,
                id: intervalStart,
                name: avgPower,
                color: color,
                indexMatabolismel: indexMatabolismel,
                zone: zone
              });

              stripLines.push({
                type: 'dash',
                value: intervalStart,
                color: color,
                lineDashType: 'dash'
              });
              stripLines.push({
                type: 'dash',
                value: intervalEnd,
                color: color,
                lineDashType: 'dash'
              });
            }

            intervalOn = false;
          }
        } else if (this_avg > activityFTP * 1.0) {
          if (this_avg < activityFTP * 1.05) {
            color = '#ffd357';
            zone = 'Threshold';
          } else if (this_avg < activityFTP * 1.2) {
            color = '#ffa75c';
            zone = 'Vo2max';
          } else if (this_avg < activityFTP * 1.5) {
            color = '#ff7e75';
            zone = 'Anaerobic';
          } else {
            color = '#f06292';
            zone = 'Sprint';
          }

          //인터벌 시작 지점에서 stripline 생성
          if (intervalOn === false) {
            intervalStart = point.x;

            intervalOn = true;
          }
        }

        moving10Points.push({
          x: point.x,
          y: wattsArray[index],
          color: color,
          zone: zone,
          id: intervalStart
        });
        //				moving10Points.push({x:point.x, y:this_avg, z: 0, color:color, zone:zone});

        index++;
      } else {
        //				moving10Points.push({x:point.x, y:wattsArray[index], color:color, zone:zone});
        //
      }
    });

    bubbleArray.push({ x: 0, y: 0, z: 0, color: 'transparent' }); //기본값
    sortJSON(stripLines, 'power', 'asc');

    return {
      moving10Points: moving10Points,
      stripLineArray: stripLines,
      bubbleArray: bubbleArray
    };
  }

  function ArrangeHrZone(heartrateArray, heartratePoints, zoneType) {
    let hrZoneArr = [];
    let hrZonePoints = [];

    let z1_sum = 0;
    let z2_sum = 0;
    let z3_sum = 0;
    let z4_sum = 0;
    let z5_sum = 0;

    //zoneType 마다 다르게
    if (zoneType === 0) {
      //			console.log(maxHr);

      _.each(heartratePoints, function(point, index) {
        let color = '#f5f5f5';
        let zone = 0;

        if (point.y > 0 && point.y <= maxHr * 0.6) {
          color = '#cccccc';
          zone = 1;
          z1_sum++;
        } else if (point.y > maxHr * 0.6 && point.y <= maxHr * 0.7) {
          color = '#76b5e8';
          zone = 2;
          z2_sum++;
        } else if (point.y > maxHr * 0.7 && point.y <= maxHr * 0.8) {
          color = '#b0d36d';
          zone = 3;
          z3_sum++;
        } else if (point.y > maxHr * 0.8 && point.y <= maxHr * 0.9) {
          color = '#ffd357';
          zone = 4;
          z4_sum++;
        } else if (point.y > maxHr * 0.9) {
          color = '#ffa75c';
          zone = 5;
          z5_sum++;
        }

        hrZonePoints.push({ x: point.x, y: point.y, color: color, zone: zone });
      });
    }

    let total_sum = 1 + (z1_sum + z2_sum + z3_sum + z4_sum + z5_sum);

    let z1p = Math.round((1000 * z1_sum) / total_sum) / 10;
    let z2p = Math.round((1000 * z2_sum) / total_sum) / 10;
    let z3p = Math.round((1000 * z3_sum) / total_sum) / 10;
    let z4p = Math.round((1000 * z4_sum) / total_sum) / 10;
    let z5p = Math.round((1000 * z5_sum) / total_sum) / 10;

    let zone_sum_arr = Array(z5_sum, z4_sum, z3_sum, z2_sum, z1_sum);
    let zone_per_arr = Array(z5p, z4p, z3p, z2p, z1p);

    //		console.log(total_sum);

    _.each(zone_per_arr, function(val, index) {
      let color = '#ffffff';

      if (5 - index === 1) {
        color = '#cccccc';
      } else if (5 - index === 2) {
        color = '#76b5e8';
      } else if (5 - index === 3) {
        color = '#b0d36d';
      } else if (5 - index === 4) {
        color = '#ffd357';
      } else if (5 - index === 5) {
        color = '#ffa75c';
      }

      hrZoneArr.push({ y: val, label: 'ZONE ' + (5 - index), color: color });
    });

    return { hrZoneArr: hrZoneArr, hrZonePoints: hrZonePoints };
  }
};

MatabolismChart.propTypes = {
  className: PropTypes.string
};

export default withRouter(MatabolismChart);
