import React from 'react';
//import uuid from 'uuid/v1';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';

import TimelineIcon from '@mui/icons-material/Timeline';
import EqualizerIcon from '@mui/icons-material/Equalizer';

//import AddBoxIcon from '@mui/icons-material/AddBox';

const useStyles = makeStyles(theme => ({
  title: { backgroundColor: theme.palette.background.colored.main },
  dialog: { width: '100%', borderBottom: '1px #dddddd solid' },
  inline: { fontWeight: 700, display: 'inline' },
  prListItem: {
    margin: '16px'
  },
  descBox: {
    padding: '16px',
    borderRadius: '3px',
    marginTop: '10px',
    backgroundColor: '#f5f5f5',
    fontWeight: 500,
    fontSize: '13px'
  },
  descBox2: {
    padding: '16px',
    borderRadius: '3px',
    margin: '16px',
    backgroundColor: '#f5f5f5',
    fontWeight: 500,
    fontSize: '13px'
  }
}));

const ResultDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, open, resultList } = props;

  //  alert(JSON.stringify(resultList[0].power_table));
  //  alert(props.resultList);

  let userResult = resultList[0].user_result;
  let powerTable = resultList[0].power_table;

  function handleClose() {
    onClose(false);
  }

  const getUserResult = userResult => {
    if (userResult.resultSelect == 'estimateCdA') {
      return (
        <div key={9999}>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <React.Fragment>
                  {t('Simulation.CdA1')}
                  <Typography
                    component="span"
                    color="secondary"
                    className={classes.inline}>
                    {userResult.power}
                  </Typography>
                  {t('Simulation.CdA2')}
                  <Typography
                    component="span"
                    color="secondary"
                    className={classes.inline}>
                    {userResult.time_string}
                  </Typography>
                  {t('Simulation.CdA3')}
                  <Typography
                    component="span"
                    color="secondary"
                    className={classes.inline}>
                    {userResult.CdA}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <div className={classes.descBox}>
                  <Typography component="div" color="primary">
                    {t('Simulation.CdABoxHeader')}
                  </Typography>
                  <Typography
                    component="pre"
                    style={{
                      fontSize: '12px',
                      marginTop: '8px',
                      overflow: 'auto',
                      whiteSpace: 'pre-wrap'
                    }}>
                    {t('Simulation.CdABoxContent')}
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </div>
      );
    } else if (userResult.resultSelect == 'estimatePR') {
      let w_pr = resultList[0].workable_pr;
      let i_pr = resultList[0].ideal_pr;

      return (
        <div key={9999}>
          <ListItem>
            <ListItemIcon style={{ marginLeft: '16px' }}>
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('Simulation.PR1')}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="primary"
                    style={{ margin: '8px 12px 0 0' }}>
                    {w_pr.power} W
                  </Typography>
                  <Typography
                    component="span"
                    color="secondary"
                    style={{ margin: '8px 12px 0 0' }}>
                    {w_pr.time_string}
                  </Typography>
                  <Typography component="span" style={{ fontSize: '12px' }}>
                    (±{w_pr.e_range[1]}W, ±{w_pr.e_range[0]})
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon style={{ marginLeft: '16px' }}>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('Simulation.PR2')}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="primary"
                    style={{ margin: '18px 12px 0 0' }}>
                    {i_pr.power} W
                  </Typography>
                  <Typography
                    component="span"
                    color="secondary"
                    style={{ margin: '18px 12px 0 0' }}>
                    {i_pr.time_string}
                  </Typography>
                  <Typography component="span" style={{ fontSize: '12px' }}>
                    (±{i_pr.e_range[1]}W, ±{i_pr.e_range[0]})
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Box className={classes.descBox2}>
            <Typography component="div" color="primary">
              {t('Simulation.PRBoxHeader')}
            </Typography>
            <Typography
              component="pre"
              style={{
                fontSize: '12px',
                marginTop: '8px',
                overflow: 'auto',
                whiteSpace: 'pre-wrap'
              }}>
              {t('Simulation.PRBoxContent')}
            </Typography>
          </Box>
        </div>
      );
    } else if (userResult.power) {
      return (
        <div key={9999}>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    component="span"
                    color="secondary"
                    className={classes.inline}>
                    {userResult.power}
                  </Typography>
                  {' watts, '}
                  <Typography
                    component="span"
                    color="secondary"
                    className={classes.inline}>
                    {userResult.time_string}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  {userResult.speed +
                    'km/h, ' +
                    userResult.wkg +
                    'w/kg, -' +
                    Number(userResult.fat_burn * 1000).toFixed(2) +
                    'g'}
                </React.Fragment>
              }
            />
          </ListItem>
        </div>
      );
    } else {
      return '';
    }
  };

  const getPowerTable = powerTable => {
    if (powerTable) {
      return (
        <div>
          {powerTable.map((item, i) => (
            <div key={i}>
              <ListItem>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        color="primary"
                        className={classes.inline}>
                        {item.power}
                      </Typography>
                      {' watts, '}
                      <Typography
                        component="span"
                        color="primary"
                        className={classes.inline}>
                        {item.time_string}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      {item.speed +
                        'km/h, ' +
                        item.wkg +
                        'w/kg, -' +
                        Number(item.fat_burn * 1000).toFixed(2) +
                        'g'}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </div>
          ))}
        </div>
      );
    } else {
      return '';
    }
  };

  /*
  function handleListItemClick(value) {
    onClose(value);
  }
*/

  return (
    <Dialog onClose={handleClose} open={open} className={classes.dialog}>
      <DialogTitle className={classes.title}>
        <Typography
          color="textPrimary"
          component="span"
          className={classes.inline}>
          {userResult.distance + 'km ' + userResult.gradeCalc + '%'}
        </Typography>
        {' 코스에 대한 시뮬레이션'}
      </DialogTitle>
      <List className={classes.dialog}>
        {open && getUserResult(userResult)}
        <Divider />
        {open && getPowerTable(powerTable)}
      </List>
    </Dialog>
  );
};

ResultDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
  //  selectedValue: PropTypes.string.isRequired,
};

export default ResultDialog;
