import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Divider, Dialog, DialogTitle, DialogContent } from '@mui/material';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import cloneDeep from 'lodash/cloneDeep';

//Chart 추가
import CanvasJSReact from '../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 560,
    overflow: 'visible',
    margin: '0px 0px 0px 0px',
    padding: '10px 4px 24px 4px',
    [theme.breakpoints.down('xl')]: {
      height: 520
    },
    [theme.breakpoints.down('lg')]: {
      padding: '10px 4px 24px 4px',
      height: 440
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 4px 24px 4px',
      height: 360
    }
  },
  streamWrap: {
    padding: '8px 4px',
    marginBottom: '12px',
    borderBottom: '1px #dddddd solid'
  },
  streamSummary: {
    background: '#f5f5f5',
    borderRadius: '4px',
    marginTop: '16px',
    padding: '6px 12px'
  },
  summaryTitle: {
    lineHeight: '32px',
    fontWeight: 500,
    fontSize: '15px'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  }
}));

const PowerCurveChart = props => {
  //다국어적용
  const { t } = useTranslation(['page']);
  const theme = useTheme();

  const extraInfo = props.extraInfo;

  //unit default
  const unit = extraInfo.unit ? extraInfo.unit : 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const { pdcData, ftp, streamDataJson, wkg, weight } = props;
  const [powerCurveData, setPowerCurveData] = useState({});
  const [pdGuideDialogOpen, setPdGuideDialogOpen] = React.useState(false);
  const [wattsChartOptions, setWattsChartOptions] = useState({});
  const [altitudeChartOptions, setAltitudeChartOptions] = useState({});
  const [powerChart, setPowerChart] = useState(null);
  const [altitudeChart, setAltitudeChart] = useState(null);

  const streamOrg = streamDataJson.stream;
  const streamData = streamDataJson.stream_json;

  const timeArray = streamOrg.time;
  const wattsArray = streamOrg.watts;
  const distanceArray = streamOrg.distance;
  const wattsPoints = streamData.watts;
  const awcPoints = streamData.awc;

  const altitudePoints = streamData.altitude;
  const durationPoints = streamData.duration;

  const wattsMax = streamData.watts_max,
    wattsMaxIndex = streamData.watts_max_index;

  const activityFTP = streamDataJson.ftp;
  const activityName = streamDataJson.activity_name;
  const altitudeMax = streamDataJson.altitude_max;
  const stravaDescription = streamDataJson.strava_description;
  const streamLength = streamDataJson.obj_length;
  const movingDistance = streamDataJson.distance;
  const movingTime = streamDataJson.moving_time;
  const rideType = streamDataJson.ride_type;

  const mmp_all = pdcData['best_data']['power_all'];

  const containerCurve = {
    width: '100%',
    height: '320px',
    margin: 'auto'
  };

  const containerPower = {
    width: '100%',
    height: '120px',
    margin: 'auto'
  };

  const containerAltitude = {
    width: '100%',
    height: '120px',
    margin: 'auto'
  };

  useEffect(() => {
    const { peak, peak_list, peak_sec, maximum_duration } = CalcPowerCurve(
      wattsArray
    );

    setPowerCurveData({
      peak_list: peak_list,
      peak_sec: peak_sec,
      maximum_duration: maximum_duration
    });

    /******* watts Chart  *******/
    setWattsChartOptions({
      animationEnabled: false,
      theme: 'light2', // "light1", "light2", "dark1", "dark2",
      backgroundColor: theme.palette.chart.background,
      title: false,
      toolTip: toolTip,
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        stripLines: [],
        crosshair: {
          enabled: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          }
        }
      },
      axisY: {
        margin: 10,
        maximum: Math.round(wattsMax),
        labelFontSize: 11,
        labelFontColor: theme.palette.chart.text.primary,
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#4db6ac',
        labelFormatter: function(e) {
          if (e.value > 999) {
            return e.value;
          } else {
            return e.value + 'w';
          }
        },
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        minimum: 1
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.battery'),
          yValueFormatString: '# %',
          lineThickness: 1,
          color: '#dddddd',
          legendMarkerType: 'square',
          dataPoints: awcPoints
        },
        {
          type: 'area',
          fillOpacity: 0.8,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 1,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: wattsPoints
        }
      ]
    });

    /******* altitude Chart  *******/
    setAltitudeChartOptions({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      //			toolTip: toolTip,
      axisX: {
        gridThickness: 1,
        tickLength: 1,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFontColor: theme.palette.chart.text.primary,
        gridColor: theme.palette.chart.secondary.axis.grid,
        labelAngle: 0,
        stripLines: [],
        labelFormatter: function(e) {
          if (rideType === 'Ride') {
            let navDistance =
              unit === 'metric'
                ? Math.round(e.value / 100) / 10 + 'km'
                : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

            return navDistance;
          } else if (rideType === 'VirtualRide') {
            return secondsToHmsLabel(e.value);
          }
        },
        crosshair: {
          enabled: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          }
        }
      },
      axisY: {
        margin: 10,
        labelFontSize: 11,
        labelFontColor: theme.palette.chart.text.primary,
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        tickColor: theme.palette.chart.secondary.axis.grid,
        labelFormatter: function(e) {
          return '';

          if (altitudeMax > 999) {
            return Math.round(e.value);
          } else if (altitudeMax < 75) {
            return Math.round(e.value) + ' m';
          } else {
            return Math.round(e.value) + 'm';
          }
        }
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.altitude'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: altitudePoints
        },
        {
          visible: false,
          type: 'line',
          fillOpacity: 0.4,
          name: t('Dashboard.duration'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: durationPoints
        }
      ]
    });
  }, []);

  const classes = useStyles();

  function handlePdGuideDialogOpen() {
    setPdGuideDialogOpen(true);
  }

  function handlePdGuideDialogClose() {
    setPdGuideDialogOpen(false);
  }

  function displayPeakSegment(sv, ev) {
    let startValue = sv;
    let endValue = ev;

    if (rideType !== 'Ride') {
      startValue = timeArray[sv];
      endValue = timeArray[ev];
    }

    let stripLength = powerChart.axisX[0].stripLines.length;

    if (stripLength > 0) {
      powerChart.axisX[0].set('stripLines', []);
      altitudeChart.axisX[0].set('stripLines', []);
    }

    //시간 거리로 변환
    if (rideType === 'Ride') {
      startValue = Math.round(distanceArray[sv]);
      endValue = Math.round(distanceArray[ev]);
    }

    powerChart.axisX[0].addTo('stripLines', {
      showOnTop: true,
      startValue: startValue,
      endValue: endValue,
      color: '#ffd357',
      opacity: 0.2
    });

    powerChart.axisX[0].addTo('stripLines', {
      value: startValue,
      color: '#666666',
      lineDashType: 'dash'
    });

    powerChart.axisX[0].addTo('stripLines', {
      value: endValue,
      color: '#666666',
      lineDashType: 'dash'
    });

    altitudeChart.axisX[0].addTo('stripLines', {
      showOnTop: true,
      startValue: startValue,
      endValue: endValue,
      color: '#ffd357',
      opacity: 0.2
    });

    altitudeChart.axisX[0].addTo('stripLines', {
      value: startValue,
      color: '#666666',
      lineDashType: 'dash'
    });

    altitudeChart.axisX[0].addTo('stripLines', {
      value: endValue,
      color: '#666666',
      lineDashType: 'dash'
    });
  }

  function hidePeakSegment() {
    let stripLength = powerChart.axisX[0].stripLines.length;
    if (stripLength > 0) {
      powerChart.axisX[0].set('stripLines', []);
      altitudeChart.axisX[0].set('stripLines', []);
    }
  }

  /******* powercurve Chart  *******/
  const toolTip = {
    shared: true,
    contentFormatter: function(e) {
      let content = secondsToHms(e.entries[0].dataPoint.x);
      content += ' ' + t('Activity.peakPower') + '<br/>';

      for (let i = 0; i < e.entries.length; i++) {
        if (i == 0) {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        } else {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        }
      }
      return content;
    }
  };

  const powerCurveOptions = {
    backgroundColor: theme.palette.chart.background,
    animationEnabled: false,
    theme: 'light2',
    title: false,
    toolTip: toolTip,
    //height: 100,
    axisX: {
      logarithmic: true,
      logarithmBase: 2,
      labelAutoFit: false,
      suffix: '',
      lineThickness: 1,
      lineColor: '#4db6ac',
      gridThickness: 1,
      gridColor: theme.palette.chart.secondary.axis.grid,
      labelFontSize: 11,
      labelFontColor: '#4db6ac',
      labelFormatter: function(e) {
        return secondsToHmsLabel(e.value);
      },
      labelAngle: 0,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        lineColor: '#999999',
        labelFormatter: function(e) {
          return secondsToHms(e.value);
        },
        updated: function(e) {
          let sv = powerCurveData.peak_sec[e.value];
          let ev = powerCurveData.peak_sec[e.value] + e.value;

          displayPeakSegment(sv, ev);
        },
        hidden: function(e) {
          hidePeakSegment();
        }
      },
      maximum: powerCurveData.maximum_duration
    },
    axisY: {
      margin: 10,
      labelFontSize: 11,
      labelFontColor: theme.palette.chart.text.primary,
      labelPlacement: 'inside',
      lineThickness: 1,
      tickLength: 2,
      lineColor: '#4db6ac',
      suffix: 'w',
      gridThickness: 1,
      gridColor: theme.palette.chart.secondary.axis.grid,
      minimum: 1
    },
    legend: {
      verticalAlign: 'top',
      fontSize: 16,
      dockInsidePlotArea: true
    },
    data: powerCurveData.peak_list
  };

  powerCurveOptions.axisY.stripLines = [
    {
      value: activityFTP,
      label: '⚡ FTP ' + activityFTP + ' W',
      labelPlacement: 'inside',
      showOnTop: true,
      labelFontSize: 13,
      labelAlign: 'far',
      labelFontColor: '#f06292',
      thickness: 1,
      color: '#f06292',
      lineDashType: 'dash'
    }
  ];

  /*
	wattsChartOptions.axisY.stripLines = [{
			value: activityFTP, 
			label:'FTP ' + activityFTP + ' W', 
			labelPlacement:"inside",
			labelFontSize: 11,
			labelAlign: "far",
			labelFontColor: '#f06292',
			thickness:1, 
			color:'#f06292',					
			lineDashType:'dash'
	}];

	wattsChartOptions.axisX.stripLines = [{
			value:wattsPoints[wattsMaxIndex].x, 
			label:wattsMax, 
			thickness:1, 
			color:'#f06292',
			labelAlign: 'near',
			lineDashType:'dash',
			labelFormatter: function (e) {
				return "";
			},
	}];	
*/

  //awc % 포함하기
  let toolTipWatts = {
    shared: true,
    contentFormatter: function(e) {
      let content = ' ';
      for (let i = 0; i < e.entries.length; i++) {
        if (i == 0) {
          content +=
            e.entries[i].dataSeries.name +
            ' ' +
            '<strong>' +
            Math.round((1000 * e.entries[i].dataPoint.y) / wattsMax) / 10 +
            '</strong>%';
          content += '<br/>';
        } else {
          content +=
            e.entries[i].dataSeries.name +
            ' ' +
            '<strong>' +
            e.entries[i].dataPoint.y +
            '</strong>w';
          content += '<br/>';
        }
      }
      return content;
    }
  };

  wattsChartOptions.toolTip = toolTipWatts;

  //console.log(powercurve_options);
  return (
    <React.Fragment>
      <div className={classes.streamWrap}></div>
      <CanvasJSChart
        containerProps={containerCurve}
        options={powerCurveOptions}
      />
      <CanvasJSChart
        containerProps={containerPower}
        options={wattsChartOptions}
        onRef={ref => setPowerChart(ref)}
      />
      <CanvasJSChart
        containerProps={containerAltitude}
        options={altitudeChartOptions}
        onRef={ref => setAltitudeChart(ref)}
      />

      <Dialog open={pdGuideDialogOpen} onClose={handlePdGuideDialogClose}>
        <DialogTitle>{t('Dashboard.powerProfile')}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}></DialogContent>
      </Dialog>
    </React.Fragment>
  );

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function PowerCurveOption() {}

  function CalcPowerCurve(powerStream) {
    const majorPowerArr = {
      5: 0,
      6: 0,
      10: 0,
      12: 0,
      15: 0,
      20: 0,
      30: 0,
      45: 0,
      60: 0,
      90: 0,
      120: 0,
      150: 0,
      180: 0,
      210: 0,
      240: 0,
      270: 0,
      300: 0,
      330: 0,
      360: 0,
      390: 0,
      420: 0,
      450: 0,
      480: 0,
      510: 0,
      540: 0,
      600: 0,
      660: 0,
      720: 0,
      780: 0,
      840: 0,
      900: 0,
      960: 0,
      1020: 0,
      1080: 0,
      1140: 0,
      1200: 0,
      1500: 0,
      1800: 0,
      2100: 0,
      2400: 0,
      2700: 0,
      3000: 0,
      3300: 0,
      3600: 0,
      4200: 0,
      4800: 0,
      5400: 0,
      6000: 0,
      6600: 0,
      7200: 0,
      9000: 0,
      10800: 0,
      12600: 0,
      14400: 0
    };

    const peak = cloneDeep(majorPowerArr);
    const peak_sec = cloneDeep(majorPowerArr);

    let peak_list = [];
    let peak_arr = [];
    let mmp_arr = [];
    let maximum_duration = 0;

    const p_sum = cloneDeep(majorPowerArr);
    const p_avg = cloneDeep(majorPowerArr);
    const p_sec = cloneDeep(majorPowerArr);

    //최적화 알고리즘
    //시작점의 시간대별 파워 초기세팅
    //초기 열차칸을 만들어놓고 한칸씩 이동하면서 더하고 빼고

    function InitPowerAvg(powerStream, majorPowerArr) {
      //		let peak_place = Array();

      for (let i = 0; i < powerStream.length; i++) {
        _.each(majorPowerArr, function(index, dur) {
          if (i < dur && powerStream.length > dur)
            p_sum[String(dur)] += powerStream[i];
        });

        if (i >= 14400) break;
      }

      _.each(majorPowerArr, function(index, dur) {
        p_avg[String(dur)] = Math.round(p_sum[String(dur)] / dur);
      });

      return p_avg;
    }

    const init_avg = InitPowerAvg(powerStream, majorPowerArr);

    //배열 돌려가면서 최대파워 업데이트 + 위치도 찾기
    //이전 합에서 하나전 것을 빼고 다음것을 더하는게 가장 최적
    //따라서 1부터 시작
    for (let j = 1; j < powerStream.length; j++) {
      _.each(majorPowerArr, function(index, dur) {
        if (j < powerStream.length - dur) {
          //합계 업데이트
          let this_sum =
            p_sum[String(dur)] - powerStream[j - 1] + powerStream[dur - 1 + j];
          let this_peak = Math.round(this_sum / dur);

          if (this_peak > peak[String(dur)]) {
            peak[String(dur)] = Math.round(p_sum[String(dur)] / dur);
            peak_sec[String(dur)] = j;
          }

          p_sum[String(dur)] = this_sum;
          p_avg[String(dur)] = Math.round(this_sum / dur);
          p_sec[String(dur)] = j;
        }
      });
    }

    _.each(peak, function(power, duration) {
      if (power > 0) {
        peak_arr.push({ x: Math.round(duration), y: Math.round(power) });
        maximum_duration = duration;

        if (mmp_all[duration] > 0) {
          mmp_arr.push({
            x: Math.round(duration),
            y: Math.round(mmp_all[duration])
          });
        }
      }
    });

    //마지막 mmp_arr 잇기
    if (peak_arr[peak_arr.length - 1].x !== mmp_arr[mmp_arr.length - 1].x) {
      mmp_arr.push({
        x: peak_arr[peak_arr.length - 1].x,
        y: peak_arr[peak_arr.length - 1].y
      });
    }

    /*
  z1: {backgroundColor:'#aaaaaa',color:'#ffffff'},
  z2: {backgroundColor:'#76b5e8',color:'#ffffff'},
  z3: {backgroundColor:'#b0d36d',color:'#ffffff'},
  z4: {backgroundColor:'#ffd357',color:'#ffffff'},
  z5: {backgroundColor:'#ffa75c',color:'#ffffff'},
  z6: {backgroundColor:'#ff7e75',color:'#ffffff'},
  z7: {backgroundColor:'#f06292',color:'#ffffff'},
*/
    //['#BCDBF3','#4db6ac','#f8bbd0','#f06292'],
    //	console.log(peak_arr);
    peak_list.push({
      name: t('Activity.this'),
      type: 'area',
      connectNullData: true,
      color: '#4db6ac',
      lineColor: '#4db6ac',
      indexLabelFontColor: '#4db6ac',
      indexLabelBackgroundColor: '#CCCCCC',
      lineThickness: 2,
      fillOpacity: '0.6',
      markerSize: 6,
      markerColor: '#4db6ac',
      markerBorderColor: '#ffffff',
      markerBorderThickness: 2,
      //		indexLabelPlacement: "inside",
      //			mouseover: displayPeakSegment,
      //			mouseout: removePeakSegment,
      dataPoints: peak_arr
    });

    peak_list.unshift({
      name: t('Activity.best'),
      type: 'area',
      connectNullData: true,
      color: '#ff7e75',
      indexLabelFontColor: '#ff7e75',
      indexLabelBackgroundColor: '#CCCCCC',
      lineColor: '#f06292',
      lineThickness: 2,
      fillOpacity: '0.4',
      markerType: 'square',
      markerSize: 6,
      markerColor: '#f06292',
      markerBorderColor: '#ffffff',
      markerBorderThickness: 2,
      //		indexLabelPlacement: "inside",
      dataPoints: mmp_arr
    });

    return {
      peak: peak,
      peak_list: peak_list,
      peak_sec: peak_sec,
      maximum_duration: maximum_duration
    };
  }
};

PowerCurveChart.propTypes = {
  className: PropTypes.string
};

export default withRouter(PowerCurveChart);
