import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Avatar, Button, Hidden, Typography, Link, Box } from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import auth from '../../../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 'fit-content',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 5,
    paddingRight: '0px'
  },
  avatar: {
    width: 24,
    height: 24,
    border: 'none',
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center'
  },
  name: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    // color: '#444444',
    cursor: 'pointer',
    marginLeft: '6px',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {extraInfo !== null ? (
        <React.Fragment>
          <Avatar
            alt={userInfo.display_name}
            className={classes.avatar}
            component={RouterLink}
            src={extraInfo.profile_image}
            to="/settings/profile"
          />
          <Hidden lgDown>
            <Box onClick={() => history.push('/settings/profile')}>
              <Typography className={classes.name}>
                {userInfo.display_name}
              </Typography>
            </Box>
          </Hidden>
        </React.Fragment>
      ) : (
        <div>
          <AccountCircleIcon htmlColor="#444444" className={classes.avatar} />
        </div>
      )}
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
