import React from 'react';
import { makeStyles } from '@mui/styles';
import { withStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '16px',
    color: '#73838b',
    fontWeight: '500',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  }
}));

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(AccordionDetails);

export default function CustomizedExpansionPanels() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          Guide for testers
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>

      <ExpansionPanel
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header">
          <Typography style={{ fontWeight: '700' }}>
            라이덕은 베타테스트 중입니다
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            라이덕은 사이클링 데이터를 분석해주고, 라이더에게 동기부여를 해주는
            서비스입니다.<div></div>
            현재는 베타테스트를 진행하고 있으며, 테스트 기간중에는 서비스가
            완전하지 않기에 여러가지 오류가 발생할 수 있습니다.<div></div>
            따라서 반드시 테스트 버전이라는 것을 인지하시고 각종 오류나 불편함을
            인내하실 수 있는 사용자만 사용을 부탁드립니다.<div></div>
            무리한 요구나 완성도 높은 서비스를 이용하고자 하시는 분들은 정식
            오픈을 기다려주시기 바랍니다.<div></div>
            <div style={{ marginBottom: '10px' }}></div>
            -파워미터나 스마트롤러가 없으면 이용이 불가능합니다.<div></div>
            -최근 3개월간 라이딩 기록이 없으면 분석이 안됩니다.<div></div>
            -전기자전거, 스피닝바이크 등으로는 이용이 여렵습니다.<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}>
        <ExpansionPanelSummary
          aria-controls="panel2d-content"
          id="panel2d-header">
          <Typography>화면에 아무것도 뜨지 않습니다. 오류가 있어요.</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            새로고침을 반복해보세요. 그래도 안된다면 임시커뮤니티
            단톡방(개별톡)에 다음과 같은 양식으로 문의를 주세요.<div></div>
            기본적인 정보를 알려주지 않으시면 조치를 해드리기 어렵습니다.
            <div></div>
            <div style={{ marginBottom: '10px' }}></div>
            -라이덕 아이디: <div></div>
            -스트라바 연동여부: <div></div>
            -파워미터/스마트롤러 사용여부: <div></div>
            -여러개의 아이디로 중복가입여부: <div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}>
        <ExpansionPanelSummary
          aria-controls="panel3d-content"
          id="panel3d-header">
          <Typography>
            스트라바 활동에 실시간으로 분석정보가 업데이트 안됩니다.
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            스트라바에 자동으로 분석자료가 업데이트 되는 데는 최대 15분까지 걸릴
            수 있습니다.<div></div>
            간혹, 활동을 수정하는 시간과 분석자료가 업데이트 되는 시간이 겹쳐
            업데이트가 안될 수 있습니다.<div></div>
            그럴 때는 대시보드-파워커브-활동명 을 클릭하시면 해당 분석내용이
            그대로 있으니 이를 수동으로 복사하여 붙여넣기를 하시면 됩니다.
            <div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}>
        <ExpansionPanelSummary
          aria-controls="panel4d-content"
          id="panel4d-header">
          <Typography>
            스트라바 활동을 수동으로 업데이트 할 수 있나요?
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            라이덕과 스트라바가 연동되셨다면 스트라바에서 활동의 제목을
            수정하시면 자동으로 몇분 뒤에 해당 활동이 라이덕에 업데이트 됩니다.
            <div></div>
            스트라바 활동에 분석자료 업데이트는 최초 1회만 됩니다.<div></div>
            이미 업데이트가 된 활동이라도 하루 이후에 라이덕 활동목록의 우측
            설정버튼을 눌러 재계산을 통해 업데이트 가능합니다
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}>
        <ExpansionPanelSummary
          aria-controls="panel5d-content"
          id="panel5d-header">
          <Typography>정규파워, 강도, 훈련피로가 뭔가요?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            FTP, NP, IF, TSS 개념은 파워미터를 활용한다면 반드시 알아야 하는
            내용입니다.<div></div>
            자전거 유튜버 피토라이더님의 영상을 참고해주세요<div></div>
            <div style={{ marginBottom: '10px' }}></div>
            https://www.youtube.com/watch?v=xXiBU3U_AoI<div></div>
            https://www.youtube.com/watch?v=xQS2AiF4p_8<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel6'}
        onChange={handleChange('panel6')}>
        <ExpansionPanelSummary
          aria-controls="panel6d-content"
          id="panel6d-header">
          <Typography>라이덕의 FTP가 다른 프로그램에 비해 낮습니다</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            일반적으로 FTP는 MAP RAMP 테스트나 20분 테스트 방식을 많이
            활용하지만,<div></div>
            실제로 이런 테스트 방식은 프로선수들의 운동데이터를 참고한
            모델이기에 아마추어들에게는 상당히 높게 추정됩니다<div></div>
            기본적으로 실내 트레이닝용이라면 이런 높은 FTP를 가이드로 하셔도
            되지만,<div></div>
            필드 라이딩에서는 이러한 트레이닝용 FTP는 대부분 현실적이지
            않습니다.<div></div>
            라이덕 같은 경우는 필드 라이딩에 맞춰진 FTP를 추정하기에
            트레이닝용에 비해 다소 낮은 가이드를 제시합니다.<div></div>
            FTP라는건 절대적인 수치나 순위경쟁용 지표가 아니니 본인의 컨디션이나
            목적에 맞게 활용하시는 것을 권장합니다.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel7'}
        onChange={handleChange('panel7')}>
        <ExpansionPanelSummary
          aria-controls="panel7d-content"
          id="panel7d-header">
          <Typography>무산소운동분석에 대해서 알려주세요</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            일반적으로 FTP의 아래에서 운동을 할 때는 오랜시간 지속이 가능하지만,
            <div></div>
            FTP보다 높은 영역의 파워에서 운동하게 되면 피로가 쌓여 더이상 페달을
            밟기 어렵게 됩니다.<div></div>이 것을 무산소 운동이나 배터리로
            부르며, 그에 대한 분석 자료입니다.<div></div>
            <div style={{ marginBottom: '10px' }}></div>
            -성냥개비 1개의 운동량 = FTP x 120% x 1분<div></div>
            -지속시간 소모W' : 무산소 영역에서의 지속시간과 운동량<div></div>
            -회복상수 : 약 30% ~ 90% 까지 회복되는 시간
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel8'}
        onChange={handleChange('panel8')}>
        <ExpansionPanelSummary
          aria-controls="panel8d-content"
          id="panel8d-header">
          <Typography>랭킹 / 카테고리 / Hour Record</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            랭킹 : 스트라바 전세계 주요 세그먼트 구간에서 약 60만개 이상의
            파워데이터를 분석하여, 5분 파워를 기준으로 나의 위치를 알려주는
            것입니다.<div></div>
            카테고리 : 미국의 USAC 아마추어등급과 UCI의 프로등급 등을 참고하여,
            5분파워를 기준으로 어떤 레이스 클래스에 속하는지 알려줍니다. (B3~B1,
            C5~C1, CT, PCT, WT 등급이 있습니다.)<div></div>
            Hour Record : 트랙에서 최상의 장비와 가장 에어로한 자세를 취했을
            때를 가정하여 한시간 달릴 수 있는 거리입니다.<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel9'}
        onChange={handleChange('panel9')}>
        <ExpansionPanelSummary
          aria-controls="panel9d-content"
          id="panel9d-header">
          <Typography>역량과 성향이 뭔가요?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            역량 : 전체 라이더에서 타입별 역량을 비교한 나의 능력치라고 보시면
            됩니다.<div></div>
            성향 : 신체조건과 영역대별 파워를 고려하여 내가 어떤 타입의
            라이더인지 표시해줍니다.<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel10'}
        onChange={handleChange('panel10')}>
        <ExpansionPanelSummary
          aria-controls="panel10d-content"
          id="panel10d-header">
          <Typography>바이크킷? 시뮬레이터에 대해서 알려주세요.</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            아직, 개발중인 내용으로 포지션의 공기저항값과 자전거와 라이더의
            종합적인 지표를 추정하고 예측해줍니다.<div></div>
            자전거와 관련된 역학공식과 각종 사이클과 관련된 실험결과 및
            논문자료를 종합하여 만든 사이클링 역학 계산기입니다.<div></div>
            시뮬레이터에서 수치를 변경하여 결과보기를 누르시면 대시보드의
            바이크킷 변경내용을 저장할 수 있습니다.<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        expanded={expanded === 'panel11'}
        onChange={handleChange('panel11')}>
        <ExpansionPanelSummary
          aria-controls="panel11d-content"
          id="panel11d-header">
          <Typography>시뮬레이터의 예상PR이 뭔가요?</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            나의 파워커브와 체중 자전거 성능 등을 종합적으로 고려하여 특정
            구간에서의 PR을 예측해줍니다.<div></div>
            파워커브로 PR 추정은 실제 비슷한 시간대의 나의 최대파워를 통해
            추정하고, 통계기반의 추정은 나와 비슷한 기량을 가진 사람을 통계에서
            찾아 추정해줍니다.<div></div>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
