import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { makeStyles, withStyles } from '@mui/styles';
import cookie from 'react-cookies';
import PowerZoneTab from './PowerZoneTab.js';
import TrainingTab from './TrainingTab.js';
import ThreeZoneTab from './ThreeZoneTab.js';
import AnimalTab from './AnimalTab.js';

import { AppBar, Box, Typography, Tabs, Tab, Switch } from '@mui/material';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  }
}));

const PowerZone = props => {
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  const extraInfo = auth.getExtraInfo();
  const [wkgSelect, setWkgSelect] = useState(false);

  const [tabSelect, setTabSelect] = React.useState(0);
  const [circle, setCircle] = useState(false);
  const [weeks, setWeeks] = React.useState(12);

  const handleChangeWkg = event => {
    let ch = Boolean(wkgSelect);
    setWkgSelect(!ch);

    if (!ch === true) {
      cookie.save('wkg_select', 1, { path: '/' });
    } else {
      cookie.save('wkg_select', 0, { path: '/' });
    }
  };

  const WkgSwitch = withStyles({
    switchBase: {
      color: '#4db6ac',
      '&$checked': {
        color: '#f06292'
      },
      '&$checked + $track': {
        backgroundColor: '#ffabc7'
      }
    },
    checked: {},
    track: {
      backgroundColor: '#a0d8d3'
    }
  })(Switch);

  const [dashboardData, setDashboardData] = useState(
    auth.getDashboardInfo()
      ? auth.getDashboardInfo()
      : {
          ftpArray: null,
          rank_percent: 0,
          rank_array: null,
          category: 'R',
          race: 'Race',
          hour_record: 0,
          ftp: 0,
          wPrime: 15000,
          weight: 10,
          forte: '',
          rider_type: null,
          rider_ability: null,
          pdcData: null,
          powerzoneData: null,
          activityData: null,
          summaryData: null,
          yScale: 1000,
          lastTs: 0
        }
  );

  useEffect(() => {
    let wkg_select = cookie.load('wkg_select');
    if (typeof wkg_select === 'undefined') {
      cookie.save('wkg_select', 0, { path: '/' });
      wkg_select = 0;
    } else {
      if (wkg_select == 1) {
        setWkgSelect(true);
      } else {
        setWkgSelect(false);
      }
    }

    if (!dashboardData.activityData) {
      if (!auth.getDashboardInfo()) {
        props.history.push('/dashboard');
      }
    }
  }, []);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={t('Powerzone.powerzoneHeader')} />
        <Tab key={1} label={t('Powerzone.seilerHeader')} />
        <Tab key={2} label={t('Powerzone.trainingHeader')} />
        <Tab key={3} label={t('Powerzone.animalHeader')} />
      </Tabs>
    );
  }

  function TabContents(props) {
    const tabSelect = props.tabSelect;

    if (tabSelect === 0) {
      return (
        <PowerZoneTab
          activityData={dashboardData.activityData}
          pdcData={dashboardData.pdcData}
          powerzoneData={dashboardData.powerzoneData}
          ftpArray={dashboardData.ftpArray}
          wkg={dashboardData.wkg}
          weight={dashboardData.weight}
        />
      );
    } else if (tabSelect === 1) {
      return (
        <ThreeZoneTab
          ftpArray={dashboardData.ftpArray}
          wkg={dashboardData.wkg}
          weight={dashboardData.weight}
          maxHr={dashboardData.maxHr}
        />
      );
    } else if (tabSelect === 2) {
      return (
        <TrainingTab
          activityData={dashboardData.activityData}
          pdcData={dashboardData.pdcData}
          powerzoneData={dashboardData.powerzoneData}
          ftpArray={dashboardData.ftpArray}
          wkg={dashboardData.wkg}
          weight={dashboardData.weight}
        />
      );
    } else if (tabSelect === 3) {
      return <AnimalTab powerzoneData={dashboardData.powerzoneData} />;
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.powerzone')}
        </Typography>
      </Box>

      <div className={classes.whiteLine}></div>

      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      {dashboardData.summaryData != null ? (
        <TabContents tabSelect={tabSelect} />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  );
};

PowerZone.propTypes = {
  history: PropTypes.object
};

export default withRouter(PowerZone);
