import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { makeStyles } from '@mui/styles';

import Topbar from './components/Topbar';
import LandingFooter from './components/Footer';
import Footer from '../Footer';
import CookiePolicy from 'components/CookiePolicy';

const useStyles = makeStyles(theme => ({
  '@global': { body: { backgroundColor: theme.palette.background.default } },
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  },
  stack: {
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  }
}));

const Minimal = props => {
  const { children } = props;
  const { pathname } = useLocation();

  const classes = useStyles();
  const isLanding = pathname === '/landing';
  const hasCookiePolicy = cookie.load('cookie_policy') ? true : false;

  return (
    <div className={classes.root}>
      {isLanding ? <></> : <Topbar />}

      <main className={classes.content}>{children}</main>
      {isLanding ? <LandingFooter /> : <Footer />}

      {/* 쿠키 배너 때문에 언어 가려서 높이 늘리기 */}
      {!hasCookiePolicy ? <div className={classes.stack} /> : null}

      {/* 쿠키 정책 배너 */}
      {!hasCookiePolicy ? <CookiePolicy /> : null}
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
