import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';

import { Box, Divider, Typography } from '@mui/material';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px'
    }
  },
  desc: {
    margin: '8px 0',
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  desc2: {
    display: 'list-item',
    fontSize: '14px',
    margin: '8px 0 8px 20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  infoWarp: {
    maxWidth: 1600,
    margin: '20px auto'
  },
  infoBoxWarp: {
    width: '100%',
    margin: 'auto',
    padding: '10px 5px 5px 5px',
    borderRadius: 5,
    textAlign: 'center',
    display: 'inline-block',
    margin: '5px 0',
    verticalAlign: 'top'
  },
  infoBoxResponsive: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 5px)'
    }
  },
  infoBoxZ2: {
    background: '#76b5e8',
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    }
  },
  infoBoxZ6: {
    background: '#FF7E75'
  },
  infoBoxZ2Z6: {
    background: 'linear-gradient(to right, #76b5e8, #FF7E75)'
  },
  infoBoxTitleWarp: {
    color: '#fff',
    fontSize: 16,
    [theme.breakpoints.down('xl')]: {
      fontSize: 14
    }
  },
  infoBoxContentWarp: {
    backgroundColor: theme.palette.background.paper,
    padding: '16px 8px',
    borderRadius: 5,
    margin: '12px 0 0 0',
    color: theme.palette.text.forth,
    [theme.breakpoints.down('xl')]: {
      fontSize: 14,
      margin: '8px 0 0 0',
      padding: '8px 4px'
    }
  },
  tabContentBold: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 12,
    [theme.breakpoints.down('xl')]: {
      fontSize: 14,
      marginBottom: 8,
      padding: '8px 4px'
    }
  },
  tabContentNomal: {
    fontWeight: 400,
    fontSize: 14,
    [theme.breakpoints.down('xl')]: {
      fontSize: 12
    }
  },
  infoBoxAddWarp: {
    whiteSpace: 'pre-line',
    fontSize: 14,
    margin: '8px 0',
    width: '100%',
    textAlign: 'left',
    [theme.breakpoints.down('xl')]: {
      fontSize: 12
    }
  }
}));

const ThreeZoneTab = props => {
  const { ftpArray, maxHr, wkg, weight } = props;
  const theme = useTheme();
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const ftp = ftpArray.wftp;
  const wftp = ftpArray.wftp;
  const tte = ftpArray.wtte;

  const [hrPowerArr, setHrPowerArr] = useState([]);

  const [ftpData, setFtpData] = useState([]);
  const [hrData, setHrData] = useState([]);

  // const [ ftpEomji, setFtpEomji ] = useState([]);

  let key = 0;
  let maximum = 16;

  const containerThreeZone = {
    minWidth: '300px',
    height: window.innerHeight > 800 ? '360px' : '240px',
    margin: '0 auto 10px auto'
  };

  useEffect(() => {
    let lt_ftp_chart = [];
    let lt_ftp_points = [];
    let lt_hr_chart = [];
    let lt_hr_points = [];

    let hr_power_arr = [];
    // let lt_ftp_eomji_chart = [];
    // let lt_ftp_eomji_points = [];
    // // 동물 이모지
    // lt_ftp_eomji_points.push({x:60, y:6, indexLabel: "🐮", indexLabelFontColor:"#00000060"});
    // lt_ftp_eomji_points.push({x:70, y:6, indexLabel: "🐪", indexLabelFontColor:"#00000060"});
    // lt_ftp_eomji_points.push({x:83, y:6, indexLabel: "🐷", indexLabelFontColor:"#00000060"});
    // lt_ftp_eomji_points.push({x:95, y:6, indexLabel: "🐗", indexLabelFontColor:"#00000060"});
    // lt_ftp_eomji_points.push({x:104,y:6, indexLabel: "🐺", indexLabelFontColor:"#00000060"});
    // lt_ftp_eomji_points.push({x:115,y:6, indexLabel: "🐆", indexLabelFontColor:"#00000060"});

    //ftp

    for (let i = 50; i <= 120; i += 1) {
      let lt = 0.5;

      if (i <= 80) {
        lt = i / 40;
      } else if (i <= 100) {
        lt = Math.pow((i - 80) * 0.08, 1.41) + 2.0;
      } else if (i <= 120) {
        lt = Math.pow((i - 100) * 0.27, 1.4) + 4.0;
      }

      let match_hr =
        (wftp * (i / 100) - wftp * 0.5) *
          ((maxHr - maxHr * 0.6) / (wftp * 1.2 - wftp * 0.5)) +
        maxHr * 0.6;

      lt_ftp_points.push({
        x: i,
        y: Math.round(100 * lt) / 100,
        label: Math.round(Math.round(wftp * i) / 100) + 'W',
        hr: Math.round(match_hr)
      });

      //      power_hr_arr[Math.round(wftp*i/100)] = match_hr;
      hr_power_arr[Math.round(match_hr)] = Math.round((wftp * i) / 100);

      maximum = lt + 1;
    }

    // console.log(lt_ftp_points);

    //hr 저강도 중강도 고강도 scatter 띄우기
    for (let i = 60; i <= 100; i++) {
      // 저강도 중강도 고강도
      if (i == 69) {
        lt_hr_points.push({
          x: i,
          y: 14,
          indexLabel: t('Powerzone.lowIntensity')
        });
      } else if (i == 83) {
        lt_hr_points.push({
          x: i,
          y: 14,
          indexLabel: t('Powerzone.midIntensity')
        });
      } else if (i == 95) {
        lt_hr_points.push({
          x: i,
          y: 14,
          indexLabel: t('Powerzone.highIntensity')
        });
      }

      // 라벨
      else {
        lt_hr_points.push({
          x: i,
          y: 100,
          label: Math.round((maxHr * i) / 100)
        });
      }
    }

    //data
    lt_ftp_chart.push({
      axisXType: 'primary',
      name: 'lt-ftp chart',
      type: 'spline',
      lineDashType: 'shortDash',
      lineColor: '#999999',
      indexLabelFontColor: '#999999',
      lineThickness: 3,
      markerSize: 3,
      markerColor: '#999999',
      fillOpacity: '0.8',
      dataPoints: lt_ftp_points
    });

    lt_hr_chart.push({
      axisXType: 'secondary',
      name: '',
      type: 'scatter',
      indexLabelAlign: 'center',
      indexLabelFontSize:
        window.innerWidth < 720 ? (language == 'en' ? 10 : 14) : 20,
      lineColor: 'transparent',
      indexLabelFontColor: '#f5f5f5',
      markerSize: 0,
      markerColor: 'transparent',
      fillOpacity: '0.0',
      toolTipContent: ' ',
      dataPoints: lt_hr_points
    });

    // lt_ftp_eomji_chart.push({
    //   axisXType: "primary",
    // 	name: "imoji",
    // 	type: "scatter",
    // 	indexLabelAlign: "center",
    // 	indexLabelFontSize: (window.innerWidth < 720)? 16:28,
    // 	markerSize: 0,
    // 	fillOpacity: "0.0",
    // 	toolTipContent: null,
    // 	dataPoints: lt_ftp_eomji_points
    // });

    setFtpData(lt_ftp_chart[0]);
    setHrData(lt_hr_chart[0]);

    setHrPowerArr(hr_power_arr);
    // setFtpEomji(lt_ftp_eomji_chart[0])
  }, []);

  function searchPowerByHr(hr) {
    let power = 0;
    for (let i = hr - 10; i <= hr; i++) {
      power = hrPowerArr[i] !== undefined ? hrPowerArr[i] : power + 1;
      // console.log(hr + " " + " " + power);
    }

    return power;
  }

  const options = {
    backgroundColor: theme.palette.chart.background,
    animationEnabled: false,
    zoomEnabled: true,
    theme: 'light2',
    title: {
      text: false
    },
    toolTip: {
      shared: false,
      contentFormatter: function(e) {
        let content = '';
        if (e.entries.length > 0)
          for (let i = 0; i < e.entries.length; i++) {
            //          console.log(e.entries[i].dataPoint);
            //          console.log(e.entries[i].dataPoint.hr + " " + searchPowerByHr(e.entries[i].dataPoint.hr));

            content +=
              "<div style='color:" +
              e.entries[i].dataSeries.color +
              "'>" +
              e.entries[i].dataPoint.y +
              ' mmol<br/>' +
              e.entries[i].dataPoint.hr +
              ' bpm<br/>' +
              searchPowerByHr(e.entries[i].dataPoint.hr) +
              ' watts<br/>' +
              '</div>';
          }
        return content;
      }
    },
    axisX: {
      labelFontColor: theme.palette.chart.text.primary,
      gridThickness: 0,
      lineThickness: 1,
      interval: 15,
      labelFontSize: window.innerWidth < 720 ? 10 : 14,
      labelFormatter: function(e) {
        if (window.innerWidth < 720) {
          return e.label + '\n(' + e.value + '%)';
        } else {
          return e.label + '\n(FTP ' + e.value + '%)';
        }
      },
      stripLines: [
        {
          value: 80,
          label: 'LT1 (2mmol) ' + Math.round(wftp * 0.8) + 'W',
          lineDashType: 'dash',
          color: '#76b5e8',
          labelAlign: 'center',
          labelFontSize: window.innerWidth < 720 ? 11 : 14,
          labelFontColor: '#333'
        },
        {
          value: 100,
          label: 'LT2 (4mmol, MLSS) ' + wftp + 'W',
          lineDashType: 'dash',
          color: '#f06292',
          labelAlign: 'center',
          labelFontSize: window.innerWidth < 720 ? 11 : 14,
          labelFontColor: '#333'
        },
        {
          startValue: 50,
          endValue: 80,
          color: '#9fcbee',
          opacity: 1.0
        },
        {
          startValue: 80,
          endValue: 100,
          color: '#ffd767',
          opacity: 0.8
        },
        {
          startValue: 100,
          endValue: 120,
          color: '#ff8880',
          opacity: 1.0
        }
      ]
    },
    axisX2: {
      labelFontColor: theme.palette.chart.text.primary,
      gridThickness: 0,
      lineThickness: 1,
      interval: 8,
      labelFontSize: window.innerWidth < 720 ? 10 : 14,
      labelFormatter: function(e) {
        return 'HR ' + e.label + '\n(' + e.value + '%)';
      },
      stripLines: [
        {
          startValue: 65,
          endValue: 70,
          label: t('Powerzone.lowIntensityTarget'),
          color: '#76b5e8',
          //          color: "#539fe1",
          blend: 'Multiply',
          labelAlign: 'center',
          labelBackgroundColor: 'transparent',
          labelFontSize: window.innerWidth < 720 ? 12 : 16,
          labelFontColor: '#466c8b',
          opacity: 0.5
        },
        {
          startValue: 90,
          endValue: 95,
          label: t('Powerzone.highIntensityTarget'),
          // color: "#ff5e55",
          color: '#ff564a',
          labelAlign: 'center',
          labelBackgroundColor: 'transparent',
          labelFontSize: window.innerWidth < 720 ? 12 : 16,
          labelFontColor: '#b23c33',
          opacity: 0.5
        }
      ]
    },
    axisY: {
      labelFontColor: theme.palette.chart.text.primary,
      titleFontColor: theme.palette.chart.text.primary,
      title: 'Blood Lactate mmol/L',
      gridThickness: 0,
      lineThickness: 1,
      maximum: maximum,
      titleFontSize: window.innerWidth < 720 ? 10 : 14
    },
    legend: {
      verticalAlign: 'top',
      fontSize: window.innerWidth < 720 ? 10 : 14,
      dockInsidePlotArea: true
    },
    data: [ftpData, hrData /* ftpEomji */]
  };

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <Box className={classes.tabContent}>
          <CanvasJSChart
            containerProps={containerThreeZone}
            options={options}
          />

          <Divider />
          <Box className={classes.infoWarp}>
            <Box
              className={clsx(
                classes.infoBoxWarp,
                classes.infoBoxZ2,
                classes.infoBoxResponsive
              )}>
              <Box className={classes.infoBoxTitleWarp}>
                🐮 🐪 🐷 {t('Powerzone.lowIntensityTarget')}
              </Box>
              <Box className={classes.infoBoxContentWarp}>
                <Box className={classes.tabContentBold}>
                  {Math.round((maxHr * 65) / 100)}bpm ~{' '}
                  {Math.round((maxHr * 70) / 100)}bpm
                  <span className={classes.tabContentNomal}> (65%~70%)</span>
                  <br />
                  {searchPowerByHr(Math.round((maxHr * 65) / 100))}W ~{' '}
                  {searchPowerByHr(Math.round((maxHr * 70) / 100))}W
                  <span className={classes.tabContentNomal}>
                    {' '}
                    (
                    {Math.round(
                      (100 * searchPowerByHr(Math.round((maxHr * 65) / 100))) /
                        wftp
                    )}
                    %~
                    {Math.round(
                      (100 * searchPowerByHr(Math.round((maxHr * 70) / 100))) /
                        wftp
                    )}
                    %)
                  </span>
                  <br />
                </Box>
                <Box className={classes.tabContentNomal}>
                  {t('Powerzone.lowIntensityDesc')}
                </Box>
              </Box>
            </Box>

            <Box
              className={clsx(
                classes.infoBoxWarp,
                classes.infoBoxZ6,
                classes.infoBoxResponsive
              )}>
              <Box className={classes.infoBoxTitleWarp}>
                🐺 🐆 {t('Powerzone.highIntensityTarget')}
              </Box>
              <Box className={classes.infoBoxContentWarp}>
                <Box className={classes.tabContentBold}>
                  {Math.round((maxHr * 90) / 100)}bpm ~{' '}
                  {Math.round((maxHr * 95) / 100)}bpm
                  <span className={classes.tabContentNomal}> (90%~95%)</span>
                  <br />
                  {searchPowerByHr(Math.round((maxHr * 90) / 100))}W ~{' '}
                  {searchPowerByHr(Math.round((maxHr * 95) / 100))}W
                  <span className={classes.tabContentNomal}>
                    {' '}
                    (
                    {Math.round(
                      (100 * searchPowerByHr(Math.round((maxHr * 90) / 100))) /
                        wftp
                    )}
                    %~
                    {Math.round(
                      (100 * searchPowerByHr(Math.round((maxHr * 95) / 100))) /
                        wftp
                    )}
                    %)
                  </span>
                  <br />
                </Box>
                <Box className={classes.tabContentNomal}>
                  {t('Powerzone.highIntensityDesc')}
                </Box>
              </Box>
            </Box>

            <Box className={clsx(classes.infoBoxWarp, classes.infoBoxZ2Z6)}>
              <Box className={classes.infoBoxTitleWarp}>
                🐘 {t('Powerzone.polarizationTraining')}
              </Box>
              <Box className={classes.infoBoxContentWarp}>
                <Box className={classes.tabContentNomal}>
                  {t('Powerzone.polarizationDesc')}
                </Box>
              </Box>
            </Box>

            <Box className={classes.infoBoxAddWarp}>
              {t('Powerzone.AdditionalDesc1')}
            </Box>
            <Box className={classes.infoBoxAddWarp}>
              {t('Powerzone.AdditionalDesc2')}
            </Box>
          </Box>

          <Divider />
          <Box style={{ padding: '12px 0px' }}>
            <Typography className={classes.descTitle} component="div">
              {t('Powerzone.seilerzoneIs')}
            </Typography>
            <Typography className={classes.desc} component="div">
              {t('Powerzone.seilerIsDesc1')}
            </Typography>
            <Typography className={classes.desc2} component="div">
              {t('Powerzone.seilerIsDesc2')}
            </Typography>
            <Typography className={classes.desc2} component="div">
              {t('Powerzone.seilerIsDesc3')}
            </Typography>
            <Typography className={classes.desc2} component="div">
              {t('Powerzone.seilerIsDesc4')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

ThreeZoneTab.propTypes = {
  history: PropTypes.object
};

export default withRouter(ThreeZoneTab);
