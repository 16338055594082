import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { clubListAdminApi } from 'utils/clientApis';
import AdminModal from 'views/Peloton/Common/AdminModal';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
//
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const ClubList = props => {
  const [clubData, setClubData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    handleGetClubList();
  }, []);

  const handleGetClubList = async () => {
    try {
      const response = await clubListAdminApi();
      if (response) {
        const clubDataArr = response.data.response;
        const disabledClub = clubDataArr.filter(club => club.active === false);
        setClubData(disabledClub);
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @return: Modal Controller
   */
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState('');
  const [selectedClub, setSelectedClub] = useState({});

  const handleClickOpen = (label, club) => {
    setSelectedModal(label);
    setSelectedClub(club);
    setOpen(true);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                펠로톤 이름
              </TableCell>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                신청자
              </TableCell>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                펠로톤 정보
              </TableCell>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                개설 승인
              </TableCell>
            </TableRow>
          </TableHead>
          {/*  */}
          <TableBody>
            {clubData &&
              clubData.map((club, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    align="center"
                    style={{ minWidth: '80px' }}
                    component="th"
                    scope="row">
                    {club.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: '80px' }}></TableCell>
                  <TableCell align="center" style={{ minWidth: '80px' }}>
                    {club.description}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: '80px' }}>
                    <CheckCircleOutlineOutlinedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleClickOpen('accept', {
                          club: club
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {selectedModal === 'accept' && open && (
          <AdminModal
            label={selectedModal}
            openState={open}
            setOpen={setOpen}
            clubData={selectedClub}
          />
        )}
      </TableContainer>
    </React.Fragment>
  );
};

ClubList.propTypes = {
  history: PropTypes.object
};

export default withRouter(ClubList);

const useStyles = makeStyles(theme => ({
  tabPanel: {
    padding: '16px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px'
    }
  },
  desc: {
    margin: '8px 0',
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  desc2: {
    display: 'list-item',
    fontSize: '14px',
    margin: '8px 0 8px 20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  guideFtp: {
    float: 'left',
    height: '30px',
    lineHeight: '30px',
    margin: '8px 0 12px 8px',
    fontSize: '16px',
    color: '#999999',
    fontWeight: 800,
    [theme.breakpoints.down('lg')]: {
      margin: '4px 0 8px 4px',
      fontSize: '14px'
    }
  },
  textField: {
    fontSize: '16px',
    float: 'right',
    height: '30px',
    margin: '8px 0 12px 8px',
    [theme.breakpoints.down('lg')]: {
      margin: '4px 0 8px 4px',
      fontSize: '14px'
    }
  },
  tableHeader: {
    fontSize: '14px',
    padding: '8px 16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      padding: '4px 8px'
    }
  },
  tableCellPowerzone: {
    width: 'clamp(160px, 10vw, 200px)',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0px 16px 8px'
    }
  },
  tableCellPowerzoneChar: {
    display: 'inline-block',
    width: 30
  },
  tableCellPowerzoneDesc: {
    fontSize: '12px',
    wordBreak: 'keep-all',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  tableCellAchievements: {
    width: 'clamp(220px, 40vw, 400px)',
    padding: '16px 60px 16px 0',
    [theme.breakpoints.down('md')]: {
      paddingRight: '40px'
    }
  },
  tableCellGraph: {
    position: 'relative',
    height: '40px'
  },
  tableCellGraphText: {
    position: 'absolute',
    width: 'calc(100% + 40px)',
    lineHeight: '40px'
  },
  tableCellGraphTextPercent: {
    float: 'left',
    paddingLeft: '12px'
  },
  tableCellGraphTextWatt: {
    float: 'right',
    paddingRight: '20px',
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  tableCellWeekText: {
    fontSize: '14px',
    width: '0px'
  },
  tableCellWeekTextDesc: {
    width: 'clamp(150px, 20vw, 200px)'
  },
  tableCellWeekStatusDesc: {
    fontSize: '14px',
    width: 'clamp(150px, 40vw, 500px)',
    wordBreak: 'keep-all'
  },
  tableCellWeekDownText: {
    margin: '10px auto',
    fontWeight: 600
  },
  tableCellWeekStatusDownDesc: {
    fontSize: '14px',
    margin: '10px auto'
  },
  tableTextDesc: {
    whiteSpace: 'pre'
  },
  z1: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z2: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z3: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z4: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z5: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z6: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z7: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#f06292',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },

  z0Bar: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#eeeeee',
    color: '#ffffff',
    height: '40px',
    fontSize: '12px',
    position: 'absolute'
  },
  z1Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z2Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z3Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z4Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z5Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z6Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z7Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#f06292',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  }
}));
