import React from 'react';

export function decodePolyline(encoded) {
  // 이스케이프된 문자 처리
  encoded = encoded.replace(/\\\\/g, '\\');
  var points = [];
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;

  while (index < len) {
    var b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    var dlng = result & 1 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
}

export const PolylineToSVG = ({
  polyline,
  width,
  height,
  backgroundColor = '#4DB6AC',
  style
}) => {
  const originalPoints = decodePolyline(polyline);
  const points = originalPoints.filter((_, index) => index % 5 === 0);

  let minLat = Math.min(...points.map(p => p.lat));
  let maxLat = Math.max(...points.map(p => p.lat));
  let minLng = Math.min(...points.map(p => p.lng));
  let maxLng = Math.max(...points.map(p => p.lng));

  const svgWidth = width;
  const svgHeight = height;
  const padding = width / 10;
  const svgStrokeWidth = (width / 30).toFixed(0);
  const radius = 5;

  let lngRange = maxLng - minLng;
  let latRange = maxLat - minLat;

  // 패딩을 고려한 실제 그리기 영역의 크기
  let drawWidth = svgWidth - padding * 2;
  let drawHeight = svgHeight - padding * 2;

  let scaleX = drawWidth / lngRange;
  let scaleY = drawHeight / latRange;

  let scale = Math.min(scaleX, scaleY);

  // 중앙 정렬을 위한 추가 계산
  let offsetX = (svgWidth - lngRange * scale) / 2;
  let offsetY = (svgHeight - latRange * scale) / 2;

  // path의 시작점 조정
  let path =
    'M' +
    points
      .map(
        p =>
          `${Math.round((p.lng - minLng) * scale + offsetX)},${Math.round(
            svgHeight - (p.lat - minLat) * scale - offsetY
          )}`
      )
      .join(' L');

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      style={style}
      xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="100%"
        fill={backgroundColor}
        rx={radius}
        ry={radius}
      />
      {/* 패딩을 고려하여 path 그리기 */}
      <path
        d={path}
        fill="none"
        stroke="#FFFFFF"
        strokeWidth={svgStrokeWidth}
      />
    </svg>
  );
};
