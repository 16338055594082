import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {},
  boxWrap: {
    padding: '0 0 20vh',
    maxWidth: '520px',
    textAlign: 'center',
    margin: 'auto'
  },
  title: {
    color: '#4DB6AC',
    fontSize: 'min(60px, 9.0vw)',
    lineHeight: 'min(65px, 7.5vw)',
    fontWeight: 900
  },
  subTitle: {
    color: '#4DB6AC',
    fontSize: 'min(34px, 6vw)',
    lineHeight: 'min(50px, 10vw)'
  },
  contents_1: {
    color: '#AAAAAA',
    fontSize: 'min(34px, 6vw)',
    lineHeight: 'min(40px, 4.0vw)',
    fontWeight: 800
  },
  contents_2: {
    color: '#AAAAAA',
    fontSize: 'min(20px, 4.0vw)',
    lineHeight: 'min(32px, 6vw)',
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
    margin: 'min(40px, 8.0vw) 0'
  },
  contents_3: {
    color: '#444444',
    fontSize: 'min(20px, 4.0vw)',
    lineHeight: 'min(32px, 6vw)',
    fontWeight: 900
  },
  underMaintenanceImg: {
    width: '70%',
    margin: 'min(15px, 7vw) 0'
  },
  titleBoxWrap: {
    margin: 'min(30px, 3.0vw) 0'
  },
  contentsBoxWrap: {
    margin: 'min(40px, 4.0vw) 0'
  }
}));

const Landing = props => {
  //다국어적용
  const { t } = useTranslation(['page']);
  const { history } = props;
  const lang = i18n.language;
  const classes = useStyles();

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <div className={classes.boxWrap}>
        <Box className={classes.titleBoxWrap}>
          <Typography component="div" className={classes.title}>
            WAIT!
          </Typography>
          <Typography component="div" className={classes.subTitle}>
            PAGE UNDER MAINTENANCE
          </Typography>
        </Box>
        <Box>
          <img
            alt="under_maintenance"
            src="/images/under_maintenance_01.png"
            className={classes.underMaintenanceImg}
          />
        </Box>
        <Box className={classes.contentsBoxWrap}>
          <Typography component="div" className={classes.contents_1}>
            {t('Maintenance.contents_1')}
          </Typography>
          <Typography component="div" className={classes.contents_2}>
            {t('Maintenance.contents_2')}
          </Typography>
          <Typography component="div" className={classes.contents_3}>
            {t('Maintenance.contents_3')}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default withRouter(Landing);
