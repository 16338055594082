import React, { useEffect, useState } from 'react';

import { Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import validate from 'validate.js';

import {
  addEventParentCommentApi,
  editEventCommentApi
} from 'utils/clientApis';
import Comment from './Comment';
import CommentWrite from 'views/Peloton/Common/CommentWrite';

const schema = {
  comment: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500,
      minimum: 3
    }
  }
};

const useStyles = makeStyles(theme => ({
  title: { margin: '8px 0' },
  comment: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexDirection: 'column',
    padding: '16px 16px',
    marginTop: '8px'
  },
  commentChildren: {
    marginLeft: '48px'
  },
  returnIconContainer: {
    textAlign: 'right'
  }
}));

function Comments({ commentHistory, userInfoNow, eventId, handleGetComments }) {
  const classes = useStyles();

  // 코멘트 생성을 위한 formState
  const [commentState, setCommentState] = useState({
    data: {
      contents: ''
    },
    errors: {},
    touched: {},
    isValid: false
  });
  // 코멘트 생성 상황에서 리렌더링을

  /**
   * @return : 코멘트 생성의 Change Handling.
   */
  useEffect(() => {
    const errors = validate(commentState.data, schema);
    setCommentState(prevState => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [commentState.data, schema]);

  // comment 생성
  const handleAddComment = async () => {
    try {
      const response = await addEventParentCommentApi(
        eventId,
        commentState.data
      );

      // response &&
      //     console.log(response)
      handleGetComments();
      setCommentState({
        data: {
          contents: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // comment 수정
  const submitEditComment = async (commentState, commentId) => {
    try {
      const response = await editEventCommentApi(eventId, commentId, {
        contents: commentState.data.contents,
        isPublic: true
      });

      if (response.data.success) {
        handleGetComments();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditChangeComment = event => {
    event.persist();

    const { name, value } = event.target;
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  return (
    <>
      {/* 댓글 */}
      {commentHistory.length ? (
        <>
          <Typography variant="h6" className={classes.title}>
            댓글
          </Typography>
          {commentHistory.map(comment => {
            return (
              <div key={comment.id}>
                <Comment
                  comment={comment}
                  type="parent"
                  userInfoNow={userInfoNow}
                  eventId={eventId}
                  handleGetComments={handleGetComments}
                  submitEditComment={submitEditComment}
                />

                {/* 대댓글 */}
                {/* TODO: 현재 대댓글 기능 비활성화. 디자인 수정 필요 */}
                {/* {comment.childCommunityComments.length ? (
                  <Box className={classes.commentChildren}>
                    {comment.childCommunityComments.map(childComment => (
                      <Comment
                        key={childComment.id}
                        type="child"
                        comment={childComment}
                        userInfoNow={userInfoNow}
                        eventId={eventId}
                        handleGetComments={handleGetComments}
                        submitEditComment={submitEditComment}
                      />
                    ))}
                  </Box>
                ) : null} */}
              </div>
            );
          })}
        </>
      ) : null}
      <Container className={classes.comment}>
        {/* 댓글 작성 */}
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginBottom: '16px' }}>
          댓글 작성
        </Typography>
        <CommentWrite
          value={commentState.data.contents || ''}
          onEditChange={handleEditChangeComment}
          onButtonClick={handleAddComment}
        />
      </Container>
    </>
  );
}

export default Comments;
