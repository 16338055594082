import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import { useCopyLink } from 'utils/useCopyLink';
import { statusTracker } from 'utils/helper';
import {
  fetchSingleEventApi,
  fetchPelotonURI,
  getEventJoinUserInfoApi,
  fetchEventCommentsApi
} from 'utils/clientApis';
//

//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
//
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';
import { ProfilePop } from 'views/Peloton/Common/ProfilePopModal';
import EventModal from 'views/Peloton/Club/Menu/EventList/Read/EventModal';
import PelotonLayout from 'views/Peloton/Common/PelotonLayout';

import EventInfo from './EventInfo';
import ParticipantJoin from './ParticipantJoin';
import EventMap from './EventMap';
import EventImageCarousel from './EventImageCarousel';
import Organizer from './Organizer';
import Comments from './Comments';
import PopUpDialog from 'views/Peloton/Common/PopUpDialogLayout';
import CreateEvent from 'views/Peloton/Club/Menu/EventList/Create';
import LinkCopyDialog from 'views/Peloton/Common/LinkCopyDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '28px'
  },
  canvas: {
    borderRadius: '8px',
    marginTop: '16px'
  },
  title: {
    fontSize: '32px',
    color: theme.palette.text.third,
    fontWeight: 'bold',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px'
    }
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'full'
  },
  divider: {
    margin: '8px 0'
  },
  buttonIcon: {
    padding: '4px'
  },
  eventEnd: {
    textAlign: 'center',
    marginBottom: '16px'
  }
}));

function ReadEvent() {
  // 필요 데이터
  const { t } = useTranslation(['page']);
  const classes = useStyles();
  const history = useHistory();
  const { uriName, eventId } = useParams();

  // 기능 중심
  const [event, setEvent] = useState({});
  const [courseData, setCourseData] = useState();
  const [locationData, setLocationData] = useState({});
  const [eventUsers, setEventUsers] = useState([]);
  const [clubData, setClubData] = useState();
  const [initialMarker, setInitialMarker] = useState();
  const [userInfoNow, setUserInfoNow] = useState({});
  const [commentHistory, setCommentHistory] = useState([]);
  // 링크 복사하기
  const [linkCopyDialogOpen, setLinkCopyDialogOpen] = useState(false);
  const { copyLinkToClipboard } = useCopyLink();
  // 프로필 이미지
  const [selectedUser, setSelectedUser] = useState();
  const [profileOpen, setProfileOpen] = useState(false);
  // 참여 신청 모달
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(null);
  // 수정, 삭제 메뉴
  const [postAnchorEl, setPostAnchorEl] = useState(null);
  const postOpen = Boolean(postAnchorEl);
  const ITEM_HEIGHT = 48;
  // 수정 다이얼로그
  const [openEditPop, setOpenEditPop] = useState(false);

  // 개설자 정보
  const [isAuthor, setIsAuthor] = useState(false);
  // 클럽 매니저여부
  const [isManager, setIsManager] = useState(false);
  // 참여 신청 여부
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    handleFetchPelotonInfo();
    handleGetComments();
  }, []);

  const handleFetchPelotonInfo = async () => {
    try {
      const response = await fetchPelotonURI(uriName);
      // 클럽 가입 여부 확인
      if (
        response?.myClubUserResponse.join !== false ||
        response?.myClubUserResponse.blocked === true
      ) {
        setClubData(response);
        handleFetchSingleEvent();
      } else {
        history.push(`/peloton/${uriName}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFetchSingleEvent = async () => {
    try {
      const response = await fetchSingleEventApi(eventId);
      setEvent(response.data.response);
      setLocationData(response.data.response.eventLocations[0]);
      setEventUsers(response.data.response.eventJoinUsers);
      setCourseData(response.data.response.eventMapFiles[0]);
      setUserInfoNow({
        role: response.data.response.clubUserResponse.clubRole,
        nickname: response.data.response.clubUserResponse.displayName
      });

      // 클럽 관리자 여부
      const clubRole = response.data.response.clubUserResponse?.clubRole;
      setIsManager(clubRole === 'MANAGER' || clubRole === 'ADMIN');

      response.data.response.eventJoinUsers.map(joinClubUser => {
        // 참여자인지 확인
        if (
          joinClubUser.userId ===
          response.data.response.clubUserResponse?.userId
        ) {
          // 개설자인지 확인
          if (joinClubUser.host === true) {
            setIsAuthor(true);
          }
          // 참여 신청 여부 확인
          setIsApplied(true);
        }
      });

      // 이벤트의 위치 정보가 있을 경우에만 지도에 마커를 표시
      if (
        response.data.response.eventLocations[0].latitude &&
        response.data.response.eventLocations[0].longitude
      ) {
        setInitialMarker({
          latitude: response.data.response.eventLocations[0].latitude,
          longitude: response.data.response.eventLocations[0].longitude
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetComments = async () => {
    try {
      if (eventId) {
        const response = await fetchEventCommentsApi(eventId);
        setCommentHistory(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @return: 링크 복사하기
   */
  const handleCopyLinker = () => {
    const result = copyLinkToClipboard();
    setLinkCopyDialogOpen(result);
  };

  // 게시글 메뉴 클릭 핸들러
  const handlePostClick = event => {
    setPostAnchorEl(event.currentTarget);
  };

  // 게시글 메뉴 닫기 핸들러
  const handlePostClose = () => {
    setPostAnchorEl(null);
  };

  /**
   * @return: 프로필 이미지 팝업
   */
  const handleClickProfile = userId => {
    setSelectedUser(userId);
    setProfileOpen(true);
  };

  /**
   * @return: Modal Controller
   */
  const handleClickEventModalOpen = async label => {
    setEventModalOpen(true);
    await setSelectedLabel(label);
  };

  const getUserInfo = () => {
    return getEventJoinUserInfoApi(eventId, selectedUser);
  };

  const handleOpenEditPop = () => {
    setOpenEditPop(true);
  };
  const handleCloseEditPop = () => {
    setOpenEditPop(false);
  };

  return (
    <PelotonLayout clubName={clubData?.name}>
      <>
        {event.id ? (
          <>
            {/**
             * 현재 라우트
             */}
            {event && initialMarker && (
              <Box className={classes.canvas}>
                <Card key={event.id} className={classes.cardContainer}>
                  <CardContent
                    elementtype="div"
                    style={{ display: 'flex', flexDirection: 'column' }}>
                    {/**
                     * 제목
                     */}
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px'
                      }}>
                      <Typography className={classes.title} variant="h2">
                        {event.name}
                      </Typography>

                      {event.status === 'OPEN' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleCopyLinker}
                            className={classes.buttonIcon}
                            size="large">
                            <ShareIcon style={{ width: '19px' }} />
                          </IconButton>
                          {(isAuthor || isManager) && (
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={event => handlePostClick(event)}
                              className={classes.buttonIcon}
                              size="large">
                              <MoreVertIcon />
                            </IconButton>
                          )}

                          <Menu
                            id="post-long-menu"
                            anchorEl={postAnchorEl}
                            keepMounted
                            open={postOpen}
                            onClose={handlePostClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                borderRadius: '16px'
                              }
                            }}>
                            {/* 작성자인 경우에 메뉴 */}
                            {isAuthor && (
                              <div>
                                <MenuItem
                                  onClick={() => {
                                    handleOpenEditPop();
                                    handlePostClose();
                                  }}>
                                  수정
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleClickEventModalOpen('cancelEvent')
                                  }>
                                  개설된 이벤트 취소(폭파)
                                </MenuItem>
                              </div>
                            )}
                            {/* 관리자인 경우에만 보여줄 메뉴 (단, 작성자가 아닐 때) */}
                            {!isAuthor && isManager && (
                              <div>
                                <div
                                  style={{
                                    fontWeight: '700',
                                    color: '#F06292',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: '14px'
                                  }}>
                                  관리자 권한
                                </div>
                                <MenuItem
                                  onClick={() =>
                                    handleClickEventModalOpen('cancelEvent')
                                  }>
                                  개설된 이벤트 취소(폭파)
                                </MenuItem>
                              </div>
                            )}
                          </Menu>
                        </div>
                      )}
                    </Box>

                    {/* 약속 시간, 장소, 태그 */}
                    <EventInfo event={event} locationData={locationData} />

                    <Divider className={classes.divider} />

                    {/* 이벤트 참여 및 이벤트 참여취소, 이벤트 참여자들 */}
                    <ParticipantJoin
                      event={event}
                      isApplied={isApplied}
                      eventUsers={eventUsers}
                      handleClickEventModalOpen={handleClickEventModalOpen}
                      handleClickProfile={handleClickProfile}
                    />

                    {/* 코스와 마커 */}
                    <EventMap
                      event={event}
                      courseData={courseData}
                      initialMarker={initialMarker}
                      locationData={locationData}
                    />

                    <Divider className={classes.divider} />

                    {/* 게시물 컴포넌트 */}
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      style={{ minHeight: '100px' }}>
                      {event.description
                        ?.split('\n')
                        .map((paragraph, index) => (
                          <React.Fragment key={index}>
                            {paragraph}
                            <br />
                          </React.Fragment>
                        ))}
                    </Typography>

                    {/* 이미지 나열 */}
                    <EventImageCarousel event={event} />

                    {/* 이미지 없는 경우 이벤트 취소 / 완료 */}
                    {event.status !== 'OPEN' &&
                    event.eventThumbFiles.length === 0 ? (
                      <Box className={classes.eventEnd}>
                        <Typography
                          variant="h4"
                          style={{
                            backgroundColor: '#ffffffaa',
                            color: '#000000',
                            padding: 5,
                            borderRadius: 8
                          }}>
                          {`${statusTracker(event.status)}된 이벤트입니다.`}
                        </Typography>
                      </Box>
                    ) : null}

                    <Divider className={classes.divider} />

                    {/* Organizer */}
                    <Organizer
                      eventJoinUsers={event.eventJoinUsers}
                      handleClickProfile={handleClickProfile}
                    />

                    <Divider className={classes.divider} />

                    {/* Comments */}
                    <Comments
                      commentHistory={commentHistory}
                      userInfoNow={userInfoNow}
                      eventId={eventId}
                      handleGetComments={handleGetComments}
                    />
                  </CardContent>
                </Card>
              </Box>
            )}
            {linkCopyDialogOpen && (
              <LinkCopyDialog
                copyModalOpen={linkCopyDialogOpen}
                handleCloseCopyModal={() => setLinkCopyDialogOpen(false)}
              />
            )}
            {profileOpen && (
              <ProfilePop
                openState={profileOpen}
                setOpen={setProfileOpen}
                getUserInfo={getUserInfo}
              />
            )}
            {eventModalOpen && (
              <EventModal
                selectedLabel={selectedLabel}
                openState={eventModalOpen}
                setOpen={setEventModalOpen}
                uriName={uriName}
                eventId={eventId}
              />
            )}
            {openEditPop && (
              <PopUpDialog
                openPop={openEditPop}
                title="이벤트 수정하기"
                handleClose={handleCloseEditPop}>
                <CreateEvent
                  handleClosePop={handleCloseEditPop}
                  handleFetchEvent={handleFetchSingleEvent}
                />
              </PopUpDialog>
            )}
          </>
        ) : (
          <CustomCircularProgress />
        )}
      </>
    </PelotonLayout>
  );
}
export default withRouter(ReadEvent);

ReadEvent.propTypes = {
  history: PropTypes.object
};
