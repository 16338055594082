import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';

import { AppBar, Box, Divider, Typography, Tabs, Tab } from '@mui/material';

import i18n from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

//jQuery 추가
import $ from 'jquery';

//

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px'
  },
  desc: {}
}));

const Metabolism = props => {
  const { ftpArray, metabolismArray, wkg, weight } = props;
  const classes = useStyles();
  const theme = useTheme();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const ftp = ftpArray.wftp;
  const tte = ftpArray.wtte;

  const [circle, setCircle] = useState(false);

  const [tabSelect, setTabSelect] = React.useState(0);

  const [metabolismChart, setMetabolismChart] = useState({});

  let key = 0;

  const containerMetabolism = {
    minWidth: '300px',
    height: window.innerHeight > 800 ? '360px' : '240px',
    margin: '0 auto 10px auto'
  };

  function getDateYmd(date) {
    if (language === 'en') {
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    } else {
      return (
        date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      );
    }
  }

  //평균 구하기
  function averageArray(arr) {
    const result = arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);

    return result / arr.length;
  }

  useEffect(() => {
    const stripLineX = [];
    const stripLineY = [];

    function onItemMouseOver(e) {
      for (let i = 0; i < 7; i++) {
        e.chart.data[i].set('fillOpacity', 0.2);
      }

      e.chart.data[e.dataSeriesIndex].set('fillOpacity', 1);
    }

    function onItemMouseOut(e) {
      for (let i = 0; i < 7; i++) {
        e.chart.data[i].set('fillOpacity', 0.8);
      }
    }

    console.log('metabolismArray');
    console.log(metabolismArray);

    if (metabolismArray !== null) {
      let metabolism_list = [];

      let fat_arr = metabolismArray.fat_arr;
      let all_arr = metabolismArray.all_arr;
      let glyc_arr = metabolismArray.glyc_arr;
      let fatmax = metabolismArray.fatmax;
      let at = metabolismArray.at;
      let wftp = metabolismArray.wftp;

      let fatmax_hour = metabolismArray.fatmax_hour;

      let fat_points = [];
      let glyc_points = [];
      let all_points = [];

      _.each(fat_arr, function(points, index) {
        if (index > 30) {
          fat_points.push(points);
        }
      });

      metabolism_list.push({
        name: 'fat chart',
        type: 'area',
        color: '#ffd357',
        fillOpacity: '0.6',
        lineThickness: 2,
        markerSize: 2,
        dataPoints: fat_points
      });

      let gly_c1 = 0;
      let gly_c2 = 0;

      _.each(glyc_arr, function(points, index) {
        if (index > 30) {
          glyc_points.push(points);

          //탄수화물 한시간과 펫맥스 비율을 통해 최대시간 추정
          if (gly_c1 === 0 && points.x >= fatmax) gly_c1 = points.y;
          if (gly_c2 === 0 && points.x >= at) gly_c2 = points.y;
        }
      });

      metabolism_list.push({
        name: 'glyc chart',
        type: 'area',
        color: '#4db6ac',
        fillOpacity: '0.3',
        lineThickness: 2,
        markerSize: 2,
        dataPoints: glyc_points
      });

      _.each(all_arr, function(points, index) {
        if (index > 30) {
          all_points.push(points);
        }
      });

      let hour_cal = Math.round(at * 3600 * 0.4184 * 0.0024);

      metabolism_list.push({
        name: 'all chart',
        type: 'area',
        color: '#dddddd',
        fillOpacity: '0.2',
        lineThickness: 2,
        markerSize: 2,
        dataPoints: all_points
      });

      let stripsX = [
        {
          value: fatmax,
          label: 'FatMax ' + fatmax + 'W',
          lineDashType: 'dash',
          color: '#f06292',
          labelFontSize: 14,
          labelAlign: 'far',
          labelFontColor: theme.palette.chart.text.secondary
        },
        {
          value: at,
          label: '1 hour ' + at + 'W, ' + hour_cal + ' kcal',
          lineDashType: 'dash',
          color: '#aaaaaa',
          labelFontSize: 14,
          labelAlign: 'far',
          labelFontColor: theme.palette.chart.text.secondary
        },
        {
          startValue: at,
          endValue: wftp,
          label: ' Over an hour ',
          color: '#cccccc',
          labelFontSize: 14,
          opacity: '.3',
          labelAlign: 'center',
          labelFontColor: theme.palette.chart.text.third,
          labelBackgroundColor: 'transparent'
        },
        {
          startValue: Math.round(fatmax * 0.89),
          endValue: Math.round(fatmax * 1.06),
          labelFontSize: 14,
          color: '#ffd357',
          opacity: '.3'
        }
      ];
      /*
      let stripsY = [
        {
          showOnTop: true, 
          startValue: 60*4.1,
          endValue: 90*4.1,
          label: "Glycogen usage",
          color: "#f06292",
          labelFontSize: 12,
          opacity: ".1",
          labelAlign: "near",
          labelFontColor: "#f06292",
          labelBackgroundColor: "transperent",
        },
      ];
*/
      let fatmax_hourcal = Math.round(fatmax_hour);
      let fatmax_if = fatmax / wftp;
      let fatmax_duration = Math.round(
        3600 * (gly_c2 / gly_c1) * (fatmax / wftp)
      );

      $('#text_fatmax').html(fatmax);
      $('#text_hour_power').html(at);
      $('#text_fatmax_duration').html(secondsToHmsLabel(fatmax_duration));
      $('#text_fatmax_tss').html(
        Math.round((fatmax_duration * fatmax_if * fatmax_if) / 36)
      );
      $('#text_hour_cal').html(hour_cal);
      $('#text_fat_cal').html(fatmax_hourcal);
      $('#text_fat_weight').html(Math.round((10 * fatmax_hourcal) / 9.45) / 10);

      //glyc 4.1
      //fat 9.45

      setMetabolismChart({
        metabolism_list: metabolism_list,
        stripsX: stripsX
      });
    }

    function secondsToHms(time) {
      let d = Number(time);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      if (h > 0) {
        let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
        let md = m > 0 ? m + t('Common.min') : '';

        return hd + md;
      } else if (h <= 0) {
        let md = m > 0 ? m + t('Common.min') + ' ' : '';
        let sd = s > 0 ? s + t('Common.sec') : '';

        return md + sd;
      }
    }

    function secondsToHmsLabel(time) {
      let d = Number(time);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      if (h == 0 && m == 0 && s == 0) {
        return '';
      } else if (h > 0) {
        let hd = h > 0 ? h + ':' : '00';
        let md = m > 0 ? m : '00';

        return hd + md;
      } else if (h <= 0) {
        let md = m > 0 ? m + ':' : '';
        let sd = s > 0 ? s : '00';

        return md + sd;
      }
    }

    stripLineY.push({
      type: 'dash',
      value: 0,
      color: '#000000',
      lineDashType: 'dash',
      lineThickness: 2,
      lineColor: '#333333'
    });
  }, []);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={'Metabolism'} />
      </Tabs>
    );
  }

  function onItemMouseOver(e) {
    e.chart.data[0].set('visible', true);
    e.chart.data[1].set('visible', false);
    e.chart.data[2].set('visible', false);
    e.chart.data[3].set('visible', false);

    e.chart.data[e.dataSeriesIndex].set('visible', true);
  }

  function onItemMouseOut(e) {
    e.chart.data[0].set('visible', true);
    e.chart.data[1].set('visible', true);
    e.chart.data[2].set('visible', false);
    e.chart.data[3].set('visible', false);
  }

  const options = {
    backgroundColor: theme.palette.chart.background,
    animationEnabled: false,
    theme: 'light2',
    title: false,
    zoomEnabled: false,
    toolTip: {
      shared: true,
      contentFormatter: function(e) {
        let content =
          "<div style='margin:-6px; background:#444444; padding:4px 8px;'>";
        content +=
          "<div style='color:#999999;'>" +
          t('SideBar.metabolism') +
          ' at ' +
          e.entries[0].dataPoint.x +
          ' W</div>';

        for (let i = 0; i < e.entries.length; i++) {
          if (i == 0) {
            content += "<div style='color:#ffd357'>";
            content +=
              t('Common.fat') +
              ' <strong>' +
              e.entries[i].dataPoint.y +
              '</strong> kcal';
            content +=
              ' (' +
              Math.round(
                (100 * e.entries[0].dataPoint.y) / e.entries[2].dataPoint.y
              ) +
              '%)';
            content += '</div>';
          } else if (i == 1) {
            content += "<div style='color:#4db6ac'>";
            content +=
              t('Common.glycogen') +
              ' <strong>' +
              e.entries[i].dataPoint.y +
              '</strong> kcal';
            content +=
              ' (' +
              Math.round(
                (100 * e.entries[1].dataPoint.y) / e.entries[2].dataPoint.y
              ) +
              '%)';
            content += '</div>';
          } else if (i == 2) {
            content += "<div style='color:#aaaaaa'>";
            content +=
              t('Common.totalEnergy') +
              ' <strong>' +
              e.entries[i].dataPoint.y +
              '</strong> kcal';
            content += '</div>';
          }
        }

        content += '</div>';

        return content;
      }
    },
    axisX: {
      interval: 20,
      stripLines: metabolismChart.stripsX,
      labelFormatter: function(e) {
        return e.value + ' W';
      },
      lineThickness: 1,
      lineColor: '#aaaaaa',
      gridThickness: 1,
      gridColor: theme.palette.chart.secondary.axis.grid,
      labelFontColor: '#aaaaaa'
    },
    axisY: {
      stripLines: metabolismChart.stripsY,
      labelFontSize: 11,
      labelFontColor: '#aaaaaa',
      labelFormatter: function(e) {
        return e.value + ' Cal';
      },
      tickLength: 2,
      lineThickness: 1,
      lineColor: '#aaaaaa',
      gridThickness: 1,
      gridColor: theme.palette.chart.secondary.axis.grid,
      minimum: 0
    },
    /*    
    axisY2: {
      labelFontSize: 11,
      labelFontColor: "#aaaaaa",
      labelFormatter: function (e) {
        console.log(e);
        return Math.round(e.value/4.1);  
      },
      tickLength: 2,
      lineThickness: 1,
      lineColor:"#aaaaaa",
      gridThickness: 1,
      gridColor: "#eeeeee",
      minimum : 0,
    },
*/

    legend: {
      horizontalAlign: 'center',
      verticalAlign: 'bottom',
      fontSize: 12,
      fontColor: '#666666',
      itemmouseover: onItemMouseOver,
      itemmouseout: onItemMouseOut
    },
    data: metabolismChart.metabolism_list
  };

  return (
    <React.Fragment>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      <Box className={classes.tabPanel}>
        <CanvasJSChart containerProps={containerMetabolism} options={options} />
        <Divider />
        <Box style={{ textAlign: 'center', padding: '16px' }}>
          <Typography style={{ fontSize: '20px', marginBottom: '12px' }}>
            FatMax : <span id="text_fatmax"></span> W
          </Typography>
          <Typography style={{ fontSize: '20px' }}>
            Duration : <span id="text_fatmax_duration"></span>, TSS :{' '}
            <span id="text_fatmax_tss"></span>
          </Typography>
        </Box>
        <Divider />
        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Common.MetabolismIs')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Common.MetabolismIsDesc')}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

Metabolism.propTypes = {
  history: PropTypes.object
};

export default withRouter(Metabolism);
