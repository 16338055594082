//import React from 'react';
//import uuid from 'uuid/v1';
//#f06292 f491b2
//8cc1ec 4db6ac
//76b5e8 d1e6f7

export default {
  ftp: {
    animations: {
      enabled: false,
    },
    chart: {
      type: 'line',
      offsetY: 0,
      offsetX: 0,
      animations: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 0,
        left: 0,
        blur: 5,
        opacity: 0.2
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 0 
      }
    }, 
    fill : {
      opacity: [1.0, 1.0, 0.7, 0.4]
    }, 
    dataLabels: {
      enabled: true,
      enabledOnSeries: [3],
      style: {
        fontSize: '11px',
        fontWeight: 'normal',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 3,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#ffc41c',
        opacity: 0.9,
      }, 
    },	
    colors: ['#ffd357','#f491b2','#4db6ac','#8cc1ec'],
    stroke: {
        width: [2, 2, 1, 1],
        curve: ['smooth', 'smooth', 'smooth', 'smooth'],
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], 
        opacity: 0.2,
      },
    },
    legend: {
      fontSize: '11px',
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
    },
/* 
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 200,
            color: '#85cdc6'
          }, {
            from: 200,
            to: 212,
            color: '#73c6be'
          }, {
            from: 212,
            to: 214,
            color: '#61beb5'
          }, {
            from: 214,
            to: 230,
            color: '#4fb7ad'
          }]
        }, 
        dataLabels: {
          position: 'top', 
        },
        endingShape: "rounded",
        columnWidth: "60%",
      }
    },
*/    
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: function(value) {
          let week = 12-value;

          if(week === 0) return "this week";
          else return week + "weeks ago";
        }
      },
      axisTicks: {
        show: true,
      },
      tooltip :{
        enabled: false
      } 
    },
    yaxis: {
      seriesName: 'FTP',
      show: false,
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (value) => { return Math.round(value) + ' w' },
      },
    },
    tooltip: {
      x: {
        format: 'yyyy MMM dd'
      }
    },
  },
};
