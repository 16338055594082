import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';

import { Box, Button, Dialog, Paper, Typography } from '@mui/material';

const useStyles = makeStyles({
  paper: {
    margin: 6,
    padding: 6,
    width: 'auto',
    textAlign: 'center'
  },
  header: {
    fontSize: '15px',
    textAlign: 'center',
    padding: 12
  },
  notice: {
    fontSize: '13px',
    padding: 12,
    textAlign: 'left'
  },
  alert: {
    fontSize: '13px',
    padding: '0 12px',
    textAlign: 'left'
  },
  getInvite: {
    margin: '20px auto'
  },
  input: {
    marginLeft: 4,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  dialog: { marginTop: '6px' },
  inline: {
    display: 'inline'
  },
  progress: {
    alignItems: 'center'
  }
});

const InviteCodeDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, open } = props;
  const [loading, setLoading] = useState(true);
  const [invitation, setInvitation] = useState(-1);

  function handleClose() {
    onClose();
  }

  useEffect(() => {
    const initList = async () => {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-invitation.php`,
            {
              params: {
                order: 'check_today'
              }
            }
          )
          .then(response => {
            if (response.data) {
              const json = response.data;
              setInvitation(json.today_remain_codes);
              setLoading(false);
            }
          });
      } catch (error) {
        console.error(error);
      }
    };

    initList();
  }, []);

  const handleConnectStrava = event => {
    const hash = '0';
    window.location =
      'https://www.strava.com/oauth/authorize?client_id=62149&response_type=code&resource_state=3&approval_prompt=force&redirect_uri=https://' +
      window.location.hostname +
      '/landing?hash=' +
      hash +
      '&scope=activity:read_all,profile:read_all';
    return false;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <Paper className={classes.paper}>
        <Typography className={classes.header}>
          {t('Landing.remainInvites')}
          <Typography color="secondary" component="span">
            {invitation}
          </Typography>
        </Typography>

        {invitation > 0 ? (
          <div>
            <Box className={classes.notice}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {t('Landing.inviteCondition')}
              </pre>
            </Box>
            <Box className={classes.alert}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {t('Landing.inviteAlert')}
              </pre>
            </Box>
            <Button
              className={classes.getInvite}
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              onClick={() => handleConnectStrava()}>
              {t('Landing.getInviteCode')}
            </Button>
          </div>
        ) : (
          <Box className={classes.notice}>
            <Typography color="secondary">
              {t('Landing.todayInviteFinished')}
            </Typography>
          </Box>
        )}
      </Paper>
    </Dialog>
  );
};

InviteCodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default InviteCodeDialog;
