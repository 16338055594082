import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//
import _ from 'lodash';
import dayjs from 'dayjs';
import validate from 'validate.js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from 'utils/getImageUrl';
//
import {
  addPelotonEventApi,
  fetchPelotonURI,
  fetchSingleEventApi,
  updatePelotonEventApi
} from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import {
  Link,
  Fab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Divider,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//
import SingleMarkerMap from '../Map/SingleMarkerMap';
import CourseMap from '../Map/CourseMap';
import * as turf from '@turf/turf';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
      minimum: 1
    }
  },
  description: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  maximumUser: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: true,
      greaterThan: 1
    }
  },
  distance: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  elevation: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  pace: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const childSchema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  locationString: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  canvas: {
    backgroundColor: theme.palette.background.paper,
    padding: '16px 16px',
    border: '1px #dddddd solid',
    borderRadius: '8px',
    marginTop: '8px'
  },
  createForm: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    margin: '8px 0',
    width: 'calc(100% - 16px)'
  },
  buttonWrap: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    '& > *': {
      marginRight: '6px'
    }
  },
  button: {},
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    marginTop: '8px',
    gap: '8px',
    borderRadius: '4px',
    border: '1px dashed var(--divider, rgba(0, 0, 0, 0.12))',
    padding: '24px 16px'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  footer: {
    marginTop: '24px'
  },
  // dateTimePicker: {
  //   '& .MuiOutlinedInput-root': {
  //     '&.Mui-focused fieldset': {
  //       borderColor: '#4DB6AC' // 원하는 색상으로 변경
  //     },
  //     '&.Mui-inputLabel': {
  //       color: '#4DB6AC'
  //     }
  //   }
  // },
  image: {
    borderRadius: '6px',
    width: '100%',
    overflow: 'hidden'
  },
  imagePreviews: {
    width: '100%'
  },
  xButton: {
    color: 'white',
    position: 'absolute',
    right: '4px',
    top: '4px',
    height: '16px',
    cursor: 'pointer'
  },
  input: {
    fontSize: '16px'
  }
}));

/**
 * CreateEvent는 create와 edit 기능을 모두 지니는 컴포넌트입니다.
 * 둘 모두 팝업 형태로 사용하여 PopUpDialog를 둘러쌓여 사용중입니다.
 *
 * 이벤트 생성 버튼 클릭시 만든 이벤트 페이지로 이동합니다.
 * 이벤트 생성 취소 버튼 클릭시 handleClosePop을 통해 모달을 닫습니다.
 *
 * 이벤트 수정 버튼 클릭시 handleFetchEvent를 통해 리렌더링을 합니다.
 * 이벤트 수정 취소 버튼 클릭시 handleClosePop을 통해 모달을 닫습니다.
 *
 * 이벤트 생성 및 수정 구분 로직
 * url에 /peloton/:pelotonName/event/:eventId 형태인 경우 eventId를 통해 데이터를 불러와 이벤트 수정이 가능하도록 화면을 그립니다.
 * url에 /peloton/test1/event 형태로 eventId가 없는 경우 이벤트 생성이 가능하도록 화면을 그립니다.
 *
 */
function CreateEvent({ handleClosePop, handleFetchEvent }) {
  const history = useHistory();
  const { uriName, eventId } = useParams();

  const { t } = useTranslation(['page']);
  const classes = useStyles();

  // 실제 이미지 데이터의 핸들링을 위한 상태
  const [formImages, setFormImages] = useState([]);
  // 이미지 미리보기를 위한 상태
  const [imageThumbs, setImageThumbs] = useState([]);
  // 지도 파일을 위한 상태
  const [mapFiles, setMapFiles] = useState();
  const [formState, setFormState] = useState({
    values: {
      clubId: 0,
      name: '', //*
      description: '', //*
      status: 'OPEN',
      distance: '', // km //*
      elevation: '', // m //*
      terrain: '',
      difficulty: '',
      pace: '', // *
      minimumLevel: 0, // 고정
      maximumUser: 2, //*
      minimumUser: 0, // 고정
      isClubOnly: false, // 고정
      meetingTime: '',
      deleteImageIds: [],
      eventLocations: [
        {
          //*
          name: '',
          timeString: '', // meetingTime 과 동일하게
          locationString: '', // 광역 / 지역 순서 (ex, 서울시 송파구, 고양시 일산서구)
          latitude: 0,
          longitude: 0
        }
      ]
    },
    errors: {},
    touched: {},
    isValid: false
  });

  const [eventData, setEventData] = useState({});
  const [clubData, setClubData] = useState({});
  // 모달 컨트롤
  const [dialogOpen, setDialogOpen] = useState(false);
  // 집결지 마커
  const [currentLocation, setCurrentLocation] = useState();
  // Mapbox,  지도 데이터 상태
  const [geoJson, setGeoJson] = useState();

  /**
   * @return : 이벤트 생성 기능으로 마운트 될 경우
   */
  useEffect(() => {
    handleFetchPelotonInfo();

    // 신규 이벤트 생성
    if (typeof eventId == 'undefined') {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          meetingTime: dayjs()
            .add(1, 'hour')
            .minute(Math.ceil(new Date().getMinutes() / 5) * 5)
            .toISOString()
        }
      }));

      // 집결지 기본값 설정 (현재 위치, 없으면 서울 중심부)
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => {
            updateLocation(position.coords.latitude, position.coords.longitude);
          },
          error => {
            updateLocation(37.5665, 126.978); // 기본값으로 서울 중심부 설정
            console.error('Geolocation 정보를 가져올 수 없습니다.', error);
          }
        );
      } else {
        console.log('이 브라우저는 Geolocation을 지원하지 않습니다.');
        updateLocation(37.5665, 126.978); // 기본값으로 서울 중심부 설정
      }
    }
    // 기존 이벤트 수정
    else {
      handleFetchSingleEvent();
    }
  }, []);

  /**
   * @return : 수정 기능으로 마운트 될 경우
   */
  useEffect(() => {
    if (eventData && !_.isEmpty(eventData)) {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          name: eventData.name,
          description: eventData.description,
          status: eventData.status,
          distance: eventData.distance,
          elevation: eventData.elevation,
          terrain: eventData.terrain,
          difficulty: eventData.difficulty,
          pace: eventData.pace,
          minimumLevel: eventData.minimumLevel,
          maximumUser: eventData.maximumUser,
          minimumUser: eventData.minimumUser,
          isClubOnly: eventData.isClubOnly,
          meetingTime: dayjs(eventData.meetingTime).toISOString(),
          eventLocations: [
            {
              name: eventData.eventLocations[0].name,
              timeString: dayjs(eventData.eventLocations[0].timeString),
              locationString: eventData.eventLocations[0].locationString,
              latitude: eventData.eventLocations[0].latitude,
              longitude: eventData.eventLocations[0].longitude
            }
          ]
        }
      }));

      // 집결지 설정, 이 값을 설정해야 singleMarkerMap이 렌더링됨
      updateLocation(
        eventData.eventLocations[0].latitude,
        eventData.eventLocations[0].longitude
      );

      // 코스파일이 있는 경우, 맵 루트 설정.
      if (eventData.eventMapFiles[0] && eventData.eventMapFiles[0].geoJson) {
        setGeoJson(JSON.parse(eventData.eventMapFiles[0].geoJson));
      }

      // 이 항목은, 해당 파일들을 originId로 그룹화 하고, 필요한 "SMALL" 사이즈를 반환합니다.
      const groupedImages = groupByOriginId(eventData?.eventThumbFiles);
      Object.entries(groupedImages).forEach(([originId, images]) => {
        const filePath = getImageUrl(images, 'SMALL');
        setImageThumbs(currentFiles => [
          ...currentFiles,
          {
            filePath: filePath,
            originId: originId
          }
        ]);
      });
    }
  }, [eventData]);

  // originId를 key로 하여, prop으로 들어오는 이미지 배열을 그룹화 합니다.
  const groupByOriginId = imageArray => {
    return imageArray.reduce((acc, image) => {
      const key = image.originId;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(image);
      return acc;
    }, {});
  };

  // 입력 validation
  useEffect(() => {
    const errors = validate(formState.values, schema);
    const childObjectErrors = validate(
      formState.values.eventLocations[0],
      childSchema
    );

    setFormState(formState => ({
      ...formState,
      isValid: errors || childObjectErrors ? false : true,
      errors: errors || childObjectErrors || {}
    }));
  }, [formState.values]);

  const hasError = field => {
    return formState.touched[field] && formState.errors[field];
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (name.startsWith('eventLocations')) {
      const field = name.split('.')[1]; // 예: "eventLocations.name"
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          eventLocations: prevFormState.values.eventLocations.map(
            (location, index) =>
              // 여기서는 첫 번째 요소만 업데이트하도록 설정했습니다.
              // 다른 로직이 필요한 경우 수정해야 합니다.
              index === 0 ? { ...location, [field]: value } : location
          )
        }
      }));
    }
    // 다른 필드를 변경하는 경우
    else {
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          [name]: value
        }
      }));
    }
  };

  // 클럽 정보 조회
  const handleFetchPelotonInfo = async () => {
    try {
      const response = await fetchPelotonURI(uriName);

      // 관리자가 아닌경우 리다이렉트
      const myClubRole = response.myClubUserResponse?.clubRole;
      if (myClubRole !== 'ADMIN' && myClubRole !== 'MANAGER') {
        history.push(`/peloton/${response.uriName}`);
      }

      setClubData(response);

      // 클럽 정보를 가져온 후, formState에 클럽 ID를 설정
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          clubId: response.id
        }
      }));
    } catch (err) {
      console.error(err);
    }
  };

  // 이벤트 정보 조회
  const handleFetchSingleEvent = async () => {
    try {
      const response = await fetchSingleEventApi(eventId);
      setEventData(response.data.response);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @return : 이미지 핸들러
   */
  const imageInputRef = useRef(null);
  const mapFileInputRef = useRef(null);

  // 이미지 업로드 핸들러
  const handleImageUpload = async event => {
    const uploadedFiles = Array.from(event.target.files);
    // "sample.jpeg"의 형태로는 사용할 수 없어, URI 형태로 변환해주는 작업을 진행합니다. imagePreviewMaker()
    const newImageThumbs = await imagePreviewMaker(uploadedFiles);

    setFormImages(currentFiles => {
      const updatedFiles =
        currentFiles && Array.isArray(currentFiles)
          ? [...currentFiles, ...uploadedFiles.map(file => ({ files: file }))]
          : uploadedFiles.map(file => ({ files: file }));
      return updatedFiles;
    });
    setImageThumbs(currentThumbs => [
      ...currentThumbs,
      ...newImageThumbs.map(uri => ({ filePath: uri, originId: null }))
    ]);
  };

  // 사용할 수 있는 URI로 이미지 file path를 변환해줍니다.
  const imagePreviewMaker = async files => {
    try {
      const newImageURIs = await Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
      return newImageURIs;
    } catch (error) {
      console.error('이미지를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  // 이미지 삭제 핸들러
  const handleRemoveImage = (originId, index) => {
    // formState에 담겨 서버로 보내지는 '삭제된 이미지의 id'를 setState합니다.
    setFormState(prevState => {
      const deleteImageIds = Array.isArray(prevState.values.deleteImageIds)
        ? prevState.values.deleteImageIds
        : [];
      if (originId) {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            // 이전 deleteImageIds 배열에 originId 추가
            deleteImageIds: [...deleteImageIds, originId]
          }
        };
      } else {
        return prevState;
      }
    });
    // originId가 존재하는 기존 게시글의 이미지를 삭제할 경우
    if (originId) {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter(image => image.originId !== originId)
      );
      setFormImages(currentFiles =>
        currentFiles.filter(image => image.originId !== originId)
      );
    }
    // 신규 이미지는 대상의 index를 key로 하여 삭제를 진행
    else {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter((_, i) => i !== index)
      );
      setFormImages(currentFiles => currentFiles.filter((_, i) => i !== index));
    }
  };

  // 연월일시 포매터
  const handleDateAndTime = newValue => {
    if (newValue) {
      // Dayjs 객체를 ISO 8601 문자열로 변환
      const formattedDate = newValue.toISOString();
      setFormState(prevFormState => ({
        ...prevFormState,
        values: {
          ...prevFormState.values,
          meetingTime: formattedDate
        }
      }));
    }
  };

  // 맵파일 등록
  const handleMapUploadFile = event => {
    const file = event.target.files[0];
    file.extension = file.name.split('.').pop();
    if (file.extension === 'gpx' || file.extension === 'tcx') {
      setMapFiles({ mapFiles: file });
      const coordinates = extractGPSCoordinatesFromGPX(file);

      coordinates.then(pData => {
        const polylineData = [];
        pData.forEach(l => {
          polylineData.push([Number(l.lon), Number(l.lat), l.ele]);
        });

        const newGeoJson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [...polylineData]
              }
            }
          ]
        };
        setGeoJson(newGeoJson);
        getDistanceElevationGain(newGeoJson);
      });
    }
  };

  const getDistanceElevationGain = newGeoJson => {
    // 거리 계산
    const distance = turf.length(newGeoJson, { units: 'kilometers' });

    // 상승 고도 계산
    let totalElevationGain = 0;
    const coords = newGeoJson.features[0].geometry.coordinates;
    for (let i = 1; i < coords.length; i++) {
      const elevationGain = coords[i][2] - coords[i - 1][2];
      if (elevationGain > 0) {
        totalElevationGain += elevationGain;
      }
    }

    if (distance > 0) {
      setFormState(prevState => ({
        ...prevState,
        values: {
          ...prevState.values,
          distance: distance.toFixed(2),
          elevation: totalElevationGain.toFixed(0)
        }
      }));
    }
  };

  function extractGPSCoordinatesFromGPX(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject('No file provided');
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        const content = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'application/xml');

        // GPX 파일 좌표 추출
        if (file.extension === 'gpx') {
          const trkpts = xmlDoc.getElementsByTagName('trkpt');
          const coordinates = Array.from(trkpts).map(pt => ({
            lat: pt.getAttribute('lat'),
            lon: pt.getAttribute('lon'),
            ele: pt.getElementsByTagName('ele')[0]
              ? parseFloat(pt.getElementsByTagName('ele')[0].textContent)
              : null
          }));
          resolve(coordinates);
        }
        // TCX 파일 좌표 추출
        else if (file.extension === 'tcx') {
          const positions = xmlDoc.getElementsByTagName('Trackpoint');
          const coordinates = Array.from(positions).map(pos => ({
            lat: pos.getElementsByTagName('LatitudeDegrees')[0].textContent,
            lon: pos.getElementsByTagName('LongitudeDegrees')[0].textContent,
            ele: pos.getElementsByTagName('AltitudeMeters')[0]
              ? parseFloat(
                  pos.getElementsByTagName('AltitudeMeters')[0].textContent
                )
              : null
          }));
          resolve(coordinates);
        }
      };
      reader.onerror = e => {
        reject('Error reading file');
      };
      reader.readAsText(file);
    });
  }

  // 이벤트 수정
  const handleUpdateEvent = async event => {
    event.preventDefault();
    try {
      const requestBody = formState.values;
      const response = await updatePelotonEventApi(
        eventId,
        requestBody,
        formImages,
        mapFiles
      );

      if (response) {
        handleFetchEvent();
        handleClosePop();
      }
      // }
    } catch (err) {
      console.error(err);
    }
  };

  // 이벤트 생성
  const handleCreateEvent = async event => {
    event.preventDefault();
    try {
      const requestBody = formState.values;
      const response = await addPelotonEventApi(
        requestBody,
        formImages,
        mapFiles
      );

      if (response) {
        history.push(
          `/peloton/${clubData.uriName}/event/${response.data.response}`
        );
      }
      // }
    } catch (err) {
      console.error(err);
    }
  };

  // 집결지 콜백
  const updateLocation = (newLatitude, newLongitude) => {
    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        eventLocations: prevState.values.eventLocations.map((location, index) =>
          index === 0
            ? { ...location, latitude: newLatitude, longitude: newLongitude }
            : location
        )
      }
    }));
    setCurrentLocation({
      latitude: newLatitude,
      longitude: newLongitude
    });
  };

  return (
    <div className={classes.root}>
      <Box className={classes.canvas}>
        <form className={classes.createForm}>
          <TextField
            className={classes.textField}
            error={hasError('name')}
            fullWidth
            helperText={
              hasError('name')
                ? formState.errors.name[0]
                : '이벤트의 제목을 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'제목'}
            name="name"
            onChange={handleChange}
            // onKeyPress={handleKeyPress}
            type="text"
            value={formState.values.name || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError('description')}
            fullWidth
            helperText={
              hasError('description')
                ? formState.errors.description[0]
                : '이벤트의 내용을 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'설명'}
            name="description"
            multiline
            rows={4}
            onChange={handleChange}
            // onKeyPress={handleKeyPress}
            type="text"
            value={formState.values.description || ''}
            variant="outlined"
          />
          <div style={{ width: 'calc(100% - 16px)', marginBottom: '8px' }}>
            <DemoContainer components={['DateTimePicker']}>
              <DemoItem>
                <DateTimePicker
                  disablePast
                  sx={{ width: '100%' }}
                  fullWidth
                  helperText={
                    hasError('meetingTime')
                      ? formState.errors.meetingTime[0]
                      : '일정을 선택하세요.'
                  }
                  label="날짜와 시간"
                  name="meetingTime"
                  value={dayjs(formState.values?.meetingTime)}
                  onChange={newValue => handleDateAndTime(newValue)}
                  variant="outlined"
                  minDateTime={dayjs()
                    .add(1, 'hour')
                    .second(0)
                    .millisecond(0)} // 현재시간 +1 이상?
                  ampm={false} // 24시간 형식 사용
                  format="YYYY-MM-DD HH:mm" // 날짜 형식 지정
                />
              </DemoItem>
            </DemoContainer>
          </div>
          <TextField
            className={classes.textField}
            error={hasError('locationString')}
            fullWidth
            helperText={
              hasError('locationString')
                ? formState.values.eventLocations[0]?.locationString
                : '집결할 지역을 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'지역'}
            name="eventLocations.locationString"
            onChange={handleChange}
            placeholder="ex) 서울특별시 용산구"
            // onKeyPress={handleKeyPress}
            type="text"
            value={formState.values.eventLocations[0]?.locationString || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError('eventLocations')}
            fullWidth
            helperText={
              hasError('eventLocations')
                ? formState.values.eventLocations[0]?.name
                : '집결할 장소를 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'집결지'}
            name="eventLocations.name"
            onChange={handleChange}
            placeholder="ex) 남산 약수터"
            type="text"
            value={formState.values.eventLocations[0]?.name || ''}
            variant="outlined"
          />
          <div style={{ width: 'calc(100% - 16px)' }}>
            {currentLocation && (
              <SingleMarkerMap
                updateLocation={updateLocation}
                initialMarker={currentLocation}
              />
            )}
          </div>
          <FormHelperText style={{ width: '100%', padding: '0 24px' }}>
            집결지 위치를 핀으로 표시해주세요.
          </FormHelperText>

          <TextField
            className={classes.textField}
            error={hasError('maximumUser')}
            fullWidth
            helperText={
              hasError('maximumUser')
                ? formState.errors.maximumUser[0]
                : '이벤트에 참여할 수 있는 최대 인원수를 선택하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'최대 참여자 수'}
            name="maximumUser"
            onChange={handleChange}
            placeholder="명"
            // onKeyPress={handleKeyPress}
            type="number"
            value={formState.values.maximumUser || ''}
            variant="outlined"
          />
          <FormControl
            variant="outlined"
            fullWidth
            style={{ width: 'calc(100% - 16px)' }}>
            <InputLabel id="pace">이벤트 성격</InputLabel>
            <Select
              labelId="pace"
              id="pace"
              size="medium"
              name="pace"
              value={formState.values.pace}
              label="이벤트 성격"
              onChange={handleChange}>
              <MenuItem value={'BEGINNER'}>입문</MenuItem>
              <MenuItem value={'RECOVERY'}>리커버리</MenuItem>
              <MenuItem value={'INTERMEDIATE'}>중급</MenuItem>
              <MenuItem value={'ADVANCED'}>상급</MenuItem>
              <MenuItem value={'ELITE'}>최상급</MenuItem>
            </Select>
            <FormHelperText style={{ width: '100%' }}>
              이 이벤트의 성격을 선택하세요.
            </FormHelperText>
          </FormControl>
          <Container
            className={classes.uploadBox}
            onClick={() => mapFileInputRef.current.click()}
            style={{ cursor: 'pointer' }}>
            <UploadFileIcon style={{ color: '#4db6ac' }} />
            <div className={classes.textContainer}>
              <Typography
                align="center"
                color="primary"
                variant="body1"
                style={{ textDecoration: 'underline', marginRight: '2px' }}>
                클릭하여
              </Typography>
              <input
                id="upload-input"
                type="file"
                accept=".gpx,.tcx"
                style={{ display: 'none' }}
                ref={mapFileInputRef}
                onChange={handleMapUploadFile}
              />
              <Typography align="center" color="textSecondary" variant="body1">
                코스파일 업로드
              </Typography>
            </div>
            <Typography align="center" color="textSecondary" variant="body1">
              GPX, TCX (max. 3MB)
            </Typography>
          </Container>
          <FormHelperText style={{ width: '100%', padding: '0 24px' }}>
            이벤트의 코스파일을 등록하세요.
          </FormHelperText>
          {geoJson && (
            <div style={{ width: 'calc(100% - 16px)', margin: '8px 0px' }}>
              <CourseMap geoJson={geoJson} />
            </div>
          )}
          <TextField
            className={classes.textField}
            error={hasError('distance')}
            fullWidth
            helperText={
              hasError('distance')
                ? formState.errors.distance[0]
                : '이벤트에서 예상되는 거리를 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'거리(km)'}
            name="distance"
            onChange={handleChange}
            placeholder="km"
            // onKeyPress={handleKeyPress}
            type="text"
            value={formState.values.distance || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError('elevation')}
            fullWidth
            helperText={
              hasError('elevation')
                ? formState.errors.elevation[0]
                : '이벤트에서 예상되는 획득 고도를 입력하세요.'
            }
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
            label={'고도(m)'}
            name="elevation"
            onChange={handleChange}
            placeholder="m"
            // onKeyPress={handleKeyPress}
            type="text"
            value={formState.values.elevation || ''}
            variant="outlined"
          />
          {imageThumbs.length === 0 ? (
            <>
              <Container
                className={classes.uploadBox}
                onClick={() => imageInputRef.current.click()}
                style={{ cursor: 'pointer' }}>
                <UploadFileIcon style={{ color: '#4db6ac' }} />
                <div className={classes.textContainer}>
                  <Typography
                    align="center"
                    color="primary"
                    variant="body1"
                    style={{ textDecoration: 'underline', marginRight: '2px' }}>
                    클릭하여
                  </Typography>
                  <input
                    id="upload-input"
                    type="file"
                    accept="image/.JPG, .JPEG, .PNG"
                    style={{ display: 'none' }}
                    ref={imageInputRef}
                    onChange={handleImageUpload}
                  />
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1">
                    이벤트 이미지 업로드
                  </Typography>
                </div>
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body1">
                  PNG, JPG, JPEG (max. 3MB)
                </Typography>
              </Container>
              <FormHelperText style={{ width: '100%', padding: '0 24px' }}>
                이벤트의 대표이미지를 등록하세요. (1280*720px 권장)
              </FormHelperText>
            </>
          ) : (
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                  alignItems: 'center',
                  width: '100%',
                  padding: '8px'
                }}>
                {imageThumbs &&
                  imageThumbs.length > 0 &&
                  imageThumbs.map((image, idx) => {
                    return (
                      <Box
                        key={idx}
                        style={{
                          position: 'relative',
                          marginRight: '8px',
                          display: 'flex',
                          flexDirection: 'row',
                          width: '23%',
                          alignItems: 'center',
                          overflow: 'hidden',
                          height: '100%'
                        }}>
                        <img
                          key={image.id}
                          className={classes.image}
                          src={image.filePath}
                          alt={`imagePreview ${idx}`}
                        />
                        <HighlightOffIcon
                          onClick={() => handleRemoveImage(image.originId, idx)} // originId와 idx(인덱스)를 전달
                          className={classes.xButton}
                        />
                      </Box>
                    );
                  })}
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center'
                }}
                className={classes.imagePreviews}>
                <Fab
                  color="primary"
                  size="small"
                  style={{
                    display: 'flex',
                    marginLeft: '8px'
                  }}
                  onClick={() => imageInputRef.current.click()}>
                  <input
                    id="upload-input"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={imageInputRef}
                    onChange={handleImageUpload}
                  />
                  <AddCircleIcon style={{ color: 'white' }} />
                </Fab>
              </Box>
            </>
          )}
          <Box className={classes.footer}>
            <Typography align="center" color="textSecondary" variant="body2">
              계속하면{' '}
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/community-guideline"
                underline="always"
                target="_blank">
                라이덕 커뮤니티 가이드라인
              </Link>{' '}
              및 <br />
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/privacy"
                underline="always"
                target="_blank">
                개인정보 처리방침
              </Link>
              을 읽고 동의한 것으로 간주합니다. <br />
            </Typography>
            <div className={classes.buttonWrap}>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                size="large"
                onClick={handleClosePop}
                variant="text">
                취소
              </Button>
              {eventId ? (
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  onClick={handleUpdateEvent}
                  variant="contained">
                  이벤트 수정
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  onClick={handleCreateEvent}
                  variant="contained">
                  이벤트 생성
                </Button>
              )}
            </div>
          </Box>
        </form>
      </Box>
      <Dialog
        open={dialogOpen}
        // onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <>
          <DialogTitle id="alert-dialog-title" text="h5">
            이벤트를 생성 하시겠습니까?
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              text="body1"
              color="textPrimary">
              {''}
            </DialogContentText>
            <Divider />
            <br />
            <DialogContentText
              id="alert-dialog-description"
              text="small"
              color="textSecondary">
              {/* 이 펠로톤에서 활동하는 동안 닉네임, FTP (W/Kg), wTSS, 라이딩 활동기록이 이 펠로톤의 멤버들에게 공개됩니다. */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="secondary">
              취소
            </Button>
            <Button
              onClick={handleCreateEvent}
              Size="medium"
              State="enabled"
              variant="text"
              autoFocus
              color="primary">
              이벤트 생성
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
}
export default withRouter(CreateEvent);

CreateEvent.propTypes = {
  history: PropTypes.object
};
