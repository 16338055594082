import React, { useState, useEffect } from 'react';
import ReactDOM, { render } from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

//import './App.css';

/*
import {
//  BikeUI,		
//  Budget,
//  TotalUsers,
//  HowToUse,
//  Segment,
//  TotalProfit,
//  LatestSales,
//  UsersByDevice,
//  LatestProducts,
//  LatestOrders
} from './Tab';
*/

import _ from 'lodash';
import axios from 'axios';
import auth from '../../utils/auth';

//import Outdoor from './Outdoor';
import Indoor from './Indoor';
// import Workout from './Workout';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  }
}));

const TrainingTab = () => {
  const classes = useStyles();
  const extraInfo = auth.getExtraInfo()
    ? auth.getExtraInfo()
    : { pretest: 'none' };
  const gears = JSON.parse(extraInfo.bikes_json);

  const [tabSelect, setTabSelect] = React.useState(0);
  //  const [gearTab, setGearTab ] = React.useState(0);

  useEffect(() => {}, []);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  const [viewport, setViewport] = useState({
    latitude: 37.5326,
    longitude: 126.924612,
    width: '100%',
    height: '500px',
    zoom: 10
  });

  function TabContent(props) {
    const tabSelect = props.tabSelect;

    if (tabSelect == 0) {
      return <Indoor />;
      // } else if(tabSelect == 1) {
      //   return (
      //     <Workout />
      //   );
    }
  }

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={'Indoor'} />
        {/* <Tab key={1} label={"Workout"} />	 */}
      </Tabs>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      <Box className={classes.tabPanel}>
        <TabContent tabSelect={tabSelect} />
      </Box>
    </div>
  );
};

export default TrainingTab;
